import * as yup from "yup";

export default async function store(data) {
  const schema = yup.object().shape({
    razao_social: yup.string().required("Obrigatório"),
    nome_fantasia: yup.string().required("Obrigatório"),
    nome_responsavel: yup.string().required("Obrigatório"),
    cnpj: yup.string().required("Obrigatório"),
    email: yup.string().email("E-mail inválido!").required("Obrigatório"),
    cidade: yup.string().required("Obrigatório"),
    cep: yup.string().required("Obrigatório"),
    logradouro: yup.string().required("Obrigatório"),
    numero: yup.string().required("Obrigatório"),
    bairro: yup.string().required("Obrigatório"),
    estado: yup.string().required("Obrigatório"),
    telefone_comercial: yup.string().required("Obrigatório"),
    percentual: yup.string().required("Obrigatório"),
    superior: yup.string().required("Obrigatório"),
  });

  await schema.validate(data, {
    abortEarly: false,
  });
}
