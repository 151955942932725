import React, { useRef, useState } from 'react';
import { Button, Row, Col, Container } from 'reactstrap';
import cep from 'cep-promise';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useHistory, useLocation } from 'react-router-dom';

import CartaoEmpresarial from '../../components/cartaoEmpresarial';
import { Form } from '@unform/web';
import Input from '../../components/unformInput';
import MaskedInput from '../../components/unformMaskedInput';
import { GoChevronDown } from 'react-icons/go';
import api from '../../services/api';
import validator from '../../validations/cartaoEmpresarial';
import { telefoneCelularMask } from '../../utils/masks';
import { useWhiteLabel } from '../../contexts/whitelabel';

function CartaoEscolhido() {
  const formRef = useRef(null);
  const { dadosWhiteLabel } = useWhiteLabel();
  const history = useHistory();

  async function handleSubmit(data) {
    try {
      formRef.current.setErrors({});
      await validator(data);

      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });

      await api.post('/email-cartao-empresarial', {
        ...data,
        whitelabel: dadosWhiteLabel?.url_whitelabel,
      });
      Swal.close();
      window.scrollTo(0, 0);
      return history.push(
        `/${
          dadosWhiteLabel?.url_whitelabel || 'poupa-club'
        }/obrigado-pelo-interesse`
      );
    } catch (err) {
      const validationErrors = {};
      Swal.close();
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        console.log(validationErrors)
        formRef.current.setErrors(validationErrors);
        toast.error('Verifique os campos em vermelho!');
      } else {
        // console.log(err);
        const error =
          err?.response?.data?.error || 'Ocorreu um erro, tente novamente!';
        toast.error(error);
      }
    }
  }

  return (
    <>
      <Container className='mt-6'>
        <br />
        <Row>
          <Col lg={4}>
            <Row>
              <Col lg={12}>
                <div>
                  <CartaoEmpresarial />
                </div>
              </Col>
            </Row>
          </Col>

          <Col lg={4} className='my-1 txt-primary'>
            <h2
              className='h2-title'
              style={{
                fontSize: 45,
                color: dadosWhiteLabel
                  ? dadosWhiteLabel?.whitelabel_text_color_1
                  : '',
              }}
            >
              Vantagens <br /> de ser nosso parceiro(a)
            </h2>
            <GoChevronDown
              style={{
                color: dadosWhiteLabel
                  ? dadosWhiteLabel?.whitelabel_arrows
                  : '',
              }}
              size={40}
              color='orange'
              className='mt-1 mb-1 ml-2'
            />
            <p className='txt-poupaclub-2'>
              <strong>
                Possuimos mais de 3 mil clientes ativos que utilizam o cartão de
                descontos
              </strong>
              . É uma ótima alternativa para aumentar suas vendas, atrair e
              fidelizar consumidores. Incentive os compradores de primeira
              viagem a comprar ao mesmo tempo que turbina suas vendas como um
              todo.
              <br />
              <br />
              <strong>
                Sua empresa, organização ou escola pode ganhar um diferencial ao
                oferecer vantagens e descontos exclusivos!
              </strong>
            </p>
          </Col>

          <Col lg={4}>
            <Form
              className='form-poupaclub mb-1'
              ref={formRef}
              onSubmit={handleSubmit}
            >
              <Row className='justify-center'>
                <Col>
                  <h3
                    className='txt-secondary bold txt-solicite'
                    style={{
                      color: dadosWhiteLabel
                        ? dadosWhiteLabel?.whitelabel_text_color_4
                        : '',
                    }}
                  >
                    Solicite o contato de nossa equipe
                  </h3>
                </Col>
              </Row>

              <Row>
                <Col lg={12}>
                  <Input
                    className='input-field'
                    name='empresa'
                    placeholder='Empresa'
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <Input
                    className='input-field'
                    name='responsavel'
                    placeholder='Responsável'
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <MaskedInput
                    className='input-field'
                    name='telefone'
                    placeholder='Telefone ou Celular com DDD'
                    mask={telefoneCelularMask}
                    removeEspecial
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <Input
                    className='input-field'
                    placeholder='E-mail'
                    name='email'
                  />
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <Input
                    className='input-field'
                    type='number'
                    placeholder='N° funcionários'
                    name='numero_funcionários'
                  />
                </Col>
              </Row>
              <Row>
                <Col className='justify-center mt-3'>
                  <Button
                    className='btn-pill bold mt-1 btn-enviar-agendamento'
                    color='secondary'
                    size='sm'
                  >
                    Enviar
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </Container>
    </>
  );
}

export default CartaoEscolhido;
