import {
  Marker,
  GoogleMap,
  useLoadScript,
  InfoWindow,
} from "@react-google-maps/api";
import {
  Container,
  Col,
  Row,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  CardImg,
  Button,
  Input,
} from "reactstrap";
import { FaSearch, FaGlobe, FaWhatsapp } from "react-icons/fa";
import { GrMail, GrFacebook, GrInstagram, GrLinkedin } from "react-icons/gr";
import api from "../../services/api";
import Placeholder from "./PlaceholderParceiro";
import { useHistory } from "react-router-dom";
import Carousel from "react-elastic-carousel";
import InfiniteScroll from "react-infinite-scroll-component";
import ReactHtmlParser from "react-html-parser";
import { useEffect, useRef, useState } from "react";
import iconeFiltro from "../../assets/icone_filtro.png";
import { useAuth } from "../../contexts/auth";
import segmentos from "../../utils/segmentos";
import { useWhiteLabel } from "../../contexts/whitelabel";
import formatPhone from "../../utils/formatPhone";

const icons = {
  user: "M17.084 15.812a7 7 0 1 0-10.168 0A5.996 5.996 0 0 1 12 13a5.996 5.996 0 0 1 5.084 2.812zM12 23.728l-6.364-6.364a9 9 0 1 1 12.728 0L12 23.728zM12 12a3 3 0 1 1 0-6 3 3 0 0 1 0 6z",
  parceiro:
    "M320 384H128V224H64v256c0 17.7 14.3 32 32 32h256c17.7 0 32-14.3 32-32V224h-64v160zm314.6-241.8l-85.3-128c-6-8.9-16-14.2-26.7-14.2H117.4c-10.7 0-20.7 5.3-26.6 14.2l-85.3 128c-14.2 21.3 1 49.8 26.6 49.8H608c25.5 0 40.7-28.5 26.6-49.8zM512 496c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V224h-64v272z",
};

const mapContainerStyle = {
  height: "400px",
  width: "100%",
};

function ProximosDeMim() {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: "AIzaSyDPtixtR-BJZuY7cz9bgdIS_6_1GyqUNUg",
  });
  const { signed } = useAuth();
  const searchRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [coords, setCoords] = useState(() =>
    localStorage.coords ? JSON.parse(localStorage.coords) : null
  );
  const [activeMarker, setActiveMarker] = useState(null);
  const [data, setData] = useState(null);

  const [segmento, setSegmento] = useState("");

  const history = useHistory();
  const { dadosWhiteLabel } = useWhiteLabel();

  const saveLocation = ({ coords }) => {
    const obj = {
      latitude: coords.latitude,
      longitude: coords.longitude,
    };
    localStorage.setItem("coords", JSON.stringify(obj));
    setCoords(obj);
    filtrarConsulta("");
  };

  const getLocation = () =>
    navigator.geolocation.getCurrentPosition(saveLocation);

  let consultaGenerica;

  async function buscarParceiros(value) {
    return await new Promise((resolve) => {
      clearTimeout(consultaGenerica);

      consultaGenerica = setTimeout(() => {
        resolve(filtrarConsulta(value));
      }, 300);
    });
  }

  async function filtrarConsulta(value) {
    const query = `?nome=${value || ""}&latitude=${coords.latitude}&longitude=${
      coords.longitude
    }&segmento=${segmento || ""}`;

    const { data: res } = await api.get("/parceiros-proximos" + query);
    if (isLoading) {
      setIsLoading(false);
    }
    setData(res);
    setCurrentPage(1);
  }

  async function consultarParceiros() {
    const nome = searchRef.current.value;
    const query = `?nome=${nome || ""}&latitude=${coords.latitude}&longitude=${
      coords.longitude
    }&segmento=${segmento || ""}`;

    const { data: res } = await api.get("/parceiros-proximos" + query);

    if (res) {
      setData({
        ...data,
        parceiros: data.parceiros.concat(res.parceiros),
      });
    }
    setCurrentPage(currentPage + 1);
  }

  const handleActiveMarker = (marker) => {
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
  };

  const handleOnLoad = (map) => {
    const bounds = new window.google.maps.LatLngBounds();
    data?.parceiros?.forEach(({ position }) => bounds.extend(position));
    map.fitBounds(bounds);
  };

  useEffect(() => {
    if (coords) {
      filtrarConsulta("");
    } else {
      getLocation();
    }
  }, [coords, segmento]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main>
      {signed ? (
        <Container>
          <Row className="mt-3 align-flex-end">
            {/* <Col lg={1} className="mb-3">
              <div className="d-flex align-center">
                <span className="d-flex align-center txt-tertiary">
                  <img
                    src={iconeFiltro}
                    alt="icone de filtros"
                    className="mr-1"
                    style={{
                      maxHeight: "34px",
                    }}
                  />
                  <h6
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Filtros
                  </h6>
                </span>
              </div>
            </Col> */}
            <Col lg={2} className="mb-3">
              <Input
                ref={searchRef}
                type="select"
                className="input-field-select"
                onChange={(e) => setSegmento(e.target.value)}
              >
                <option value="" selected>
                  Segmento
                </option>
                {segmentos.map((segmento) => (
                  <option value={segmento.url}>{segmento.nome}</option>
                ))}
              </Input>
            </Col>
            <Col lg={4} className="mb-3">
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText
                    className={
                      dadosWhiteLabel
                        ? "access-icon-generic mb-0"
                        : "access-icon mb-0"
                    }
                  >
                    <FaSearch
                      className={
                        dadosWhiteLabel ? "icon-color-generic" : "icon-color"
                      }
                    />
                  </InputGroupText>
                </InputGroupAddon>
                <input
                  placeholder="Busque pelo nome do parceiro"
                  type="text"
                  className={
                    dadosWhiteLabel
                      ? "access-input-generic font-400 form-control"
                      : "access-input font-400 form-control"
                  }
                  ref={searchRef}
                  onChange={(e) => buscarParceiros(e.target.value)}
                />
              </InputGroup>
            </Col>
          </Row>

          <Row className="mb-30 busca-segmento">
            <Col lg={12} className="d-flex align-center">
              <h4
                className="bold txt-primary"
                style={{
                  color: dadosWhiteLabel
                    ? dadosWhiteLabel?.whitelabel_text_color_1
                    : "",
                }}
              >
                Próximos a mim
              </h4>
              <div className="hr" />
            </Col>
            <Col lg={10} md={12} className="offset-lg-1 offset-md-0">
              <Col
                lg={6}
                md={7}
                sm={7}
                className="offset-lg-4 offset-md-5 offset-sm-5 sm-hidden mt-3"
              >
                <h4
                  className="bold txt-primary"
                  style={{
                    color: dadosWhiteLabel
                      ? dadosWhiteLabel?.whitelabel_text_color_1
                      : "",
                  }}
                >
                  Unidades
                </h4>
              </Col>
            </Col>
            <Col lg={10} md={12} className="offset-lg-1 offset-md-0 mt-3">
              {!isLoading ? (
                <>
                  <InfiniteScroll
                    dataLength={data?.parceiros?.length || 0} //This is important field to render the next data
                    next={consultarParceiros}
                    hasMore={data?.pages > currentPage}
                    loader={<Placeholder />}
                  >
                    {data?.parceiros?.map((m, i) => (
                      <>
                        <Row className="mt-60 mb-30" key={i}>
                          <Col lg={5} sm={12}>
                            <h5 className="txt-tertiary bold text-center mb-3">
                              {m.descricao}
                            </h5>
                            <Carousel itemsToShow={1}>
                              {m.imagem?.url && (
                                <div className="img-parceiro-segmento align-end">
                                  <span>
                                    <CardImg
                                      src={m.imagem.url}
                                      alt={m.nome_fantasia}
                                    />
                                  </span>
                                </div>
                              )}
                              {m.imagem_parceiro_1_id?.url && (
                                <div className="img-parceiro-segmento align-end">
                                  <span>
                                    <CardImg
                                      src={m.imagem_parceiro_1_id?.url}
                                      alt={m.nome_fantasia}
                                    />
                                  </span>
                                </div>
                              )}
                              {m.imagem_parceiro_2_id?.url && (
                                <div className="img-parceiro-segmento align-end">
                                  <span>
                                    <CardImg
                                      src={m.imagem_parceiro_2_id?.url}
                                      alt={m.nome_fantasia}
                                    />
                                  </span>
                                </div>
                              )}
                              {m.imagem_parceiro_3_id?.url && (
                                <div className="img-parceiro-segmento align-end">
                                  <span>
                                    <CardImg
                                      src={m.imagem_parceiro_3_id?.url}
                                      alt={m.nome_fantasia}
                                    />
                                  </span>
                                </div>
                              )}
                            </Carousel>
                            <hr />
                            <Row>
                              <Col lg={12} md={12} sm={12}>
                                <h5
                                  className="txt-secondary bold"
                                  style={{
                                    color: dadosWhiteLabel
                                      ? dadosWhiteLabel?.whitelabel_text_color_2
                                      : "",
                                  }}
                                >
                                  Contatos
                                </h5>
                                <h6 className="txt-tertiary">
                                  {m.website && (
                                    <>
                                      {" "}
                                      <strong>
                                        <FaGlobe />{" "}
                                      </strong>
                                      <a
                                        href={m.website}
                                        target="_blank"
                                        rel="noreferrer"
                                        style={{ color: "gray" }}
                                      >
                                        {m.website}
                                      </a>
                                      <br />{" "}
                                    </>
                                  )}
                                  {(Number(m.telefone_comercial) > 0 ||
                                    Number(m.telefone_celular) > 0) && (
                                    <>
                                      <strong>
                                        <FaWhatsapp />{" "}
                                      </strong>
                                      {Number(m.telefone_celular) > 0 && (
                                        <a
                                          href={`https://api.whatsapp.com/send?phone=+55${m.telefone_celular}`}
                                          rel="noreferrer"
                                          target="_blank"
                                          style={{ color: "gray" }}
                                        >
                                          {formatPhone(m.telefone_celular)}
                                        </a>
                                      )}
                                      {Number(m.telefone_comercial) > 0 &&
                                        Number(m.telefone_celular) > 0 && (
                                          <> / </>
                                        )}
                                      {Number(m.telefone_comercial) > 0 &&
                                        formatPhone(m.telefone_comercial)}{" "}
                                      <br />
                                    </>
                                  )}
                                  {m.email && (
                                    <>
                                      <strong>
                                        <GrMail />{" "}
                                      </strong>
                                      {m.email}
                                      <br />
                                    </>
                                  )}
                                  <div className="mt-3">
                                    {m.facebook && (
                                      <>
                                        <a
                                          href={m.facebook}
                                          target="_blank"
                                          rel="noreferrer"
                                          style={{
                                            color: "gray",
                                            textDecoration: "none",
                                          }}
                                        >
                                          <strong>
                                            <GrFacebook />
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                          </strong>
                                        </a>
                                      </>
                                    )}
                                    {m.instagram && (
                                      <>
                                        <a
                                          href={m.instagram}
                                          target="_blank"
                                          rel="noreferrer"
                                          style={{
                                            color: "gray",
                                            textDecoration: "none",
                                          }}
                                        >
                                          <strong>
                                            <GrInstagram />
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                          </strong>
                                        </a>
                                      </>
                                    )}
                                    {m.linkedin && (
                                      <>
                                        <a
                                          href={m.linkedin}
                                          target="_blank"
                                          rel="noreferrer"
                                          style={{
                                            color: "gray",
                                            textDecoration: "none",
                                          }}
                                        >
                                          <strong>
                                            <GrLinkedin />
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                          </strong>
                                        </a>
                                      </>
                                    )}
                                  </div>
                                </h6>
                              </Col>
                            </Row>
                            {m?.cep && (
                              <Row>
                                <Col lg={12} md={12} sm={12}>
                                  <h5
                                    className="txt-secondary bold mt-3"
                                    style={{
                                      color: dadosWhiteLabel
                                        ? dadosWhiteLabel?.whitelabel_text_color_2
                                        : "",
                                    }}
                                  >
                                    Localização
                                  </h5>
                                  <h6 className="txt-tertiary">
                                    {m.logradouro}, {m.numero} {m.complemento}
                                    <br /> {m.bairro} - {m.cidade} - {m.estado}
                                    <br /> CEP: {m.cep}
                                  </h6>
                                </Col>
                              </Row>
                            )}
                          </Col>

                          <Col lg={7} md={7} sm={12}>
                            <div className="mb-1 esconto-parceiro">
                              <h5
                                className="txt-secondary bold text-center"
                                style={{
                                  color: dadosWhiteLabel
                                    ? dadosWhiteLabel?.whitelabel_text_color_2
                                    : "",
                                }}
                              >
                                {m.subtitulo}
                              </h5>
                            </div>
                            <div
                              className={`mb-5 desconto-parceiro ${
                                dadosWhiteLabel?.whitelabel_button_3 &&
                                dadosWhiteLabel?.whitelabel_button_3
                              }`}
                            >
                              <h5
                                className="txt-secondary bold text-center"
                                style={{
                                  color: dadosWhiteLabel
                                    ? dadosWhiteLabel?.whitelabel_text_color_2
                                    : "",
                                }}
                              >
                                {m.beneficio}
                              </h5>
                            </div>
                            <h5 className="txt-tertiary bold text-center mt-3">
                              Sobre o estabelecimento
                            </h5>
                            <h6 className="txt-tertiary mb-5">
                              {ReactHtmlParser(m?.sobre_estabelecimento)}
                            </h6>
                            <h5 className="txt-tertiary bold text-center mt-3">
                              Regras de utilização
                            </h5>
                            <h6 className="txt-tertiary mb-5">
                              {ReactHtmlParser(m?.regras_utilizacao)}
                            </h6>
                          </Col>
                        </Row>
                        <hr />
                      </>
                    ))}
                  </InfiniteScroll>
                </>
              ) : (
                <Placeholder />
              )}
            </Col>
            {coords && (
              <Col lg={10} md={12} className="offset-lg-1 offset-md-0 mt-3">
                {isLoaded && (
                  <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    zoom={13}
                    center={{
                      lat: coords.latitude,
                      lng: coords.longitude,
                    }}
                    options={{
                      styles: [
                        {
                          featureType: "poi",
                          elementType: "labels",
                          stylers: [{ visibility: "off" }],
                        },
                      ],
                    }}
                  >
                    {data?.parceiros?.map((parceiro) => (
                      <Marker
                        key={parceiro.id}
                        position={{
                          lat: Number(parceiro.latitude),
                          lng: Number(parceiro.longitude),
                        }}
                        onClick={() => handleActiveMarker(parceiro.id)}
                      >
                        {activeMarker === parceiro.id ? (
                          <InfoWindow
                            onCloseClick={() => setActiveMarker(null)}
                          >
                            <div>{parceiro.nome_fantasia}</div>
                          </InfoWindow>
                        ) : null}
                      </Marker>
                    ))}
                    <Marker
                      position={{
                        lat: coords.latitude,
                        lng: coords.longitude,
                      }}
                      icon={{
                        path: icons.user,
                        fillColor: "#ff9901",
                        fillOpacity: 0.8,
                        strokeWeight: 0,
                        rotation: 0,
                        scale: 1.4,
                        anchor: new window.google.maps.Point(10, 20),
                      }}
                    />
                  </GoogleMap>
                )}
              </Col>
            )}
          </Row>
        </Container>
      ) : (
        <div className="mt-5">
          <Container className="w-100 d-flex justify-content-center">
            <Row>
              <Col lg={12} md={12} sm={12}>
                <h5
                  className="bold txt-tertiary"
                  style={{
                    color: dadosWhiteLabel
                      ? dadosWhiteLabel?.whitelabel_text_color_1
                      : "",
                  }}
                >
                  Para acessar as informações dos parceiros, faça seu{" "}
                  <Button
                    color="secondary"
                    size="sm"
                    className={`btn-pill bold ${
                      dadosWhiteLabel?.whitelabel_button_1 &&
                      dadosWhiteLabel?.whitelabel_button_1
                    }`}
                    onClick={() => {
                      window.scrollTo(0, 0);
                      history.push(
                        `/${
                          dadosWhiteLabel?.url_whitelabel || "poupa-club"
                        }/login`
                      );
                    }}
                  >
                    login
                  </Button>
                </h5>
              </Col>
            </Row>
          </Container>
          <Container className="w-100 d-flex justify-content-center">
            <Row>
              <Col>
                <h5
                  className="bold txt-secondary"
                  style={{
                    color: dadosWhiteLabel
                      ? dadosWhiteLabel?.whitelabel_text_color_2
                      : "",
                  }}
                >
                  ou
                </h5>
              </Col>
            </Row>
          </Container>
          <Container className="w-100 d-flex justify-content-center">
            <Row>
              <Col>
                <h5
                  className="bold txt-tertiary"
                  style={{
                    color: dadosWhiteLabel
                      ? dadosWhiteLabel?.whitelabel_text_color_1
                      : "",
                  }}
                >
                  <Button
                    color="secondary"
                    size="sm"
                    className={`btn-pill bold ${
                      dadosWhiteLabel?.whitelabel_button_1 &&
                      dadosWhiteLabel?.whitelabel_button_1
                    }`}
                    onClick={() => {
                      window.scrollTo(0, 0);
                      history.push(
                        `/${
                          dadosWhiteLabel?.url_whitelabel || "poupa-club"
                        }/cartao`
                      );
                    }}
                  >
                    Peça agora
                  </Button>{" "}
                  seu cartão e comece a poupar
                </h5>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </main>
  );
}

export default ProximosDeMim;
