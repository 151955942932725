import React, { useRef } from 'react';
import {
  Container,
  Row,
  Col,
  Button,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import { MdEmail, MdLock } from 'react-icons/md';
import { Form } from '@unform/web';
import Input from '../../components/unformInput';
import { useAuth } from '../../contexts/auth';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

import loginValidator from '../../validations/loginValidator';
import { ValidationError } from 'yup';
import api from '../../services/api';

import { useWhiteLabel } from '../../contexts/whitelabel';
import { Link } from 'react-router-dom';

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'ml-3 btn btn-success',
    cancelButton: 'btn btn-danger',
  },
  buttonsStyling: false,
});

function Acesso(props) {
  const formRef = useRef(null);
  const { signIn } = useAuth();

  const { dadosWhiteLabel } = useWhiteLabel();

  // console.log(dadosWhiteLabel);

  async function handleSubmit(data) {
    try {
      formRef.current.setErrors({});
      await loginValidator(data);
      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      const url = dadosWhiteLabel?.url_whitelabel || 'poupa-club';

      const { data: dt } = await api.post('/sessions-verify-terms', data);
      if (dt.error) {
        swalWithBootstrapButtons
          .fire({
            title: `Aviso de primeiro acesso`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Concordo',
            cancelButtonText: 'Não',
            reverseButtons: true,
            html: `Ao continuar você concorda que leu e aceitou os nossos <a href="/${url}/termos" target="_blank">&nbsp;Termos de uso</a> e <a href="/${url}/privacidade" target="_blank">Política de privacidade</a>.`,
          })
          .then(async (result) => {
            if (result.value) {
              Swal.fire({
                title: 'Aguarde',
                allowOutsideClick: false,
                showConfirmButton: false,
                onBeforeOpen: () => {
                  Swal.showLoading();
                },
              });
              data.aceita_termos = true;
              await signIn(data);
              Swal.close();
            }
          });
      } else {
        await signIn(data);
        Swal.close();
      }
    } catch (err) {
      const validationErrors = {};
      if (err instanceof ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        Swal.close();
        formRef.current.setErrors(validationErrors);
        toast.error('Verifique os campos em vermelho!');
      } else {
        const error =
          err?.response?.data?.error || 'Ocorreu um erro tente novamente';
        Swal.fire({
          text: error,
          icon: 'error',
        });
      }
    }
  }

  return (
    <>
      <Container>
        <br />
        <br />
        <Row className='mt-6'>
          <Col lg={5} className='offset-lg-1 row-login'>
            <Row className='mb-6'>
              <Col lg={11} className='offset-lg-1'>
                <h4 className='access-title'>Login</h4>
              </Col>
            </Row>
            <Form onSubmit={handleSubmit} ref={formRef}>
              <Row>
                <Col lg={11} className='offset-lg-1'>
                  <InputGroup>
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText
                        className={
                          dadosWhiteLabel
                            ? 'access-icon-generic'
                            : 'access-icon'
                        }
                      >
                        <MdEmail
                          className={
                            dadosWhiteLabel
                              ? 'icon-color-generic'
                              : 'icon-color'
                          }
                        />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder={'E-mail'}
                      type='email'
                      name='email'
                      className={
                        dadosWhiteLabel
                          ? 'access-input-generic'
                          : 'access-input'
                      }
                    />
                  </InputGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={11} className='offset-lg-1'>
                  <InputGroup>
                    <InputGroupAddon addonType='prepend'>
                      <InputGroupText
                        className={
                          dadosWhiteLabel
                            ? 'access-icon-generic'
                            : 'access-icon'
                        }
                      >
                        <MdLock
                          className={
                            dadosWhiteLabel
                              ? 'icon-color-generic'
                              : 'icon-color'
                          }
                        />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder='Senha'
                      type='password'
                      className={
                        dadosWhiteLabel
                          ? 'access-input-generic'
                          : 'access-input'
                      }
                      name='senha'
                    />
                  </InputGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={11} className='offset-lg-1'>
                  <Button
                    className={
                      dadosWhiteLabel
                        ? `${dadosWhiteLabel?.whitelabel_button_1} btn-pill mb-1 bold`
                        : 'btn-pill mb-1 bold'
                    }
                    color='entrar'
                  >
                    ENTRAR
                  </Button>
                </Col>
              </Row>
            </Form>
            <br />
            <Row className='forgot-pass-row'>
              <Col lg={11} className='offset-lg-1'>
                <Link
                  className={
                    dadosWhiteLabel ? 'forgot-pass-generic' : 'forgot-pass'
                  }
                  to={`/${
                    dadosWhiteLabel?.url_whitelabel || 'poupa-club'
                  }/esqueceu-senha`}
                >
                  Esqueci meu login ou senha
                </Link>
              </Col>
            </Row>
            <br />
          </Col>
          <Col lg={6}></Col>
          {/* <Col lg={6}>
            {tipoDeAcesso !== 'admin' && (
              <>
                <Row>
                  <Col lg={8} className='offset-lg-1'>
                    <h4 className='access-title'>Consulta rápida</h4>
                    <div className='cpf-description mb-3'>
                      Consulte a validação do Cartão de Desconto de seu cliente
                    </div>
                  </Col>
                </Row>
                <Row className='row-login'>
                  <Col lg={8} className='offset-lg-1'>
                    <Row>
                      <Col lg={12}>
                        <InputGroup>
                          <CustomInput
                            placeholder='CPF do cliente'
                            type='text'
                            className='access-input-cpf'
                          />
                          <InputGroupAddon addonType='append'>
                            <Button className='btn-search'>
                              <MdSearch />
                            </Button>
                          </InputGroupAddon>
                        </InputGroup>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </>
            )}
          </Col> */}
        </Row>
      </Container>
      <br />
    </>
  );
}

export default Acesso;
