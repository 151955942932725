import React, { useEffect, useRef } from "react";
import { Form } from "@unform/web";
import { Row, Col, Button } from "reactstrap";
import { ValidationError } from "yup";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import cep from "cep-promise";
import { useHistory } from "react-router-dom";

import InputUnform from "../../../components/unformInput";
import MaskedInput from "../../../components/unformMaskedInput";
import UnformSelect from "../../../components/unformSelect";
import {
  cepMask,
  cpfMask,
  dateMask,
  telefoneCelularMask,
} from "../../../utils/masks";

import api from "../../../services/api";
import convertStringDate from "../../../utils/convertStringDate";
import validator from "../../../validations/criarClienteFuncionario";

let ultimoCep = "";

function AlterarDados() {
  const formRef = useRef(null);
  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
    async function getData() {
      const params = new URLSearchParams(window.location.search);
      const id = params.get("id");
      if (id) {
        Swal.fire({
          title: "Aguarde",
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });
        try {
          const { data } = await api.get(`/funcionario/${id}`);

          if (data) {
            formRef.current.setData(data);
            Swal.close();
          } else {
            Swal.close();
            toast.error("Não existem dados para serem apresentados");
          }
        } catch (e) {
          Swal.close();
          toast.error("Não foi possível carregar os dados");
        }
      }
    }

    getData();
  }, []);

  async function handleSubmit(data) {
    try {
      await validator(data);
      formRef.current.setErrors({});

      const body = {
        ...data,
        data_nascimento: convertStringDate(data.data_nascimento),
        telefone_celular: Number(data.telefone_celular) || 0,
        telefone_residencial: Number(data.telefone_residencial) || 0,
        cpf: Number(data.cpf) || 0,
        cep: Number(data.cep) || 0,
        numero: Number(data.numero) || 0,
      };

      Swal.fire({
        title: "Aguarde",
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      const params = new URLSearchParams(window.location.search);
      const id = params.get("id");
      if (id) {
        await api.post(`/clientes-funcionarios/${id}`, body);
        toast.success("Funcionário alterado com sucesso");
      } else {
        await api.post("/clientes-funcionarios", body);
        toast.success("Funcionário cadastrado com sucesso");
      }

      Swal.close();
      history.push("/acesso/funcionarios");
    } catch (err) {
      const validationErrors = {};
      Swal.close();
      if (err instanceof ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
          console.log(validationErrors[error.path]);
        });
        console.log(validationErrors);
        formRef.current.setErrors(validationErrors);
        toast.error("Verifique os campos em vermelho!");
      } else {
        toast.error(
          err?.response?.data?.error || "Ocorreu um erro, tente novamente!"
        );
      }
    }
  }

  async function buscaCep(cepValue) {
    if (cepValue) {
      if (ultimoCep !== cepValue) {
        try {
          if (cepValue?.replaceAll("_", "")?.length === 9) {
            const result = await cep(cepValue);

            formRef.current.setData({
              ...formRef.current.getData(),
              bairro: result.neighborhood,
              cidade: result.city,
              estado: result.state,
              logradouro: result.street,
            });
            ultimoCep = cepValue;
          } else if (cepValue?.length === 0) {
            formRef.current.setData({
              ...formRef.current.getData(),
              bairro: "",
              cidade: "",
              estado: "",
              logradouro: "",
            });
          }
        } catch (error) {
          formRef.current.setData({
            ...formRef.current.getData(),
            bairro: "",
            cidade: "",
            estado: "",
            logradouro: "",
          });
          toast.warn("CEP não encontrado!");
        }
      }
    }
  }

  return (
    <>
      <Row>
        <Col>
          <h5 className="txt-tertiary">Criar funcionario</h5>
        </Col>
      </Row>
      <div className="card-form">
        <Form
          onSubmit={(data) => {
            handleSubmit(data);
          }}
          ref={formRef}
        >
          <Row>
            <Col lg={4}>
              <span className="placholder-top">Nome</span>
              <InputUnform
                type="text"
                name="nome"
                className="input-field-inner"
              />
            </Col>
            <Col lg={4}>
              <span className="placholder-top">Email</span>
              <InputUnform
                type="text"
                name="email"
                className="input-field-inner"
              />
            </Col>
            <Col lg={4}>
              <span className="placholder-top">CPF</span>
              <MaskedInput
                className="input-field-inner"
                mask={cpfMask}
                removeEspecial
                name="cpf"
              />
            </Col>
          </Row>
          <Row>
            <Col lg={2}>
              <span className="placholder-top">Data nascimento</span>
              <MaskedInput
                className="input-field-inner"
                name="data_nascimento"
                mask={dateMask}
              />
            </Col>
            <Col lg={4}>
              <span className="placholder-top">Celular</span>
              <MaskedInput
                className="input-field-inner"
                name="telefone_celular"
                mask={telefoneCelularMask}
                removeEspecial
              />
            </Col>
            <Col lg={4}>
              <span className="placholder-top">Telefone</span>
              <MaskedInput
                className="input-field-inner"
                name="telefone_residencial"
                mask={telefoneCelularMask}
                removeEspecial
              />
            </Col>
            <Col lg={2}>
              <span className="placholder-top">Gênero</span>
              <UnformSelect
                name="sexo"
                className="input-field-inner"
                options={[
                  {
                    value: "",
                    label: "Gênero",
                  },
                  {
                    value: "masculino",
                    label: "Masculino",
                  },
                  {
                    value: "feminino",
                    label: "Feminino",
                  },
                  {
                    value: "outros",
                    label: "Outros",
                  },
                ]}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={5}>
              <span className="placholder-top">CEP</span>
              <MaskedInput
                className="input-field-inner"
                mask={cepMask}
                removeEspecial
                name="cep"
                onChange={(e) => {
                  buscaCep(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={7}>
              <span className="placholder-top">Endereço</span>
              <InputUnform
                placeholder=""
                type="text"
                name="logradouro"
                className="input-field-inner"
              />
            </Col>
            <Col lg={2}>
              <span className="placholder-top">Nº</span>
              <InputUnform
                placeholder=""
                type="text"
                name="numero"
                className="input-field-inner"
              />
            </Col>
            <Col lg={3}>
              <span className="placholder-top">Complemento</span>
              <InputUnform
                placeholder=""
                type="text"
                name="complemento"
                className="input-field-inner"
              />
            </Col>
          </Row>
          <Row>
            <Col lg={5}>
              <span className="placholder-top">Bairro</span>
              <InputUnform
                placeholder=""
                type="text"
                name="bairro"
                className="input-field-inner"
              />
            </Col>
            <Col lg={5}>
              <span className="placholder-top">Cidade</span>
              <InputUnform
                placeholder=""
                type="text"
                name="cidade"
                className="input-field-inner"
              />
            </Col>
            <Col lg={2}>
              <span className="placholder-top">Estado</span>
              <InputUnform
                placeholder=""
                type="text"
                name="estado"
                className="input-field-inner"
              />
            </Col>
          </Row>

          <Row className="mt-20">
            <Col className="justify-end">
              <Button color="green" className="mb-1 btn-save">
                Salvar
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}

export default AlterarDados;
