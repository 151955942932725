import { BrowserRouter, Route, Switch } from 'react-router-dom';

import { AuthProvider } from './contexts/auth';
import { WhiteLabelProvider } from './contexts/whitelabel';
import RoutesUsuario from './routesUsuario';

export default function Routes() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <WhiteLabelProvider>
          <Switch>
            <RoutesUsuario />
          </Switch>
        </WhiteLabelProvider>
      </AuthProvider>
    </BrowserRouter>
  );
}
