import { Redirect, Route, useHistory } from "react-router-dom";

import MapaParceiros from "./pages/parceiros/MapaParceiros";
import ProximosDeMim from "./pages/parceiros/proximosDeMim";
import SejaParceiro from "./pages/parceiros/sejaParceiro";
import SejaRevendedor from "./pages/revendedores/sejaRevendedor";
import Home from "./pages/Home/index";
import Sobre from "./pages/sobre/index";
import Cartao from "./pages/descontos/cartao";
import CartaoEscolhido from "./pages/cartaoEscolhido/index";
import CartaoEmpresarial from "./pages/cartaoEscolhido/empresarial";
import VendaPj from "./pages/cartaoEscolhido/vendaPj";
import AgendarExameConsulta from "./pages/agendamento/consultaExame";
import BeneficiosDesconto from "./pages/descontos/beneficiosDescontos";
import ConsultaParceiros from "./pages/parceiros/consulta";
import SegmentosParceiros from "./pages/parceiros/segmentos";
import DescontoFarmacia from "./pages/descontos/descontoFarmacia";
import Welcome from "./pages/acessos/usuario/welcome";
import DashboardRepresentante from "./pages/acessos/usuario/dashboardRepresentante";
import DashboardAdimplencia from "./pages/acessos/usuario/dashboardAdimplencia";
import DashboardProdutos from "./pages/acessos/usuario/dashboardProdutos";
import DashboardFinanceiro from "./pages/acessos/usuario/dashboardFinanceiro";
import Clientes from "./pages/acessos/usuario/clientes";
import ClientesPj from "./pages/acessos/usuario/clientesPJ";
import GerarBoleto from "./pages/acessos/usuario/gerarBoleto";
import AlterarDadosCadastrais from "./pages/acessos/usuario/alterarDados";
import AlterarDadosCadastraisAdm from "./pages/acessos/usuario/alterarDadosAdm";
import AlterarDadosCadastraisUserAdm from "./pages/acessos/usuario/alterarDadosUserAdm";
import CriarParceiro from "./pages/acessos/usuario/criarParceiro";
import CriarRevendedor from "./pages/acessos/usuario/criarRevendedor";
import ConsultarTodosAdm from "./pages/acessos/usuario/consutarTodosAdm";
import ConsultarClientesAdm from "./pages/acessos/usuario/consultarClientesAdm";
import ConsultarClientesPjAdm from "./pages/acessos/usuario/consultarClientesPjAdm";
import Faturas from "./pages/acessos/usuario/faturas";
import TrocarSenha from "./pages/acessos/usuario/trocarSenha";
import Dependentes from "./pages/acessos/usuario/dependentes";
import SolicitarRepresentacao from "./pages/acessos/usuario/solicitarRepresentacao";
import RelatorioParceiro from "./pages/acessos/usuario/relatorioParceiro";
import RelatorioAdmin from "./pages/acessos/usuario/relatorioAdmin";
import Cobrancas from "./pages/acessos/usuario/cobrancas";
import FalarComEquipe from "./pages/acessos/usuario/falarComEquipe";
import CadastrarToken from "./pages/acessos/usuario/cadastroTokenJuno";
import LinkVenda from "./pages/acessos/usuario/linkVenda";
import VinculoRepresentante from "./pages/acessos/usuario/vinculoRepresentante";
import NivelAcesso from "./pages/acessos/usuario/nivelAcesso";
import Assinatura from "./pages/acessos/usuario/assinatura";
import AssinaturaAdm from "./pages/acessos/usuario/assinaturaAdm";
import DashboardCidades from "./pages/acessos/usuario/dashboardCidades";

import WhiteLabel from "./pages/acessos/usuario/whiteLabel";
import DadosWhiteLabel from "./pages/acessos/usuario/sobreWhiteLabel";

import relatorioAdminRevendedores from "./pages/acessos/usuario/relatorioAdminRevendedores";
import PortalVendas from "./pages/acessos/usuario/portalVendasCartao";
import PortalVendasCartaoEscolhido from "./pages/acessos/usuario/portalVendasCartaoEscolhido";

import cobrancasParRev from "./pages/acessos/usuario/cobrancasParRev";

import CriarMarca from "./pages/acessos/usuario/criarMarca";
import ConsultarMarca from "./pages/acessos/usuario/consultarMarca";
import EditarMarca from "./pages/acessos/usuario/editarMarca";

import BloqueioParceiros from "./pages/acessos/usuario/bloqueioParceiros";

import ObrigadoPeloInteresse from "./pages/obrigadoInteresse";
import Acesso from "./pages/acessos/index";
import EsqueciSenha from "./pages/acessos/forgotPass";
import LayoutAcessos from "./pages/acessos/usuario/index";
import { useAuth } from "./contexts/auth";
import CartaoDigital from "./pages/acessos/usuario/cartaoDigital";
import ConsultaQr from "./pages/consultarqr/index";
import Termos from "./pages/termos/index";
import Privacidade from "./pages/privacidade/index";
import DefaultLayout from "./layouts/defaults";
import CriarCartao from "./pages/acessos/usuario/criarCartao";
import ListarCartao from "./pages/acessos/usuario/listarCartao";
import ConsultarFuncionarios from "./pages/acessos/usuario/consultarFuncionarios";
import CriarFuncionarios from "./pages/acessos/usuario/criarFuncionario";
import ListarProdutosInternos from "./pages/acessos/usuario/listarProdutosInternos";
import CriarProdutoInterno from "./pages/acessos/usuario/criarProdutoIterno";
import CobrancasInternas from "./pages/acessos/usuario/cobrancasInternas";
import Vouchers from "./pages/acessos/usuario/vouchers";
import CriarVoucher from "./pages/acessos/usuario/criarVoucher";

import Produto from "./pages/cartaoEscolhido/produto";
import { useCallback } from "react";

const acessos = [
  {
    id: 6,
    label: "Financeiro",
    value: "/acesso/dashboard/financeiro",
  },
  {
    id: 6,
    label: "Representante",
    value: "/acesso/dashboard/representante",
  },
  {
    id: 6,
    label: "Adimplência",
    value: "/acesso/dashboard/adimplencia",
  },
  {
    id: 6,
    label: "Produtos",
    value: "/acesso/dashboard/produtos",
  },
  {
    id: 6,
    label: "Cidades",
    value: "/acesso/dashboard/cidades",
  },
  {
    id: 2,
    label: "Parcelas",
    value: "/acesso/faturas",
  },
  {
    id: 50,
    label: "Funcionarios",
    value: "/acesso/funcionarios",
  },
  {
    id: 46,
    label: "Assinatura",
    value: "/acesso/assinatura",
  },
  {
    id: 45,
    label: "Cartão digital",
    value: "/acesso/cartao-digital",
  },
  {
    id: 5,
    label: "Dependentes",
    value: "/acesso/dependentes",
  },
  {
    id: 14,
    label: "Portal de vendas",
    value: "/acesso/portal-venda",
  },
  {
    id: 9,
    label: "Portal de vendas",
    value: "/acesso/portal-venda",
  },
  {
    id: 8,
    label: "Link de vendas",
    value: "/acesso/link-venda",
  },
  {
    id: 13,
    label: "Link de vendas",
    value: "/acesso/link-venda",
  },
  {
    id: 10,
    label: "Relatórios",
    value: "/acesso/relatorios",
  },
  {
    id: 15,
    label: "Relatório",
    value: "/acesso/relatorios",
  },
  {
    id: 44,
    label: "Clientes PF",
    value: "/acesso/clientes",
  },
  {
    id: 44,
    label: "Clientes PJ",
    value: "/acesso/clientes_pj",
  },
  {
    id: 7,
    label: "Clientes PF",
    value: "/acesso/clientes",
  },
  {
    id: 7,
    label: "Clientes PJ",
    value: "/acesso/clientes_pj",
  },

  {
    id: 32,
    label: "Token juno",
    value: "/acesso/cadastro-token",
  },
  {
    id: 33,
    label: "Token juno",
    value: "/acesso/cadastro-token",
  },
  {
    id: 43,
    label: "Trocar senha",
    value: "/acesso/trocar-senha",
  },
  {
    id: 16,
    label: "Solicitar representação",
    value: "/acesso/solicitar-representacao",
  },
  {
    id: 17,
    label: "Nível de acesso",
    value: "/acesso/nivel-acesso",
  },
  {
    id: 37,
    label: "Cobranças",
    value: "/acesso/cobrancas",
  },
  {
    id: 18,
    label: "Relatório",
    value: "/acesso/relatorio-adm/clientes",
  },
  {
    id: 52,
    label: "Assinaturas",
    value: "/acesso/assinatura/cliente",
  },
  {
    id: 34,
    label: "Clientes PF",
    value: "/acesso/clientes",
  },
  {
    id: 34,
    label: "Clientes PJ",
    value: "/acesso/clientes_pj",
  },
  {
    id: 35,
    label: "Consultar parceiros",
    value: "/acesso/consulta-todos/parceiros",
  },
  {
    id: 39,
    label: "Consultar marcas",
    value: "/acesso/marca-consulta",
  },
  {
    id: 19,
    label: "Relatório",
    value: "/acesso/relatorio-adm/parceiro",
  },
  {
    id: 47,
    label: "White Label",
    value: "/acesso/whitelabel",
  },
  {
    id: 48,
    label: "Bloqueio de parceiros",
    value: "/acesso/bloqueio-parceiro",
  },
  {
    id: 20,
    label: "Relatório",
    value: "/acesso/relatorio-adm/revendedor",
  },
  {
    id: 36,
    label: "Consultar revendedores",
    value: "/acesso/consulta-todos/revendedores",
  },
  {
    id: 41,
    label: "Vínculo representante",
    value: "/acesso/vinculo-representante",
  },
  {
    id: 1,
    label: "Fale conosco",
    value: "/acesso/falar-suporte",
  },
  {
    id: 53,
    label: "Cartões",
    value: "/acesso/cartoes",
  },
  {
    id: 37,
    label: "Cobranças internas",
    value: "/acesso/cobrancas_internas",
  },
  {
    id: 51,
    label: "Produto interno",
    value: "/acesso/produto_interno",
  },
  {
    id: 51,
    label: "Cobranças internas",
    value: "/acesso/cobrancas_internas",
  },
  {
    id: 24,
    label: "Alterar dados parceiro",
    value: "/acesso/alterar-dados-adm/clientes",
  },
  {
    id: 25,
    label: "Alterar dados revendedor",
    value: "/acesso/alterar-dados-adm/parceiros",
  },
  {
    id: 26,
    label: "Alterar dados parceiro",
    value: "/acesso/alterar-dados-adm/parceiros",
  },
  {
    id: 38,
    label: "Criar marca",
    value: "/acesso/marca-editar",
  },
  {
    id: 54,
    label: "Produto interno",
    value: "/acesso/produto_interno",
  },
  {
    id: 54,
    label: "Produto interno",
    value: "/acesso/criar_produto_interno",
  },
  {
    id: 55,
    label: "Voucher",
    value: "/acesso/voucher",
  },
  {
    id: 55,
    label: "Voucher",
    value: "/acesso/criar_voucher",
  },
  {
    id: 31,
    label: "Criar revendedor",
    value: "/acesso/criar-revendedor",
  },
  {
    id: 30,
    label: "Criar parceiro",
    value: "/acesso/criar-parceiro",
  },
  {
    id: 56,
    label: "Cobranças",
    value: "/acesso/cobranca",
  },
];

export default function Routes() {
  const { signed, user } = useAuth();
  const history = useHistory();

  const AuthRoute = useCallback(
    ({ path, ...rest }) => {
      const acesso = acessos.filter((f) =>
        f.value.includes(path.replace("/:tipo", "").replace("/:id", ""))
      );

      if (
        acesso.length &&
        user?.access?.some((a) => acesso.some((s) => s.id === a.tela))
      ) {
        return <Route path={path} {...rest} />;
      } else {
        if (path === window.location.pathname) {
          history.push("/acesso/welcome");
        }
        return <Route path={path} component={Welcome} />;
      }
    },
    [user]
  );

  return (
    <>
      <Route
        path={[
          "/",
          "/:whitelabel",
          "/:whitelabel/sobre",
          "/:whitelabel/termos",
          "/:whitelabel/privacidade",
          "/:whitelabel/mapa-de-parceiros",
          "/:whitelabel/cartao",
          "/:whitelabel/consultaqr/:cpf",
          "/:whitelabel/cartao-escolhido/:tipo",
          "/:whitelabel/cartao-empresarial",
          "/:whitelabel/agendar-consulta-exame",
          "/:whitelabel/seja-parceiro",
          "/:whitelabel/seja-revendedor",
          "/:whitelabel/beneficios-descontos",
          "/:whitelabel/parceiros",
          "/:whitelabel/proximos-de-mim",
          "/:whitelabel/parceiros/categoria/:categoria",
          "/:whitelabel/parceiros/segmento/:segmento",
          "/:whitelabel/parceiros/marca/:marca",
          "/:whitelabel/desconto-farmacia",
          "/:whitelabel/login",
          "/:whitelabel/esqueceu-senha",
          "/:whitelabel/obrigado-pelo-interesse",
          "/:whitelabel/cartao-empresarial/:id",
        ]}
      >
        <DefaultLayout>
          <Route path="/" exact component={Home} />
          <Route path="/:whitelabel" exact component={Home} />

          <Route path="/:whitelabel/sobre" exact component={Sobre} />
          <Route path="/:whitelabel/termos" exact component={Termos} />

          <Route
            path="/:whitelabel/privacidade"
            exact
            component={Privacidade}
          />

          <Route
            path="/:whitelabel/mapa-de-parceiros"
            exact
            component={MapaParceiros}
          />
          <Route path="/:whitelabel/cartao" exact component={Cartao} />
          <Route
            path="/:whitelabel/consultaqr/:cpf"
            exact
            component={ConsultaQr}
          />
          <Route
            path="/:whitelabel/cartao-escolhido/:tipo"
            exact
            render={(props) => (
              <CartaoEscolhido key={props.match.params.tipo} {...props} />
            )}
          />
          <Route
            path="/:whitelabel/cartao-empresarial"
            exact
            component={CartaoEmpresarial}
          />
          <Route
            path="/:whitelabel/cartao-empresarial/:id"
            exact
            component={VendaPj}
          />

          <Route path="/:whitelabel/produto/:id" exact component={Produto} />

          <Route
            path="/:whitelabel/agendar-consulta-exame"
            exact
            component={AgendarExameConsulta}
          />
          <Route
            path="/:whitelabel/seja-parceiro"
            exact
            component={SejaParceiro}
          />
          <Route
            path="/:whitelabel/seja-revendedor"
            exact
            component={SejaRevendedor}
          />
          <Route
            path="/:whitelabel/beneficios-descontos"
            exact
            component={BeneficiosDesconto}
          />
          <Route
            path="/:whitelabel/parceiros"
            exact
            component={ConsultaParceiros}
          />
          <Route
            path="/:whitelabel/proximos-de-mim"
            exact
            component={ProximosDeMim}
          />
          <Route
            path="/:whitelabel/parceiros/categoria/:categoria"
            exact
            render={(props) => (
              <SegmentosParceiros
                key={props.match.params.categoria}
                {...props}
              />
            )}
          />
          <Route
            path="/:whitelabel/parceiros/segmento/:segmento"
            exact
            render={(props) => (
              <SegmentosParceiros
                key={props.match.params.segmento}
                {...props}
              />
            )}
          />
          <Route
            path="/:whitelabel/parceiros/marca/:marca"
            exact
            render={(props) => (
              <SegmentosParceiros key={props.match.params.marca} {...props} />
            )}
          />
          <Route
            path="/:whitelabel/desconto-farmacia"
            exact
            component={DescontoFarmacia}
          />
          <Route
            path="/:whitelabel/login"
            exact
            render={(props) => (
              <Acesso key={props.match.params.tipo} {...props} />
            )}
          />
          <Route
            path="/:whitelabel/esqueceu-senha"
            exact
            render={(props) => (
              <EsqueciSenha key={props.match.params.tipo} {...props} />
            )}
          />
          <Route
            path="/:whitelabel/obrigado-pelo-interesse"
            exact
            component={ObrigadoPeloInteresse}
          />
        </DefaultLayout>
      </Route>

      {signed && (
        <Route
          path={[
            "/acesso/welcome",
            "/acesso/funcionarios",
            "/acesso/criar-funcionario",
            "/acesso/dashboard",
            "/acesso/clientes",
            "/acesso/clientes_pj",
            "/acesso/dados-cadastrais",
            "/acesso/link-venda",
            "/acesso/faturas",
            "/acesso/trocar-senha",
            "/acesso/dependentes",
            "/acesso/falar-suporte",
            "/acesso/solicitar-representacao",
            "/acesso/nivel-acesso",
            "/acesso/relatorio-adm/clientes",
            "/acesso/relatorio-adm/parceiro",
            "/acesso/relatorio-adm/revendedor",
            "/acesso/emitir-boleto/:tipo",
            "/acesso/criar-parceiro",
            "/acesso/criar-revendedor",
            // "/acesso/cancelar-cadastro/:tipo",
            "/acesso/alterar-dados-adm/:tipo/:id",
            "/acesso/relatorios",
            "/acesso/portal-venda",
            "/acesso/cobranca",
            "/acesso/cadastro-token",
            "/acesso/clientes",
            "/acesso/consulta-todos/:tipo",
            "/acesso/cobrancas",
            "/acesso/cobrancas_internas",
            "/acesso/voucher",
            "/acesso/criar_voucher",
            "/acesso/marca",
            "/acesso/marca-consulta",
            "/acesso/marca-editar",
            "/acesso/vinculo-representante",
            "/acesso/cartao-digital",
            "/acesso/assinatura",
            "/acesso/whitelabel",
            "/acesso/bloqueio-parceiro",
            "/acesso/dados-whitelabel",
            "/acesso/cartoes/:tipo/:id",
            "/acesso/cartoes",
            "/acesso/produto_interno",
            "/acesso/criar_produto_interno",
          ]}
        >
          <LayoutAcessos>
            <Route path="/acesso/trocar-senha" exact component={TrocarSenha} />
            {(user.perfil === "U" ||
              user.perfil === "R" ||
              user.perfil === "P") && (
              <>
                <Route
                  path="/acesso/falar-suporte"
                  exact
                  component={FalarComEquipe}
                />
                <Route
                  path="/acesso/dados-cadastrais"
                  exact
                  component={AlterarDadosCadastrais}
                />
                <Route
                  path="/acesso/produto_interno"
                  exact
                  component={ListarProdutosInternos}
                />
                <Route
                  path="/acesso/cobrancas_internas"
                  exact
                  component={CobrancasInternas}
                />
              </>
            )}
            {(user.perfil === "P" || user.perfil === "R") && (
              <>
                <Route
                  path="/acesso/cadastro-token"
                  exact
                  component={CadastrarToken}
                />
                <Route
                  path="/acesso/portal-venda"
                  exact
                  component={PortalVendas}
                />
                <Route
                  path="/acesso/portal-venda/:tipo"
                  exact
                  component={PortalVendasCartaoEscolhido}
                />
                <Route
                  path="/acesso/cobranca"
                  exact
                  component={cobrancasParRev}
                />
              </>
            )}
            {(user.perfil === "U" || user.perfil === "F") && (
              <>
                <Route path="/acesso/faturas" exact component={Faturas} />
                <Route
                  path="/acesso/cartao-digital"
                  exact
                  component={CartaoDigital}
                />
                <Route
                  path="/acesso/dependentes"
                  exact
                  component={Dependentes}
                />
                <Route path="/acesso/assinatura" exact component={Assinatura} />
              </>
            )}
            {user.perfil === "A" && (
              <>
                <Route
                  path="/acesso/dados-cadastrais"
                  exact
                  component={AlterarDadosCadastraisUserAdm}
                />
                <AuthRoute
                  path="/acesso/cartoes/:tipo"
                  exact
                  component={CriarCartao}
                />
                <Route path="/acesso/cartoes" exact component={ListarCartao} />
                <AuthRoute
                  path="/acesso/criar_produto_interno"
                  exact
                  component={CriarProdutoInterno}
                />
                <Route
                  path="/acesso/produto_interno"
                  exact
                  component={ListarProdutosInternos}
                />
                <Route
                  path="/acesso/assinatura/cliente"
                  exact
                  component={AssinaturaAdm}
                />
                <AuthRoute
                  path="/acesso/alterar-dados-adm/:tipo/:id"
                  exact
                  render={(props) => (
                    <AlterarDadosCadastraisAdm
                      key={props.match.params.tipo}
                      {...props}
                    />
                  )}
                />
                <AuthRoute
                  path="/acesso/criar-revendedor"
                  exact
                  component={CriarRevendedor}
                />
                <AuthRoute
                  path="/acesso/criar-parceiro"
                  exact
                  component={CriarParceiro}
                />
                <Route
                  path="/acesso/relatorio-adm/clientes"
                  exact
                  component={RelatorioAdmin}
                />
                <Route
                  path="/acesso/relatorio-adm/parceiro"
                  exact
                  component={relatorioAdminRevendedores}
                />
                <Route
                  path="/acesso/relatorio-adm/revendedor"
                  exact
                  component={relatorioAdminRevendedores}
                />
                <Route path="/acesso/cobrancas" exact component={Cobrancas} />
                <Route
                  path="/acesso/cobrancas_internas"
                  exact
                  component={CobrancasInternas}
                />
                <Route
                  path="/acesso/emitir-boleto/:tipo"
                  exact
                  render={(props) => (
                    <GerarBoleto key={props.match.params.tipo} {...props} />
                  )}
                />
                <Route
                  path="/acesso/clientes"
                  exact
                  component={ConsultarClientesAdm}
                />
                <Route
                  path="/acesso/clientes_pj"
                  exact
                  component={ConsultarClientesPjAdm}
                />
                <AuthRoute
                  path="/acesso/consulta-todos/:tipo"
                  exact
                  render={(props) => (
                    <ConsultarTodosAdm
                      key={props.match.params.tipo}
                      {...props}
                    />
                  )}
                />
                <Route path="/acesso/marca" exact component={CriarMarca} />
                <AuthRoute
                  path="/acesso/marca-consulta"
                  exact
                  component={ConsultarMarca}
                />
                <Route
                  path="/acesso/bloqueio-parceiro"
                  exact
                  component={BloqueioParceiros}
                />
                <Route
                  path="/acesso/vinculo-representante"
                  exact
                  component={VinculoRepresentante}
                />
                <AuthRoute
                  path="/acesso/marca-editar/:id"
                  exact
                  render={(props) => (
                    <EditarMarca key={props.match.params.tipo} {...props} />
                  )}
                />
                <Route
                  path="/acesso/nivel-acesso"
                  exact
                  component={NivelAcesso}
                />
                <Route path="/acesso/whitelabel" exact component={WhiteLabel} />
                <AuthRoute
                  path="/acesso/dados-whitelabel"
                  exact
                  component={DadosWhiteLabel}
                />
              </>
            )}
            {(user.perfil === "P" || user.perfil === "R") && (
              <>
                <Route path="/acesso/clientes" exact component={Clientes} />
                <Route
                  path="/acesso/clientes_pj"
                  exact
                  component={ClientesPj}
                />
                <Route path="/acesso/link-venda" exact component={LinkVenda} />
                <Route
                  path="/acesso/relatorios"
                  exact
                  component={RelatorioParceiro}
                />
              </>
            )}
            {user.perfil === "P" && (
              <Route
                path="/acesso/solicitar-representacao"
                exact
                component={SolicitarRepresentacao}
              />
            )}
            {(user.perfil === "P" ||
              user.perfil === "R" ||
              user.perfil === "A") && (
              <>
                <Route
                  path="/acesso/dashboard/financeiro"
                  exact
                  component={DashboardFinanceiro}
                />
                <Route
                  path="/acesso/dashboard/representante"
                  exact
                  component={DashboardRepresentante}
                />
                <Route
                  path="/acesso/dashboard/adimplencia"
                  exact
                  component={DashboardAdimplencia}
                />
                <Route
                  path="/acesso/dashboard/produtos"
                  exact
                  component={DashboardProdutos}
                />
                <Route
                  path="/acesso/dashboard/cidades"
                  exact
                  component={DashboardCidades}
                />
              </>
            )}
            <AuthRoute
              path="/acesso/criar_voucher"
              exact
              component={CriarVoucher}
            />
            <Route path="/acesso/voucher" exact component={Vouchers} />
            <Route
              path="/acesso/funcionarios"
              exact
              component={ConsultarFuncionarios}
            />
            <Route
              path="/acesso/criar-funcionario"
              exact
              component={CriarFuncionarios}
            />
            <Route path="/acesso/welcome" exact component={Welcome} />
            <Redirect to="/acesso/welcome" />
          </LayoutAcessos>
        </Route>
      )}
    </>
  );
}
