import React, { useState } from 'react';
import { Row, Col, Container, Button, Table } from 'reactstrap';
import { GoChevronDown } from 'react-icons/go';

import ConsultaImg from '../../assets/consulta.png';
import ExameIMG from '../../assets/exame.png';
import FarmaciaIMG from '../../assets/farmacia.png';
import PetshopImg from '../../assets/petshop.png';

import IconsDescImg from '../../assets/icons-com-descricao.png';
import Beneficios from '../../assets/beneficios.png';
import OndeComo from '../../assets/ondecomo.png';
import Clube from '../../assets/clube.png';

import { Link, useLocation } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import ReactHtmlParser from 'react-html-parser';

import { useWhiteLabel } from '../../contexts/whitelabel';
import { useAuth } from '../../contexts/auth';

function BeneficosDesconto() {
  const location = useLocation();
  const ondeComoUsar = useRef(null);
  const clubeDeDescontos = useRef(null);
  const { uppo } = useAuth();

  const { dadosWhiteLabel } = useWhiteLabel();

  useEffect(() => {
    if (location.hash) {
      if (location.hash === '#onde-e-como-usar') {
        ondeComoUsar?.current?.scrollIntoView();
      } else if (location.hash === '#clube-de-descontos') {
        clubeDeDescontos?.current?.scrollIntoView();
      }
    }
  }, [location]);

  return (
    <main>
      <Container>
        <br />
        <Row className='mt-3 txt-primary mb-5'>
          <Col lg={4}>
            <img
              style={{
                transform: dadosWhiteLabel?.beneficios_tabela_img_img?.url
                  ? ''
                  : 'scaleX(-1)',
              }}
              className='img-lado pr-1'
              src={
                dadosWhiteLabel?.beneficios_tabela_img_img?.url
                  ? dadosWhiteLabel?.beneficios_tabela_img_img?.url
                  : Beneficios
              }
              alt='Benef Desc'
            />
          </Col>
          <Col lg={8}>
            <Row>
              <GoChevronDown
                size={40}
                color='orange'
                className='ml-4'
                style={{
                  color: dadosWhiteLabel
                    ? dadosWhiteLabel?.whitelabel_arrows
                    : '',
                }}
              />
              <h2
                className='h2-title-2 ml-3'
                style={{
                  fontSize: 45,
                  color: dadosWhiteLabel
                    ? dadosWhiteLabel?.whitelabel_text_color_1
                    : '',
                }}
              >
                Benefícios e descontos
              </h2>
            </Row>
            <br />
            <div className='pl-1'>
              <Table striped className='table-beneficios'>
                <tbody
                  className='table-css'
                  style={{
                    color: dadosWhiteLabel
                      ? dadosWhiteLabel?.whitelabel_text_color_4
                      : '',
                  }}
                >
                  <tr>
                    <td className='w-15'>
                      <img
                        src={
                          dadosWhiteLabel?.beneficios_tabela_icone_1_img?.url ||
                          ConsultaImg
                        }
                        alt='Consulta'
                        width='80%'
                      />
                    </td>
                    {dadosWhiteLabel?.beneficios_tabela_texto_1 ? (
                      <td className='w-30'>
                        {dadosWhiteLabel?.beneficios_tabela_texto_1}
                      </td>
                    ) : (
                      <td className='w-30 gray'>Consultas médicas</td>
                    )}
                    <td>
                      {dadosWhiteLabel?.beneficios_tabela_subtexto_1 ? (
                        <h5>{dadosWhiteLabel?.beneficios_tabela_subtexto_1}</h5>
                      ) : (
                        <h5>
                          até <strong>60</strong>% de desconto
                        </h5>
                      )}
                    </td>
                  </tr>
                  <tr>
                    <td className='w-15'>
                      <img
                        src={
                          dadosWhiteLabel?.beneficios_tabela_icone_2_img?.url ||
                          ExameIMG
                        }
                        alt='Exames'
                        width='80%'
                      />
                    </td>
                    {dadosWhiteLabel?.beneficios_tabela_texto_2 ? (
                      <td className='w-30'>
                        {dadosWhiteLabel?.beneficios_tabela_texto_2}
                      </td>
                    ) : (
                      <td className='w-30 gray'>Exames médicos</td>
                    )}
                    <td>
                      {dadosWhiteLabel?.beneficios_tabela_subtexto_2 ? (
                        <h5>{dadosWhiteLabel?.beneficios_tabela_subtexto_2}</h5>
                      ) : (
                        <h5>
                          até <strong>70%</strong> de desconto
                        </h5>
                      )}
                    </td>
                  </tr>
                  {/* <tr>
                  <td rowspan='3' className='w-15'>
                    <img src={OdontoImg} alt='Odontologia' width='80%' />
                  </td>
                  <td rowspan='3' className='w-30'>
                    Odontologia Próteses e Implantes
                  </td>
                  <td className='small-text-table d-flex'>
                    <div className='w-70'>
                      <strong>Assistência Odontológica</strong> a partir de
                      {'  '}
                    </div>
                    <div className='text-center w-30'>
                      <strong className='table-price bolder'>R$ 16,90</strong>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className='small-text-table d-flex'>
                    <div className='w-70'>
                      <strong>Próteses com parcelas</strong> a partir de{' '}
                    </div>
                    <div className='text-center w-30'>
                      <strong className='table-price bolder'>R$ 99,90</strong>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className='small-text-table d-flex'>
                    <div className='w-70'>
                      <strong>Implantes com parcelas</strong> a partir de{' '}
                    </div>
                    <div className='text-center w-30'>
                      <strong className='table-price bolder'>R$ 149,90</strong>
                    </div>
                  </td>
                </tr> */}
                  <tr>
                    <td rowspan={!dadosWhiteLabel ? '4' : '1'} className='w-15'>
                      <img
                        src={
                          dadosWhiteLabel?.beneficios_tabela_icone_3_img?.url ||
                          FarmaciaIMG
                        }
                        alt='Farmacia'
                        width='80%'
                      />
                    </td>
                    {dadosWhiteLabel?.beneficios_tabela_texto_3 ? (
                      <td className='w-30'>
                        {dadosWhiteLabel?.beneficios_tabela_texto_3}
                      </td>
                    ) : (
                      <td rowspan='4' className='w-30 gray'>
                        Farmácia
                        <p className='mt-1 gray'>
                          Descontos podem chegar a 75%
                        </p>
                      </td>
                    )}
                    <td>
                      {dadosWhiteLabel?.beneficios_tabela_subtexto_3 ? (
                        <h5>{dadosWhiteLabel?.beneficios_tabela_subtexto_3}</h5>
                      ) : (
                        <h5>
                          Em toda a{' '}
                          <strong>
                            rede Drogaria São Paulo e Drogaria Pacheco
                          </strong>
                        </h5>
                      )}
                    </td>
                  </tr>
                  {!dadosWhiteLabel && (
                    <>
                      <tr>
                        <td
                          className='small-text-table d-flex align-center'
                          style={{
                            backgroundColor: '#dedede',
                          }}
                        >
                          <div className='w-70'>
                            <br />
                            <h6>Medicamento Genérico tarjado </h6>
                          </div>
                          <div className='text-center'>
                            <p
                              style={{
                                marginBottom: 0,
                              }}
                            >
                              Mínimo de desconto
                            </p>
                            <strong className='table-price bolder'>30%</strong>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td className='small-text-table d-flex'>
                          <div className='w-70'>
                            <h6>Medicamento Marca e Similar tarjado</h6>
                          </div>
                          <div className='text-center w-30'>
                            <strong className='table-price bolder'>15%</strong>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td
                          className='small-text-table d-flex'
                          style={{
                            backgroundColor: '#dedede',
                          }}
                        >
                          <div className='w-70'>
                            <h6>Medicamento OTC</h6>
                          </div>
                          <div className='text-center w-30'>
                            <strong className='table-price bolder'>10%</strong>
                          </div>
                        </td>
                      </tr>
                    </>
                  )}
                  <tr
                    style={{
                      backgroundColor: '#fff',
                    }}
                  >
                    <td className='w-15'>
                      <img
                        src={
                          dadosWhiteLabel?.beneficios_tabela_icone_4_img?.url ||
                          PetshopImg
                        }
                        alt='Petshop'
                        width='80%'
                      />
                    </td>
                    {dadosWhiteLabel?.beneficios_tabela_texto_4 ? (
                      <td className='w-30'>
                        {dadosWhiteLabel?.beneficios_tabela_texto_4}
                      </td>
                    ) : (
                      <td className='w-30 gray'>Veterinária e PetShop</td>
                    )}
                    <td>
                      {dadosWhiteLabel?.beneficios_tabela_subtexto_4 ? (
                        <h5>{dadosWhiteLabel?.beneficios_tabela_subtexto_4}</h5>
                      ) : (
                        <h5>
                          <div className='w-70'>
                            <h5>Exames, consultas e produtos para seu pet</h5>
                          </div>{' '}
                        </h5>
                      )}
                    </td>
                  </tr>
                  <tr
                    style={{
                      backgroundColor: '#f2f2f2',
                    }}
                  >
                    <td
                      className='w-15'
                      style={{
                        padding: 5,
                      }}
                    />

                    <td
                      className='w-30'
                      style={{
                        padding: 5,
                      }}
                    />
                    <td
                      style={{
                        padding: 5,
                      }}
                    />
                  </tr>
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
        <br />
        <br />
        <div ref={ondeComoUsar}>
          {!dadosWhiteLabel ? (
            <Row className='mt-5 mb-5 txt-primary' id='onde-e-como-usar'>
              <Col lg={6} className='mb-3 mt-5 pt-3'>
                <br />

                <h3 className='h3-title'>
                  Onde e como usar
                  <br /> o Cartão PoupaClub
                </h3>
                <br />
                <p className='txt-poupaclub-3'>
                  Basta&nbsp;
                  <strong>
                    apresentar o seu Cartão PoupaClub na hora
                    <br /> de pagar a compra e o desconto é aplicado
                    <br /> automaticamente
                  </strong>
                  , sem complicação. É muito
                  <br /> simples e fácil.
                  <br />
                  <br />
                  <strong>Quanto posso esperar economizar?</strong> Poupe mais
                  <br /> de R$1000 por mês com os descontos oferecidos
                  <br /> por nossos parceiros. Quanto mais usar, mais vai
                  <br /> economizar!
                </p>
                <br />
                <div style={{ textAlign: 'end' }} className='mb-5'>
                  <Link
                    className='btn-pill btn-green btn-conheca'
                    size='sm'
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                    to='/cartao'
                  >
                    Conheça mais
                  </Link>
                </div>
              </Col>
              <Col lg={6} className='mb-3'>
                <img
                  src={OndeComo}
                  alt='Benef Desc'
                  width='100%'
                  className='pb-1'
                />
              </Col>
            </Row>
          ) : (
            <>
              <Row className='mt-5 mb-5 txt-primary' id='onde-e-como-usar'>
                <Col lg={6} className='mb-3 mt-0 pt-3'>
                  <br />
                  <h3
                    className='h3-title'
                    style={{
                      color: dadosWhiteLabel
                        ? dadosWhiteLabel?.whitelabel_text_color_1
                        : '',
                    }}
                  >
                    {dadosWhiteLabel?.onde_como_titulo}
                  </h3>
                  <br />
                  {ReactHtmlParser(dadosWhiteLabel?.onde_como_texto)}
                  <br />
                  <div style={{ textAlign: 'end' }} className='mb-5'>
                    <Link
                      className={`btn-pill btn-green btn-conheca ${
                        dadosWhiteLabel?.whitelabel_button_2 &&
                        dadosWhiteLabel?.whitelabel_button_2
                      }`}
                      size='sm'
                      onClick={() => {
                        window.scrollTo(0, 0);
                      }}
                      to={`${
                        dadosWhiteLabel?.url_whitelabel || 'poupa-club'
                      }/cartao`}
                    >
                      Conheça mais
                    </Link>
                  </div>
                </Col>
                <Col lg={6} className='mb-3 mt-4'>
                  <img
                    src={dadosWhiteLabel?.home_img_saiba_mais_img?.url || ''}
                    width='100%'
                    alt='Como'
                  />
                </Col>
              </Row>
            </>
          )}
        </div>
        <br />
        <br />
        <div ref={clubeDeDescontos}>
          <Row
            className='mt-5 txt-primary'
            id='clube-de-descontos'
            ref={clubeDeDescontos}
          >
            <Col lg={8} className='mt-3 pr-5'>
              <Row className='mb-3'>
                <GoChevronDown
                  size={40}
                  color='orange'
                  className='ml-4'
                  style={{
                    color: dadosWhiteLabel
                      ? dadosWhiteLabel?.whitelabel_arrows
                      : '',
                  }}
                />
                <h2
                  className='h2-title-2 ml-3'
                  style={{
                    fontSize: 45,
                    color: dadosWhiteLabel
                      ? dadosWhiteLabel?.whitelabel_text_color_1
                      : '',
                  }}
                >
                  Clube de Descontos
                </h2>
              </Row>
              <p className='txt-poupaclub-2'>
                {dadosWhiteLabel ? (
                  ReactHtmlParser(dadosWhiteLabel?.clube_desconto_texto)
                ) : (
                  <>
                    <strong>
                      Nosso cartão é aceito em mais de 1000 estabelecimentos
                      pelo Brasil
                    </strong>
                    . Você poderá usufruir de{' '}
                    <strong>
                      descontos exclusivos de até 70% em compras,
                      entretenimento, saúde e bem-estar
                    </strong>{' '}
                    oferecidos por uma rede credenciada de parceiros.
                    <br />
                    <br />O Clube de Descontos PoupaClub conta com{' '}
                    <strong>
                      bares, restaurantes, cursos de idiomas, academias, loja de
                      roupas e acessórios, clínicas médicas e odontológicas,
                      clínicas veterinárias, pet shops, farmácias e muito mais!{' '}
                    </strong>
                    Economize na compra de produtos e serviços das marcas que
                    você mais gosta e confia.
                  </>
                )}
              </p>
              <br />
              <br />
              <div style={{ textAlign: 'center', maxWidth: '100%' }}>
                <img
                  src={dadosWhiteLabel?.home_img_1_img?.url || IconsDescImg}
                  alt='Farmacia'
                  width='100%'
                />
              </div>
              <br />
              <div style={{ textAlign: 'center' }}>
                <button
                  className={
                    dadosWhiteLabel
                      ? 'btn-pill btn-lg mb-3 bold btn btn-secondary my-3 sm ' +
                        dadosWhiteLabel?.whitelabel_button_2
                      : 'btn-pill btn-lg mb-3 bold btn btn-secondary my-3 sm'
                  }
                  onClick={() => {
                    uppo();
                  }}
                >
                  Conheça todos os parceiros {!dadosWhiteLabel && 'PoupaClub'}
                </button>
              </div>
            </Col>
            <Col lg={4} className='mt-3'>
              <img
                className='img-lado'
                src={dadosWhiteLabel?.clube_desconto_img_img?.url || Clube}
                alt='Clube'
              />
            </Col>
          </Row>
        </div>
        <br />
        <br />
      </Container>
    </main>
  );
}

export default BeneficosDesconto;
