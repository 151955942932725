import {
  Container,
  Col,
  Row,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  CardImg,
  Button,
} from "reactstrap";

import { FaSearch } from "react-icons/fa";
import saude from "../../assets/saude.png";
import veterinaria from "../../assets/veterinaria.png";
import clubeDescontos from "../../assets/clube_descontos.png";
import { Link, useHistory } from "react-router-dom";
import segmentos from "../../utils/segmentos";
import useFetch from "../../hooks/useFetch";
import Placeholder from "./PlaceholderParceiro";
import api from "../../services/api";
import { useEffect, useRef, useState } from "react";
import iconeFiltro from "../../assets/icone_filtro.png";
import estados from "../../utils/estadosBrasileiros";
import cidades from "../../utils/cidadesBrasileiras";

import { useWhiteLabel } from "../../contexts/whitelabel";

function ConsultaParceiro() {
  const { data: parceirosData, mutate } = useFetch("/marcas");
  const history = useHistory();
  const [estado, setEstado] = useState(null);
  const [cidade, setCiade] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const [parceiros, setParceiros] = useState([]);

  const { dadosWhiteLabel, parceirosBlacklist } = useWhiteLabel();

  const refSelect = useRef(null);

  let consultaGenerica;

  // const parceiros = false;

  async function buscarParceiros(value) {
    return await new Promise((resolve) => {
      clearTimeout(consultaGenerica);

      consultaGenerica = setTimeout(() => {
        resolve(filtrarConsulta(value));
      }, 300);
    });
  }

  async function filtrarConsulta(value = "") {
    const { data: res } = await api.get(
      `/marcas?nome=${value}&estado=${estado || ""}&cidade=${cidade || ""}`
    );
    mutate(res, false);
  }

  const showMap = ({ coords }) => {
    localStorage.setItem(
      "coords",
      JSON.stringify({
        latitude: coords.latitude,
        longitude: coords.longitude,
      })
    );

    history.push(`proximos-de-mim`);
  };

  const getLocation = () => navigator.geolocation.getCurrentPosition(showMap);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (isLoaded) {
      filtrarConsulta(refSelect?.current?.value);
    } else {
      setIsLoaded(true);
    }
    function filterParceiros() {
      if (parceirosBlacklist) {
        const clubeDescontos = parceirosData?.clubeDescontos?.filter(
          (f) => !parceirosBlacklist.some((s) => s.marca_id === f.id)
        );
        const medicinaOdontologia = parceirosData?.medicinaOdontologia?.filter(
          (f) => !parceirosBlacklist.some((s) => s.marca_id === f.id)
        );
        const veterinariaPetShop = parceirosData?.veterinariaPetShop?.filter(
          (f) => !parceirosBlacklist.some((s) => s.marca_id === f.id)
        );
        const object = {
          clubeDescontos,
          medicinaOdontologia,
          veterinariaPetShop,
        };
        setParceiros(object);
      } else {
        setParceiros(parceirosData);
      }
    }
    filterParceiros();
  }, [estado, cidade, parceirosBlacklist, parceirosData]);

  return (
    <Container>
      <Row className="mt-3 align-flex-end">
        {/* <Col lg={1} className="mb-3">
          <div className="d-flex align-center">
            <span className="d-flex align-center txt-tertiary">
              <img
                src={iconeFiltro}
                alt="icone de filtros"
                className="mr-1"
                style={{
                  maxHeight: "34px",
                }}
              />
              <h6
                style={{
                  marginBottom: 0,
                }}
              >
                Filtros
              </h6>
            </span>
          </div>
        </Col> */}
        <Col lg={2} className="mb-3">
          <Input
            type="select"
            className="input-field-select"
            onChange={(e) => {
              history.push(
                `/${
                  dadosWhiteLabel?.url_whitelabel || "poupa-club"
                }/parceiros/segmento/${e.target.value}`
              );
            }}
          >
            <option value="" selected>
              Segmento
            </option>
            {segmentos.map((segmento) => (
              <option value={segmento.url}>{segmento.nome}</option>
            ))}
          </Input>
        </Col>
        <Col lg={3} className="mb-3">
          <InputGroup>
            <InputGroupAddon addonType="prepend">
              <InputGroupText
                className={
                  dadosWhiteLabel
                    ? "access-icon-generic mb-0"
                    : "access-icon mb-0"
                }
              >
                <FaSearch
                  className={
                    dadosWhiteLabel ? "icon-color-generic" : "icon-color"
                  }
                />
              </InputGroupText>
            </InputGroupAddon>
            <input
              placeholder="Busque pelo nome do parceiro"
              type="text"
              className={
                dadosWhiteLabel
                  ? "access-input-generic font-400 form-control"
                  : "access-input font-400 form-control"
              }
              ref={refSelect}
              onChange={(e) => buscarParceiros(e.target.value)}
            />
          </InputGroup>
        </Col>
        <Col lg={2} className="mb-3">
          <Input
            type="select"
            className="input-field-select"
            onChange={(e) => {
              setEstado(e.target.value);
              setCiade(null);
            }}
          >
            <option value="" selected>
              Estado
            </option>
            {estados.map((estado) => (
              <option value={estado.value}>{estado.label}</option>
            ))}
          </Input>
        </Col>
        <Col lg={2} className="mb-3">
          <Input
            type="select"
            className="input-field-select"
            onChange={(e) => setCiade(e.target.value)}
          >
            <option value="" selected>
              Cidade
            </option>
            {estado &&
              cidades
                .find((f) => f.estado === estado)
                ?.cidades?.map((cidade) => (
                  <option value={cidade}>{cidade}</option>
                ))}
          </Input>
        </Col>
        <Col lg={3} className="mb-3" block>
          <Button
            block
            color="outline-secondary btn-pill"
            className={`btn-pill bold ${
              dadosWhiteLabel?.whitelabel_button_1 &&
              dadosWhiteLabel?.whitelabel_button_1
            }`}
            onClick={getLocation}
          >
            Parceiros próximos a mim
          </Button>
        </Col>
      </Row>

      <Row className="my-3 segmentos-parceiros">
        <Col lg={12}>
          <hr className="bold" />
        </Col>
        <Col lg={4} sm={12} className="mt-3">
          <Row>
            <Col lg={12} className="mb-3">
              <h4
                className="bold txt-primary text-center"
                style={{
                  color: dadosWhiteLabel
                    ? dadosWhiteLabel?.whitelabel_text_color_1
                    : "",
                }}
              >
                Saúde
              </h4>
              {!dadosWhiteLabel && <CardImg src={saude} alt="Áreas da saúde" />}
            </Col>
            {parceiros ? (
              parceiros.medicinaOdontologia?.map((m) => (
                <Col lg={12}>
                  <div className="img-parceiro">
                    <span>
                      <Link
                        to={`parceiros/marca/${m.id}`}
                        className="bold txt-tertiary"
                        onClick={() => window.scrollTo(0, 0)}
                      >
                        <CardImg src={m.imagem.url} alt={m.nome} />
                      </Link>
                    </span>
                  </div>
                  <div className="txt-tertiary">{m.subtitulo}</div>
                  <div className="txt-tertiary my-3 descricao-parceiro">
                    {m.descricao}
                  </div>
                  <Link
                    to={`parceiros/marca/${m.id}`}
                    className="bold txt-tertiary"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    Saiba mais
                  </Link>
                  <br />
                  <hr />
                </Col>
              ))
            ) : (
              <Placeholder />
            )}
            <Col lg={12} className="mt-60 mb-60 justify-center footer-btns">
              <Link
                className={`btn-pill btn btn-gold ${
                  dadosWhiteLabel?.whitelabel_button_1 &&
                  dadosWhiteLabel?.whitelabel_button_1
                }`}
                to={`parceiros/categoria/medicina-e-odontologia`}
                onClick={() => window.scrollTo(0, 0)}
              >
                Veja todos os parceiros da área de Saúde
              </Link>
            </Col>
          </Row>
        </Col>
        <Col lg={4} sm={12} className="mt-3">
          <Row>
            <Col lg={12} className="mb-3">
              <h4
                className="bold txt-primary text-center"
                style={{
                  color: dadosWhiteLabel
                    ? dadosWhiteLabel?.whitelabel_text_color_1
                    : "",
                }}
              >
                Clube de Descontos
              </h4>
              {!dadosWhiteLabel && (
                <CardImg src={clubeDescontos} alt="Clube de Descontos" />
              )}
            </Col>
            {parceiros ? (
              parceiros.clubeDescontos?.map((m) => (
                <Col lg={12}>
                  <div className="img-parceiro">
                    <Link
                      to={`parceiros/marca/${m.id}`}
                      className="bold txt-tertiary"
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      <CardImg src={m.imagem.url} alt={m.nome} />
                    </Link>
                  </div>
                  <div className="txt-tertiary">{m.subtitulo}</div>
                  <div className="txt-tertiary my-3 descricao-parceiro">
                    {m.descricao}
                  </div>
                  <Link
                    to={`parceiros/marca/${m.id}`}
                    className="bold txt-tertiary"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    Saiba mais
                  </Link>
                  <br />
                  <hr />
                </Col>
              ))
            ) : (
              <Placeholder />
            )}
            <Col lg={12} className="mt-60 mb-60 justify-center footer-btns">
              <Link
                className={`btn-pill btn btn-especial ${
                  dadosWhiteLabel?.whitelabel_button_2 &&
                  dadosWhiteLabel?.whitelabel_button_2
                }`}
                to={`parceiros/categoria/clube-de-descontos`}
                onClick={() => window.scrollTo(0, 0)}
              >
                Veja os nossos parceiros de diversos segmentos
              </Link>
            </Col>
          </Row>
        </Col>
        <Col lg={4} sm={12} className="mt-3">
          <Row>
            <Col lg={12} className="mb-3">
              <h4
                className="bold txt-primary text-center"
                style={{
                  color: dadosWhiteLabel
                    ? dadosWhiteLabel?.whitelabel_text_color_1
                    : "",
                }}
              >
                Veterinária e PetShop
              </h4>
              {!dadosWhiteLabel && (
                <CardImg src={veterinaria} alt="Veterinária e PetShop" />
              )}
            </Col>
            {parceiros ? (
              parceiros.veterinariaPetShop?.map((m) => (
                <Col lg={12}>
                  <div className="img-parceiro">
                    <Link
                      to={`parceiros/marca/${m.id}`}
                      className="bold txt-tertiary"
                      onClick={() => window.scrollTo(0, 0)}
                    >
                      <CardImg src={m.imagem.url} alt={m.nome} />
                    </Link>
                  </div>
                  <div className="txt-tertiary">{m.subtitulo}</div>
                  <div className="txt-tertiary my-3 descricao-parceiro">
                    {m.descricao}
                  </div>
                  <Link
                    to={`parceiros/marca/${m.id}`}
                    className="bold txt-tertiary"
                    onClick={() => window.scrollTo(0, 0)}
                  >
                    Saiba mais
                  </Link>
                  <br />
                  <hr />
                </Col>
              ))
            ) : (
              <Placeholder />
            )}
            <Col lg={12} className="mt-60 mb-60 justify-center footer-btns">
              <Link
                className={`btn-pill btn btn-green ${
                  dadosWhiteLabel?.whitelabel_button_3 &&
                  dadosWhiteLabel?.whitelabel_button_3
                }`}
                to={`parceiros/categoria/veterinaria-e-petshop`}
                onClick={() => window.scrollTo(0, 0)}
              >
                Veja todos os parceiros da área de Veterinária
              </Link>
            </Col>
          </Row>
        </Col>
      </Row>
      {/* <Row className="segmentos">
        <Col
          lg={12}
          style={{
            marginBottom: 0,
          }}
        >
          <h5 className="bold txt-tertiary">Busque por segmento</h5>
          <hr className="bold" />
        </Col>
        <Col lg={4} sm={12}>
          <Row>
            {segmentos.slice(0, 7).map((segmento) => (
              <Col lg={12}>
                <Link
                  className="txt-primary"
                  to={`parceiros/segmento/${segmento.url}`}
                  onClick={() => window.scrollTo(0, 0)}
                  style={{
                    color: dadosWhiteLabel
                      ? dadosWhiteLabel?.whitelabel_text_color_2
                      : "",
                  }}
                >
                  {segmento.nome}
                </Link>
              </Col>
            ))}
          </Row>
        </Col>
        <Col lg={4} sm={12}>
          <Row>
            {segmentos.slice(7, 14).map((segmento) => (
              <Col lg={12}>
                <Link
                  className="txt-primary"
                  to={`parceiros/segmento/${segmento.url}`}
                  onClick={() => window.scrollTo(0, 0)}
                  style={{
                    color: dadosWhiteLabel
                      ? dadosWhiteLabel?.whitelabel_text_color_2
                      : "",
                  }}
                >
                  {segmento.nome}
                </Link>
              </Col>
            ))}
          </Row>
        </Col>
        <Col lg={4} sm={12}>
          <Row>
            {segmentos.slice(14).map((segmento) => (
              <Col lg={12}>
                <Link
                  className="txt-primary"
                  to={`parceiros/segmento/${segmento.url}`}
                  onClick={() => window.scrollTo(0, 0)}
                  style={{
                    color: dadosWhiteLabel
                      ? dadosWhiteLabel?.whitelabel_text_color_2
                      : "",
                  }}
                >
                  {segmento.nome}
                </Link>
              </Col>
            ))}
          </Row>
        </Col>
      </Row> */}
    </Container>
  );
}

export default ConsultaParceiro;
