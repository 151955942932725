import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Table,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledCollapse,
  Button,
} from "reactstrap";
import Swal from "sweetalert2";
import Pagination from "react-paginate";
import { FaChevronLeft, FaChevronRight, FaEllipsisH } from "react-icons/fa";
import { FiFilter } from "react-icons/fi";
import { Link } from "react-router-dom";

import { GrTrash, GrEdit, GrHide, GrFormView } from "react-icons/gr";

import AsyncSelect from "../../../components/AsyncSelect";

import formatCPF from "../../../utils/formatCPF";
import formatCNPJ from "../../../utils/formatCNPJ";
import api from "../../../services/api";

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: "ml-3 btn btn-success",
    cancelButton: "btn btn-danger",
  },
  buttonsStyling: false,
});

function ConsultarTodosAdm(props) {
  const tipo = props.match.params.tipo;
  let timeoutConsulta;

  const [filtro, setFiltro] = useState(null);
  const [status, setStatus] = useState("");

  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);

  const [tipoUser, setTipoUser] = useState(null);

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  useEffect(() => {
    switch (tipo) {
      case "clientes":
        setTipoUser("cliente");
        break;
      case "parceiros":
        setTipoUser("parceiro");
        break;
      case "revendedores":
        setTipoUser("revendedor");
        break;
      default:
        break;
    }
    async function getData() {
      Swal.fire({
        title: "Aguarde",
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      if (tipo === "clientes") {
        if (!filtro) {
          const { data } = await api.get(
            `/clientes?page=${page + 1}&status=${status}`
          );
          setData(data);
        } else {
          const { data: response } = await api.get(
            `/titular/${filtro.value}?status=${status}`
          );
          const dados = {
            rows: [],
          };
          dados.rows.push(response);
          setData(dados);
          setStatus("");
        }
      } else if (tipo === "revendedores") {
        if (!filtro) {
          const { data } = await api.get(
            `/revendedores?page=${page + 1}&status=${status}`
          );
          setData(data);
        } else {
          const { data: response } = await api.get(
            `/revendedor/${filtro.value}&status=${status}`
          );
          const dados = {
            rows: [],
          };
          dados.rows.push(response);
          setData(dados);
          setStatus("");
        }
      } else if (tipo === "parceiros") {
        if (!filtro) {
          const { data } = await api.get(
            `/parceiros?page=${page + 1}&status=${status}`
          );
          setData(data);
        } else {
          const { data: response } = await api.get(
            `/parceiro/${filtro.value}?status=${status}`
          );
          const dados = {
            rows: [],
          };
          dados.rows.push(response);
          setData(dados);
          setStatus("");
        }
      }
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 500);
      Swal.close();
    }
    getData();
  }, [tipo, page, filtro, status]);

  async function handleActivateInactivate(id, option, status) {
    swalWithBootstrapButtons
      .fire({
        title: `Tem certeza que deseja ${option} esse ${tipoUser}?`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          Swal.fire({
            title: "Aguarde",
            allowOutsideClick: false,
            showConfirmButton: false,
            onBeforeOpen: () => {
              Swal.showLoading();
            },
          });
          const body = {
            id,
            status,
            tipoUser,
          };
          api
            .put("/ativar-inativar", body)
            .then(() => {
              Swal.close();
              setData({
                ...data,
                "data.rows": data?.rows?.map((e) =>
                  e.id === id ? (e.status = status) : e.status
                ),
              });
            })
            .catch((error) => {
              const msg =
                error?.response?.data?.error ||
                "Ocorreu um erro, tente novamente mais tarde!";

              swalWithBootstrapButtons.fire("Ops...", msg, "error");
            });
        }
      });
  }

  async function handleDelete(id) {
    swalWithBootstrapButtons
      .fire({
        title: `Tem certeza que deseja excluir este parceiro?`,
        icon: "warning",
        text: "Esta operação não poderá ser revertida!",
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          Swal.fire({
            title: "Aguarde",
            allowOutsideClick: false,
            showConfirmButton: false,
            onBeforeOpen: () => {
              Swal.showLoading();
            },
          });

          api
            .delete(`/parceiros/${id}`)
            .then(() => {
              Swal.close();
              setData({
                ...data,
                "data.rows": data?.rows?.filter((f) => f.id !== id),
              });
            })
            .catch((error) => {
              const msg =
                error?.response?.data?.error ||
                "Ocorreu um erro, tente novamente mais tarde!";

              swalWithBootstrapButtons.fire("Ops...", msg, "error");
            });
        }
      });
  }

  const debounceConsulta = async (body) => {
    return await new Promise((resolve) => {
      clearTimeout(timeoutConsulta);

      timeoutConsulta = setTimeout(() => {
        resolve(filtrarConsulta(body));
      }, 500);
    });
  };

  async function filtrarConsulta({ value, param }) {
    if (tipo === "clientes") {
      const result = await api.get(`options/clientes?${param}=${value}`);
      return result?.data?.map((cliente) => ({
        value: cliente?.id,
        cpf: cliente?.cpf,
        nome: cliente?.nome,
      }));
    } else if (tipo === "parceiros") {
      const result = await api.get(`options/parceiros?${param}=${value}`);
      return result?.data?.map((parceiro) => ({
        value: parceiro?.id,
        cnpj: parceiro?.cnpj,
        nome: parceiro?.nome,
      }));
    } else if (tipo === "revendedores") {
      const result = await api.get(`options/revendedores?${param}=${value}`);
      return result?.data?.map((revendedor) => ({
        value: revendedor.id,
        cnpj: revendedor.cnpj,
        nome: revendedor.nome,
      }));
    }
  }

  return (
    <>
      <Row>
        <Col lg={7}>
          <h5 className="txt-tertiary mb-3">
            {tipo === "parceiros" ? "Parceiros" : "Revendedores"}
          </h5>
        </Col>
      </Row>
      <div className="card-form p-0">
        <div className="p-3 justify-content-between d-flex align-center">
          <p className="txt-muted">Total de registros: {data?.total || 0}</p>
          <Button color="muted" id="toggler">
            <FiFilter /> Filtros
          </Button>
          <div />
          <Link
            to={
              tipo === "parceiros"
                ? `/acesso/criar-parceiro`
                : `/acesso/criar-revendedor`
            }
            color="green"
            className="btn-save btn btn-green"
          >
            Adicionar
          </Link>
        </div>
        <div className="filter-collapse">
          <UncontrolledCollapse toggler="#toggler">
            <Row className="mt-3">
              <Col lg={4} sm={6} className="mb-1">
                <AsyncSelect
                  loadOptions={(value) =>
                    debounceConsulta({
                      value,
                      param: "nome",
                    })
                  }
                  getOptionLabel={(option) => option.nome}
                  onChange={setFiltro}
                  cacheOptions
                  isClearable
                  value={filtro}
                  placeholder={"Busque pelo nome"}
                  noOptionsMessage={() => "Digite o nome"}
                />
              </Col>
              {(tipo === "parceiros" || tipo === "Revendedores") && (
                <Col lg={4} sm={6} className="mb-1">
                  <AsyncSelect
                    loadOptions={(value) =>
                      debounceConsulta({
                        value,
                        param: "cnpj",
                      })
                    }
                    cacheOptions
                    onChange={setFiltro}
                    value={filtro}
                    isClearable
                    getOptionLabel={(option) => option.cnpj}
                    placeholder={"CNPJ"}
                    noOptionsMessage={() => "Digite o CNPJ"}
                  />
                </Col>
              )}
              <Col lg={2} sm={6} className="mb-1">
                <select
                  className="input-field-inner-select opts-select"
                  onChange={(e) => {
                    setStatus(e.target.value);
                    setFiltro(null);
                  }}
                  value={status}
                >
                  <option value="" disabled selected>
                    Status
                  </option>
                  <option key={"T"} value={""}>
                    Todos
                  </option>
                  <option key={"A"} value={"A"}>
                    A
                  </option>
                  <option key={"I"} value={"I"}>
                    I
                  </option>
                </select>
              </Col>
            </Row>
          </UncontrolledCollapse>
        </div>
        <Row>
          <Col lg={12}>
            <Table responsive>
              <thead>
                <tr>
                  <th>{tipo === "clientes" ? "Nome" : "Razão social"}</th>
                  {tipo !== "clientes" && <th>Nome Fantasia</th>}
                  <th>{tipo === "clientes" ? "CPF" : "CNPJ"}</th>
                  <th className="text-center">Status</th>
                  <th className="text-center">Ações</th>
                </tr>
              </thead>
              <tbody>
                {data?.rows?.map((data) => (
                  <>
                    <tr key={data?.id}>
                      <td>{data?.nome || data?.razao_social}</td>
                      {tipo !== "clientes" && <td>{data?.nome_fantasia}</td>}
                      <td>
                        {(data?.cpf &&
                          formatCPF(`00000000000${data?.cpf}`.slice(-11))) ||
                          (data?.cnpj &&
                            formatCNPJ(
                              `00000000000000${data?.cnpj}`.slice(-14)
                            ))}
                      </td>
                      <td className="text-center">
                        {data?.status === "A" ? (
                          <div className="txt-primary"> {data?.status}</div>
                        ) : (
                          <div className="txt-danger"> {data?.status}</div>
                        )}
                      </td>
                      <td className="text-center">
                        <UncontrolledButtonDropdown size="xs">
                          <DropdownToggle color="link link-gray" size="xs">
                            <FaEllipsisH />
                          </DropdownToggle>
                          <DropdownMenu right>
                            {data?.status === "A" ? (
                              <DropdownItem
                                className="justify-start"
                                onClick={() =>
                                  handleActivateInactivate(
                                    data?.id,
                                    "inativar",
                                    "I"
                                  )
                                }
                              >
                                <GrHide />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Inativar
                              </DropdownItem>
                            ) : (
                              <DropdownItem
                                className="justify-start"
                                onClick={() =>
                                  handleActivateInactivate(
                                    data?.id,
                                    "ativar",
                                    "A"
                                  )
                                }
                              >
                                <GrFormView />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ativar
                              </DropdownItem>
                            )}

                            <DropdownItem className="justify-start">
                              <Link
                                to={`/acesso/alterar-dados-adm/${tipo}/${data?.id}`}
                                style={{
                                  textDecoration: "none",
                                  color: "#16181B",
                                }}
                              >
                                <GrEdit />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Editar
                              </Link>
                            </DropdownItem>

                            {tipo === "parceiros" && (
                              <DropdownItem
                                className="justify-start"
                                onClick={() => handleDelete(data?.id)}
                              >
                                <GrTrash />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Excluir
                              </DropdownItem>
                            )}
                          </DropdownMenu>
                        </UncontrolledButtonDropdown>
                      </td>
                    </tr>
                    {data?.dependentes &&
                      data?.dependentes?.map((dependente, i) => (
                        <tr
                          style={{
                            fontWeight: "300",
                          }}
                        >
                          <td>{dependente?.nome}</td>
                          <td>
                            {dependente?.cpf &&
                              formatCPF(
                                `00000000000${dependente?.cpf}`.slice(-11)
                              )}
                          </td>
                          <td
                            style={{
                              backgroundColor: "orange",
                              color: "white",
                            }}
                            className="text-center"
                            colSpan={2}
                          >
                            {dependente?.parentesco}
                          </td>
                        </tr>
                      ))}
                  </>
                ))}
              </tbody>
            </Table>
            <Pagination
              nextLabel={<FaChevronRight />}
              previousLabel={<FaChevronLeft />}
              pageCount={Number(data?.pages) || 1}
              onPageChange={({ selected }) => setPage(selected)}
              initialPage={page}
              containerClassName={"pagination justify-end"}
              activeClassName={"active"}
            />
          </Col>
        </Row>
      </div>
    </>
  );
}

export default ConsultarTodosAdm;
