import { useState, useEffect } from "react";
import {
  Col,
  Row,
  UncontrolledPopover,
  Button,
  Table,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { TiExport } from "react-icons/ti";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import {
  startOfYear,
  endOfYear,
  startOfMonth,
  endOfMonth,
  format,
} from "date-fns";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import InfiniteScroll from "react-infinite-scroll-component";
import { ResponsiveFunnel } from "@nivo/funnel";
import { ResponsiveBar } from "@nivo/bar";
import { ResponsivePie } from "@nivo/pie";
import DatePicker from "react-datepicker";
import xlsx from "json-as-xlsx";

import html2canvas from "html2canvas";
import jsPdf from "jspdf";

import { AiOutlineLineChart } from "react-icons/ai";
import { BsCardList } from "react-icons/bs";

import api from "../../../services/api";

function formatDataChartDeclinio(nnsortedArray) {
  const objectMonth = [
    {
      mes: "Jan.",
    },
    {
      mes: "Fev.",
    },
    {
      mes: "Mar.",
    },
    {
      mes: "Abr.",
    },
    {
      mes: "Mai.",
    },
    {
      mes: "Jun.",
    },
    {
      mes: "Jul.",
    },
    {
      mes: "Ago.",
    },
    {
      mes: "Set.",
    },
    {
      mes: "Out.",
    },
    {
      mes: "Nov.",
    },
    {
      mes: "Dez.",
    },
    {
      keys: [],
    },
  ];
  const array = nnsortedArray.sort((a, b) => a.mes - b.mes);
  array.forEach((item) => {
    objectMonth[item.mes - 1][item.cidade] = item.valor;
    if (!objectMonth[12].keys.includes(item.cidade)) {
      objectMonth[12].keys.push(item.cidade);
    }
  });

  return objectMonth;
}

function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("/");
}

function Dashboard() {
  const startMonth = startOfMonth(new Date());
  const endMonth = endOfMonth(new Date());

  const startYear = startOfYear(new Date());
  const endYear = endOfYear(new Date());

  const [periodo, setPeriodo] = useState({
    dataInicio: format(startMonth, "yyyy/MM/dd"),
    dataFim: format(endMonth, "yyyy/MM/dd"),
  });
  const [periodoYear, setPeriodoYear] = useState({
    dataInicio: format(startYear, "yyyy/MM/dd"),
    dataFim: format(endYear, "yyyy/MM/dd"),
  });
  const [modal, setModal] = useState(false);

  const [tipo, setTipo] = useState("");
  const [filtro, setFiltro] = useState(null);
  const [dataCidades, setDataCidades] = useState(null);

  const [dataForGraphs, setDataForGraphs] = useState([]);

  const [selectedOpt, setSelectedOpt] = useState(null);

  const [dataBarChart, setDataBarChart] = useState(null);

  const [csvData, setCsvData] = useState([]);

  const onChangePeriodo = (dates) => {
    const [start, end] = dates;

    setPeriodo({
      start,
      end,
      dataInicio: start ? formatDate(start) : format(startMonth, "yyyy/MM/dd"),
      dataFim: end ? formatDate(end) : format(endMonth, "yyyy/MM/dd"),
    });
  };

  const printPDF = () => {
    const domElement = document.getElementById("charts");
    html2canvas(domElement).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPdf({
        orientation: "l",
      });
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);
      pdf.save(`${new Date().toISOString()}.pdf`);
    });
  };

  useEffect(() => {
    async function getDataCidades() {
      setDataCidades(null);
      try {
        Swal.fire({
          title: "Aguarde",
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });
        const body = {
          dataInicio: `'${periodo.dataInicio}'`,
          dataFim: `'${periodo.dataFim}'`,
          tipo: tipo !== "" ? tipo : null,
          user: filtro ? filtro.value : null,
        };
        const { data } = await api.post("/dashboard-cidades", body);
        if (data.length) {
          const sortedData = data.sort(
            (a, b) => Number(b?.valor) - Number(a?.valor)
          );
          console.log(sortedData);
          let dataArray = [];
          let dataArrayBarChart = [];
          sortedData.forEach((cidade) => {
            dataArray.push({
              id: cidade.cidade,
              value: Number(cidade.valor),
              label: cidade.cidade,
            });
            dataArrayBarChart.push({
              name: String(cidade?.cidade),
              Vendas: Number(cidade?.vendas),
              VendasFixed: 5,
              Representantes: Number(cidade?.representantes),
              RepresentantesFixed: Number(cidade?.representantes) * 3,
              Valor: Number(cidade.valor),
              ValorFixed:
                Number(cidade.valor) < 500 ? 10 : Number(cidade.valor) / 70,
            });
          });
          setDataBarChart(dataArrayBarChart);
          setDataCidades(dataArray);
        } else {
          setDataBarChart(null);
          setDataCidades(null);
        }
        Swal.close();
      } catch (err) {
        toast.error("Ocorreu um erro ao buscar dados!");
        Swal.close();
      }
    }
    getDataCidades();
  }, [periodo, tipo, filtro]);

  useEffect(() => {
    async function getDataGraphs() {
      setDataForGraphs([]);
      try {
        Swal.fire({
          title: "Aguarde",
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });
        if (selectedOpt === "EV") {
          const body = {
            dataInicio: `'${periodoYear.dataInicio}'`,
            dataFim: `'${periodoYear.dataFim}'`,
          };
          const { data } = await api.post("/dashboard-cidades-evolucao", body);
          if (data) {
            setDataForGraphs(formatDataChartDeclinio(data));
          } else {
            setDataForGraphs([]);
          }
        } else if (selectedOpt === "AN") {
          const body = {
            dataInicio: `'${periodo.dataInicio}'`,
            dataFim: `'${periodo.dataFim}'`,
            tipo: tipo !== "" ? tipo : null,
            user: filtro ? filtro.value : null,
          };
          const { data } = await api.post("/dashboard-cidades-analitico", body);
          if (data) {
            setDataForGraphs(data);
            setCsvData(data);
          } else {
            setDataForGraphs([]);
            setCsvData([]);
          }
        }
        Swal.close();
      } catch (e) {
        toast.error("Ocorreu um erro ao buscar dados!");
        Swal.close();
      }
    }
    getDataGraphs();
  }, [selectedOpt, periodo, tipo, filtro, periodoYear]);

  // async function getDadosInfiniteScroll() {
  //   const body = {
  //     dataInicio: `'${periodo.dataInicio}'`,
  //     dataFim: `'${periodo.dataFim}'`,
  //     tipo: tipo !== "" ? tipo : null,
  //     user: filtro ? filtro.value : null,
  //   };
  //   const { data } = await api.post(
  //     `/dashboard-Cidades-analitico&page=${currentPage + 1}`,
  //     body
  //   );

  //   if (data) {
  //     setDataForGraphs(data);
  //   }
  //   setCurrentPage(currentPage + 1);
  // }

  const toggle = (selected) => {
    setSelectedOpt(selected);
    setModal(!modal);
  };

  const handleExport = () => {
    const columns = [
      {
        label: "Cidade",
        value: (row) => (row.cidade ? row.cidade : ""),
      },
      {
        label: "Cliente",
        value: (row) => (row.cliente ? row.cliente : ""),
      },
      {
        label: "Vendedor",
        value: (row) => (row.vendedor ? row.vendedor : ""),
      },
      {
        label: "Pedido",
        value: (row) => (row.pedido ? row.pedido : ""),
      },
      {
        label: "Valor",
        value: (row) =>
          row.valor
            ? row.valor.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })
            : "",
      },
    ];

    const settings = {
      sheetName: "FirstSheet",
      fileName: `relatorio-produtos-${format(new Date(), "dd-MM-yyyy")}`,
      extraLength: 3, // A bigger number means that columns should be wider
      writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
    };

    xlsx(columns, csvData, settings, true);
  };

  return (
    <>
      <Row>
        <Col lg={9} className="d-flex justify-between align-center">
          <h5 className="txt-tertiary">Dashboard</h5>
        </Col>
        <Col lg={3}>
          <div className="mb-3 d-flex justify-end">
            <Button
              id="periodo_emissao"
              type="button"
              color="link"
              className="ml-1 mt-3 btn-block"
              style={{
                maxHeight: 38,
              }}
            >
              Período
            </Button>
            <UncontrolledPopover
              placement="bottom"
              target="#periodo_emissao"
              trigger="legacy"
              hideArrow
            >
              <DatePicker
                selected={periodo?.start}
                onChange={onChangePeriodo}
                startDate={periodo?.start}
                endDate={periodo?.end}
                locale="pt-BR"
                selectsRange
                inline
              />
            </UncontrolledPopover>
            <Button
              color="outline-primary"
              className="btn-save mt-3 ml-3 d-flex"
              onClick={() => printPDF()}
              size="md"
              style={{
                maxHeight: 38,
              }}
            >
              <TiExport size={20} />
              &nbsp;&nbsp;Exportar
            </Button>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="card-form">
            <Row className="justify-center">
              <Col lg={2} xs={12} sm={12}>
                <Button
                  style={{ textAlign: "center" }}
                  id="toggler-4"
                  className="btn-benefdesc"
                  size="sm"
                  onClick={() => {
                    toggle("EV");
                  }}
                  block
                >
                  <AiOutlineLineChart size={20} />
                  &nbsp;&nbsp;Evolução e declínio
                </Button>
              </Col>
              <Col lg={2} xs={12} sm={12}>
                <Button
                  style={{ textAlign: "center" }}
                  id="toggler-4"
                  className="btn-benefdesc"
                  size="sm"
                  onClick={() => {
                    toggle("AN");
                  }}
                  block
                >
                  <BsCardList size={20} />
                  &nbsp;&nbsp;Analítico
                </Button>
              </Col>
            </Row>
            <hr />
            <Row className="mt-3 ml-0" id="charts">
              {dataCidades ? (
                <>
                  <StackedChartBar data={dataBarChart} />
                  {/* <StackedChart data={dataCidades} />
                  <PieChart data={dataCidades} /> */}
                </>
              ) : (
                <Col className="justify-center">
                  <h6 style={{ textAlign: "center", color: "gray" }}>
                    Sem registros nesse período
                  </h6>
                </Col>
              )}
            </Row>
          </div>
        </Col>
      </Row>
      <Modal isOpen={modal} size="xl">
        <ModalHeader toggle={toggle}>
          {selectedOpt === "EV" ? "Evolução e declínio" : "Analítico"}
        </ModalHeader>
        <ModalBody>
          <Row className="justify-end">
            {selectedOpt === "EV" ? (
              <LineChartView data={dataForGraphs} />
            ) : (
              <>
                {csvData.length && (
                  <Button
                    onClick={handleExport}
                    className="btn-save mb-2 d-flex mr-2"
                  >
                    <TiExport size={20} />
                    Exportar tabela
                  </Button>
                )}
                <div
                  style={{
                    maxHeight: "300px",
                    overflowY: "auto",
                    width: "100%",
                  }}
                >
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Cidade</th>
                        <th>Cliente</th>
                        <th>Vendedor</th>
                        <th>Pedido</th>
                        <th className="justify-center">Valor</th>
                      </tr>
                    </thead>
                    <tbody>
                      {/* <InfiniteScroll
                      dataLength={dataForGraphs?.vendas?.length || 0}
                      next={getDadosInfiniteScroll}
                      hasMore={dataForGraphs?.vendas?.pages > currentPage}
                      loader={<h6>Carregando...</h6>}
                    > */}
                      {dataForGraphs?.map((rep) => (
                        <tr key={Math.random().toString(36).substring(7)}>
                          <td>{rep?.cidade}</td>
                          <td>{rep?.cliente}</td>
                          <td>{rep?.vendedor}</td>
                          <td>{rep?.pedido}</td>
                          <td className="justify-center">
                            {Number(rep?.valor)?.toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })}
                          </td>
                        </tr>
                      ))}
                      {/* </InfiniteScroll> */}
                    </tbody>
                  </Table>
                </div>
              </>
            )}
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
}

function StackedChartBar({ data }) {
  return (
    <>
      <Col className="mb-3 justify-center" lg={12}>
        <span
          style={{
            backgroundColor: "#7367f0",
            marginLeft: "15px",
            marginRight: "5px",
            marginTop: "2px",
            padding: "0px",
            borderRadius: "100px",
            color: "white",
            width: "15px",
            height: "15px",
            textAlign: "center",
            fontWeight: "bold",
          }}
        />
        Vendas
        <span
          style={{
            backgroundColor: "#ea5455",
            marginLeft: "15px",
            marginRight: "5px",
            marginTop: "2px",
            padding: "0px",
            borderRadius: "100px",
            color: "white",
            width: "15px",
            height: "15px",
            textAlign: "center",
            fontWeight: "bold",
          }}
        />
        Representantes
        <span
          style={{
            backgroundColor: "#28c76f",
            marginLeft: "15px",
            marginRight: "5px",
            marginTop: "2px",
            padding: "0px",
            borderRadius: "100px",
            color: "white",
            width: "15px",
            height: "15px",
            textAlign: "center",
            fontWeight: "bold",
          }}
        />
        Valor
      </Col>
      <ResponsiveContainer
        height={data?.length <= 2 ? 180 : data?.length * 90 + 10}
        width="100%"
      >
        <BarChart
          layout="vertical"
          data={data}
          margin={{
            top: 10,
            right: 50,
            left: 50,
            bottom: 0,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type="number" tick={false} />
          <YAxis type="category" dataKey="name" />
          <Bar dataKey="VendasFixed" stackId="a" fill="#7367f0">
            {/* <LabelList fill="white" position="insideTop" angle="90" offset="35">
            Vendas
          </LabelList> */}
            <LabelList
              fill="white"
              dataKey="Vendas"
              position="center"
              offset="7"
            />
          </Bar>
          <Bar dataKey="RepresentantesFixed" stackId="a" fill="#ea5455">
            {/* <LabelList fill="white" position="insideTop" angle="90" offset="60">
            Representantes
          </LabelList> */}
            <LabelList
              fill="white"
              dataKey="Representantes"
              position="center"
              offset="7"
            />
          </Bar>
          <Bar dataKey="ValorFixed" stackId="a" fill="#28c76f">
            {/* <LabelList fill="white" position="insideTop" angle="90" offset="30">
            Valor
          </LabelList> */}
            <LabelList
              fill="white"
              dataKey="Valor"
              position="center"
              offset="7"
              formatter={(datakey) => {
                return datakey.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                });
              }}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </>
  );
}

function PieChart({ data }) {
  return (
    <Col className="container-stacked-bar-chart" lg={8}>
      <ResponsivePie
        data={data}
        margin={{ top: 40, right: 100, bottom: 80, left: -50 }}
        innerRadius={0.5}
        sortByValue={true}
        colors={{ scheme: "accent" }}
        padAngle={0.7}
        cornerRadius={3}
        activeOuterRadiusOffset={8}
        borderWidth={1}
        borderColor={{ from: "color", modifiers: [["darker", 0.2]] }}
        arcLinkLabelsSkipAngle={10}
        arcLinkLabelsTextColor="#333333"
        arcLinkLabelsThickness={2}
        arcLinkLabelsColor={{ from: "color" }}
        arcLabelsSkipAngle={10}
        arcLabelsTextColor={{ from: "color", modifiers: [["darker", 2]] }}
        valueFormat={(value) => {
          return value?.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          });
        }}
        defs={[
          {
            id: "dots",
            type: "patternDots",
            background: "inherit",
            color: "rgba(255, 255, 255, 0.3)",
            size: 4,
            padding: 1,
            stagger: true,
          },
          {
            id: "lines",
            type: "patternLines",
            background: "inherit",
            color: "rgba(255, 255, 255, 0.3)",
            rotation: -45,
            lineWidth: 6,
            spacing: 10,
          },
        ]}
        fill={[
          {
            match: {
              id: "ruby",
            },
            id: "dots",
          },
          {
            match: {
              id: "c",
            },
            id: "dots",
          },
          {
            match: {
              id: "go",
            },
            id: "dots",
          },
          {
            match: {
              id: "python",
            },
            id: "dots",
          },
          {
            match: {
              id: "scala",
            },
            id: "lines",
          },
          {
            match: {
              id: "lisp",
            },
            id: "lines",
          },
          {
            match: {
              id: "elixir",
            },
            id: "lines",
          },
          {
            match: {
              id: "javascript",
            },
            id: "lines",
          },
        ]}
        legends={[
          {
            anchor: "right",
            direction: "column",
            justify: false,
            translateX: 0,
            translateY: 200,
            itemsSpacing: 0,
            itemWidth: 100,
            itemHeight: 18,
            itemTextColor: "#999",
            itemDirection: "left-to-right",
            itemOpacity: 1,
            symbolSize: 18,
            symbolShape: "circle",
            effects: [
              {
                on: "hover",
                style: {
                  itemTextColor: "#000",
                },
              },
            ],
          },
        ]}
      />
    </Col>
  );
}

function StackedChart({ data }) {
  return (
    <Col className="container-stacked-bar-chart" lg={4}>
      <ResponsiveFunnel
        data={data}
        margin={{ top: 20, right: 50, bottom: 100, left: 0 }}
        valueFormat={(value) => {
          return value?.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          });
        }}
        colors={{ scheme: "accent" }}
        borderWidth={0}
        labelColor="black"
        borderOpacity={1}
        beforeSeparatorLength={30}
        beforeSeparatorOffset={99}
        afterSeparatorLength={67}
        shapeBlending={1}
        currentPartSizeExtension={50}
        currentBorderWidth={0}
        motionConfig="wobbly"
      />
    </Col>
  );
}

function LineChartView({ data }) {
  const [zoom, setZoom] = useState("linear");

  function changeZoom() {
    if (zoom === "linear") {
      setZoom("symlog");
    } else {
      setZoom("linear");
    }
  }

  return (
    <Col
      className="container-stacked-bar-chart-evo"
      onClick={changeZoom}
      lg={12}
    >
      <ResponsiveBar
        data={data}
        keys={data[12]?.keys}
        indexBy="mes"
        margin={{ top: 50, right: 183, bottom: 80, left: 110 }}
        padding={0}
        valueScale={{ type: zoom }}
        indexScale={{ type: "band", round: true }}
        colors={{ scheme: "nivo" }}
        valueFormat={(value) => {
          return value?.toLocaleString("pt-BR", {
            style: "currency",
            currency: "BRL",
          });
        }}
        borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Mês",
          legendPosition: "middle",
          legendOffset: 32,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "R$ em vendas",
          legendPosition: "middle",
          legendOffset: -100,
          format: function (value) {
            return value?.toLocaleString("pt-BR", {
              style: "currency",
              currency: "BRL",
            });
          },
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        legends={[
          {
            dataFrom: "keys",
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: "left-to-right",
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </Col>
  );
}

export default Dashboard;
