import {
  Table,
  Col,
  Row,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import AsyncSelect from "../../../components/AsyncSelect";
import api from "../../../services/api";
import { useEffect, useRef, useState } from "react";
import formatCpf from "../../../utils/formatCPF";
import iconeFiltro from "../../../assets/icone_filtro.png";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { FaEllipsisH } from "react-icons/fa";
import ModalCobranca from "./modalCobranca";

function GerarBoleto() {
  const [data, setData] = useState(null);
  const [filtro, setFiltro] = useState(null);
  const [modal, setModal] = useState(false);
  const [clienteModal, setClienteModal] = useState(null);

  let timeoutConsulta;

  const debounceConsulta = async (body) => {
    return await new Promise((resolve) => {
      clearTimeout(timeoutConsulta);

      timeoutConsulta = setTimeout(() => {
        resolve(filtrarConsulta(body));
      }, 500);
    });
  };

  async function filtrarConsulta({ value, param }) {
    const result = await api.get(`options/clientes?${param}=${value}`);
    return result.data.map((cliente) => ({
      value: cliente.id,
      cpf: cliente.cpf,
      nome: cliente.nome,
    }));
  }

  async function gerarSegundaVia(id) {
    try {
      Swal.fire({
        title: "Aguarde",
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      const { data: pedido } = await api.get(`/cliente/segunda-via/${id}`);
      const link = pedido.link || pedido.installmentLink;
      if (link) {
        window.open(link);
      } else {
        toast.warn("Nenhuma parcela em aberto");
      }
    } catch (err) {
      const error =
        err?.response?.data?.error || "Ocorreu um erro, tente novamente!";
      toast.error(error);
    } finally {
      Swal.close();
    }
  }

  const toggle = () => setModal(!modal);

  const abrirModalBoleto = (cliente) => {
    setClienteModal(cliente);
    toggle();
  };

  useEffect(() => {
    async function getTitular() {
      if (filtro) {
        const { data: result } = await api.get(`/titular/${filtro.value}`);
        setData(result);
      } else {
        setData(null);
      }
    }
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 500);
    getTitular();
  }, [filtro]);

  return (
    <>
      <Row>
        <Col lg={11} className="mb-1">
          <div className="justify-between">
            <h5 className="txt-tertiary">Emitir boletos</h5>
          </div>
        </Col>
      </Row>
      <div className="card-form p-0">
        <Row>
          <Col lg={12}>
            <Row className="mt-3 ml-1 mr-1">
              <Col lg={4} sm={6} className="mb-1">
                <AsyncSelect
                  loadOptions={(value) =>
                    debounceConsulta({
                      value,
                      param: "nome",
                    })
                  }
                  getOptionLabel={(option) => option.nome}
                  onChange={setFiltro}
                  value={filtro}
                  cacheOptions
                  isClearable
                  placeholder={"Busque pelo nome"}
                  noOptionsMessage={() => "Digite o nome"}
                />
              </Col>
              <Col lg={4} sm={6} className="mb-1">
                <AsyncSelect
                  loadOptions={(value) =>
                    debounceConsulta({
                      value,
                      param: "cpf",
                    })
                  }
                  cacheOptions
                  onChange={setFiltro}
                  value={filtro}
                  isClearable
                  getOptionLabel={(option) => option.cpf}
                  placeholder={"CPF"}
                  noOptionsMessage={() => "Digite o CPF"}
                />
              </Col>
            </Row>
            <hr />
            <Table responsive>
              <thead>
                <tr>
                  <th>Nome do titular</th>
                  <th>CPF</th>
                  <th>Status</th>
                  <th>Ações</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>{data?.nome}</td>
                  <td>
                    {data?.cpf &&
                      formatCpf(`00000000000${data?.cpf}`.slice(-11))}
                  </td>
                  <td>
                    {data?.status === "A" ? (
                      <div className="txt-primary"> {data?.status}</div>
                    ) : (
                      <div className="txt-danger"> {data?.status}</div>
                    )}
                  </td>
                  <td>
                    {data && (
                      <UncontrolledDropdown size="xs">
                        <DropdownToggle color="link link-gray" size="xs">
                          <FaEllipsisH size="1.3em" />
                        </DropdownToggle>
                        <DropdownMenu right>
                          <DropdownItem
                            onClick={() => abrirModalBoleto(data?.id)}
                            size="xs"
                            className="btn-danger"
                          >
                            Gerar boleto
                          </DropdownItem>
                          <DropdownItem
                            size="xs"
                            onClick={() => gerarSegundaVia(data?.id)}
                            className="btn-danger"
                          >
                            2°via último pedido
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    )}
                  </td>
                </tr>
              </tbody>

              {data?.dependentes?.length > 0 && (
                <>
                  <thead className="border-top">
                    <tr>
                      <th>Dependentes</th>
                      <th></th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.dependentes?.map((dependente) => (
                      <tr key={dependente.id}>
                        <td>{dependente.nome}</td>
                        <td>
                          {formatCpf(`00000000000${dependente.cpf}`.slice(-11))}
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                    ))}
                  </tbody>
                </>
              )}
            </Table>
          </Col>
        </Row>
      </div>
      <ModalCobranca toggle={toggle} cliente={clienteModal} isOpen={modal} />
    </>
  );
}

export default GerarBoleto;
