import { Button, Col, Row } from "reactstrap";
import CartaoGold from "../../components/cartaoGold";
import CartaoBlack from "../../components/cartaoBlack";
import CartaoEspecial from "../../components/cartaoEspecial";
import { useHistory, useLocation } from "react-router";
import useFetch from "../../hooks/useFetch";
import { useWhiteLabel } from "../../contexts/whitelabel";
import CartaoEmpresarial from "../../components/cartaoEmpresarial";

function SectionCartoes() {
  const { dadosWhiteLabel } = useWhiteLabel();
  const { data: cartoes } = useFetch(`/cartoes`);
  const history = useHistory();
  const location = useLocation();
  const portalVendas = window.location.pathname === "/acesso/portal-venda";
  const query = location.search.substr(1).split("&").filter(Boolean);

  const options = portalVendas
    ? {
        lg: 4,
        md: 4,
        sm: 12,
      }
    : {
        lg: 3,
        md: 6,
        sm: 12,
      };

  function goTo(tipo) {
    window.scrollTo(0, 0);
    if (portalVendas) {
      history.push(`/acesso/portal-venda/${tipo}`);
    } else {
      const vendedor = query
        .find((f) => f.includes("vend="))
        ?.replace("vend=", "");

      const urlWhiteLabel = dadosWhiteLabel
        ? dadosWhiteLabel.url_whitelabel
        : "poupa-club";
      history.push(
        `/${urlWhiteLabel}/cartao-escolhido/${tipo}?${
          vendedor ? "vend=" + vendedor : ""
        }`
      );
    }
  }

  function goToEmpresarial() {
    window.scrollTo(0, 0);
    if (portalVendas) {
      history.push(`/acesso/portal-venda/empresarial`);
    } else {
      const vendedor = query
        .find((f) => f.includes("vend="))
        ?.replace("vend=", "");

      const urlWhiteLabel = dadosWhiteLabel
        ? dadosWhiteLabel.url_whitelabel
        : "poupa-club";
      history.push(
        `/${urlWhiteLabel}/cartao-empresarial?${
          vendedor ? "vend=" + vendedor : ""
        }`
      );
    }
  }

  function getCartao(tipo) {
    if (dadosWhiteLabel) {
      const tipoCartoes = {
        especial: 1,
        gold: 2,
        black: 3,
      };

      return dadosWhiteLabel[`cartao${tipoCartoes[tipo]}`];
    } else {
      return cartoes?.find((f) => f.descricao === tipo);
    }
  }

  return (
    <>
      <Row className="justify-center">
        <Col {...options} className="mb-3 height-auto">
          <div
            className={
              portalVendas ? "card-cartoes-menor card" : "card-cartoes card"
            }
          >
            <CartaoEspecial data={getCartao("especial")} />
            <div className="box-cartao">
              <div className="justify-center">
                <div
                  className={
                    dadosWhiteLabel
                      ? "bold price-subtitle-whitelabel"
                      : "bold price-subtitle"
                  }
                  style={{
                    color: dadosWhiteLabel
                      ? dadosWhiteLabel?.whitelabel_text_color_3
                      : "",
                  }}
                >
                  R$ &nbsp;
                </div>
                <div
                  style={{
                    marginBottom: "-5px",
                    display: "flex",
                  }}
                >
                  <div
                    className={
                      dadosWhiteLabel
                        ? "bold price-title-whitelabel"
                        : "bold price-title"
                    }
                    style={{
                      color: dadosWhiteLabel
                        ? dadosWhiteLabel?.whitelabel_text_color_3
                        : "",
                    }}
                  >
                    {
                      Number(getCartao("especial")?.valor / 12)
                        ?.toLocaleString("pt-BR", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                        ?.split(",")[0]
                    }
                    ,
                  </div>
                  <div
                    className={
                      dadosWhiteLabel
                        ? "bold price-up-whitelabel"
                        : "bold price-up"
                    }
                    style={{
                      color: dadosWhiteLabel
                        ? dadosWhiteLabel?.whitelabel_text_color_3
                        : "",
                    }}
                  >
                    {
                      Number(getCartao("especial")?.valor / 12)
                        ?.toLocaleString("pt-BR", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                        ?.split(",")[1]
                    }
                  </div>
                </div>
                <div
                  className={
                    dadosWhiteLabel
                      ? "bold price-subtitle-whitelabel"
                      : "bold price-subtitle"
                  }
                  style={{
                    color: dadosWhiteLabel
                      ? dadosWhiteLabel?.whitelabel_text_color_3
                      : "",
                  }}
                >
                  por mês
                </div>
              </div>
              <div className="mt-3">
                <p
                  className={
                    dadosWhiteLabel ? "via-cartao-whitelabel" : "via-cartao"
                  }
                  style={{
                    color: dadosWhiteLabel
                      ? dadosWhiteLabel?.whitelabel_text_color_3
                      : "",
                  }}
                >
                  via cartão de crédito
                </p>
              </div>
              <div className="footer-poupaclub justify-center">
                <Button
                  className={
                    dadosWhiteLabel
                      ? "btn-pill-3 bold " + dadosWhiteLabel?.cor_cartao_1
                      : "btn-pill-3 bold"
                  }
                  color="especial"
                  size="lg"
                  onClick={() => goTo("especial")}
                >
                  Peça agora
                </Button>
              </div>
            </div>
          </div>
        </Col>
        <Col
          {...options}
          className="mb-3 height-auto"
          hidden={dadosWhiteLabel?.qtd_cartoes < 2}
        >
          <div
            className={
              portalVendas ? "card-cartoes-menor card" : "card-cartoes card"
            }
          >
            <CartaoGold data={getCartao("gold")} />
            <div className="box-cartao">
              <div className="justify-center">
                <div
                  className={
                    dadosWhiteLabel
                      ? "bold price-subtitle-whitelabel"
                      : "bold price-subtitle"
                  }
                  style={{
                    color: dadosWhiteLabel
                      ? dadosWhiteLabel?.whitelabel_text_color_3
                      : "",
                  }}
                >
                  R$ &nbsp;
                </div>
                <div
                  style={{
                    marginBottom: "-5px",
                    display: "flex",
                  }}
                >
                  <div
                    className={
                      dadosWhiteLabel
                        ? "bold price-title-whitelabel"
                        : "bold price-title"
                    }
                    style={{
                      color: dadosWhiteLabel
                        ? dadosWhiteLabel?.whitelabel_text_color_3
                        : "",
                    }}
                  >
                    {
                      Number(getCartao("gold")?.valor / 12)
                        ?.toLocaleString("pt-BR", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                        ?.split(",")[0]
                    }
                    ,
                  </div>
                  <div
                    className={
                      dadosWhiteLabel
                        ? "bold price-up-whitelabel"
                        : "bold price-up"
                    }
                    style={{
                      color: dadosWhiteLabel
                        ? dadosWhiteLabel?.whitelabel_text_color_3
                        : "",
                    }}
                  >
                    {
                      Number(getCartao("gold")?.valor / 12)
                        ?.toLocaleString("pt-BR", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                        ?.split(",")[1]
                    }
                  </div>
                </div>
                <div
                  className={
                    dadosWhiteLabel
                      ? "bold price-subtitle-whitelabel"
                      : "bold price-subtitle"
                  }
                  style={{
                    color: dadosWhiteLabel
                      ? dadosWhiteLabel?.whitelabel_text_color_3
                      : "",
                  }}
                >
                  por mês
                </div>
              </div>
              <div className="mt-3">
                <p
                  className={
                    dadosWhiteLabel ? "via-cartao-whitelabel" : "via-cartao"
                  }
                  style={{
                    color: dadosWhiteLabel
                      ? dadosWhiteLabel?.whitelabel_text_color_3
                      : "",
                  }}
                >
                  via cartão de crédito
                </p>
              </div>
              <div className="footer-poupaclub justify-center">
                <Button
                  className={
                    dadosWhiteLabel
                      ? "btn-pill-3 bold " + dadosWhiteLabel?.cor_cartao_2
                      : "btn-pill-3 bold"
                  }
                  color="gold"
                  size="lg"
                  onClick={() => goTo("gold")}
                >
                  Peça agora
                </Button>
              </div>
            </div>
          </div>
        </Col>
        <Col
          {...options}
          className="mb-3 height-auto"
          hidden={dadosWhiteLabel?.qtd_cartoes < 3}
        >
          <div
            className={
              portalVendas ? "card-cartoes-menor card" : "card-cartoes card"
            }
          >
            <CartaoBlack data={getCartao("black")} />
            <div className="box-cartao">
              <div className="justify-center">
                <div
                  className={
                    dadosWhiteLabel
                      ? "bold price-subtitle-whitelabel"
                      : "bold price-subtitle"
                  }
                  style={{
                    color: dadosWhiteLabel
                      ? dadosWhiteLabel?.whitelabel_text_color_3
                      : "",
                  }}
                >
                  R$ &nbsp;
                </div>
                <div
                  style={{
                    marginBottom: "-5px",
                    display: "flex",
                  }}
                >
                  <div
                    className={
                      dadosWhiteLabel
                        ? "bold price-title-whitelabel"
                        : "bold price-title"
                    }
                    style={{
                      color: dadosWhiteLabel
                        ? dadosWhiteLabel?.whitelabel_text_color_3
                        : "",
                    }}
                  >
                    {
                      Number(getCartao("black")?.valor / 12)
                        ?.toLocaleString("pt-BR", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                        ?.split(",")[0]
                    }
                    ,
                  </div>
                  <div
                    className={
                      dadosWhiteLabel
                        ? "bold price-up-whitelabel"
                        : "bold price-up"
                    }
                    style={{
                      color: dadosWhiteLabel
                        ? dadosWhiteLabel?.whitelabel_text_color_3
                        : "",
                    }}
                  >
                    {
                      Number(getCartao("black")?.valor / 12)
                        ?.toLocaleString("pt-BR", {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })
                        ?.split(",")[1]
                    }
                  </div>
                </div>
                <div
                  className={
                    dadosWhiteLabel
                      ? "bold price-subtitle-whitelabel"
                      : "bold price-subtitle"
                  }
                  style={{
                    color: dadosWhiteLabel
                      ? dadosWhiteLabel?.whitelabel_text_color_3
                      : "",
                  }}
                >
                  por mês
                </div>
              </div>
              <div className="mt-3">
                <p
                  className={
                    dadosWhiteLabel ? "via-cartao-whitelabel" : "via-cartao"
                  }
                  style={{
                    color: dadosWhiteLabel
                      ? dadosWhiteLabel?.whitelabel_text_color_3
                      : "",
                  }}
                >
                  via cartão de crédito
                </p>
              </div>
              <div className="footer-poupaclub justify-center">
                <Button
                  className={
                    dadosWhiteLabel
                      ? "btn-pill-3 bold " + dadosWhiteLabel?.cor_cartao_3
                      : "btn-pill-3 bold"
                  }
                  color="black"
                  size="lg"
                  onClick={() => goTo("black")}
                >
                  Peça agora
                </Button>
              </div>
            </div>
          </div>
        </Col>
        {!portalVendas && (
          <Col {...options} className="mb-3 height-auto">
            <div
              className={
                portalVendas ? "card-cartoes-menor card" : "card-cartoes card"
              }
            >
              <CartaoEmpresarial />
              <div className="box-cartao">
                <div className="justify-center"></div>
                <div className="mt-3"></div>
                <div className="footer-poupaclub justify-center">
                  <Button
                    className={"btn-pill-3 bold"}
                    color="empresarial"
                    size="lg"
                    onClick={goToEmpresarial}
                  >
                    Peça agora
                  </Button>
                </div>
              </div>
            </div>
          </Col>
        )}
      </Row>
    </>
  );
}

export default SectionCartoes;
