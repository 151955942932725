import { useEffect, useState } from 'react';
import { MobileView } from 'react-device-detect';
import { FaRegCreditCard, FaSearch } from 'react-icons/fa';
import {
  FiAlignJustify,
  FiAlignLeft,
  FiCreditCard,
  FiDollarSign,
  FiFileText,
  FiHelpCircle,
  FiHome,
  FiKey,
  FiMenu,
  FiPieChart,
  FiShoppingBag,
  FiShoppingCart,
  FiUserPlus,
  FiUsers,
} from 'react-icons/fi';
import {
  Menu,
  MenuItem,
  ProSidebar,
  SidebarContent,
  SidebarFooter,
  SidebarHeader,
  SubMenu,
} from 'react-pro-sidebar';
import 'react-pro-sidebar/dist/css/styles.css';
import { Link, useHistory, useLocation } from 'react-router-dom';
import {
  Button,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
  Row,
} from 'reactstrap';
import logo1 from '../../../assets/logo-1.svg';
import { useAuth } from '../../../contexts/auth';
import { useWhiteLabel } from '../../../contexts/whitelabel';
import FooterAccess from '../../../layouts/FooterAcess';
import HeaderAcess from '../../../layouts/HeaderAcess';

const menusInit = [
  {
    menu: 'Dashboard',
    icon: (props) => <FiPieChart {...props} />,
    itens: [
      {
        id: 6,
        label: 'Financeiro',
        value: '/acesso/dashboard/financeiro',
      },
      {
        id: 6,
        label: 'Representante',
        value: '/acesso/dashboard/representante',
      },
      {
        id: 6,
        label: 'Adimplência',
        value: '/acesso/dashboard/adimplencia',
      },
      {
        id: 6,
        label: 'Produtos',
        value: '/acesso/dashboard/produtos',
      },
      {
        id: 6,
        label: 'Cidades',
        value: '/acesso/dashboard/cidades',
      },
    ],
  },
  {
    menu: 'Acesso',
    icon: (props) => <FiDollarSign {...props} />,
    url: {
      id: 2,
      label: 'Parcelas',
      value: '/acesso/faturas',
    },
  },
  {
    menu: 'Acesso',
    icon: (props) => <FiUsers {...props} />,
    url: {
      id: 50,
      label: 'Funcionarios',
      value: '/acesso/funcionarios',
    },
  },
  {
    menu: 'Acesso',
    icon: (props) => <FaRegCreditCard {...props} />,
    url: {
      id: 46,
      label: 'Assinatura',
      value: '/acesso/assinatura',
    },
  },
  {
    menu: 'Acesso',
    icon: (props) => <FiCreditCard {...props} />,
    url: {
      id: 45,
      label: 'Cartão digital',
      value: '/acesso/cartao-digital',
    },
  },
  {
    menu: 'Dependentes',
    icon: (props) => <FiUsers {...props} />,
    url: {
      id: 5,
      label: 'Dependentes',
      value: '/acesso/dependentes',
    },
  },
  {
    menu: 'Vendas',
    icon: (props) => <FiShoppingCart {...props} />,
    itens: [
      {
        id: 14,
        label: 'Portal de vendas',
        value: '/acesso/portal-venda',
      },
      {
        id: 9,
        label: 'Portal de vendas',
        value: '/acesso/portal-venda',
      },
      {
        id: 8,
        label: 'Link de vendas',
        value: '/acesso/link-venda',
      },
      {
        id: 13,
        label: 'Link de vendas',
        value: '/acesso/link-venda',
      },
      {
        id: 8,
        label: 'Cobranças',
        value: '/acesso/cobranca',
      },
      {
        id: 13,
        label: 'Cobranças',
        value: '/acesso/cobranca',
      },
    ],
  },

  {
    menu: 'Relatórios',
    icon: (props) => <FiFileText {...props} />,
    url: {
      id: 10,
      label: 'Relatórios',
      value: '/acesso/relatorios',
    },
  },
  {
    menu: 'Relatório',
    icon: (props) => <FiFileText {...props} />,
    url: {
      id: 15,
      label: 'Relatório',
      value: '/acesso/relatorios',
    },
  },
  {
    menu: 'Consultar clientes',
    icon: (props) => <FiUsers {...props} />,
    itens: [
      {
        id: 44,
        label: 'Clientes PF',
        value: '/acesso/clientes',
      },
      {
        id: 44,
        label: 'Clientes PJ',
        value: '/acesso/clientes_pj',
      },
      {
        id: 7,
        label: 'Clientes PF',
        value: '/acesso/clientes',
      },
      {
        id: 7,
        label: 'Clientes PJ',
        value: '/acesso/clientes_pj',
      },
    ],
  },
  // {
  //   menu: 'Consultar clientes',
  //   icon: (props) => <FiUsers {...props} />,
  //   url: {
  //     id: 7,
  //     label: 'Consultar clientes',
  //     value: '/acesso/clientes',
  //   },
  // },
  {
    menu: 'Token juno',
    icon: (props) => <FiKey {...props} />,
    url: {
      id: 32,
      label: 'Token juno',
      value: '/acesso/cadastro-token',
    },
  },
  {
    menu: 'Token juno',
    icon: (props) => <FiKey {...props} />,
    url: {
      id: 33,
      label: 'Token juno',
      value: '/acesso/cadastro-token',
    },
  },

  {
    menu: 'Acesso',
    icon: (props) => <FiHome {...props} />,
    itens: [
      // {
      //   id: 43,
      //   label: "Trocar senha",
      //   value: "/acesso/trocar-senha",
      // },
      // {
      //   id: 3,
      //   label: 'Alterar dados cadastrais',
      //   value: '/acesso/dados-cadastrais',
      // },
      // {
      //   id: 4,
      //   label: 'Trocar senha',
      //   value: '/acesso/trocar-senha',
      // },
    ],
  },

  {
    menu: 'Representantes',
    icon: (props) => <FiShoppingBag {...props} />,
    url: {
      id: 16,
      label: 'Solicitar representação',
      value: '/acesso/solicitar-representacao',
    },
  },
  {
    menu: 'Criar usuário',
    icon: (props) => <FiUserPlus {...props} />,
    itens: [
      {
        id: 17,
        label: 'Nível de acesso',
        value: '/acesso/nivel-acesso',
      },
    ],
  },
  {
    menu: 'Clientes',
    icon: (props) => <FiUsers {...props} />,
    itens: [
      {
        id: 34,
        label: 'Cobranças',
        value: '/acesso/cobrancas',
      },
      {
        id: 18,
        label: 'Relatório',
        value: '/acesso/relatorio-adm/clientes',
      },
      {
        id: 52,
        label: 'Assinaturas',
        value: '/acesso/assinatura/cliente',
      },
      // {
      //   id: 21,
      //   label: "Emitir boletos",
      //   value: "/acesso/emitir-boleto/clientes",
      // },
      {
        id: 34,
        label: 'Clientes PF',
        value: '/acesso/clientes',
      },
      {
        id: 34,
        label: 'Clientes PJ',
        value: '/acesso/clientes_pj',
      },
      // {
      //   id: 24,
      //   label: 'Alterar dados cadastrais',
      //   value: '/acesso/alterar-dados-adm/clientes',
      // },
    ],
  },
  {
    menu: 'Parceiros',
    icon: (props) => <FiUsers {...props} />,
    itens: [
      // {
      //   id: 30,
      //   label: 'Criar parceiro',
      //   value: '/acesso/criar-parceiro',
      // },
      // {
      //   id: 38,
      //   label: "Criar Marca",
      //   value: "/acesso/marca",
      // },
      {
        id: 35,
        label: 'Consultar parceiros',
        value: '/acesso/consulta-todos/parceiros',
      },
      {
        id: 39,
        label: 'Consultar marcas',
        value: '/acesso/marca-consulta',
      },
      // {
      //   id: 25,
      //   label: 'Alterar dados cadastrais',
      //   value: '/acesso/alterar-dados-adm/parceiros',
      // },
      // {
      //   id: 40,
      //   label: "Editar marca",
      //   value: "/acesso/marca-editar",
      // },
      {
        id: 19,
        label: 'Relatório',
        value: '/acesso/relatorio-adm/parceiro',
      },
      {
        id: 47,
        label: 'White Label',
        value: '/acesso/whitelabel',
      },
      {
        id: 48,
        label: 'Bloqueio de parceiros',
        value: '/acesso/bloqueio-parceiro',
      },
    ],
  },
  {
    menu: 'Revendedores',
    icon: (props) => <FiUsers {...props} />,
    itens: [
      // {
      //   id: 31,
      //   label: 'Criar revendedor',
      //   value: '/acesso/criar-revendedor',
      // },
      {
        id: 20,
        label: 'Relatório',
        value: '/acesso/relatorio-adm/revendedor',
      },
      {
        id: 36,
        label: 'Consultar revendedores',
        value: '/acesso/consulta-todos/revendedores',
      },
      // {
      //   id: 26,
      //   label: 'Alterar dados cadastrais',
      //   value: '/acesso/alterar-dados-adm/revendedores',
      // },
      {
        id: 41,
        label: 'Vínculo representante',
        value: '/acesso/vinculo-representante',
      },
    ],
  },
  {
    menu: 'Dependentes',
    icon: (props) => <FiHelpCircle {...props} />,
    url: {
      id: 1,
      label: 'Fale conosco',
      value: '/acesso/falar-suporte',
    },
  },
  {
    menu: 'Produtos',
    icon: (props) => <FiCreditCard {...props} />,
    itens: [
      {
        id: 53,
        label: 'Cartões',
        value: '/acesso/cartoes',
      },
      {
        id: 54,
        label: 'Produto interno',
        value: '/acesso/produto_interno',
      },
      {
        id: 54,
        label: 'Cobranças internas',
        value: '/acesso/cobrancas_internas',
      },
      {
        id: 55,
        label: 'Voucher',
        value: '/acesso/voucher',
      },
      {
        id: 51,
        label: 'Produto interno',
        value: '/acesso/produto_interno',
      },
      {
        id: 51,
        label: 'Cobranças internas',
        value: '/acesso/cobrancas_internas',
      },
    ],
  },
];

function Layout({ children }) {
  let history = useHistory();
  const location = useLocation();
  const { user } = useAuth();
  const [menus, setMenus] = useState([]);
  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);

  const [cpfCnpj, setCpfCnpj] = useState('');

  const { dadosWhiteLabel } = useWhiteLabel();

  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  function verCliente() {
    history.push(
      `/${
        dadosWhiteLabel?.url_whitelabel || 'poupa-club'
      }/consultaqr/${cpfCnpj}`
    );
  }

  useEffect(() => {
    if (!!user) {
      setMenus([]);
      // console.log(user?.access, menusInit);
      const validaTelas = menusInit.map((menu) => {
        if (menu.url) {
          if (user?.access?.some((acesso) => acesso.tela === menu.url.id)) {
            return menu;
          }
        } else {
          return {
            ...menu,
            itens: menu.itens.filter((submenu) =>
              user?.access?.some((acesso) => acesso.tela === submenu.id)
            ),
          };
        }

        return null;
      });

      // console.log(validaTelas);

      setMenus(validaTelas);
    }
  }, [user, user.access]);

  const toggleCollapsed = () => setCollapsed(!collapsed);

  const handleToggleSidebar = (value) => {
    setToggled(value);
  };

  const handleToggled = () => {
    if (toggled) {
      setToggled(false);
    }
  };

  return (
    <main className='d-flex vh100'>
      <ProSidebar
        breakPoint='md'
        collapsed={collapsed}
        toggled={toggled}
        onToggle={handleToggleSidebar}
      >
        <SidebarHeader>
          {collapsed ? (
            <div className='d-flex justify-content-center align-items-center md-hidden mt-3'>
              <Button onClick={toggleCollapsed} color='white'>
                <FiMenu size={22} />
              </Button>
            </div>
          ) : (
            <>
              <Link
                to='/acesso/welcome'
                onClick={scrollToTop}
                className='logo-header'
              >
                <img
                  src={dadosWhiteLabel?.whitelabel_logo_img?.url || logo1}
                  className='img-logo logo-sidebar'
                  alt='Poupa club'
                />
              </Link>

              <Button
                onClick={toggleCollapsed}
                color='white'
                className='md-hidden'
              >
                <FiAlignLeft size={22} />
              </Button>
            </>
          )}
          {user.perfil !== 'U' && (
            <MobileView>
              <Row className='mr-1 ml-1'>
                <Col>
                  <InputGroup>
                    <Input
                      className='form-control input-field mt-3 mr-1 w-50'
                      placeholder='Digite o CPF ou CNPJ'
                      onChange={(e) => setCpfCnpj(e.target.value)}
                      type='number'
                    />
                    <InputGroupAddon addonType='prepend'>
                      <Button
                        className='mt-3 btn-sm btn-round'
                        onClick={verCliente}
                      >
                        <FaSearch size={12} />
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>
                </Col>
              </Row>
            </MobileView>
          )}
        </SidebarHeader>
        <SidebarContent>
          <Menu iconShape='round'>
            {menus.map((m) =>
              m?.itens?.length > 0 ? (
                <>
                  <SubMenu
                    title={m.menu}
                    icon={<m.icon color='#625f6e' size={19} />}
                    aria-label='adm'
                  >
                    {m.itens.map((item) => (
                      <MenuItem
                        key={item.value}
                        onClick={handleToggled}
                        className={
                          location.pathname === item.value ? 'active' : ''
                        }
                      >
                        <Link to={item.value}>{item.label}</Link>
                      </MenuItem>
                    ))}
                  </SubMenu>
                </>
              ) : (
                m?.url && (
                  <MenuItem
                    key={m.url.value}
                    icon={<m.icon color='#625f6e' size={19} />}
                    title={m.url.label}
                    onClick={handleToggled}
                    className={
                      location.pathname === m.url.value ? 'active' : ''
                    }
                  >
                    <Link to={m.url.value}>{m.url.label}</Link>
                  </MenuItem>
                )
              )
            )}
          </Menu>
        </SidebarContent>
        <SidebarFooter>
          <div className='justify-center text-center my-3 xcoding pb-1'>
            Made with &nbsp;
            <span
              style={{
                color: 'red',
              }}
            >
              ❤
            </span>
            &nbsp; by &nbsp;
            <a
              href='https://xcoding.com.br?ref=poupaclub'
              rel='noreferrer'
              target='_blank'
              style={{
                color: '#151320',
                fontWeight: 600,
              }}
            >
              Xcoding
            </a>
          </div>
        </SidebarFooter>
      </ProSidebar>
      <div className='adm-content'>
        <HeaderAcess
          toggle={
            <div
              className='btn-toggle'
              onClick={() => handleToggleSidebar(true)}
            >
              <FiAlignJustify size={28} />
            </div>
          }
        />
        <main className='my-3'>{children}</main>
        <FooterAccess />
      </div>
    </main>
  );
}

export default Layout;
