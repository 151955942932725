import React, { createContext, useContext, useEffect, useState } from "react";
import contrast from "get-contrast";
import api from "../services/api";
import { useAuth } from "./auth";

const WhiteLabelContext = createContext();

export const WhiteLabelProvider = ({ children }) => {
  const [loading, setLoading] = useState(true);

  const [dadosWhiteLabel, setDadosWhiteLabel] = useState(null);
  const [parceirosBlacklist, setParceirosBlacklist] = useState(null);

  const { user } = useAuth();

  useEffect(() => {
    async function buscaDadosWhiteLabel() {
      const location = window.location.pathname.split("/")[1];

      if (!["poupa-club", "acesso", "", "login"].includes(location)) {
        const { data } = await api.get(
          `/buscar-whitelabel?url=${window.location.pathname.split("/")[1]}`
        );
        if (data) {
          setDadosWhiteLabel(data);
          document.querySelector("title").innerText = "Clube de descontos";
          const { data: blacklist } = await api.get(
            `/parceiros-wl/${data?.parceiro_info?.id}`
          );
          setParceirosBlacklist(blacklist);
        } else {
          setLoading(false);
        }
      }
    }
    buscaDadosWhiteLabel();
  }, []);

  useEffect(() => {
    async function buscaDadosWhiteLabel() {
      const location = window.location.pathname.split("/")[1];
      const headerBg =
        document.documentElement.style.getPropertyValue("--header-bg");

      if (
        !dadosWhiteLabel &&
        ["poupa-club", "acesso", "", "login"].includes(location)
      ) {
        const { data } = await api.get(`/buscar-whitelabel`);
        if (data) {
          setDadosWhiteLabel(data);
          document.querySelector("title").innerText = "Clube de descontos";
          const { data: blacklist } = await api.get(
            `/parceiros-wl/${data?.parceiro_info?.id}`
          );
          setParceirosBlacklist(blacklist);
          return;
        } else {
          return setTimeout(() => {
            setLoading(false);
          }, 500);
        }
      } else if (dadosWhiteLabel) {
        setColors();
      } else if (headerBg && (!user.responsavel || !dadosWhiteLabel)) {
        setLoading(true);
        setDadosWhiteLabel(null);
        document.documentElement.style.setProperty("--header-bg", "");
        document.documentElement.style.setProperty("--header-bg-secondary", "");
        document.documentElement.style.setProperty("--header-bg-primary", "");
        document.documentElement.style.setProperty("--header-color", "");
        document.documentElement.style.setProperty("--header-sub-bg", "");
        document.documentElement.style.setProperty("--color-secondary", "");
        document.documentElement.style.setProperty("--text-orange", "");

        return;
      }
    }
    if (user || dadosWhiteLabel) {
      buscaDadosWhiteLabel();
    } else {
      return setTimeout(() => {
        setLoading(false);
      }, 700);
    }
  }, [user, dadosWhiteLabel]);

  function setColors() {
    const bgSubmenu =
      contrast.ratio(dadosWhiteLabel?.whitelabel_footer_background, "#000000") >
      5
        ? "rgba(0,0,0,.15)"
        : "rgba(255,255,255,.15)";

    document.documentElement.style.setProperty(
      "--header-bg",
      dadosWhiteLabel?.whitelabel_footer_background
    );
    document.documentElement.style.setProperty(
      "--header-bg-secondary",
      dadosWhiteLabel?.whitelabel_footer_background
    );
    document.documentElement.style.setProperty(
      "--header-color",
      dadosWhiteLabel?.whitelabel_footer_text_color
    );
    document.documentElement.style.setProperty(
      "--header-bg-primary",
      dadosWhiteLabel?.whitelabel_footer_background
    );
    document.documentElement.style.setProperty(
      "--text-orange",
      dadosWhiteLabel?.whitelabel_footer_text_color
    );
    document.documentElement.style.setProperty("--header-sub-bg", bgSubmenu);
    document.documentElement.style.setProperty(
      "--color-secondary",
      dadosWhiteLabel.whitelabel_arrows
    );
  }

  return (
    <WhiteLabelContext.Provider
      value={{
        loading,
        setLoading,
        dadosWhiteLabel,
        parceirosBlacklist,
      }}
    >
      {children}
    </WhiteLabelContext.Provider>
  );
};

export function useWhiteLabel() {
  const context = useContext(WhiteLabelContext);

  return context;
}
