import React, { useEffect, useState, useRef } from 'react';
import NumberFormat from 'react-number-format';
import { Form } from '@unform/web';
import InputUnform from '../../components/unformInput';
import {
  Container,
  Col,
  Row,
  Button,
  CardImg,
  Card,
  CardBody,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
} from 'reactstrap';
import { ValidationError } from 'yup';
import Swal from 'sweetalert2';
import OndeComo from '../../assets/ondecomo.png';
import { Link } from 'react-router-dom';
import icones from '../../assets/icones.png';
import { TiChevronRight } from 'react-icons/ti';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import ReactHtmlParser from 'react-html-parser';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import MaskedInput from '../../components/unformMaskedInput';
//banners do carousel
import bannerHome from '../../assets/banner 1.png';
import banner2 from '../../assets/banner 2.png';
import banner3 from '../../assets/banner 3.png';
import banner4 from '../../assets/banner 4.png';
import banner5 from '../../assets/banner 5.png';

import img_drog_sao_paulo from '../../assets/drogaria-sao-paulo.png';
import img_drog_pacheco from '../../assets/drogaria_pacheco.png';

import pilula from '../../assets/pilula.png';
import etiqueta from '../../assets/etiqueta.png';

import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import cardFooter from '../../assets/card-footer.png';

import useFetch from '../../hooks/useFetch';
import api from '../../services/api';
import { useWhiteLabel } from '../../contexts/whitelabel';
import CartoesHome from '../../components/cartoesHome';
import CartoesWhiteLabelHome from '../../components/cartoesWhiteLabelHome';

import solicitarContatoValidator from '../../validations/solicitarContato';
import { phoneMask, cpfMask, dateMask } from '../../utils/masks';

import totalizadoresImg from '../../assets/imgTotalizador.png';
import { useAuth } from '../../contexts/auth';

function Home() {
  const formRef = useRef(null);
  const history = useHistory();
  const { uppo } = useAuth();

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const [cartoes, setCartoes] = useState([]);

  const { dadosWhiteLabel, parceirosBlacklist } = useWhiteLabel();

  const { data: parceirosData } = useFetch('/relevancia');

  const [parceiros, setParceiros] = useState([]);

  const [calculos, setCalculos] = useState({
    a: 0,
    b: 0,
    c: 0,
    d: 0,
    e: 0,
    f: 0,
    g: 0,
    h: 0,
    i: 0,
    j: 0,
  });

  useEffect(() => {
    async function getPrecoCartoes() {
      try {
        const { data: result } = await api.get('/cartoes');
        setCartoes(result);
      } catch (err) {}
    }
    function filterParceiros() {
      if (parceirosBlacklist) {
        setParceiros(
          parceirosData?.filter(
            (f) => !parceirosBlacklist.some((s) => s.marca_id === f.id)
          )
        );
      } else {
        setParceiros(parceirosData);
      }
    }
    filterParceiros();
    getPrecoCartoes();
  }, [parceirosData, parceirosBlacklist]);

  async function handleSubmit(data) {
    try {
      formRef.current.setErrors({});
      await solicitarContatoValidator(data);
      Swal.fire({
        title: 'Por favor, aguarde...',
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      const url = window.location.pathname.split('/');
      if (url[1] !== 'poupa-club') {
        data.whitelabel = url[1];
      }
      await api.post('/email-solicitar-contato', data);
      Swal.close();
      window.scrollTo(0, 0);
      return history.push(
        `${
          dadosWhiteLabel?.url_whitelabel || 'poupa-club'
        }/obrigado-pelo-interesse`
      );
    } catch (err) {
      const validationErrors = {};
      Swal.close();
      if (err instanceof ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
        toast.error('Verifique os campos em vermelho!');
      } else {
        toast.error('Ocorreu um erro tente novamente mais tarde!');
      }
    }
  }

  function changeCalculos(e, letra) {
    const valor = e.target.value;
    const max = e.target.attributes.getNamedItem('max').value;
    e.target.style.backgroundSize = `${(valor / max) * 100}% 100%`;
    setCalculos({
      ...calculos,
      [letra]: valor,
    });
  }

  function changeCalculosInput(e, letra) {
    const valor = Number(
      e.target.value.replace('R$ ', '').replaceAll('.', '').replace(',', '.')
    );
    const range = document.getElementById(`range-${letra}`);
    const max = Number(range.max);
    const min = max > valor ? valor : max;
    range.style.backgroundSize = `${(min / max) * 100}% 100%`;
    setCalculos({
      ...calculos,
      [letra]: valor,
    });
    console.log(range);
  }

  return (
    <>
      <CarouselProvider isIntrinsicHeight totalSlides={5} infinite isPlaying>
        <Slider>
          <Slide index={0}>
            <a
              href={`${dadosWhiteLabel?.url_whitelabel || 'poupa-club'}/cartao`}
            >
              <CardImg
                className='border-radius'
                src={dadosWhiteLabel?.home_banner_1_img?.url || bannerHome}
                alt='Cartões PoupaClub'
              />
            </a>
          </Slide>
          <Slide index={1}>
            <a
              href={`${dadosWhiteLabel?.url_whitelabel || 'poupa-club'}/cartao`}
            >
              <CardImg
                className='border-radius'
                src={dadosWhiteLabel?.home_banner_2_img?.url || banner2}
                alt='Pedir cartão'
              />
            </a>
          </Slide>
          <Slide index={2}>
            <a
              href={`${
                dadosWhiteLabel?.url_whitelabel || 'poupa-club'
              }/agendar-consulta-exame`}
            >
              <CardImg
                className='border-radius'
                src={dadosWhiteLabel?.home_banner_3_img?.url || banner3}
                alt='Agendar avaliação'
              />
            </a>
          </Slide>
          <Slide index={3}>
            <a
              href={`${
                dadosWhiteLabel?.url_whitelabel || 'poupa-club'
              }/agendar-consulta-exame`}
            >
              <CardImg
                className='border-radius'
                src={dadosWhiteLabel?.home_banner_4_img?.url || banner4}
                alt='Veterinário'
              />
            </a>
          </Slide>
          <Slide index={4}>
            <a
              href={`${dadosWhiteLabel?.url_whitelabel || 'poupa-club'}/cartao`}
            >
              <CardImg
                className='border-radius'
                src={dadosWhiteLabel?.home_banner_5_img?.url || banner5}
                alt='Clube benefícios'
              />
            </a>
          </Slide>
        </Slider>
      </CarouselProvider>
      <Container>
        <div className='justify-center mt-60 mb-30 align-center'>
          {parceiros && (
            <CarouselProvider
              width={20}
              isIntrinsicHeight
              naturalSlideHeight
              naturalSlideWidth
              totalSlides={(parceiros?.length || 0) * 3}
              visibleSlides={5}
              infinite
              isPlaying
              step={2}
            >
              <ButtonBack>
                <BsChevronLeft color='#898787' size={30} />
              </ButtonBack>
              <Container>
                <Slider>
                  {parceiros
                    .concat(parceiros)
                    .concat(parceiros)
                    .map((parceiro, index) => (
                      <Slide index={index} key={index}>
                        <button className='btn-none' onClick={() => uppo()}>
                          <div className='filter-gray'>
                            <CardImg
                              src={parceiro.imagem.url}
                              alt={parceiro.nome}
                              width='100%'
                              className='img-banner my-3'
                            />
                          </div>
                        </button>
                      </Slide>
                    ))}
                </Slider>
              </Container>
              <ButtonNext>
                <BsChevronRight color='#898787' size={30} />
              </ButtonNext>
            </CarouselProvider>
          )}
        </div>
      </Container>
      <Container>
        <Row className='mt-5 txt-primary'>
          <Col className='mb-60' lg={12}>
            <h2
              className='bold txt-primary text-center'
              style={{
                color: dadosWhiteLabel
                  ? dadosWhiteLabel?.whitelabel_text_color_1
                  : '',
                backgroundImage: 'none',
              }}
            >
              {!dadosWhiteLabel
                ? 'Calcule o quanto você pode Poupar com o PoupaClub:'
                : dadosWhiteLabel?.texto_desconto
                ? dadosWhiteLabel?.texto_desconto
                : 'Calcule o quanto você pode economizar:'}
            </h2>
          </Col>
          <Col lg={6} md={12} sm={12} xs={12} className='justify-center'>
            <div className='div-totalizadores mb-2'>
              <div className='div-totalizadores-interna'>
                <div className='div-totalizadores-interna-align'>
                  <label
                    className='div-totalizadores-label'
                    style={{
                      color: dadosWhiteLabel
                        ? dadosWhiteLabel?.whitelabel_text_color_1
                        : '',
                    }}
                  >
                    Restaurantes
                  </label>
                  <Input
                    type='range'
                    min='0'
                    max='3000'
                    step='1'
                    value={calculos.a}
                    className='input-range'
                    onChange={(e) => {
                      changeCalculos(e, 'a');
                    }}
                    id='range-a'
                  />
                </div>
                <NumberFormat
                  style={{
                    color: dadosWhiteLabel
                      ? dadosWhiteLabel?.whitelabel_text_color_1
                      : '',
                  }}
                  className='input-totalizaodres'
                  value={Number(calculos?.a)}
                  thousandSeparator='.'
                  decimalSeparator=','
                  onChange={(e) => {
                    changeCalculosInput(e, 'a');
                  }}
                  prefix={'R$ '}
                />
              </div>
              <div className='div-totalizadores-interna'>
                <div className='div-totalizadores-interna-align'>
                  <label
                    className='div-totalizadores-label'
                    style={{
                      color: dadosWhiteLabel
                        ? dadosWhiteLabel?.whitelabel_text_color_1
                        : '',
                    }}
                  >
                    Vestuário
                  </label>
                  <Input
                    type='range'
                    defaultValue='0'
                    min='0'
                    max='3000'
                    step='1'
                    className='input-range'
                    onChange={(e) => {
                      changeCalculos(e, 'b');
                    }}
                    value={calculos.b}
                    id='range-b'
                  />
                </div>
                <NumberFormat
                  style={{
                    color: dadosWhiteLabel
                      ? dadosWhiteLabel?.whitelabel_text_color_1
                      : '',
                  }}
                  className='input-totalizaodres'
                  value={Number(calculos?.b)}
                  thousandSeparator='.'
                  decimalSeparator=','
                  onChange={(e) => {
                    changeCalculosInput(e, 'b');
                  }}
                  prefix={'R$ '}
                />
              </div>
              <div className='div-totalizadores-interna'>
                <div className='div-totalizadores-interna-align'>
                  <label
                    className='div-totalizadores-label'
                    style={{
                      color: dadosWhiteLabel
                        ? dadosWhiteLabel?.whitelabel_text_color_1
                        : '',
                    }}
                  >
                    Farmácia
                  </label>
                  <Input
                    type='range'
                    defaultValue='0'
                    min='0'
                    max='3000'
                    step='1'
                    className='input-range'
                    onChange={(e) => {
                      changeCalculos(e, 'c');
                    }}
                    value={calculos.c}
                    id='range-c'
                  />
                </div>
                <NumberFormat
                  style={{
                    color: dadosWhiteLabel
                      ? dadosWhiteLabel?.whitelabel_text_color_1
                      : '',
                  }}
                  className='input-totalizaodres'
                  value={Number(calculos?.c)}
                  thousandSeparator='.'
                  decimalSeparator=','
                  onChange={(e) => {
                    changeCalculosInput(e, 'c');
                  }}
                  prefix={'R$ '}
                />
              </div>
              <div className='div-totalizadores-interna'>
                <div className='div-totalizadores-interna-align'>
                  <label
                    className='div-totalizadores-label'
                    style={{
                      color: dadosWhiteLabel
                        ? dadosWhiteLabel?.whitelabel_text_color_1
                        : '',
                    }}
                  >
                    Consultas e Exames Médicos
                  </label>
                  <Input
                    type='range'
                    defaultValue='0'
                    min='0'
                    max='3000'
                    step='1'
                    className='input-range'
                    onChange={(e) => {
                      changeCalculos(e, 'd');
                    }}
                    value={calculos.d}
                    id='range-d'
                  />
                </div>
                <NumberFormat
                  style={{
                    color: dadosWhiteLabel
                      ? dadosWhiteLabel?.whitelabel_text_color_1
                      : '',
                  }}
                  className='input-totalizaodres'
                  value={Number(calculos?.d)}
                  thousandSeparator='.'
                  decimalSeparator=','
                  onChange={(e) => {
                    changeCalculosInput(e, 'd');
                  }}
                  prefix={'R$ '}
                />
              </div>
              <div className='div-totalizadores-interna'>
                <div className='div-totalizadores-interna-align'>
                  <label
                    className='div-totalizadores-label'
                    style={{
                      color: dadosWhiteLabel
                        ? dadosWhiteLabel?.whitelabel_text_color_1
                        : '',
                    }}
                  >
                    Odontologia
                  </label>
                  <Input
                    type='range'
                    defaultValue='0'
                    min='0'
                    max='3000'
                    step='1'
                    className='input-range'
                    onChange={(e) => {
                      changeCalculos(e, 'e');
                    }}
                    value={calculos.e}
                    id='range-e'
                  />
                </div>
                <NumberFormat
                  style={{
                    color: dadosWhiteLabel
                      ? dadosWhiteLabel?.whitelabel_text_color_1
                      : '',
                  }}
                  className='input-totalizaodres'
                  value={Number(calculos?.e)}
                  thousandSeparator='.'
                  decimalSeparator=','
                  onChange={(e) => {
                    changeCalculosInput(e, 'e');
                  }}
                  prefix={'R$ '}
                />
              </div>
              <div className='div-totalizadores-interna'>
                <div className='div-totalizadores-interna-align'>
                  <label
                    className='div-totalizadores-label'
                    style={{
                      color: dadosWhiteLabel
                        ? dadosWhiteLabel?.whitelabel_text_color_1
                        : '',
                    }}
                  >
                    Pet-Shop / Veterinários
                  </label>
                  <Input
                    type='range'
                    defaultValue='0'
                    min='0'
                    max='3000'
                    step='1'
                    className='input-range'
                    onChange={(e) => {
                      changeCalculos(e, 'f');
                    }}
                    value={calculos.f}
                    id='range-f'
                  />
                </div>
                <NumberFormat
                  style={{
                    color: dadosWhiteLabel
                      ? dadosWhiteLabel?.whitelabel_text_color_1
                      : '',
                  }}
                  className='input-totalizaodres'
                  value={Number(calculos?.f)}
                  thousandSeparator='.'
                  decimalSeparator=','
                  onChange={(e) => {
                    changeCalculosInput(e, 'f');
                  }}
                  prefix={'R$ '}
                />
              </div>
              <div className='div-totalizadores-interna'>
                <div className='div-totalizadores-interna-align'>
                  <label
                    className='div-totalizadores-label'
                    style={{
                      color: dadosWhiteLabel
                        ? dadosWhiteLabel?.whitelabel_text_color_1
                        : '',
                    }}
                  >
                    Academias
                  </label>
                  <Input
                    type='range'
                    defaultValue='0'
                    min='0'
                    max='3000'
                    step='1'
                    className='input-range'
                    onChange={(e) => {
                      changeCalculos(e, 'g');
                    }}
                    value={calculos.g}
                    id='range-g'
                  />
                </div>
                <NumberFormat
                  style={{
                    color: dadosWhiteLabel
                      ? dadosWhiteLabel?.whitelabel_text_color_1
                      : '',
                  }}
                  className='input-totalizaodres'
                  value={Number(calculos?.g)}
                  thousandSeparator='.'
                  decimalSeparator=','
                  onChange={(e) => {
                    changeCalculosInput(e, 'g');
                  }}
                  prefix={'R$ '}
                />
              </div>
              <div className='div-totalizadores-interna'>
                <div className='div-totalizadores-interna-align'>
                  <label
                    className='div-totalizadores-label'
                    style={{
                      color: dadosWhiteLabel
                        ? dadosWhiteLabel?.whitelabel_text_color_1
                        : '',
                    }}
                  >
                    Beleza
                  </label>
                  <Input
                    type='range'
                    defaultValue='0'
                    min='0'
                    max='3000'
                    step='1'
                    className='input-range'
                    onChange={(e) => {
                      changeCalculos(e, 'h');
                    }}
                    value={calculos.h}
                    id='range-h'
                  />
                </div>
                <NumberFormat
                  style={{
                    color: dadosWhiteLabel
                      ? dadosWhiteLabel?.whitelabel_text_color_1
                      : '',
                  }}
                  className='input-totalizaodres'
                  value={Number(calculos?.h)}
                  thousandSeparator='.'
                  decimalSeparator=','
                  onChange={(e) => {
                    changeCalculosInput(e, 'h');
                  }}
                  prefix={'R$ '}
                />
              </div>
              <div className='div-totalizadores-interna'>
                <div className='div-totalizadores-interna-align'>
                  <label
                    className='div-totalizadores-label'
                    style={{
                      color: dadosWhiteLabel
                        ? dadosWhiteLabel?.whitelabel_text_color_1
                        : '',
                    }}
                  >
                    Educação / Cursos
                  </label>
                  <Input
                    type='range'
                    defaultValue='0'
                    min='0'
                    max='3000'
                    step='1'
                    className='input-range'
                    onChange={(e) => {
                      changeCalculos(e, 'i');
                    }}
                    value={calculos.i}
                    id='range-i'
                  />
                </div>
                <NumberFormat
                  style={{
                    color: dadosWhiteLabel
                      ? dadosWhiteLabel?.whitelabel_text_color_1
                      : '',
                  }}
                  className='input-totalizaodres'
                  value={Number(calculos?.i)}
                  thousandSeparator='.'
                  decimalSeparator=','
                  onChange={(e) => {
                    changeCalculosInput(e, 'i');
                  }}
                  prefix={'R$ '}
                />
              </div>
              <div className='div-totalizadores-interna'>
                <div className='div-totalizadores-interna-align'>
                  <label
                    className='div-totalizadores-label'
                    style={{
                      color: dadosWhiteLabel
                        ? dadosWhiteLabel?.whitelabel_text_color_1
                        : '',
                    }}
                  >
                    Lazer (Parques/Viagem/Cinema)
                  </label>
                  <Input
                    type='range'
                    defaultValue='0'
                    min='0'
                    max='3000'
                    step='1'
                    className='input-range'
                    onChange={(e) => {
                      changeCalculos(e, 'j');
                    }}
                    value={calculos.j}
                    id='range-j'
                  />
                </div>
                <NumberFormat
                  style={{
                    color: dadosWhiteLabel
                      ? dadosWhiteLabel?.whitelabel_text_color_1
                      : '',
                  }}
                  className='input-totalizaodres'
                  value={Number(calculos?.j)}
                  thousandSeparator='.'
                  decimalSeparator=','
                  onChange={(e) => {
                    changeCalculosInput(e, 'j');
                  }}
                  prefix={'R$ '}
                />
              </div>
              <div className='justify-end mt-3'>
                <NumberFormat
                  style={{
                    color: dadosWhiteLabel
                      ? dadosWhiteLabel?.whitelabel_text_color_1
                      : '',
                  }}
                  className='input-totalizaodres-total'
                  value={Math.round(
                    Number(calculos.a) +
                      Number(calculos.b) +
                      Number(calculos.c) +
                      Number(calculos.d) +
                      Number(calculos.e) +
                      Number(calculos.f) +
                      Number(calculos.g) +
                      Number(calculos.h) +
                      Number(calculos.i) +
                      Number(calculos.j)
                  )}
                  thousandSeparator='.'
                  decimalSeparator=','
                  onChange={(e) => {
                    changeCalculosInput(e, 'j');
                  }}
                  prefix={'Total:  R$ '}
                />
              </div>
            </div>
          </Col>
          <Col
            lg={5}
            md={12}
            sm={12}
            xs={12}
            className='justify-center offset-lg-1'
          >
            <div className='w-100'>
              <CardImg
                className='img-totalizadores'
                src={
                  dadosWhiteLabel?.img_simulador_desconto_img?.url ||
                  totalizadoresImg
                }
                alt='Totalizadores'
              />
              <h2 className='text-center mb-2 mt-2 text-economizar'>
                {!dadosWhiteLabel ? (
                  <>Com o PoupaClub, você pode ECONOMIZAR até:</>
                ) : (
                  'Você pode economizar até:'
                )}
              </h2>
              <Card className='card-econmizar w-100'>
                <div className='vertical-center'>
                  <div
                    style={{
                      zIndex: 2,
                    }}
                  >
                    <h1
                      className={
                        !dadosWhiteLabel
                          ? 'text-center text-economizar-h1'
                          : 'text-center text-economizar-h1-wl'
                      }
                    >
                      {Math.round(
                        calculos.a * 0.25 +
                          calculos.b * 0.15 +
                          calculos.c * 0.35 +
                          calculos.d * 0.6 +
                          calculos.e * 0.3 +
                          calculos.f * 0.3 +
                          calculos.g * 0.2 +
                          calculos.h * 0.2 +
                          calculos.i * 0.4 +
                          calculos.j * 0.3
                      ).toLocaleString('pt-BR', {
                        currency: 'BRL',
                        style: 'currency',
                      })}
                    </h1>
                  </div>
                </div>
              </Card>

              <Row>
                <Col className='justify-center mt-2'>
                  <Link
                    className={`btn-pill my-3 weight-500 btn-conheca btn btn-md btn-primary mr-3 ${
                      dadosWhiteLabel?.whitelabel_button_1 &&
                      dadosWhiteLabel?.whitelabel_button_1
                    }`}
                    to={`/${
                      dadosWhiteLabel?.url_whitelabel || 'poupa-club'
                    }/cartao`}
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                  >
                    Quero comprar
                  </Link>

                  <Button
                    className={`btn-pill my-3 weight-500 btn-conheca btn btn-md btn-secondary ${
                      dadosWhiteLabel?.whitelabel_button_2 &&
                      dadosWhiteLabel?.whitelabel_button_2
                    }`}
                    onClick={toggle}
                  >
                    Solicitar contato
                  </Button>
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className='mt-3 txt-primary'>
          <Col lg={8} className='offset-lg-4 justify-center mb-1'>
            {!dadosWhiteLabel ? (
              <img
                src={icones}
                alt='Variedade de segmentos'
                className='mw-100 mb-3'
              />
            ) : (
              <img
                src={dadosWhiteLabel?.home_img_1_img?.url}
                alt='Variedade de segmentos'
                className='mw-100 mb-3'
              />
            )}
          </Col>
          <Col lg={4} className='justify-end'>
            <h1
              className='h1-title'
              style={{
                color: dadosWhiteLabel
                  ? dadosWhiteLabel?.whitelabel_text_color_1
                  : '',
              }}
            >
              Benefícios <br />e descontos
            </h1>
            <TiChevronRight
              size={40}
              color={`${
                dadosWhiteLabel ? dadosWhiteLabel.whitelabel_arrows : 'orange'
              }`}
              className='ml-3'
            />
          </Col>
          <Col lg={8}>
            {dadosWhiteLabel ? (
              ReactHtmlParser(dadosWhiteLabel?.home_texto_1)
            ) : (
              <h5 className='font-400 txt-tertiary'>
                Faça parte do Clube de Descontos com o Cartão PoupaClub que
                permite{' '}
                <strong>
                  inúmeras vantagens e benefícios na hora de fazer suas compras
                </strong>
                . Além de acesso a consultas médicas, odontológicas e exames com
                preços acessíveis que cabem no seu bolso.{' '}
                <strong>É economia para toda família!</strong>
                <br />
                <br />
                Quando se trata de produtos, serviços, medicamentos e até lazer,
                as pessoas estão sempre procurando maneiras de economizar. Isso
                sem falar dos imprevistos médicos. Pessoas que não têm convênio
                estão em desvantagem óbvia.{' '}
                <strong>
                  É aí que você pode considerar o uso de um cartão de desconto!
                </strong>
              </h5>
            )}

            <div className='my-3'>
              <br />
              <Link
                to={`/${
                  dadosWhiteLabel?.url_whitelabel || 'poupa-club'
                }/cartao`}
                size='lg'
                onClick={() => {
                  window.scrollTo(0, 0);
                }}
                className={!dadosWhiteLabel ? 'text-orange' : ''}
                style={{
                  color: dadosWhiteLabel
                    ? dadosWhiteLabel?.whitelabel_text_color_2
                    : '',
                }}
              >
                <h5
                  className={
                    !dadosWhiteLabel ? 'text-orange weight-400' : 'weight-400'
                  }
                >
                  Conheça nossos cartões de descontos
                </h5>
              </Link>
            </div>
          </Col>
        </Row>
      </Container>

      <div
        className={'info-farmacias'}
        style={{
          backgroundImage: dadosWhiteLabel
            ? `url(
            ${dadosWhiteLabel?.home_img_convenio_farma_img?.url}
          )`
            : '',
        }}
      >
        <Row>
          <Col lg={3}></Col>
          <Col lg={9}>
            <Container className='farmacia-card mb-5 mt-5'>
              <div className='text-center mb-2 w-100'>
                <h6
                  className='h6-title text-center'
                  style={{
                    color: dadosWhiteLabel
                      ? dadosWhiteLabel?.whitelabel_text_color_1
                      : '',
                  }}
                >
                  {dadosWhiteLabel ? '' : 'O melhor Convênio Farmácia'}
                </h6>
              </div>
              {dadosWhiteLabel ? (
                <img
                  src={dadosWhiteLabel?.home_img_beneficio_farma_img?.url}
                  alt='desconto farmácia'
                  width='100%'
                />
              ) : (
                <>
                  <div>
                    <strong>
                      <p className='subtitle-small mt-4'>Nossa parceria com:</p>
                    </strong>
                  </div>
                  <div className='div-drogarias-home'>
                    <Row>
                      <Col>
                        <img
                          src={img_drog_sao_paulo}
                          className='img-drogarias-home'
                          alt='Drogarias São Paulo'
                        />
                      </Col>
                      <Col>
                        <img
                          src={img_drog_pacheco}
                          className='img-drogarias-home'
                          alt='Drogarias Pacheco'
                        />
                      </Col>
                    </Row>
                  </div>
                  <h5>
                    <div className='subtitle-small mb-5'>
                      Descontos para as lojas físicas e também nos APPs
                    </div>
                  </h5>
                </>
              )}
              <Row>
                {!dadosWhiteLabel ? (
                  <>
                    <Col md={6} className='mb-3'>
                      <Row>
                        <Col md={12} className='justify-center'>
                          <div className='circle-icon'>
                            <img src={pilula} alt='icone pilula' height='60%' />
                          </div>
                        </Col>

                        <Col md={12} className='text-center'>
                          <h3 className='pre-desconto'>&nbsp;</h3>
                          <h1 className='title-desconto'>30%</h1>
                          <h2 className='subtitle-desconto'>
                            de deconto <br /> em medicamento
                          </h2>
                        </Col>
                      </Row>
                    </Col>
                    <Col md={6} className='mb-3'>
                      <Row>
                        <Col md={12} className='justify-center'>
                          <div className='circle-icon'>
                            <img
                              src={etiqueta}
                              alt='icone etiqueta'
                              width='65%'
                            />
                          </div>
                        </Col>
                        <Col md={12} className='text-center'>
                          <h3 className='pre-desconto'>pode chegar até</h3>
                          <h1 className='title-desconto'>75%</h1>
                          <h2 className='subtitle-desconto'>
                            em demais ofertas
                            <br /> no convênio farmácia
                          </h2>
                        </Col>
                      </Row>
                    </Col>
                  </>
                ) : (
                  <Col md={12} className='mb-3'>
                    {/* <div className="mt-3">
                      {ReactHtmlParser(dadosWhiteLabel?.texto_convenio_farma)}
                    </div> */}
                  </Col>
                )}
                <Col lg={12} className='justify-center'>
                  <Link
                    className={`btn-pill my-3 weight-500 btn-conheca btn btn-md btn-secondary ${
                      dadosWhiteLabel?.whitelabel_button_1 &&
                      dadosWhiteLabel?.whitelabel_button_1
                    }`}
                    to={`/${
                      dadosWhiteLabel?.url_whitelabel || 'poupa-club'
                    }/desconto-farmacia`}
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                  >
                    Cadastre-se gratuitamente
                  </Link>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </div>

      <Row
        className={
          !dadosWhiteLabel
            ? 'justify-center title-background my-3'
            : 'justify-center my-3'
        }
      >
        <Col className='mb-60'>
          <h2
            className='bold txt-primary text-center'
            style={{
              color: dadosWhiteLabel
                ? dadosWhiteLabel?.whitelabel_text_color_1
                : '',
              backgroundImage: 'none',
            }}
          >
            Encontre o melhor para sua necessidade!
          </h2>
        </Col>
      </Row>

      <div className='mt-60 cartao-poupa px-5'>
        {dadosWhiteLabel ? (
          <CartoesWhiteLabelHome
            cartoes={[
              { id: 1, cartao: dadosWhiteLabel.cartao1 },
              { id: 2, cartao: dadosWhiteLabel.cartao2 },
              { id: 3, cartao: dadosWhiteLabel.cartao3 },
            ]}
          />
        ) : (
          <CartoesHome cartoes={cartoes} />
        )}
      </div>

      <Container>
        <Row className='mt-5 mb-5 txt-primary justify-center'>
          {!dadosWhiteLabel ? (
            <>
              <Col lg={6} className='mb-3 mt-5 pt-3'>
                <br />
                <h3 className='h3-title'>
                  Onde e como usar
                  <br /> o Cartão PoupaClub
                </h3>
                <br />
                <p className='txt-poupaclub-3'>
                  Basta&nbsp;
                  <strong>
                    apresentar o seu Cartão PoupaClub na hora
                    <br /> de pagar a compra e o desconto é aplicado
                    <br /> automaticamente
                  </strong>
                  , sem complicação. É muito
                  <br /> simples e fácil.
                  <br />
                  <br />
                  <strong>Quanto posso esperar economizar?</strong> Poupe mais
                  <br /> de R$1000 por mês com os descontos oferecidos
                  <br /> por nossos parceiros. Quanto mais usar, mais vai
                  <br /> economizar!
                </p>
                <br />
                <div style={{ textAlign: 'end' }} className='mb-5'>
                  <Link
                    className='btn-pill btn-green btn-conheca'
                    size='sm'
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                    to='/cartao'
                  >
                    Conheça mais
                  </Link>
                </div>
              </Col>
              <Col lg={6} className='mb-3'>
                <img
                  src={OndeComo}
                  alt='Benef Desc'
                  width='100%'
                  className='pb-1'
                />
              </Col>
            </>
          ) : (
            <>
              <Col lg={6} className='mb-3 mt-0 pt-3'>
                <br />
                <h3
                  className='h3-title'
                  style={{
                    color: dadosWhiteLabel
                      ? dadosWhiteLabel?.whitelabel_text_color_1
                      : '',
                  }}
                >
                  {dadosWhiteLabel?.onde_como_titulo}
                </h3>
                <br />
                {ReactHtmlParser(dadosWhiteLabel?.onde_como_texto)}
                <br />
                <div style={{ textAlign: 'end' }} className='mb-5'>
                  <Link
                    className={`btn-pill btn-green btn-conheca ${
                      dadosWhiteLabel?.whitelabel_button_2 &&
                      dadosWhiteLabel?.whitelabel_button_2
                    }`}
                    size='sm'
                    onClick={() => {
                      window.scrollTo(0, 0);
                    }}
                    to={`${
                      dadosWhiteLabel?.url_whitelabel || 'poupa-club'
                    }/cartao`}
                  >
                    Conheça mais
                  </Link>
                </div>
              </Col>
              <Col lg={6} className='mb-3 mt-4'>
                <img
                  src={dadosWhiteLabel?.home_img_saiba_mais_img?.url || ''}
                  width='100%'
                  alt='Como'
                />
              </Col>
            </>
          )}
        </Row>
      </Container>

      <div className='info-descontos'>
        <Container>
          <Row className='text-center justify-content-center'>
            {!dadosWhiteLabel ? (
              <>
                <Col
                  lg={3}
                  md={6}
                  className='my-3 txt-primary mw-dados-responsive-26'
                >
                  <span className='text-orange'>+ de 500 </span>
                  parceiros
                  <br />
                  em
                  <span className='text-orange'> + de 2000 </span>
                  lojas
                  <br />
                  com descontos exclusivos
                </Col>
                <Col lg={3} md={6} className='my-3 mw-dados-responsive-26'>
                  <span className='text-orange'>+ de 110.000</span>
                  <br />
                  <span className='txt-primary'>vidas beneficiadas</span>
                </Col>
                <Col lg={3} md={6} className='my-3 mw-dados-responsive-26'>
                  <span className='text-orange'>+ de 70% </span>
                  <span className='txt-primary'>de descontos</span>
                </Col>
                <Col lg={3} md={6} className='my-3 mw-dados-responsive-26'>
                  <span className='text-orange'>
                    + de R$ 1000 em <br /> economia{' '}
                  </span>
                  <span className='txt-primary'>
                    por mês utilizando o clube <br />
                    de descontos
                  </span>
                </Col>
              </>
            ) : (
              <>
                <Col lg={3} md={6} className='my-3 mw-dados-responsive-26'>
                  {ReactHtmlParser(dadosWhiteLabel?.totalizador_home_1)}
                </Col>
                {dadosWhiteLabel?.totalizador_home_2
                  ?.replace('<p>​</p>', '')
                  ?.replace('<p><br></p>', '')
                  ?.trim() && (
                  <Col lg={3} md={6} className='my-3 mw-dados-responsive-26'>
                    {ReactHtmlParser(dadosWhiteLabel?.totalizador_home_2)}
                  </Col>
                )}
                {dadosWhiteLabel?.totalizador_home_3
                  ?.replace('<p>​</p>', '')
                  ?.replace('<p><br></p>', '')
                  ?.trim() && (
                  <Col lg={3} md={6} className='my-3 mw-dados-responsive-26'>
                    {ReactHtmlParser(dadosWhiteLabel?.totalizador_home_3)}
                  </Col>
                )}

                {dadosWhiteLabel?.totalizador_home_4
                  ?.replace('<p>​</p>', '')
                  ?.replace('<p><br></p>', '')
                  ?.trim() && (
                  <Col lg={3} md={6} className='my-3 mw-dados-responsive-26'>
                    {ReactHtmlParser(dadosWhiteLabel?.totalizador_home_4)}
                  </Col>
                )}
              </>
            )}
          </Row>
        </Container>
      </div>

      <Container>
        <Row className='justify-center'>
          <div className='sobre-nos col-lg-6'>
            <div
              className='txt-sobre-nos'
              style={{
                color: dadosWhiteLabel
                  ? dadosWhiteLabel?.whitelabel_text_color_1
                  : '',
              }}
            >
              {dadosWhiteLabel ? (
                ReactHtmlParser(dadosWhiteLabel?.texto_sobre_propaganda)
              ) : (
                <>
                  <strong>
                    O PoupaClub é uma empresa que disponibiliza o Cartão de
                    Descontos e Benefícios exclusivos para você e sua família.
                    São três modalidades de cartões. Cada um atende um tipo de
                    necessidade.
                  </strong>
                  <br />
                </>
              )}

              <Link
                to={`${dadosWhiteLabel?.url_whitelabel || 'poupa-club'}/sobre`}
                className={dadosWhiteLabel ? '' : 'link-secondary'}
                style={{
                  color: dadosWhiteLabel
                    ? dadosWhiteLabel?.whitelabel_text_color_4
                    : '',
                }}
              >
                Saiba mais
              </Link>
            </div>

            <div className='img-sobre-nos'>
              <img
                src={
                  dadosWhiteLabel?.img_sobre_propaganda_img?.url || cardFooter
                }
                alt='Sobre nós'
                height='150'
              />
            </div>
          </div>
        </Row>
      </Container>
      <Modal isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle}>Solicitar contato</ModalHeader>
        <ModalBody>
          <Form
            className='form-poupaclub mt-3 form-desconto'
            onSubmit={handleSubmit}
            ref={formRef}
          >
            <Row>
              <Col>
                <InputUnform
                  className='input-field'
                  placeholder='Nome completo'
                  name='nome'
                />
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <MaskedInput
                  className='input-field'
                  placeholder='CPF'
                  name='cpf'
                  mask={cpfMask}
                  removeEspecial
                />
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <MaskedInput
                  className='input-field'
                  placeholder='Data de Nascimento'
                  name='data_nascimento'
                  mask={dateMask}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <InputUnform
                  className='input-field'
                  placeholder='E-mail'
                  name='email'
                />
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <MaskedInput
                  className='input-field'
                  name='telefone_celular'
                  placeholder='Telefone ou Celular com DDD'
                  mask={phoneMask}
                  removeEspecial
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div className='justify-center my-3 btn-form'>
                  <Button
                    color='secondary'
                    size='lg'
                    className={
                      dadosWhiteLabel
                        ? 'btn-pill mb-3 bold btn-quero-meus-desc ' +
                          dadosWhiteLabel?.whitelabel_button_3
                        : 'btn-pill mb-3 bold btn-quero-meus-desc'
                    }
                  >
                    Solicitar
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </ModalBody>
      </Modal>
    </>
  );
}

export default Home;
