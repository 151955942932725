export default function formatPhone(unformattedPhone) {
  const phoneSplitted = unformattedPhone.split("");
  let phone;
  if (phoneSplitted.length < 11) {
    phone =
      "(" +
      phoneSplitted[0] +
      phoneSplitted[1] +
      ")" +
      " " +
      phoneSplitted[2] +
      phoneSplitted[3] +
      phoneSplitted[4] +
      phoneSplitted[5] +
      "-" +
      phoneSplitted[6] +
      phoneSplitted[7] +
      phoneSplitted[8] +
      phoneSplitted[9];
  } else {
    phone =
      "(" +
      phoneSplitted[0] +
      phoneSplitted[1] +
      ")" +
      phoneSplitted[2] +
      phoneSplitted[3] +
      phoneSplitted[4] +
      phoneSplitted[5] +
      phoneSplitted[6] +
      "-" +
      phoneSplitted[7] +
      phoneSplitted[8] +
      phoneSplitted[9] +
      phoneSplitted[10];
  }

  return phone;
}
