function MapaParceiro() {
  return (
    <>
      <header>Header</header>
      <main>
        <h1>Mapa de Parceiros PoupaClub</h1>
        <iframe
          title='mapa de parceiros'
          src='https://www.google.com/maps/d/u/0/embed?mid=1Vb2HS6FDHCGS8-zX0mj1afFjuJAqvq4k'
          width='100%'
          height='480'
        ></iframe>
      </main>
      <footer>Footer</footer>
    </>
  );
}

export default MapaParceiro;
