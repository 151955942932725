import * as yup from "yup";

export default async function store(data) {
  const schema = yup.object().shape({
    razao_social: yup.string().required("Obrigatório"),
    nome_fantasia: yup.string().required("Obrigatório"),
    nome_responsavel: yup.string().required("Obrigatório"),
    cnpj: yup.string().required("Obrigatório"),
    email: yup.string().email("E-mail inválido!").required("Obrigatório"),
    descricao: yup.string().required("Obrigatório"),
    subtitulo: yup.string().required("Obrigatório"),
    beneficio: yup.string().required("Obrigatório"),
  });

  await schema.validate(data, {
    abortEarly: false,
  });
}
