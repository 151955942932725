export default function formatDate(unformattedDate) {
  const date = unformattedDate.split("T")[0];
  let dataToFormat = new Date(date);
  let dataFormatada =
    adicionaZero(dataToFormat.getDate().toString()) +
    "/" +
    adicionaZero(dataToFormat.getMonth() + 1).toString() +
    "/" +
    dataToFormat.getFullYear();

  return dataFormatada;
}

function adicionaZero(numero) {
  if (numero <= 9) return "0" + numero;
  else return numero;
}
