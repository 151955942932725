import { useEffect } from "react";
import { Row, Card } from "reactstrap";

import { toast } from "react-toastify";
import Swal from "sweetalert2";

import api from "../../../services/api";
import { useAuth } from "../../../contexts/auth";

function SolicitarRepresentacao() {
  const { user } = useAuth();

  useEffect(() => {
    async function solicitarRepresentacao() {
      try {
        Swal.fire({
          title: "Aguarde",
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });
        const body = {
          usuario: user.nome,
        };

        await api.post("/solicitar-representacao", body);

        toast.success("Representação solicitada");
        Swal.close();
      } catch (e) {
        Swal.close();
        toast.error("Não foi possível solicitar");
      }
    }
    solicitarRepresentacao();
  }, []);

  return (
    <>
      <Row className="row-obrigado">
        <Card className="card-obrigado">
          <h5 className="txt-secondary bold">Obrigado pelo seu interesse</h5>

          <h5 className="txt-primary obrigado-txt-inside">
            Dentro de 48 horas, entramos em
            <br /> contato via telefone. Caso não haja
            <br /> resposta, enviaremos um e-mail.
          </h5>
        </Card>
      </Row>
    </>
  );
}

export default SolicitarRepresentacao;
