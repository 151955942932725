import * as yup from "yup";

const regexCpf = /^([0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2}|[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}\-?[0-9]{2})$/;

export default async function store(data) {
  const schema = yup.object().shape({
    nome: yup.string().required("Obrigatório"),
    cpf: yup.string().matches(regexCpf, "CPF inválido").required("Obrigatório"),
    telefone: yup.string().required("Obrigatório"),
    email: yup.string().email("E-mail inválido!").required("Obrigatório"),
    deseja: yup.string().required("Obrigatório"),
    cidade: yup.string().required("Obrigatório"),
    parceiros: yup.string().required("Obrigatório"),
  });

  await schema.validate(data, {
    abortEarly: false,
  });
}
