import { useEffect, useState, useRef } from 'react';
import {
  Col,
  Row,
  Button,
  Table,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalFooter,
  ModalBody,
  ModalHeader,
} from 'reactstrap';
import { Form } from '@unform/web';
import { ValidationError } from 'yup';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';
import { FaEllipsisH } from 'react-icons/fa';
import { GrTrash, GrInherit } from 'react-icons/gr';

import api from '../../../services/api';
import Input from '../../../components/unformInput';
import CheckboxInput from '../../../components/unformCheckbox';
import createUserValidator from '../../../validations/createUserValidator';

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'ml-3 btn btn-success',
    cancelButton: 'btn btn-danger',
  },
  buttonsStyling: false,
});

function NivelAcesso() {
  const formRef = useRef(null);
  const formRefModal = useRef(null);

  const [modal, setModal] = useState(false);
  const [modal2, setModal2] = useState(false);
  const [data, setData] = useState(null);

  const [user, setUser] = useState(null);
  const [userPermissions, setUserPermissions] = useState(null);

  const toggle = () => setModal(!modal);
  const toggle2 = () => setModal2(!modal2);

  useEffect(() => {
    async function getData() {
      try {
        Swal.fire({
          title: 'Aguarde',
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });
        const { data: result } = await api.get('/listar-adm');
        if (result) {
          setData(result);
          Swal.close();
        }
        Swal.close();
      } catch (err) {
        toast.error(
          err?.response?.data?.error || 'Ocorreu um erro, tente novamente!'
        );
        Swal.close();
      }
    }
    getData();
  }, []);

  useEffect(() => {
    async function getPermissions() {
      if (user) {
        try {
          Swal.fire({
            title: 'Aguarde',
            allowOutsideClick: false,
            showConfirmButton: false,
            onBeforeOpen: () => {
              Swal.showLoading();
            },
          });
          const { data: permissoes } = await api.get(`/permissoes/${user?.id}`);
          setUserPermissions(permissoes);
          Swal.close();
        } catch (e) {
          toast.error('Não foi possível verificar as permissões');
          Swal.close();
        }
      }
    }
    getPermissions();
  }, [user, modal]);

  useEffect(() => {
    formRefModal?.current?.setData(userPermissions);
  }, [userPermissions]);

  async function handleSubmit(formData) {
    try {
      await createUserValidator(formData);
      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      const { data: userAdm } = await api.post('/criar-adm', formData);
      const newData = data
        ? [
            ...data,
            {
              id: userAdm?.id,
              email: userAdm?.email,
              nome: userAdm?.nome,
            },
          ]
        : [
            {
              id: userAdm?.id,
              email: userAdm?.email,
              nome: userAdm?.nome,
            },
          ];
      setData(newData);
      formRef.current.reset();
      Swal.close();
      toggle2();
      toast.success('Usuário Admin criado!');
    } catch (err) {
      const validationErrors = {};
      Swal.close();
      if (err instanceof ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
        toast.error('Verifique os campos em vermelho!');
      } else {
        const error =
          err?.response?.data?.error || 'Ocorreu um erro, tente novamente!';
        toast.error(error);
      }
    }
  }

  async function handleDelete(id) {
    swalWithBootstrapButtons
      .fire({
        title: `Tem certeza que deseja excluir este administrador?`,
        text: 'Esta operação não poderá ser revertida!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          Swal.fire({
            title: 'Aguarde',
            allowOutsideClick: false,
            showConfirmButton: false,
            onBeforeOpen: () => {
              Swal.showLoading();
            },
          });
          api
            .delete(`delete-user/${id}`)
            .then(() => {
              Swal.close();
              setData(data.filter((r) => r.id !== id));
              toast.success('Excluido com sucesso');
            })
            .catch((error) => {
              const msg =
                error?.response?.data?.error ||
                'Ocorreu um erro, tente novamente mais tarde!';
              swalWithBootstrapButtons.fire('Ops...', msg, 'error');
            });
        }
      });
  }

  async function handleSubmitModal(data) {
    Swal.fire({
      title: 'Aguarde',
      allowOutsideClick: false,
      showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
    let body = [
      ...data.usuarios_permissoes,
      ...data.parceiros_permissoes,
      ...data.revededores_permissoes,
      ...data.geral_permissoes,
      ...data.produtos_permissoes,
    ];
    try {
      await api.post(`/cadastrar-permissoes/${user.id}`, body);
      toast.success('Permissões salvas');
      Swal.close();
    } catch (e) {
      Swal.close();
      toast.error('Erro ao salvar permissões!');
    }
  }

  return (
    <>
      <Row>
        <Col lg={12}>
          <h5 className='txt-tertiary'>Usuário administrativo</h5>
          <div className='card-form p-0'>
            <div className='p-3 justify-content-between d-flex align-center'>
              <p className='txt-muted'>
                Total de registros: {data?.length || 0}
              </p>
              <Button color='green' className='btn-save mt-2' onClick={toggle2}>
                Novo usuário
              </Button>
            </div>
            <div className='justify-end'>
              <Table responsive>
                {data?.length > 0 && (
                  <>
                    <thead className='border-top'>
                      <tr>
                        <th>Nome</th>
                        <th>E-mail</th>
                        <th className='text-center'>Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.map((user) => (
                        <tr key={user?.id}>
                          <td>{user?.nome}</td>
                          <td>{user?.email}</td>
                          <td className='text-center'>
                            <UncontrolledButtonDropdown size='xs'>
                              <DropdownToggle color='link link-gray' size='xs'>
                                <FaEllipsisH />
                              </DropdownToggle>
                              <DropdownMenu right>
                                <DropdownItem
                                  className='justify-start'
                                  onClick={() => {
                                    setUser(user);
                                    toggle();
                                  }}
                                >
                                  <GrInherit />
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Permissões
                                </DropdownItem>
                                <DropdownItem
                                  className='justify-start'
                                  onClick={() => {
                                    handleDelete(user?.id);
                                  }}
                                >
                                  <GrTrash />
                                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Excluir
                                </DropdownItem>
                              </DropdownMenu>
                            </UncontrolledButtonDropdown>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </>
                )}
              </Table>
            </div>
          </div>
        </Col>
      </Row>
      <Modal isOpen={modal} size='lg'>
        <ModalHeader toggle={toggle}>
          Permissões para:{' '}
          <strong style={{ color: 'gray' }}>{user?.email}</strong>
        </ModalHeader>
        <Form ref={formRefModal} onSubmit={handleSubmitModal}>
          <ModalBody>
            <h6>Geral</h6>
            <Row>
              <Col lg={12}>
                <CheckboxInput
                  className='ml-4 mr-1'
                  name='geral_permissoes'
                  options={[
                    {
                      id: 6,
                      value: 6,
                      label: 'Dashboards',
                    },
                  ]}
                />
              </Col>
            </Row>
            <hr />
            <h6>Clientes</h6>
            <Row>
              <Col lg={12}>
                <CheckboxInput
                  className='ml-4 mr-1'
                  name='usuarios_permissoes'
                  options={[
                    {
                      id: 37,
                      value: 37,
                      label: 'Cobranças',
                    },
                    {
                      id: 18,
                      value: 18,
                      label: 'Relatório',
                    },
                    {
                      id: 52,
                      value: 52,
                      label: 'Assinaturas',
                    },
                    {
                      id: 34,
                      value: 34,
                      label: 'Clientes PF/PJ',
                    },
                    {
                      id: 24,
                      value: 24,
                      label: 'Alterar dados cadastrais',
                    },
                  ]}
                />
              </Col>
            </Row>
            <hr />
            <h6>Parceiros</h6>
            <Row>
              <Col lg={12}>
                <CheckboxInput
                  className='ml-4 mr-1'
                  name='parceiros_permissoes'
                  options={[
                    {
                      id: 30,
                      value: 30,
                      label: 'Criar parceiro',
                    },
                    {
                      id: 38,
                      value: 38,
                      label: 'Criar marca',
                    },
                    {
                      id: 35,
                      value: 35,
                      label: 'Consultar parceiros',
                    },
                    {
                      id: 39,
                      value: 39,
                      label: 'Consultar marcas',
                    },
                    {
                      id: 25,
                      value: 25,
                      label: 'Alterar dados cadastrais',
                    },
                    {
                      id: 40,
                      value: 40,
                      label: 'Editar marca',
                    },
                    {
                      id: 19,
                      value: 19,
                      label: 'Relatório',
                    },
                    {
                      id: 47,
                      value: 47,
                      label: 'White Label',
                    },
                    {
                      id: 48,
                      value: 48,
                      label: 'Bloqueio de parceiros',
                    },
                  ]}
                />
              </Col>
            </Row>
            <hr />
            <h6>Revendedores</h6>
            <Row>
              <Col lg={12}>
                <CheckboxInput
                  className='ml-4 mr-1'
                  name='revededores_permissoes'
                  options={[
                    {
                      id: 20,
                      value: 20,
                      label: 'Relatório',
                    },
                    {
                      id: 36,
                      value: 36,
                      label: 'Consultar revendedores',
                    },
                    {
                      id: 31,
                      value: 31,
                      label: 'Criar revendedor',
                    },
                    {
                      id: 26,
                      value: 26,
                      label: 'Alterar dados cadastrais',
                    },
                    {
                      id: 41,
                      value: 41,
                      label: 'Vínculo representante',
                    },
                  ]}
                />
              </Col>
            </Row>
            <hr />
            <h6>Produtos</h6>
            <Row>
              <Col lg={12}>
                <CheckboxInput
                  className='ml-4 mr-1'
                  name='produtos_permissoes'
                  options={[
                    {
                      id: 53,
                      value: 53,
                      label: 'Criar e ver produtos',
                    },
                    {
                      id: 54,
                      value: 54,
                      label: 'Criar e ver produtos internos',
                    },
                    {
                      id: 55,
                      value: 55,
                      label: 'Criar e ver vouchers',
                    },
                  ]}
                />
              </Col>
            </Row>
            <hr />
            <h6 className='txt-danger text-center txt-aviso'>
              ATENÇÃO: Caso não possua nenhuma opção selecionada, o usuário terá
              permissão para todas as telas.
            </h6>
          </ModalBody>
          <ModalFooter className='mt-0'>
            <Button color='green' className='btn-save'>
              Salvar permissões
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
      <Modal isOpen={modal2} size='lg'>
        <ModalHeader toggle={toggle2}>Adicionar usuário</ModalHeader>
        <Form ref={formRef} onSubmit={(formData) => handleSubmit(formData)}>
          <ModalBody>
            <Row className='mb-1'>
              <Col lg={6}>
                <Input
                  className='input-field-inner'
                  placeholder='Digite o nome'
                  name='nome'
                  type='text'
                />
              </Col>
              <Col lg={6}>
                <Input
                  className='input-field-inner'
                  placeholder='Digite o e-mail'
                  name='email'
                  type='email'
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className='mt-0'>
            <Button color='green' className='btn-save'>
              Cadastrar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default NivelAcesso;
