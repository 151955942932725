import * as Yup from 'yup';

export default async function store(data) {
  const schema = Yup.object().shape({
    nome: Yup.string().required('Obrigatório'),
    tipo: Yup.string().required('Obrigatório'),
    // numero_funcionarios: Yup.number()
    //   .required('Obrigatório')
    //   .typeError('Obrigatório'),
    numero_dependentes: Yup.number()
      .required('Obrigatório')
      .typeError('Obrigatório'),
    desconto_boleto: Yup.number()
      .required('Obrigatório')
      .typeError('Obrigatório'),
    limitar_idade: Yup.string().required('Obrigatório'),
    idade_limite: Yup.number().typeError('Obrigatório').when('limitar_idade', (limitar_idade, field) =>
      limitar_idade === 'true' ? field.required('Obrigatório') : field
    ),
    conjuge: Yup.string().required('Obrigatório'),
  });

  await schema.validate(data, { abortEarly: false });
}
