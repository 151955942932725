import * as yup from "yup";

export default async function store(data) {
  const schema = yup.object().shape({
    email: yup.string().email("E-mail inválido!").required("Obrigatório"),
    cidade: yup.string().required("Obrigatório"),
    cep: yup.string().required("Obrigatório"),
    endereco: yup.string().required("Obrigatório"),
    numero: yup.string().required("Obrigatório"),
    bairro: yup.string().required("Obrigatório"),
    estado: yup.string().required("Obrigatório"),
    celular: yup.string().required("Obrigatório"),
    telefone: yup.string(),
  });

  await schema.validate(data, {
    abortEarly: false,
  });
}
