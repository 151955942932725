import React, { useEffect, useRef } from "react";
import { useField } from "@unform/core";
import { Col } from "reactstrap";

export default function CheckboxInput({
  name,
  options,
  col,
  initialValue = [],
  ...rest
}) {
  const inputRefs = useRef([]);
  const { fieldName, registerField, defaultValue = [] } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (refs) => {
        return refs.filter((ref) => ref.checked).map((ref) => ref.value);
      },
      clearValue: (refs) => {
        refs.forEach((ref) => {
          ref.checked = false;
        });
      },
      setValue: (refs, values) => {
        refs.forEach((ref) => {
          if (values?.includes(Number(ref.id))) {
            ref.checked = true;
          }
        });
      },
    });
  }, [defaultValue, fieldName, registerField]);

  return (
    <>
      {options.map((option, index) =>
        col ? (
          <Col lg={6} key={option.id}>
            <label htmlFor={option.id} style={{ marginBottom: 0 }}>
              <input
                defaultChecked={
                  defaultValue.some((dv) => dv === option.value) ||
                  initialValue.some((dv) => dv === option.value)
                }
                ref={(ref) => {
                  inputRefs.current[index] = ref;
                }}
                value={option.value}
                type="checkbox"
                id={option.id}
                {...rest}
                className="mr-3"
              />
              {option.label}
            </label>
          </Col>
        ) : (
          <Col lg={5} key={option.id}>
            <label
              htmlFor={option.id}
              key={option.id}
              style={{ marginBottom: 0, cursor: "pointer" }}
            >
              <input
                defaultChecked={
                  defaultValue.some((dv) => dv === option.value) ||
                  initialValue.some((dv) => dv === option.value)
                }
                style={{ cursor: "pointer" }}
                ref={(ref) => {
                  inputRefs.current[index] = ref;
                }}
                value={option.value}
                type="checkbox"
                id={option.id}
                {...rest}
              />
              {option.label}
            </label>
          </Col>
        )
      )}
    </>
  );
}
