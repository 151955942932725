import * as yup from "yup";

export default async function store(data) {
  const schema = yup.object().shape({
    empresa: yup.string().required("Obrigatório"),
    responsavel: yup.string().required("Obrigatório"),
    cidade: yup.string().required("Obrigatório"),
    segmento: yup.string().required("Obrigatório"),
    telefone: yup.string().required("Obrigatório"),
    email: yup.string().email("E-mail inválido!").required("Obrigatório"),
    horario: yup.string().required("Obrigatório"),
  });

  await schema.validate(data, {
    abortEarly: false,
  });
}
