import { Form } from '@unform/web';
import pt from 'date-fns/locale/pt-BR';
import xlsx from 'json-as-xlsx';
import { useEffect, useRef, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import {
  FaChevronLeft,
  FaChevronRight,
  FaEllipsisH,
  FaFileSignature,
  FaHandHoldingUsd,
  FaMoneyBill,
  FaPlus,
  FaRegCreditCard,
} from 'react-icons/fa';
import { FiFilter } from 'react-icons/fi';
import { GrClear, GrCurrency, GrDocumentText } from 'react-icons/gr';
import {
  IoBriefcase,
  IoCard,
  IoCheckmarkCircleOutline,
  IoCloseCircleOutline,
  IoReceiptOutline,
  IoTimeOutline,
  IoWarningOutline,
} from 'react-icons/io5';
import { RiBarcodeFill } from 'react-icons/ri';
import Pagination from 'react-paginate';
import { toast } from 'react-toastify';
import {
  Button,
  ButtonGroup,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  UncontrolledCollapse,
  UncontrolledDropdown,
  UncontrolledPopover,
  UncontrolledTooltip,
} from 'reactstrap';
import Swal from 'sweetalert2';
import { ReactComponent as Pix } from '../../../assets/pix.svg';
import TooltipCustom from '../../../components/TooltipCustom';
import CheckboxInput from '../../../components/unformCheckbox';
import useFecth from '../../../hooks/useFetch';
import api from '../../../services/api';
import convertStringDate from '../../../utils/convertStringDate';
import formatarDinheiro from '../../../utils/formatarDinheiro';
import { periodoDataLabel } from '../../../utils/periodoData';
import ModalCobranca from './modalCobranca';

registerLocale('pt-BR', pt);

const statusIcon = {
  ACTIVE: (date, i) =>
    new Date(date) >
    new Date(new Date().toISOString().slice(0, 10) + ' 12:00:00') ? (
      <>
        <TooltipCustom title='Pendente' id={i}>
          <IoTimeOutline size='1.8em' color='orange' />
        </TooltipCustom>
      </>
    ) : (
      <>
        <TooltipCustom title='Vencida' id={i}>
          <IoWarningOutline size='1.8em' color='orangered' />
        </TooltipCustom>
      </>
    ),
  PAID: (date, i) => (
    <>
      <TooltipCustom title='Paga' id={i}>
        <IoCheckmarkCircleOutline size='1.8em' color='green' />
      </TooltipCustom>
    </>
  ),
  CANCELLED: (date, i) => (
    <>
      <TooltipCustom title='Cancelada' id={i}>
        <IoCloseCircleOutline size='1.8em' color='red' />
      </TooltipCustom>
    </>
  ),
  MANUAL_RECONCILIATION: (date, i) => (
    <>
      <TooltipCustom title='Pago manualmente' id={i}>
        <FaHandHoldingUsd size='1.8em' color='darkorange' />
      </TooltipCustom>
    </>
  ),
};

const produtoIcons = {
  especial: (produto, i) => (
    <>
      <TooltipCustom title={produto} id={`produto_${i}`}>
        <IoCard size='1.8em' color='#3c9782' />
      </TooltipCustom>
    </>
  ),
  black: (produto, i) => (
    <>
      <TooltipCustom title={produto} id={`produto_${i}`}>
        <IoCard size='1.8em' />
      </TooltipCustom>
    </>
  ),
  gold: (produto, i) => (
    <>
      <TooltipCustom title={produto} id={`produto_${i}`}>
        <IoCard size='1.8em' color='#b9940c' />
      </TooltipCustom>
    </>
  ),
  empresarial: (produto, i) => (
    <>
      <TooltipCustom title={produto} id={`produto_${i}`}>
        <IoBriefcase size='1.8em' color='#afafaf' />
      </TooltipCustom>
    </>
  ),
};

const pagamentoIcons = {
  CREDIT_CARD: (nome, i) => (
    <>
      <TooltipCustom title={nome} id={`pagamento_${i}`}>
        <FaRegCreditCard size='1.8em' />
      </TooltipCustom>
    </>
  ),
  ASSINATURA: (nome, i) => (
    <>
      <TooltipCustom title={nome} id={`pagamento_${i}`}>
        <FaFileSignature size='1.8em' />
      </TooltipCustom>
    </>
  ),
  BOLETO: (nome, i) => (
    <>
      <TooltipCustom title={nome} id={`pagamento_${i}`}>
        <RiBarcodeFill size='1.8em' />
      </TooltipCustom>
    </>
  ),
  PIX: (nome, i) => (
    <>
      <TooltipCustom title={nome} id={`pagamento_${i}`}>
        <Pix width='1.8em' heigth='1.8em' />
      </TooltipCustom>
    </>
  ),
  BOLETO_PIX: (nome, i) => (
    <>
      <TooltipCustom title={nome} id={`pagamento_${i}`}>
        <Pix width='1.8em' heigth='1.8em' />
      </TooltipCustom>
    </>
  ),
};

const pagamentoIcon = (pagamento, i) => {
  const icon = pagamentoIcons[pagamento?.descricao];
  return icon ? (
    icon(pagamento.nome, i)
  ) : (
    <>
      <FaMoneyBill size='1.8em' id={`pagamento_${i}`} />
      <UncontrolledTooltip placement='bottom' target={`pagamento_${i}`}>
        {pagamento?.nome}
      </UncontrolledTooltip>
    </>
  );
};

const produtoIcon = (produto, i) => {
  const icon = produtoIcons[produto?.descricao];
  return icon ? (
    icon(`${produto?.nome} ${produto?.tipo || ''}`.trim(), i)
  ) : (
    <>
      <IoReceiptOutline size='1.8em' id={`produto_${i}`} />
      <UncontrolledTooltip placement='bottom' target={`produto_${i}`}>
        {`${produto?.nome || 'Sem nome'} ${produto?.tipo || ''}`.trim()}
      </UncontrolledTooltip>
    </>
  );
};

const NomeCliente = (nome, i) => (
  <>
    <div className='crop' id={`cliente_nome_${i}`}>
      {nome?.toLowerCase()}
    </div>
    <UncontrolledTooltip placement='bottom' target={`cliente_nome_${i}`}>
      {nome}
    </UncontrolledTooltip>
  </>
);

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'ml-3 btn btn-success',
    cancelButton: 'btn btn-danger',
  },
  buttonsStyling: false,
});

const statusRelatorio = {
  ACTIVE: (date) => {
    if (new Date(date).getTime() < new Date().getTime()) {
      return 'Vencido';
    }
    return 'Pendente';
  },
  PAID: () => 'Pago',
  CANCELLED: () => 'Cancelado',
};

function RelatorioParceiro() {
  let timeoutConsulta;
  const tableRef = useRef();
  const [dateEmissao, setDateEmissao] = useState(null);
  const [dateVencimento, setDateVencimento] = useState(null);
  const [datePagamento, setDatePagamento] = useState(null);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [modalCobranca, setModalCobranca] = useState(false);
  const [filtros, setFiltros] = useState({
    tipo: 'todos',
  });
  const [idParcelas, setIdParcelas] = useState([]);
  const [clienteModal, setClienteModal] = useState(null);

  const refNome = useRef(null);
  const refNomeFantasia = useRef(null);
  const refCpf = useRef(null);
  const refCnpj = useRef(null);

  const { data: cobrancas, mutate } = useFecth('/cobrancas-par-rev');

  const toggle = () => setModal(!modal);
  const toggleCobranca = () => setModalCobranca(!modalCobranca);

  const onChangeEmissao = (dates) => {
    const [start, end] = dates;
    setDateEmissao({ start, end });
  };
  const onChangeVencimento = (dates) => {
    const [start, end] = dates;
    setDateVencimento({ start, end });
  };
  const onChangePagamento = (dates) => {
    const [start, end] = dates;
    setDatePagamento({ start, end });
  };

  function resetPeriodo() {
    setDatePagamento(null);
    setDateVencimento(null);
    setDateEmissao(null);

    setFiltros({ tipo: 'todos' });
    setIdParcelas([]);
  }

  const debounceConsulta = async (body) => {
    return await new Promise((resolve) => {
      clearTimeout(timeoutConsulta);

      timeoutConsulta = setTimeout(() => {
        setIdParcelas([]);

        resolve(setFiltros({ ...filtros, ...body, page: 1 }));
      }, 500);
    });
  };

  function changeTipo(tipo) {
    if (filtros.tipo !== tipo) {
      setFiltros({
        ...filtros,
        page: 1,
        tipo,
      });
      setIdParcelas([]);
    }
  }

  function cancelarParcela(item) {
    swalWithBootstrapButtons
      .fire({
        title: `Esta operação não poderá ser revertida!`,
        text: `Tem certeza que deseja cancelar a cobrança ${formatarDinheiro.format(
          item?.valorparcela || 0
        )} (${item?.parcela || 1}/${item?.Pedido?.total_parcelas || 1}) de ${
          item?.Pedido?.ClientePf?.nome || ''
        } ?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          Swal.fire({
            title: 'Aguarde',
            allowOutsideClick: false,
            showConfirmButton: false,
            onBeforeOpen: () => {
              Swal.showLoading();
            },
          });
          api
            .put(`parcelas/${item.id}`)
            .then(() => {
              Swal.close();
              const body = {
                boleto: 'CANCELAMENTO',
                valor: item?.valorparcela,
                parcela: item?.parcela,
                dueDate: convertStringDate(item?.data_parcela),
                cliente: item?.Pedido?.ClientePf
                  ? item?.Pedido?.ClientePf?.id
                  : item?.Pedido?.ClientePj?.id,
                tipo: item?.Pedido?.ClientePf?.nome ? 'PF' : 'PJ',
              };

              api.post('/email-gera-cancela-boleto', body);

              mutate(
                {
                  ...cobrancas,
                  parcelas: cobrancas.parcelas.map((m) => {
                    if (m.id === item.id) {
                      return {
                        ...item,
                        status: 'CANCELLED',
                      };
                    }

                    return m;
                  }),
                },
                false
              );

              toast.success('Cancelado com sucesso!');
            })
            .catch((error) => {
              console.log(error);
              const msg =
                error?.response?.data?.error ||
                'Ocorreu um erro, tente novamente mais tarde!';

              swalWithBootstrapButtons.fire('Ops...', msg, 'error');
            });
        }
      });
  }

  const handleExport = () => {
    const columns = [
      {
        label: 'Nome/Nome fantasia',
        value: (row) =>
          row.Pedido?.ClientePf?.nome || row.Pedido?.ClientePj?.nome_fantasia,
      },
      {
        label: 'Cpf/Cnpj',
        value: (row) =>
          row.Pedido?.ClientePf?.cpf || row.Pedido?.ClientePj?.cnpj,
      },
      {
        label: 'Celular',
        value: (row) => row.Pedido?.ClientePf?.telefone_celular,
      },
      { label: 'Email', value: (row) => row.Pedido?.ClientePf?.email },
      { label: 'Descrição', value: (row) => row.Pedido?.Produto?.nome || '' },
      {
        label: 'Emissão',
        value: (row) => new Date(row.created_at).toLocaleDateString(),
      },
      {
        label: 'Vencimento',
        value: (row) => new Date(row.data_parcela).toLocaleDateString(),
      },
      {
        label: 'Valor',
        value: (row) => formatarDinheiro.format(row.valorparcela),
      },
      {
        label: 'Valor Pago',
        value: (row) =>
          row.valor_pagamento ? formatarDinheiro.format(row.valorparcela) : '',
      },
      {
        label: 'Data Pgto.',
        value: (row) =>
          row.data_pagamento
            ? new Date(row.data_pagamento).toLocaleDateString()
            : '',
      },
      {
        label: 'Status',
        value: (row) => statusRelatorio[row.status](row.data_parcela),
      },
      { label: 'Link', value: (row) => row.link_pagamento },
    ];

    const settings = {
      sheetName: 'FirstSheet',
      fileName: `relatorio-${new Date().toLocaleDateString()}`,
      extraLength: 3, // A bigger number means that columns should be wider
      writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
    };

    xlsx(columns, cobrancas.parcelas, settings, true);
  };

  const handleChangeCheck = (checked, parcela) => {
    if (checked) {
      if (!idParcelas.some((s) => s.id === parcela.id)) {
        setIdParcelas([...idParcelas, parcela]);
      }
    } else {
      setIdParcelas(idParcelas.filter((f) => f.id !== parcela.id));
    }
  };

  const handleChangeCheckAll = (checked) => {
    if (checked) {
      setIdParcelas(cobrancas.parcelas);
    } else {
      setIdParcelas([]);
    }
  };

  const abrirModalBoleto = (cliente) => {
    setClienteModal(cliente);
    toggleCobranca();
  };

  useEffect(() => {
    async function getParcelas() {
      setIdParcelas([]);
      try {
        let filtroConsulta = '';
        Object.entries(filtros).forEach(([key, value]) => {
          if (['string', 'number'].includes(typeof value)) {
            filtroConsulta += `${key}=${value}&`;
          } else {
            if (value) {
              if (value.length === undefined) {
                Object.entries(value).forEach(([key2, value2]) => {
                  filtroConsulta += `${key}${key2}=${
                    new Date(value2).toISOString().split('T')[0]
                  }&`;
                });
              } else {
                filtroConsulta += `${key}=[${value.toString()}]&`;
              }
            }
          }
        });

        const { data } = await api.get(`/cobrancas-par-rev?${filtroConsulta}`);
        mutate(data, false);
      } catch (err) {}
    }
    if (loading) {
      getParcelas();
    } else {
      setLoading(true);
    }
  }, [filtros]);

  return (
    <>
      <div className='card-form  p-0'>
        <div className='p-3 justify-content-between d-flex align-center'>
          <p className='txt-muted'>
            Total de registros: {cobrancas?.total || 0}
          </p>
          <Button color='muted' id='toggler'>
            <FiFilter /> Filtros
          </Button>
          <div />
        </div>
        <div className='filter-collapse'>
          <UncontrolledCollapse toggler='#toggler'>
            <Row className='mt-3'>
              <Col lg={2} className='mb-3' md={3}>
                <input
                  placeholder='Nome cliente'
                  className='input-field-inner form-control'
                  ref={refNome}
                  onChange={() =>
                    debounceConsulta({
                      nome: refNome?.current?.value,
                    })
                  }
                />
              </Col>
              <Col lg={2} className='mb-3' md={3}>
                <input
                  placeholder='Nome fantasia'
                  className='input-field-inner form-control'
                  ref={refNomeFantasia}
                  onChange={() =>
                    debounceConsulta({
                      nome_fantasia: refNomeFantasia?.current?.value,
                    })
                  }
                />
              </Col>
              <Col lg={2} className='mb-3' md={3}>
                <input
                  placeholder='CPF'
                  className='input-field-inner form-control'
                  ref={refCpf}
                  onChange={() =>
                    debounceConsulta({
                      cpf: refCpf?.current?.value,
                    })
                  }
                />
              </Col>
              <Col lg={2} className='mb-3' md={3}>
                <input
                  placeholder='CNPJ'
                  className='input-field-inner form-control'
                  ref={refCnpj}
                  onChange={() =>
                    debounceConsulta({
                      cnpj: refCnpj?.current?.value,
                    })
                  }
                />
              </Col>
              <Col lg={4} className='mb-3' md={4}>
                <Button
                  id='periodo_filtros'
                  type='button'
                  color='link'
                  block
                  className='mx-1'
                  onClick={toggle}
                >
                  <FaPlus className='mr-3' />
                  Filtros personalizados
                </Button>
              </Col>
            </Row>
          </UncontrolledCollapse>
        </div>

        <Row>
          <Col>
            <ButtonGroup className='w-100 not-rounded'>
              <Button
                color='tabs-secondary'
                active={filtros.tipo === 'todos'}
                onClick={() => changeTipo('todos')}
              >
                Todas
              </Button>
              <Button
                color='tabs-secondary'
                active={filtros.tipo === '1x'}
                onClick={() => changeTipo('1x')}
              >
                À vista
              </Button>
              <Button
                color='tabs-secondary'
                active={filtros.tipo === '2x'}
                onClick={() => changeTipo('2x')}
              >
                Parceladas
              </Button>
              <Button
                color='tabs-secondary'
                active={filtros.tipo === 'recorrentes'}
                onClick={() => changeTipo('recorrentes')}
              >
                Recorrentes
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
        <Table responsive className='table-cobrancas' innerRef={tableRef}>
          <thead>
            <tr>
              <th>
                <div className='pl-1 ml-1'>
                  <input
                    type='checkbox'
                    checked={idParcelas.length === 10}
                    onChange={(e) => handleChangeCheckAll(e.target.checked)}
                  />
                </div>
              </th>
              <th>Status</th>
              <th>Forma</th>
              <th>Cliente</th>
              <th className='text-center'>Produto</th>
              <th>Valor</th>
              <th>Cupom</th>
              <th>Emissão</th>
              <th>Vencimento</th>
              <th>Pagamento</th>
              <th className='text-center'>Ação</th>
            </tr>
          </thead>
          <tbody>
            {cobrancas?.parcelas?.map((parcela, i) => (
              <tr>
                <td>
                  <div className='pl-1 ml-1'>
                    <input
                      type='checkbox'
                      className='my-0 mx-0'
                      checked={idParcelas.some((s) => s.id === parcela.id)}
                      onChange={(e) =>
                        handleChangeCheck(e.target.checked, parcela)
                      }
                    />
                  </div>
                </td>
                <td>
                  <div className='pl-1'>
                    {statusIcon[parcela?.status](parcela?.data_parcela, i)}
                  </div>
                </td>
                <td>
                  <div className='pl-1'>
                    {pagamentoIcon(parcela?.Pedido?.CondicaoPagamento, i)}
                  </div>
                </td>
                <td className='td-nome'>
                  {NomeCliente(
                    parcela?.Pedido?.ClientePf?.nome ||
                      parcela?.Pedido?.ClientePj?.nome_fantasia,
                    i
                  )}
                </td>
                <td>
                  <div className='justify-center'>
                    {produtoIcon(parcela?.Pedido?.Produto, i)}
                  </div>
                </td>
                {parcela.Pedido.total_parcelas > 1 &&
                parcela?.Pedido?.CondicaoPagamento.descricao !==
                  'ASSINATURA' ? (
                  <td className='td-cobranca'>
                    <div>
                      ({parcela.parcela}/{parcela.Pedido.total_parcelas})
                      <br />
                      {formatarDinheiro.format(parcela?.valorparcela)}
                    </div>
                  </td>
                ) : (
                  <td>{formatarDinheiro.format(parcela?.valorparcela)}</td>
                )}

                <td>{parcela?.Pedido?.cupom}</td>
                <td>{new Date(parcela?.created_at).toLocaleDateString()}</td>
                <td>{new Date(parcela?.data_parcela).toLocaleDateString()}</td>
                <td className='td-cobranca'>
                  {parcela?.valor_pagamento ? (
                    <div>
                      {formatarDinheiro.format(parcela?.valor_pagamento)}
                      <br />
                      {new Date(parcela?.data_pagamento).toLocaleDateString()}
                    </div>
                  ) : (
                    <div>
                      -
                      <br />-{' '}
                    </div>
                  )}
                </td>
                <td>
                  <div className='justify-center'>
                    <UncontrolledDropdown size='xs'>
                      <DropdownToggle color='link link-gray' size='xs'>
                        <FaEllipsisH size='1.3em' />
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem
                          onClick={() =>
                            abrirModalBoleto(parcela?.Pedido?.ClientePf?.id)
                          }
                          size='xs'
                          className='justify-start'
                        >
                          <GrCurrency />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Gerar boleto
                        </DropdownItem>

                        {parcela?.status === 'ACTIVE' && (
                          <>
                            {parcela?.Pedido?.CondicaoPagamento.descricao !==
                              'ASSINATURA' && (
                              <DropdownItem
                                onClick={() =>
                                  window.open(parcela.link_pagamento)
                                }
                                size='xs'
                                className='justify-start'
                              >
                                <GrDocumentText />
                                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Gerar 2° via
                              </DropdownItem>
                            )}

                            <DropdownItem
                              onClick={() => cancelarParcela(parcela)}
                              size='xs'
                              className='justify-start'
                            >
                              <GrClear />
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cancelar parcela
                            </DropdownItem>
                          </>
                        )}
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className='justify-between ml-3'>
          <span>
            <Button
              disabled={!idParcelas.length}
              color={idParcelas.length ? 'secondary' : 'link'}
              onClick={handleExport}
              size='sm'
            >
              Exportar
            </Button>
          </span>
          <Pagination
            nextLabel={<FaChevronRight />}
            previousLabel={<FaChevronLeft />}
            pageCount={cobrancas?.pages || 1}
            onPageChange={({ selected }) =>
              setFiltros({
                ...filtros,
                page: selected + 1,
              })
            }
            forcePage={filtros.page - 1 || 0}
            // initialPage={}
            containerClassName={'pagination justify-end mb-0'}
            activeClassName={'active'}
          />
        </div>
      </div>

      <Modal isOpen={modal} toggle={toggle} size='lg' className='modal-filtros'>
        <Form
          initialData={filtros}
          className='form-filtro'
          onSubmit={(data) => {
            setIdParcelas([]);
            setFiltros({
              ...filtros,
              ...data,
              datePagamento,
              dateEmissao,
              dateVencimento,
              page: 1,
            });
          }}
        >
          <ModalHeader toggle={toggle} className='pl-9'>
            Filtro personalizado
          </ModalHeader>
          <ModalBody btn-save>
            <Row>
              <Col lg={6}>
                <div className='txt-label'>Data de emissão</div>
              </Col>
              <Col lg={6}>
                <Button
                  id='periodo_emissao'
                  type='button'
                  color='link'
                  className='ml-1'
                >
                  {periodoDataLabel(dateEmissao)}
                  <span className='small ml-1'>
                    <small>▼</small>
                  </span>
                </Button>
                <UncontrolledPopover
                  placement='bottom'
                  target='#periodo_emissao'
                  trigger='legacy'
                  hideArrow
                >
                  <DatePicker
                    selected={dateEmissao?.start}
                    onChange={onChangeEmissao}
                    startDate={dateEmissao?.start}
                    endDate={dateEmissao?.end}
                    locale='pt-BR'
                    selectsRange
                    inline
                  />
                </UncontrolledPopover>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <div className='txt-label'>Data de vencimento</div>
              </Col>
              <Col lg={6}>
                <Button
                  id='periodo_vencimento'
                  type='button'
                  color='link'
                  className='ml-1'
                >
                  {periodoDataLabel(dateVencimento)}
                  <span className='small ml-1'>
                    <small>▼</small>
                  </span>
                </Button>
                <UncontrolledPopover
                  placement='bottom'
                  target='#periodo_vencimento'
                  trigger='legacy'
                  hideArrow
                >
                  <DatePicker
                    selected={dateVencimento?.start}
                    onChange={onChangeVencimento}
                    startDate={dateVencimento?.start}
                    endDate={dateVencimento?.end}
                    locale='pt-BR'
                    selectsRange
                    inline
                  />
                </UncontrolledPopover>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <div className='txt-label'>Data de pagamento</div>
              </Col>
              <Col lg={6}>
                <Button
                  id='periodo_pagamento'
                  type='button'
                  color='link'
                  className='ml-1'
                >
                  {periodoDataLabel(datePagamento)}
                  <span className='small ml-1'>
                    <small>▼</small>
                  </span>
                </Button>
                <UncontrolledPopover
                  placement='bottom'
                  target='#periodo_pagamento'
                  trigger='legacy'
                  hideArrow
                >
                  <DatePicker
                    selected={datePagamento?.start}
                    onChange={onChangePagamento}
                    startDate={datePagamento?.start}
                    endDate={datePagamento?.end}
                    locale='pt-BR'
                    selectsRange
                    inline
                  />
                </UncontrolledPopover>
              </Col>
            </Row>

            <hr className='primary' />

            <Row
              style={{
                paddingBottom: '0',
                paddingTop: '.6em',
              }}
            >
              <CheckboxInput
                initialValue={filtros?.status}
                name='status'
                col
                options={[
                  { id: 'opt_paga', value: 'paga', label: 'Pagas' },
                  { id: 'opt_!paga', value: '!paga', label: 'Não pagas' },
                  { id: 'opt_vencida', value: 'vencida', label: 'Vencidas' },
                  {
                    id: 'opt_!vencida',
                    value: '!vencida',
                    label: 'Não vencidas',
                  },
                  {
                    id: 'opt_cancelado',
                    value: 'cancelado',
                    label: 'Cancelados',
                  },
                  {
                    id: 'opt_!cancelado',
                    value: '!cancelado',
                    label: 'Não cancelados',
                  },
                ]}
              />
            </Row>

            <hr className='primary' />
            <h6
              style={{
                paddingBottom: '.3em',
              }}
            >
              Forma de pagamento
            </h6>
            <Row>
              <CheckboxInput
                name='pagamento'
                col
                initialValue={filtros?.pagamento}
                options={[
                  {
                    id: 'opt_boleto',
                    value: 'BOLETO',
                    label: 'Boleto bancário',
                  },
                  {
                    id: 'opt_boleto_pix',
                    value: 'BOLETO_PIX',
                    label: 'PIX',
                  },
                  {
                    id: 'opt_credito',
                    value: 'CREDIT_CARD',
                    label: 'Cartão de crédito',
                  },
                ]}
              />
            </Row>
          </ModalBody>
          <ModalFooter className='justify-between'>
            <Button
              color='outline-primary'
              className='btn-save'
              type='reset'
              onClick={() => {
                resetPeriodo();
                toggle();
              }}
            >
              Limpar filtros
            </Button>
            <Button
              color='primary'
              className='btn-save'
              type='submit'
              onClick={toggle}
            >
              Aplicar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      <ModalCobranca
        toggle={toggleCobranca}
        cliente={clienteModal}
        isOpen={modalCobranca}
        adm={false}
      />
    </>
  );
}

export default RelatorioParceiro;
