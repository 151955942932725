import React, { useState } from "react";
import {
  Row,
  Col,
  Button,
  Input,
  Modal,
  ModalHeader,
  ModalBody,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from "reactstrap";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { FaLock } from "react-icons/fa";
import formatarDinheiro from "../../../utils/formatarDinheiro";
import api from "../../../services/api";
import useFetch from "../../../hooks/useFetch";
import CreditCards from "../../../components/creditCards";

const statusAssinatura = {
  ACTIVE: "Ativa",
  INACTIVE: "Inativa",
  CANCELED: "Cancelada",
  FAILED: "Falhou",
  undefined: "",
};

const returnDate = (date) =>
  date ? new Date(`${date}  03:00:00`).toLocaleDateString() : "";

function Assinatura() {
  const { data: assinatura, mutate } = useFetch("/assinatura/0");
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  async function ativarAssinatura(creditCardHash) {
    try {
      Swal.fire({
        title: "Aguarde",
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });

      const { data } = await api.put("/ativar-assinatura/0", {
        creditCardHash,
      });
      mutate(data, false);
      toggle();
      Swal.close();
    } catch (err) {
      Swal.close();
      const error =
        err?.response?.data?.error || "Ocorreu um erro, tente novamente!";

      toast.error(error);
    }
  }

  return (
    <>
      <Row>
        <Col lg={7} className="mb-1">
          <h5 className="txt-tertiary">Assinatura</h5>
        </Col>
      </Row>

      <div className="card-form">
        <Row>
          <Col lg={4}>
            <span className="placholder-top">Status</span>
            <InputGroup>
              <Input
                className="input-field-inner"
                disabled
                value={statusAssinatura[assinatura?.status] || assinatura?.status}
              />
              <InputGroupAddon addonType="append">
                <InputGroupText>
                  <FaLock />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Col>
          <Col lg={4}>
            <span className="placholder-top">Dia de cobrança</span>
            <InputGroup>
              <Input
                className="input-field-inner"
                disabled
                value={assinatura?.dia_vencimento}
              />
              <InputGroupAddon addonType="append">
                <InputGroupText>
                  <FaLock />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Col>
          <Col lg={4}>
            <span className="placholder-top">Valor</span>
            <InputGroup>
              <Input
                className="input-field-inner"
                disabled
                value={formatarDinheiro.format(
                  Number(assinatura?.plano?.valor || 0)
                )}
              />
              <InputGroupAddon addonType="append">
                <InputGroupText>
                  <FaLock />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col lg={4}>
            <span className="placholder-top">Último pagamento</span>
            <InputGroup>
              <Input
                className="input-field-inner"
                disabled
                value={returnDate(assinatura?.ultimo_pagamento)}
              />
              <InputGroupAddon addonType="append">
                <InputGroupText>
                  <FaLock />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Col>
          <Col lg={4}>
            <span className="placholder-top">Próximo pagamento</span>
            <InputGroup>
              <Input
                className="input-field-inner"
                disabled
                value={returnDate(assinatura?.proximo_pagamento)}
              />
              <InputGroupAddon addonType="append">
                <InputGroupText>
                  <FaLock />
                </InputGroupText>
              </InputGroupAddon>
            </InputGroup>
          </Col>
          <Col lg={4}>
            <br />
            {assinatura && assinatura?.status !== "ACTIVE" && (
              <Button block color="green" onClick={toggle}>
                Ativar assinatura
              </Button>
            )}
          </Col>
        </Row>

        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}></ModalHeader>
          <ModalBody>
            <CreditCards handleSubmit={ativarAssinatura} />
          </ModalBody>
        </Modal>
      </div>
    </>
  );
}

export default Assinatura;
