import {
  Button,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { Form } from "@unform/web";
import UnformSelect from "../../../components/unformSelect";
import UnformInput from "../../../components/unformInput";
import UnformMaskedInput from "../../../components/unformMaskedInput";
import UnformAsync from "../../../components/unformAsyncSelect";
import { useRef } from "react";
import api from "../../../services/api";
import { dateMask } from "../../../utils/masks";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { ValidationError } from "yup";
import validator from "../../../validations/gerarCobrancaInterna";

function ModalCobranca({ idProduct, toggle, isOpen, tipo = "PF" }) {
  const formRef = useRef();
  let timeoutConsulta;

  const handleSubmit = async (data) => {
    try {
      formRef.current.setErrors({});
      // await validator(data);
      // const body = {
      //   ...data,
      //   produto_interno: isOpen,
      //   tipo,
      // };
      Swal.fire({
        title: "Aguarde",
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      // const { data: pedido } = await api.post("/pedido_interno", body);

      // const link =
      //   pedido.link_pagamento || pedido.link || pedido.installmentLink;
      toggle();
      Swal.close();
      window.open(`/poupa-club/produto/${idProduct}&vend=${data?.vendedor}`);
    } catch (err) {
      const validationErrors = {};
      Swal.close();
      if (err instanceof ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
        toast.error("Verifique os campos em vermelho!");
      } else {
        const error =
          err?.response?.data?.error || "Ocorreu um erro, tente novamente!";
        toast.error(error);
      }
    }
  };

  const debounceConsultaRepresentantes = async (body) => {
    return await new Promise((resolve) => {
      clearTimeout(timeoutConsulta);

      timeoutConsulta = setTimeout(() => {
        resolve(filtrarRepresentantes(body));
      }, 500);
    });
  };

  async function filtrarRepresentantes({ value, param }) {
    const result = await api.get(`options/representantes?${param}=${value}`);

    return result.data.map((m) => ({
      label: m.nome_fantasia,
      value: m.usuario_id,
    }));
  }

  return (
    <Modal isOpen={isOpen}>
      <Form onSubmit={(data) => handleSubmit(data)} ref={formRef}>
        <ModalHeader toggle={toggle}>Gerar link de pedido interno</ModalHeader>
        <ModalBody>
          <Row>
            {/* <Col lg={4} className='mt-3'>
              <FormGroup>
                <Label>Parcelamento</Label>
                <UnformSelect
                  name='parcelas'
                  className='input-field-select-inner'
                  options={[...Array(12)].map((m, i) => ({
                    value: i + 1,
                    label: `${i + 1}x`,
                  }))}
                />
              </FormGroup>
            </Col> */}

            {/* <Col lg={4} className='mt-3'>
              <FormGroup>
                <Label>Data Vencimento</Label>
                <UnformMaskedInput
                  name='data_vencimento'
                  placeholder=''
                  className='input-field-inner'
                  mask={dateMask}
                />
              </FormGroup>
            </Col> */}

            {/* <Col lg={4} className='mt-3'>
              <FormGroup>
                <Label>Pgto.</Label>
                <UnformSelect
                  name='none'
                  className='input-field-select-inner'
                  options={[
                    {
                      label: 'Boleto / Pix',
                      value: '',
                    },
                  ]}
                />
              </FormGroup>
            </Col> */}

            {/* <Col lg={12}>
              <FormGroup>
                <Label>Usuário</Label>
                <UnformAsync
                  name='usuario'
                  placeholder={''}
                  noOptionsMessage={() => 'Digite para pesquisar'}
                  loadOptions={(value) =>
                    debounceConsultaUsuario({
                      value,
                      param: 'nome',
                    })
                  }
                />
              </FormGroup>
            </Col> */}

            <Col lg={12}>
              <FormGroup>
                <Label>Parceiro/revendedor</Label>
                <UnformAsync
                  name="vendedor"
                  placeholder={"Informe o nome para ocorrer o split"}
                  noOptionsMessage={() => "Digite para pesquisar"}
                  loadOptions={(value) =>
                    debounceConsultaRepresentantes({
                      value,
                      param: "nome",
                    })
                  }
                />
              </FormGroup>
            </Col>

            {/* <Col lg={12}>
              <FormGroup>
                <Label>Descrição cobrança</Label>
                <UnformInput name='descricao' className='input-field-inner' />
              </FormGroup>
            </Col> */}
          </Row>
        </ModalBody>
        <ModalFooter className="justify-between px-3">
          <Button
            color="outline-primary"
            className="btn-save"
            type="reset"
            onClick={toggle}
          >
            Cancelar
          </Button>
          <Button color="primary" className="btn-save px-6" type="submit">
            Gerar
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}

export default ModalCobranca;
