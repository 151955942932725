import { Col, Row, Button, Container } from "reactstrap";
import { Form } from "@unform/web";
import Input from "../../components/unformInput";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { ValidationError } from "yup";
import { useRef } from "react";
import emailvalidator from "../../validations/emailValidator";

import api from "../../services/api";

function EsqueciSenha() {
  const formRef = useRef(null);

  async function handleSubmit(body) {
    formRef.current.setErrors({});
    try {
      await emailvalidator(body);
      Swal.fire({
        title: "Aguarde",
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      await api.post("/esqueci-senha", body);

      Swal.close();
      toast.success("E-mail com nova senha enviado.");
      // formRef.current.reset();
    } catch (err) {
      const validationErrors = {};
      Swal.close();
      if (err instanceof ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
        toast.error("Verifique os campos em vermelho!");
      } else {
        const error =
          err?.response?.data?.error || "Ocorreu um erro, tente novamente!";
        toast.error(error);
      }
    }
  }

  return (
    <Container className="mt-6 justify-center">
      <Row>
        <Col lg={12}>
          <h5 className="txt-tertiary">Esqueceu a senha?</h5>
          <p className="txt-tertiary">
            Não se preocupe, enviaremos uma nova senha no seu e-mail.
          </p>
          <Form onSubmit={(data) => handleSubmit(data)} ref={formRef}>
            <div className="mb-3">
              <Input
                className="input-field"
                placeholder="E-mail cadastrado"
                name="email"
                type="email"
              />
            </div>
            <div>
              <Button color="green" className="btn-pill btn-block mt-1">
                Enviar nova senha
              </Button>
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default EsqueciSenha;
