import { Col, Row } from "reactstrap";
import { useEffect, useRef } from "react";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import { Button } from "reactstrap";
import { Form } from "@unform/web";
import { ValidationError } from "yup";
import { useHistory } from "react-router-dom";

import Input from "../../../components/unformInput";
// import validator from '../../../validations/criarCartaoEmpresárial';

import api from "../../../services/api";

function CriarProdutoInterno() {
  const formRef = useRef(null);
  const history = useHistory();

  async function handleSubmit(data) {
    try {
      const errors = {};
      if (!data.codigo) {
        errors.codigo = "Obrigatório";
      }
      if (!data.desconto) {
        errors.desconto = "Obrigatório";
      }

      if (Object.keys(errors).length) {
        toast.error("Verifique os campos em vermelho!");

        return formRef.current.setErrors(errors);
      }

      Swal.fire({
        title: "Aguarde",
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });

      const params1 = new URLSearchParams(window.location.search);
      const id = params1.get("id");
      if (id) {
        await api.put(`/voucher/${id}`, data);
        toast.success("Voucher alterado com sucesso");
      } else {
        await api.post("/voucher", data);
        toast.success("Voucher cadastrado com sucesso");
      }
      Swal.close();
      history.push("/acesso/voucher");
    } catch (err) {
      const validationErrors = {};
      Swal.close();
      if (err instanceof ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
        toast.error("Verifique os campos em vermelho!");
      } else {
        const error =
          err?.response?.data?.error || "Ocorreu um erro, tente novamente!";
        toast.error(error);
      }
    }
  }

  useEffect(() => {
    async function getData() {
      const params1 = new URLSearchParams(window.location.search);
      const id = params1.get("id");
      if (id) {
        Swal.fire({
          scrollbarPadding: false,
          title: "Aguarde",
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });
        try {
          const { data: res } = await api.get(`/voucher/${id}`);
          formRef.current.setData(res);
        } catch (error) {}
        Swal.close();
      }
    }

    getData();
  }, []);

  return (
    <>
      <Row>
        <Col lg={11} className="mb-1">
          <div className="justify-between">
            <h5 className="txt-tertiary">Criar produto interno</h5>
          </div>
        </Col>
      </Row>
      <Form onSubmit={handleSubmit} className="card-form" ref={formRef}>
        <Row>
          <Col lg={4} className="mb-2">
            <span className="placholder-top">Código cupom</span>
            <Input
              className="input-field-inner text-upper"
              placeholder=""
              name="codigo"
              type="text"
            />
          </Col>
          <Col lg={4} className="mb-2">
            <span className="placholder-top">% Desconto</span>

            <Input
              className="input-field-inner "
              placeholder=""
              name="desconto"
              step="any"
              type="number"
              min="1"
            />
          </Col>
        </Row>

        <Row className="mt-20">
          <Col className="justify-end">
            <Button color="green" className="btn-save mb-1">
              Salvar
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default CriarProdutoInterno;
