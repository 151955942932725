import React, { useRef, useEffect } from 'react';
import ReactInputMask from 'react-text-mask';
import { useField } from '@unform/core';

const InputMask = ({ name, className, removeEspecial, ...rest }) => {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      getValue: (ref) => {
        if (removeEspecial) {
          return ref.textMaskInputElement.state.previousConformedValue.replace(/[^A-Z0-9]/ig, "");
        }
        return ref.textMaskInputElement.state.previousConformedValue;
      },
      setValue: (ref,value) => {
        ref.inputElement.value = value
        ref.textMaskInputElement.update()
      }
    });
  }, [fieldName, registerField, removeEspecial]);
  return (
    <>
      <ReactInputMask
        ref={inputRef}
        defaultValue={defaultValue}
        className={`${className ? className : ''} form-control ${error ? "error" :  ''}`.trim()}
        {...rest}
      />
      {error && <small className='error'>{error}</small>}
    </>
  );
};
export default InputMask;
