import React from "react";
import {
  Card,
  CardBody,
  Button,
  Badge,
  CardFooter,
  Col,
  Row,
} from "reactstrap";
import { useHistory } from "react-router";

import cartaoEspecial from "../assets/cartao_especial.png";
import cartaoBlack from "../assets/cartao_black.png";
import cartaoGold from "../assets/cartao_gold.png";
import cartaoEmpresarial from "../assets/cartao_empresarial_poupa.jpg";
import { useWhiteLabel } from "../contexts/whitelabel";

function CartoesHome({ cartoes }) {
  const history = useHistory();
  const { dadosWhiteLabel } = useWhiteLabel();

  function getPrecoCartao(tipo) {
    return Number(cartoes.find((f) => f.descricao === tipo)?.valor);
  }

  return (
    <Row>
      <Col lg={3} md={6} sm={12} className="mb-60">
        <Card className="card-cartoes">
          <CardBody className="card-body-cartoes">
            <div className="card-image-cartoes">
              <img src={cartaoEspecial} alt="Cartão especial" />
            </div>
            <div className="justify-start my-3">
              <strong className="txt-tertiary txt-cartaopoupa">
                Cartão PoupaClub
              </strong>
              &nbsp;
              <div>
                <Badge pill color="especial" className="bold">
                  ESPECIAL
                </Badge>
              </div>
            </div>
            <div className="txt-tertiary">
              <div>
                <strong>1 (um) usuário titular</strong>
              </div>
              <div>Validade de 12 meses</div>
              <br />
              <div>
                À vista de R${" "}
                {getPrecoCartao("especial").toLocaleString("pt-BR", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{" "}
                (+ 15% de desconto)
              </div>
              <div>
                ou 12x R${" "}
                {(getPrecoCartao("especial") / 12).toLocaleString("pt-BR", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{" "}
                no Cartão de crédito
              </div>
              <div>
                ou 4x R${" "}
                {(getPrecoCartao("especial") / 4).toLocaleString("pt-BR", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{" "}
                no Boleto bancário
              </div>
            </div>
          </CardBody>
          <CardFooter className="card-footer-cartoes">
            <div className="mb-3 justify-center">
              <div className="bold price-subtitle">R$ &nbsp;</div>
              <div
                style={{
                  marginBottom: "-5px",
                  display: "flex",
                }}
              >
                <div className="bold price-title">
                  {
                    (getPrecoCartao("especial") / 12)
                      .toLocaleString("pt-BR", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                      ?.split(",")[0]
                  }
                  ,
                </div>
                <div className="bold price-up">
                  {
                    (getPrecoCartao("especial") / 12)
                      .toLocaleString("pt-BR", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                      ?.split(",")[1]
                  }
                </div>
              </div>
              <div className="bold price-subtitle">&nbsp;por mês</div>
            </div>
            <div>
              <p className="via-cartao">via cartão de crédito</p>
            </div>

            <div className='card-footer-cartoes justify-center'>
              <div className='w-70'>
                <Button
                  className="btn-pill bold btn-block"
                  color="especial"
                  size="lg"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    history.push(
                      `/${
                        dadosWhiteLabel?.url_whitelabel || "poupa-club"
                      }/cartao-escolhido/especial`
                    );
                  }}
                >
                  Peça agora
                </Button>
              </div>
            </div>
          </CardFooter>
        </Card>
      </Col>

      <Col lg={3} md={6} sm={12} className='mb-60'>
        <Card className='card-cartoes'>
          <CardBody className='card-body-cartoes'>
            <div className='card-image-cartoes'>
              <img src={cartaoGold} alt='Cartão Gold' />
            </div>

            <div className="justify-start my-3">
              <strong className="txt-tertiary txt-cartaopoupa">
                Cartão PoupaClub
              </strong>
              &nbsp;
              <div>
                <Badge pill color="gold" className="bold">
                  GOLD
                </Badge>
              </div>
            </div>
            <div className="txt-tertiary">
              <div>Até 5 usuários</div>
              <div>
                <strong>1 titular</strong>
              </div>
              <div>
                <strong>+ 1 Cônjuge</strong>
              </div>
              <div>
                <strong>+ 3 Dependentes</strong> menores de 18 anos
              </div>
              <div>Validade de 12 meses</div>
              <br />
              <div>
                À vista de R${" "}
                {getPrecoCartao("gold").toLocaleString("pt-BR", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{" "}
                (+ 15% de desconto)
              </div>
              <div>
                ou 12x R${" "}
                {(getPrecoCartao("gold") / 12).toLocaleString("pt-BR", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{" "}
                no Cartão de crédito
              </div>
              <div>
                ou 4x R${" "}
                {(getPrecoCartao("gold") / 4).toLocaleString("pt-BR", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{" "}
                no Boleto bancário
              </div>
            </div>
          </CardBody>
          <CardFooter className="card-footer-cartoes">
            <div className="mb-3 justify-center">
              <div className="bold price-subtitle">R$ &nbsp;</div>
              <div
                style={{
                  marginBottom: "-5px",
                  display: "flex",
                }}
              >
                <div className="bold price-title">
                  {
                    (getPrecoCartao("gold") / 12)
                      .toLocaleString("pt-BR", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                      ?.split(",")[0]
                  }
                  ,
                </div>
                <div className="bold price-up">
                  {
                    (getPrecoCartao("gold") / 12)
                      .toLocaleString("pt-BR", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                      ?.split(",")[1]
                  }
                </div>
              </div>
              <div className="bold price-subtitle">&nbsp;por mês</div>
            </div>
            <div>
              <p className="via-cartao">via cartão de crédito</p>
            </div>

            <div className='justify-center'>
              <div className='w-70'>
                <Button
                  className="btn-pill bold btn-block"
                  color="gold"
                  size="lg"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    history.push(
                      `/${
                        dadosWhiteLabel?.url_whitelabel || "poupa-club"
                      }/cartao-escolhido/gold`
                    );
                  }}
                >
                  Peça agora
                </Button>
              </div>
            </div>
          </CardFooter>{" "}
        </Card>
      </Col>

      <Col lg={3} md={6} sm={12} className="mb-60">
        <Card className="card-cartoes">
          <CardBody className="card-body-cartoes">
            <div className="card-image-cartoes">
              <img src={cartaoBlack} alt="Cartão Black" />
            </div>
            <div className="justify-start my-3">
              <strong className="txt-tertiary txt-cartaopoupa">
                Cartão PoupaClub
              </strong>
              &nbsp;
              <div>
                <Badge pill color="black" className="bold">
                  BLACK
                </Badge>
              </div>
            </div>
            <div className="txt-tertiary">
              <div>Até 5 usuários</div>
              <div>
                <strong>1 titular</strong>
              </div>
              <div>
                <strong>+ 4 Dependentes</strong> (sem limite de idade)
              </div>

              <div>Validade de 12 meses</div>
              <br />
              <div>
                À vista de R${" "}
                {getPrecoCartao("black").toLocaleString("pt-BR", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{" "}
                (+ 15% de desconto)
              </div>
              <div>
                ou 12x R${" "}
                {(getPrecoCartao("black") / 12).toLocaleString("pt-BR", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{" "}
                no Cartão de crédito
              </div>
              <div>
                ou 4x R${" "}
                {(getPrecoCartao("black") / 4).toLocaleString("pt-BR", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })}{" "}
                no Boleto bancário
              </div>
              <br />
            </div>
          </CardBody>
          <CardFooter className="card-footer-cartoes">
            <div className="mb-3 justify-center">
              <div className="bold price-subtitle">R$ &nbsp;</div>
              <div
                style={{
                  marginBottom: "-5px",
                  display: "flex",
                }}
              >
                <div className="bold price-title">
                  {
                    (getPrecoCartao("black") / 12)
                      .toLocaleString("pt-BR", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                      ?.split(",")[0]
                  }
                  ,
                </div>
                <div className="bold price-up">
                  {
                    (getPrecoCartao("black") / 12)
                      .toLocaleString("pt-BR", {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })
                      ?.split(",")[1]
                  }
                </div>
              </div>
              <div className="bold price-subtitle">&nbsp;por mês</div>
            </div>
            <div>
              <p className="via-cartao">via cartão de crédito</p>
            </div>

            <div className='card-footer-cartoes justify-center'>
              <div className='w-70'>
                <Button
                  className="btn-pill bold btn-block"
                  color="black"
                  size="lg"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    history.push(
                      `/${
                        dadosWhiteLabel?.url_whitelabel || "poupa-club"
                      }/cartao-escolhido/black`
                    );
                  }}
                >
                  Peça agora
                </Button>
              </div>
            </div>
          </CardFooter>
        </Card>
      </Col>

      <Col lg={3} md={6} sm={12} className="mb-60">
        <Card className="card-cartoes">
          <CardBody className="card-body-cartoes">
            <div className="card-image-cartoes">
              <img
                src={cartaoEmpresarial}
                alt="Cartão Black"
                style={{ width: "97%" }}
              />
            </div>
            <div className="justify-start my-3">
              <strong className="txt-tertiary txt-cartaopoupa">
                Cartão PoupaClub
              </strong>
              &nbsp;
              <div>
                <Badge pill color="empresarial" className="bold">
                  EMPRESARIAL
                </Badge>
              </div>
            </div>
            <div className="txt-tertiary">
              <div>
                <strong>Funcionários customizados</strong>
              </div>
              <div>
                <strong>Dependentes customizados</strong>
              </div>
              <div>Validade de 12 meses</div>
              <br />
              <div></div>
              <div></div>
              <div></div>
              <br />
            </div>
          </CardBody>
          <CardFooter className='card-footer-cartoes'>
            <div className='card-footer-cartoes justify-center'>
              <div className='w-70'>
                <Button
                  className="btn-pill bold btn-block"
                  color="empresarial"
                  size="lg"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    history.push(
                      `/${
                        dadosWhiteLabel?.url_whitelabel || "poupa-club"
                      }/cartao-empresarial`
                    );
                  }}
                >
                  Peça agora
                </Button>
              </div>
            </div>
          </CardFooter>
        </Card>
      </Col>
    </Row>
  );
}

export default CartoesHome;
