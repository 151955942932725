import React, { useState, useEffect } from 'react';
import {
  Col,
  Row,
  Table,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import Swal from 'sweetalert2';
import Pagination from 'react-paginate';
import { FaChevronLeft, FaChevronRight, FaEllipsisH } from 'react-icons/fa';
import { GrEdit, GrCart } from 'react-icons/gr';
import { useHistory, Link } from 'react-router-dom';
import api from '../../../services/api';
import ModalPedidoInterno from './modalPedidoInterno';
import { useAuth } from '../../../contexts/auth';
import { useWhiteLabel } from '../../../contexts/whitelabel';

function ListarProdutosInternos() {
  const { user } = useAuth();
  const { dadosWhiteLabel } = useWhiteLabel();
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const [isOpen, setIsOpen] = useState(null);
  const [idProduct, setIdProduct] = useState(null);
  const history = useHistory();

  const toggle = () => setIsOpen(null);

  useEffect(() => {
    async function getData() {
      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });

      const { data } = await api.get(`/produto_interno?page=${page + 1}`);
      setData(data);
      window.scrollTo(0, 0);

      Swal.close();
    }
    getData();
  }, [page]);

  function handleEdit(produto) {
    history.push(`/acesso/criar_produto_interno?id=${produto.id}`);
  }

  return (
    <>
      <Row>
        <Col lg={12}>
          <h5 className='txt-tertiary mb-3'>Produtos internos</h5>
        </Col>
      </Row>

      <div className='card-form  p-0'>
        <div className='p-3 justify-content-between d-flex align-center'>
          <p className='txt-muted'>Total de registros: {data?.total || 0}</p>
          {/* <Button color="muted" id="toggler">
            <FiFilter /> Filtros
          </Button> */}
          <div />
          {user.perfil === 'A' && (
            <Link
              to='/acesso/criar_produto_interno'
              color='green'
              className='btn-save btn btn-green'
            >
              Adicionar
            </Link>
          )}
        </div>
        {/* <div className="filter-collapse">
          <UncontrolledCollapse toggler="#toggler">
            <Row className="mt-3">
             
            </Row>
          </UncontrolledCollapse>
        </div> */}

        <Table responsive>
          <thead>
            <tr>
              <th>Nome</th>
              <th>Valor</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {data?.rows?.map((data_) => (
              <tr key={data_.id}>
                <td>
                  <span>{data_?.nome}</span>
                </td>
                <td>
                  <span>
                    {Number(data_?.valor || 0)?.toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </span>
                </td>
                <td>
                  <UncontrolledButtonDropdown size='xs'>
                    <DropdownToggle color='link link-gray' size='xs'>
                      <FaEllipsisH />
                    </DropdownToggle>
                    <DropdownMenu right>
                      {user.perfil === 'A' && (
                        <DropdownItem
                          className='justify-start'
                          onClick={() => handleEdit(data_)}
                        >
                          <GrEdit />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Editar
                        </DropdownItem>
                      )}
                      <DropdownItem
                        className='justify-start'
                        onClick={() => {
                          if (!data_.vendedor && !data_.participacoes?.length) {
                            setIsOpen(data_.id);
                            setIdProduct(data_.id);
                          } else {
                            window.open(
                              `/${
                                dadosWhiteLabel?.url_whitelabel || 'poupa-club'
                              }/produto/${data_.id}${
                                data_?.vendedor
                                  ? '&vend=' + data_?.vendedor
                                  : ''
                              }`
                            );
                          }
                        }}
                      >
                        <GrCart />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Gerar link de cobrança
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledButtonDropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <Pagination
          nextLabel={<FaChevronRight />}
          previousLabel={<FaChevronLeft />}
          pageCount={Number(data?.pages) || 1}
          onPageChange={({ selected }) => setPage(selected)}
          initialPage={page}
          containerClassName={'pagination justify-end'}
          activeClassName={'active'}
        />
      </div>

      <ModalPedidoInterno
        toggle={toggle}
        isOpen={isOpen}
        idProduct={idProduct}
      />
    </>
  );
}

export default ListarProdutosInternos;
