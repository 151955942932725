import ContentLoader from 'react-content-loader';
import { Col } from 'reactstrap';

const Item = () => (
  <ContentLoader
    viewBox='0 0 500 370'
    width='100%'
    backgroundColor='#f1f1f1'
    foregroundColor='#e6e6e6'
    className="mt-3"
  >
    <rect x='100' y='15' rx='10' ry='10' width='300' height='100' />
    <rect x='6' y='170' rx='0' ry='0' width='270' height='20' />
    <rect x='4' y='225' rx='0' ry='0' width='410' height='18' />
    <rect x='4' y='250' rx='0' ry='0' width='340' height='18' />
    <rect x='4' y='275' rx='0' ry='0' width='250' height='18' />
    <rect x='4' y='340' rx='0' ry='0' width='200' height='20' />
  </ContentLoader>
);

const Placeholder = () => (
  <Col>
    <Item />
    <hr className="mb-6"/>

    <Item />
    <hr className="mb-6"/>

    <Item />
    <hr className="mb-6"/>
  </Col>
);

export default Placeholder;
