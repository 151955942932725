import React, { useState, useEffect } from 'react';
import {
  Col,
  Row,
  Table,
  UncontrolledButtonDropdown,
  UncontrolledCollapse,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Button,
} from 'reactstrap';
import Swal from 'sweetalert2';
import Pagination from 'react-paginate';
import { FaChevronLeft, FaChevronRight, FaEllipsisH } from 'react-icons/fa';
import AsyncSelect from '../../../components/AsyncSelect';
import formatCPF from '../../../utils/formatCPF';
import formatCNPJ from '../../../utils/formatCNPJ';
import api from '../../../services/api';
import { FiFilter } from 'react-icons/fi';
import {
  GrEdit,
  GrHide,
  GrFormView,
  GrCurrency,
  GrDocumentText,
} from 'react-icons/gr';

import { useHistory } from 'react-router-dom';
import ModalCobranca from './modalCobranca';
import { toast } from 'react-toastify';

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'ml-3 btn btn-success',
    cancelButton: 'btn btn-danger',
  },
  buttonsStyling: false,
});

function ConsultarTodosAdm(props) {
  let timeoutConsulta;
  const [filtro, setFiltro] = useState(null);
  const [status, setStatus] = useState('');
  const [modal, setModal] = useState(false);
  const [clienteModal, setClienteModal] = useState(null);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const history = useHistory();

  useEffect(() => {
    async function getData() {
      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });

      const { data } = await api.get(
        `/clientes-pj?page=${page + 1}&status=${status}&cliente=${
          filtro?.value || ''
        }`
      );
      setData(data);
      window.scrollTo(0, 0);

      Swal.close();
    }
    getData();
  }, [page, filtro, status]);

  async function handleActivateInactivate(id, option, status) {
    swalWithBootstrapButtons
      .fire({
        title: `Tem certeza que deseja ${option} este cliente?`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          Swal.fire({
            title: 'Aguarde',
            allowOutsideClick: false,
            showConfirmButton: false,
            onBeforeOpen: () => {
              Swal.showLoading();
            },
          });
          const body = {
            id,
            status,
            tipoUser: 'cliente_pj',
          };
          api
            .put('/ativar-inativar', body)
            .then(() => {
              Swal.close();
              setData({
                ...data,
                'data.rows': data?.rows?.map((e) =>
                  e.id === id ? (e.status = status) : e.status
                ),
              });
            })
            .catch((error) => {
              const msg =
                error?.response?.data?.error ||
                'Ocorreu um erro, tente novamente mais tarde!';

              swalWithBootstrapButtons.fire('Ops...', msg, 'error');
            });
        }
      });
  }

  const debounceConsulta = async (body) => {
    return await new Promise((resolve) => {
      clearTimeout(timeoutConsulta);

      timeoutConsulta = setTimeout(() => {
        resolve(filtrarConsulta(body));
      }, 500);
    });
  };

  async function gerarSegundaVia(id) {
    try {
      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      const { data: pedido } = await api.get(
        `/cliente/segunda-via/${id}?tipo="PJ"`
      );
      const link = pedido.link || pedido.installmentLink;
      if (link) {
        window.open(link);
      } else {
        toast.warn('Nenhuma parcela em aberto');
      }
    } catch (err) {
      const error =
        err?.response?.data?.error || 'Ocorreu um erro, tente novamente!';
      toast.error(error);
    } finally {
      Swal.close();
    }
  }

  const toggle = () => setModal(!modal);

  const abrirModalBoleto = (cliente) => {
    setClienteModal(cliente);
    toggle();
  };

  async function filtrarConsulta({ value, param }) {
    const result = await api.get(
      `/options/clientes?tipo="PJ"&${param}=${value}`
    );
    return result?.data?.map((cliente) => ({
      value: cliente?.id,
      ...cliente,
    }));
  }

  function handleEdit(id) {
    history.push(`/acesso/alterar-dados-adm/clientes_pj/${id}`);
  }

  return (
    <>
      <Row>
        <Col lg={12}>
          <h5 className='txt-tertiary mb-3'>Consultar clientes</h5>
        </Col>
      </Row>

      <div className='card-form  p-0'>
        <div className='p-3 justify-content-between d-flex align-center'>
          <p className='txt-muted'>Total de registros: {data?.total || 0}</p>
          <Button color='muted' id='toggler'>
            <FiFilter /> Filtros
          </Button>
          <div />
        </div>
        <div className='filter-collapse'>
          <UncontrolledCollapse toggler='#toggler'>
            <Row className='mt-3'>
              <Col lg={5} sm={6} className='mb-1'>
                <AsyncSelect
                  loadOptions={(value) =>
                    debounceConsulta({
                      value,
                      param: 'nome',
                    })
                  }
                  getOptionLabel={(option) => option.nome}
                  onChange={(value) => {
                    setFiltro(value);
                    setPage(0);
                  }}
                  cacheOptions
                  isClearable
                  value={filtro}
                  placeholder={'Busque pelo nome fantasia'}
                  noOptionsMessage={() => 'Digite o nome'}
                />
              </Col>
              <Col lg={4} sm={6} className='mb-1'>
                <AsyncSelect
                  loadOptions={(value) =>
                    debounceConsulta({
                      value,
                      param: 'cpf',
                    })
                  }
                  cacheOptions
                  onChange={(value) => {
                    setFiltro(value);
                    setPage(0);
                  }}
                  value={filtro}
                  isClearable
                  getOptionLabel={(option) => option.cnpj}
                  placeholder={'CNPJ'}
                  noOptionsMessage={() => 'Digite o CPF'}
                />
              </Col>
              <Col lg={3} sm={6} className='mb-1'>
                <select
                  className='input-field-inner opts-select'
                  onChange={(e) => {
                    setStatus(e.target.value);
                    setPage(0);
                  }}
                  value={status}
                >
                  <option value='' disabled selected>
                    Status
                  </option>
                  <option key={'T'} value={''}>
                    Todos
                  </option>
                  <option key={'A'} value={'A'}>
                    A
                  </option>
                  <option key={'I'} value={'I'}>
                    I
                  </option>
                </select>
              </Col>
            </Row>
          </UncontrolledCollapse>
        </div>

        <Table responsive className='table-accordion'>
          <thead>
            <tr>
              <th>Razao social - Nome Fantasia</th>
              <th>CNPJ</th>
              <th className='text-center'>Status</th>
              <th className='text-center'>Ações</th>
            </tr>
          </thead>
          <tbody>
            {data?.rows?.map((data) => (
              <React.Fragment key={data.id}>
                <tr>
                  <td>
                    {data?.funcionarios?.length ? (
                      <Button
                        color='link'
                        size='small'
                        id={`cliente_${data?.id}`}
                        className='text-collpase'
                      >
                        {data?.razao_social} - {data?.nome_fantasia}{' '}
                        <span className='small ml-1'>
                          <small>▼</small>
                        </span>
                      </Button>
                    ) : (
                      <span className='text-collpase'>
                        {data?.razao_social} - {data?.nome_fantasia}
                      </span>
                    )}
                  </td>
                  <td className='w-20'>
                    {data?.cnpj &&
                      formatCNPJ(`00000000000000${data?.cnpj}`.slice(-14))}
                  </td>
                  <td className='text-center w-20'>
                    <div
                      className={
                        data?.status === 'A' ? 'txt-primary' : 'txt-danger'
                      }
                    >
                      {data?.status}
                    </div>
                  </td>
                  <td className='text-center w-20'>
                    <UncontrolledButtonDropdown size='xs'>
                      <DropdownToggle color='link link-gray' size='xs'>
                        <FaEllipsisH />
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem
                          className='justify-start'
                          onClick={() => handleEdit(data?.id)}
                        >
                          <GrEdit />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Editar
                        </DropdownItem>
                        <DropdownItem
                          onClick={() => abrirModalBoleto(data?.id)}
                          size='xs'
                          className='justify-start'
                        >
                          <GrCurrency />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Gerar boleto
                        </DropdownItem>
                        <DropdownItem
                          size='xs'
                          onClick={() => gerarSegundaVia(data?.id)}
                          className='justify-start'
                        >
                          <GrDocumentText />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2°via último pedido
                        </DropdownItem>
                        {data?.status === 'A' ? (
                          <DropdownItem
                            className='justify-start'
                            onClick={() =>
                              handleActivateInactivate(
                                data?.id,
                                'inativar',
                                'I'
                              )
                            }
                          >
                            <GrHide />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Inativar
                          </DropdownItem>
                        ) : (
                          <DropdownItem
                            className='justify-start'
                            onClick={() =>
                              handleActivateInactivate(data?.id, 'ativar', 'A')
                            }
                          >
                            <GrFormView />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ativar
                          </DropdownItem>
                        )}
                      </DropdownMenu>
                    </UncontrolledButtonDropdown>
                  </td>
                </tr>
                {data?.funcionarios?.length > 0 && (
                  <tr>
                    <td colSpan={4} className='td-collapse'>
                      <UncontrolledCollapse
                        toggler={`#cliente_${data?.id}`}
                        className='px-10'
                      >
                        <Table responsive>
                          <tbody>
                            {data?.funcionarios?.map((funcionario) => (
                              <tr key={funcionario?.ClientePf?.id}>
                                <td className='w-30'>{funcionario?.ClientePf?.nome}</td>
                                <td className='w-20'>
                                  Cpf:&nbsp;
                                  {funcionario?.ClientePf?.cpf &&
                                    formatCPF(
                                      `00000000000${funcionario?.ClientePf?.cpf}`.slice(
                                        -11
                                      )
                                    )}
                                </td>
                                <td className='w-30'>
                                  <div>
                                    Cep: {String(funcionario?.ClientePf?.cep).padStart(8, '0')}
                                  </div>
                                  <div>
                                    {`${funcionario?.ClientePf?.logradouro} ${
                                      funcionario?.ClientePf?.numero
                                    }${
                                      funcionario?.ClientePf?.complemento
                                        ? ` ${funcionario?.ClientePf?.complemento}`
                                        : ''
                                    } - ${funcionario?.ClientePf?.cidade} - ${
                                      funcionario?.ClientePf?.estado
                                    }`}
                                  </div>
                                </td>
                                <td className='w-20'>
                                  <div>Email: {funcionario?.ClientePf?.email}</div>
                                  <div>
                                    Cel: {funcionario?.ClientePf?.telefone_celular}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </UncontrolledCollapse>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </Table>

        <Pagination
          nextLabel={<FaChevronRight />}
          previousLabel={<FaChevronLeft />}
          pageCount={Number(data?.pages) || 1}
          onPageChange={({ selected }) => setPage(selected)}
          initialPage={page}
          containerClassName={'pagination justify-end'}
          activeClassName={'active'}
        />
      </div>

      <ModalCobranca
        toggle={toggle}
        cliente={clienteModal}
        isOpen={modal}
        tipo='PJ'
      />
    </>
  );
}

export default ConsultarTodosAdm;
