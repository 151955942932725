import { Col, Row, Button } from "reactstrap";
import { Form } from "@unform/web";
import Input from "../../../components/unformInput";
import { toast } from "react-toastify";
import validator from "../../../validations/alterarSenha";
import Swal from "sweetalert2";
import { ValidationError } from "yup";
import api from "../../../services/api";
import { useRef } from "react";

function TrocarSenha() {
  const formRef = useRef(null);

  async function handleSubmit(body) {
    formRef.current.setErrors({});
    try {
      await validator(body);
      Swal.fire({
        title: "Aguarde",
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      await api.put("/alterar-senha", body);

      Swal.close();
      toast.success("Senha alterada com sucesso.");
      // formRef.current.reset();
    } catch (err) {
      const validationErrors = {};
      Swal.close();
      if (err instanceof ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
        toast.error("Verifique os campos em vermelho!");
      } else {
        const error =
          err?.response?.data?.error || "Ocorreu um erro, tente novamente!";
        toast.error(error);
      }
    }
  }

  return (
    <>
      <Row>
        <Col lg={12}>
          <h5 className="txt-tertiary">Trocar senha</h5>
          <Form
            className="card-form"
            onSubmit={(data) => handleSubmit(data)}
            ref={formRef}
          >
            <div className="mb-1">
              <span className="placholder-top">Senha atual</span>
              <Input
                className="input-field-inner col-lg-4"
                placeholder="Digite sua senha atual"
                name="senha"
                type="password"
              />
            </div>
            <div className="mb-1">
              <span className="placholder-top">Nova senha</span>
              <Input
                className="input-field-inner col-lg-4"
                placeholder="Digite a sua nova senha"
                name="novaSenha"
                type="password"
              />
            </div>
            <div className="mb-1">
              <span className="placholder-top">Confirme a nova senha</span>
              <Input
                className="input-field-inner col-lg-4"
                placeholder="Redigite a sua nova senha"
                name="confirmeSenha"
                type="password"
              />
            </div>
            <Row className="mt-20">
              {/* <Col className="justify-start">
              <Button color="warning" onClick={toggle} className="mb-1 bold">
                Alterar senha
              </Button>
            </Col> */}
              <Col className="justify-end">
                <Button color="green" className="mb-1 btn-save">
                  Alterar senha
                </Button>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </>
  );
}

export default TrocarSenha;
