import { useState, useRef, useEffect } from "react";
import {
  Col,
  Row,
  Button,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Form } from "@unform/web";
import { GoChevronDown } from "react-icons/go";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { ValidationError } from "yup";

import Input from "../../../components/unformInput";
import UnformRadio from "../../../components/unformRadio";
import UnformSelect from "../../../components/unformSelect";
import AsyncSelect from "../../../components/AsyncSelect";
// import Textarea from "../../../components/unformTextarea";
// import SunEditor from '../../../components/unformSunEditor';
import ImagePreview from "../../../components/unformImagePreview";
import "suneditor/dist/css/suneditor.min.css";
import { useField } from "@unform/core";
import SunEditorComponent from "suneditor-react";

import api from "../../../services/api";
import arrayFotosWhiteLabel from "../../../utils/arrayFotosWhiteLabel";

import whiteLabelValidator from "../../../validations/whitelabelValidator";
import { Scope } from "@unform/core";

import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: "ml-3 btn btn-success",
    cancelButton: "btn btn-danger",
  },
  buttonsStyling: false,
});

function WhiteLabel() {
  const [modal, setModal] = useState(false);
  const [status, setStatus] = useState(null);
  const formRef = useRef(null);
  const formRefModal = useRef(null);
  let timeoutConsulta;

  async function handleSubmit(formData) {
    formRef.current.setErrors([]);

    if (filtro) {
      try {
        await whiteLabelValidator(formData);
        Swal.fire({
          title: "Aguarde",
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });
        const data = {};
        const dataFotos = new FormData();

        data.parceiro = filtro.value;

        data.whitelabel_button_1 = btn1Color || null;
        data.whitelabel_button_2 = btn2Color || null;
        data.whitelabel_button_3 = btn3Color || null;

        data.cor_cartao_1 = corBtnCard1 || null;
        data.cor_cartao_2 = corBtnCard2 || null;
        data.cor_cartao_3 = corBtnCard3 || null;

        data.qtd_cartoes = qtdCard || null;

        console.log(formData);

        Object.keys(formData).forEach((f) => {
          const val = formData[f];
          if (val) {
            if (arrayFotosWhiteLabel.includes(f)) {
              dataFotos.append("files", val, f);
            } else {
              data[f] = val;
              if (f.includes("cartao")) {
                if (val.imagem) {
                  dataFotos.append(
                    "files",
                    val.imagem,
                    `img_cartao_${f.substr(-1)}`
                  );
                }
                delete data[f].imagem;
              }
            }
          }
        });

        const fotos = await imagesSave(dataFotos);
        fotos.forEach((foto) => {
          data[foto.field] = foto.id;
        });

        if (data.img_cartao_1) {
          data.cartao1.imagem_id = data.img_cartao_1;
          delete data.img_cartao_1;
        }
        if (data.img_cartao_2) {
          data.cartao2.imagem_id = data.img_cartao_2;
          delete data.img_cartao_2;
        }
        if (data.img_cartao_3) {
          data.cartao3.imagem_id = data.img_cartao_3;
          delete data.img_cartao_3;
        }

        if (!edicao) {
          await api.post("/save-whitelabel", cleanObj(data));
          toast.success("WhiteLabel cadastrado com sucesso");
        } else {
          await api.put(`/edit-whitelabel/${id}`, cleanObj(data));
          toast.success("WhiteLabel alterado com sucesso");
        }
        Swal.close();
      } catch (err) {
        const validationErrors = {};
        Swal.close();
        if (err instanceof ValidationError) {
          err.inner.forEach((error) => {
            validationErrors[error.path] = error.message;
          });
          formRef.current.setErrors(validationErrors);
          toast.error("Verifique os campos em vermelho!");
        } else {
          toast.error("Ocorreu um erro tente novamente mais tarde!");
        }
      }
    } else {
      toast.error("Escolha um parceiro!");
    }
  }

  function cleanObj(obj) {
    for (var propName in obj) {
      if (
        obj[propName] === null ||
        obj[propName] === undefined ||
        obj[propName] === ""
      ) {
        delete obj[propName];
      }
    }
    return obj;
  }

  async function imagesSave(formData) {
    try {
      const { data: fotos_salvas } = await api.post("/imagens-multi", formData);
      return fotos_salvas;
    } catch (e) {
      toast.error("Erro ao salvar imagem!");
    }
  }

  function handleSubmitModal(data) {
    toggle(false);
    if (btnEscolhido === "b1") {
      setBtn1Color(data["cor-botao"]);
    } else if (btnEscolhido === "b2") {
      setBtn2Color(data["cor-botao"]);
    } else if (btnEscolhido === "b3") {
      setBtn3Color(data["cor-botao"]);
    } else if (btnEscolhido === "c1") {
      setCorBtnCard1(data["cor-botao"]);
    } else if (btnEscolhido === "c2") {
      setCorBtnCard2(data["cor-botao"]);
    } else if (btnEscolhido === "c3") {
      setCorBtnCard3(data["cor-botao"]);
    }
  }

  const [key, setKey] = useState(null);

  const [filtro, setFiltro] = useState(null);
  const [id, setId] = useState(null);

  const [whitelabelHeaderText, setWhitelabelHeaderText] = useState(null);
  const [whitelabelFooterText, setWhitelabelFooterText] = useState(null);

  const [menuColor, setMenuColor] = useState(null);
  const [footerColor, setFooterColor] = useState(null);

  const [btnEscolhido, setBtnEscolhido] = useState("");
  const [btn1Color, setBtn1Color] = useState(null); //whitelabel-button-1
  const [btn2Color, setBtn2Color] = useState(null); //whitelabel-button-2
  const [btn3Color, setBtn3Color] = useState(null); //whitelabel-button-3

  const [text1Color, setText1Color] = useState(null);
  const [text2Color, setText2Color] = useState(null);
  const [text3Color, setText3Color] = useState(null);
  const [text4Color, setText4Color] = useState(null);

  const [arrowsColor, setArrowsColor] = useState(null);

  const [corBtnCard1, setCorBtnCard1] = useState(null);
  const [corBtnCard2, setCorBtnCard2] = useState(null);
  const [corBtnCard3, setCorBtnCard3] = useState(null);

  const [qtdCard, setQtdCard] = useState(3);
  const [edicao, setEdicao] = useState(false);

  useEffect(() => {
    async function getData() {
      setEdicao(false);
      functionThatResetsFileds();
      formRef.current.reset();
      if (filtro?.value) {
        try {
          Swal.fire({
            title: "Aguarde",
            allowOutsideClick: false,
            showConfirmButton: false,
            onBeforeOpen: () => {
              Swal.showLoading();
            },
          });
          const { data } = await api.get(
            `/get-data-whitelabel/${filtro?.value}`
          );
          if (data) {
            setId(data?.id);
            setEdicao(true);
            setQtdCard(data?.qtd_cartoes || 3);
            setStatus(data.status);

            const initialData = {};
            Object.keys(data).forEach((f) => {
              const val = data[f];
              if (val) {
                if (typeof val !== "object") {
                  initialData[f] = val;
                } else {
                  if (f.includes("cartao")) {
                    initialData[f] = {
                      ...val,
                      imagem: val?.imagem?.url,
                    };
                  } else {
                    initialData[f.substr(0, f.length - 4)] = val?.url;
                  }
                }
              }
            });
            formRef.current.setData(initialData);

            setWhitelabelHeaderText(data?.whitelabel_header_text);
            setArrowsColor(data?.whitelabel_arrows);
            setWhitelabelFooterText(data?.whitelabel_footer_text_color);
            setText1Color(data?.whitelabel_text_color_1 || null);
            setText2Color(data?.vwhitelabel_text_color_2 || null);
            setText3Color(data?.whitelabel_text_color_3 || null);
            setText4Color(data?.whitelabel_text_color_4 || null);
            setBtn1Color(data?.whitelabel_button_1 || null);
            setBtn2Color(data?.whitelabel_button_2 || null);
            setBtn3Color(data?.whitelabel_button_3 || null);
            setCorBtnCard1(data?.cor_cartao_1 || null);
            setCorBtnCard2(data?.cor_cartao_2 || null);
            setCorBtnCard3(data?.cor_cartao_3 || null);
          }
        } catch (err) {
          toast.error("Ocorreu um erro ao buscar dados!");
        }
      }
      Swal.close();
    }
    getData();
  }, [filtro]);

  function functionThatResetsFileds() {
    setStatus(null);
    setWhitelabelHeaderText(null);
    setArrowsColor(null);
    setMenuColor(null);
    setFooterColor(null);
    setWhitelabelFooterText(null);
    setBtn1Color(null);
    setBtn2Color(null);
    setBtn3Color(null);
    setText1Color(null);
    setText2Color(null);
    setText3Color(null);
    setText4Color(null);
    setCorBtnCard1(null);
    setCorBtnCard2(null);
    setCorBtnCard3(null);
    setQtdCard(3);
    let randomString = Math.random().toString(36);
    setKey({
      theInputKey: randomString,
    });
  }

  function useDisplayImage() {
    const [result, setResult] = useState("");

    function uploader(e) {
      if (e.target.files[0]) {
        setResult(`url(${URL.createObjectURL(e.target.files[0])})`);
      } else {
        setResult(null);
      }
    }
    return { result, uploader };
  }

  const { result, uploader } = useDisplayImage();

  const toggle = (tipo = false) => {
    formRefModal?.current?.reset();
    setModal(!modal);
  };

  const debounceConsulta = async (body) => {
    return await new Promise((resolve) => {
      clearTimeout(timeoutConsulta);

      timeoutConsulta = setTimeout(() => {
        resolve(filtrarConsulta(body));
      }, 500);
    });
  };

  async function filtrarConsulta({ value, param }) {
    const result = await api.get(`options/parceiros?${param}=${value}`);
    return result.data.map((parceiro) => ({
      value: parceiro.id,
      cnpj: parceiro.cnpj,
      nome: parceiro.nome,
    }));
  }

  async function deleteImage(item, cb) {
    swalWithBootstrapButtons
      .fire({
        title: `Tem certeza que deseja excluir esta imagem?`,
        text: "Esta operação não poderá ser revertida!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          Swal.fire({
            title: "Aguarde",
            allowOutsideClick: false,
            showConfirmButton: false,
            onBeforeOpen: () => {
              Swal.showLoading();
            },
          });
          api
            .delete(`imagens`, {
              data: {
                parceiro: filtro?.value,
                campo: item,
              },
            })
            .then(() => {
              Swal.close();
              toast.success("Excluido com sucesso");
              cb();
            })
            .catch((error) => {
              const msg =
                error?.response?.data?.error ||
                "Ocorreu um erro, tente novamente mais tarde!";
              swalWithBootstrapButtons.fire("Ops...", msg, "error");
            });
        }
      });
  }

  async function deleteImageCartao(item, cb) {
    swalWithBootstrapButtons
      .fire({
        title: `Tem certeza que deseja excluir esta imagem?`,
        text: "Esta operação não poderá ser revertida!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          Swal.fire({
            title: "Aguarde",
            allowOutsideClick: false,
            showConfirmButton: false,
            onBeforeOpen: () => {
              Swal.showLoading();
            },
          });
          api
            .delete(`cartao/imagem`, {
              data: {
                parceiro: filtro?.value,
                descricao: item,
              },
            })
            .then(() => {
              Swal.close();
              toast.success("Excluido com sucesso");
              cb();
            })
            .catch((error) => {
              const msg =
                error?.response?.data?.error ||
                "Ocorreu um erro, tente novamente mais tarde!";
              swalWithBootstrapButtons.fire("Ops...", msg, "error");
            });
        }
      });
  }

  async function deleteWhiteLabel() {
    swalWithBootstrapButtons
      .fire({
        title: `Tem certeza que deseja excluir este parceiro WhiteLabel?`,
        text: "Esta operação não poderá ser revertida!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          Swal.fire({
            title: "Aguarde",
            allowOutsideClick: false,
            showConfirmButton: false,
            onBeforeOpen: () => {
              Swal.showLoading();
            },
          });
          api
            .delete(`/delete-whitelabel/${filtro?.value}`)
            .then(() => {
              Swal.close();
              toast.success("Excluido com sucesso");
              setEdicao(false);
              functionThatResetsFileds();
              formRef.current.reset();
            })
            .catch((error) => {
              const msg =
                error?.response?.data?.error ||
                "Ocorreu um erro, tente novamente mais tarde!";
              swalWithBootstrapButtons.fire("Ops...", msg, "error");
            });
        }
      });
  }

  async function toggleStatus() {
    try {
      await api.put(`/status-whitelabel/${filtro?.value}`);
      Swal.close();
      setStatus(status === "A" ? "I" : "A");
      toast.success("Status alterado com sucesso");
    } catch (err) {
      const msg =
        err?.response?.data?.error ||
        "Ocorreu um erro, tente novamente mais tarde!";
      swalWithBootstrapButtons.fire("Ops...", msg, "error");
    }
  }

  return (
    <>
      <Row>
        <Col lg={12}>
          <h5 className="txt-tertiary">Parceiros White Label</h5>
          <div className="card-form">
            <Row>
              <Col lg={12}>
                <Row>
                  <Col lg={4} sm={6} className="mb-1">
                    <AsyncSelect
                      loadOptions={(value) =>
                        debounceConsulta({
                          value,
                          param: "nome",
                        })
                      }
                      getOptionLabel={(option) => option.nome}
                      onChange={setFiltro}
                      value={filtro}
                      cacheOptions
                      isClearable
                      placeholder={"Busque pelo nome"}
                      noOptionsMessage={() => "Digite o nome"}
                    />
                  </Col>
                  <Col lg={4} sm={6} className="mb-1">
                    <AsyncSelect
                      loadOptions={(value) =>
                        debounceConsulta({
                          value,
                          param: "cnpj",
                        })
                      }
                      cacheOptions
                      onChange={setFiltro}
                      value={filtro}
                      isClearable
                      getOptionLabel={(option) => option.cnpj}
                      placeholder={"CNPJ"}
                      noOptionsMessage={() => "Digite o CNPJ"}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr />
            <Form
              onSubmit={(data) => handleSubmit(data)}
              ref={formRef}
              key={(key && key.theInputKey) || ""}
              className="p-0"
            >
              <div className="mb-3">
                <Tabs>
                  <TabList>
                    <Tab>Geral</Tab>
                    <Tab>Home</Tab>
                    <Tab>Cartões</Tab>
                    <Tab>Benefícios</Tab>
                    <Tab>Desconto Farmácia</Tab>
                    <Tab>Seja Parceiro</Tab>
                    <Tab>Seja Revendedor</Tab>
                    <Tab>Agendamento</Tab>
                    <Tab>Dashboard</Tab>
                    <Tab>Dados</Tab>
                  </TabList>

                  <TabPanel forceRender>
                    <hr />
                    <Row>
                      <Col lg={2} md={3} sm={12}>
                        <Label className="mt-2">
                          https://poupaclub.com.br/
                        </Label>
                      </Col>
                      <Col lg={10} md={9} sm={12}>
                        <Input
                          className="input-field-inner  mt-1 mb-2"
                          placeholder="URL do Parceiro Whitelabel"
                          name="url_whitelabel"
                          type="text"
                        />
                      </Col>
                    </Row>
                    <hr />
                    <Row className="mb-3">
                      <Col lg={3} md={6} sm={12}>
                        <Label className="label-radio">
                          Logo cabeçalho
                          <strong> (L=220px x A=91px)</strong>
                        </Label>
                        <br />
                        <span className="placholder-top">
                          Imagem - Tamanho máximo 1,5MB
                        </span>
                        <ImagePreview
                          name="whitelabel_logo"
                          handleDeleteImage={(cb) =>
                            deleteImage("whitelabel_logo", cb)
                          }
                        />
                      </Col>
                      <Col lg={9} md={6} sm={12}>
                        <Row>
                          <Col lg={4}>
                            <Label className="label-radio">
                              Cor dos textos do cabeçalho/menu
                            </Label>
                            <Row>
                              <Col lg={4}>
                                <Input
                                  className="input-field-inner  mb-2"
                                  placeholder=""
                                  value={whitelabelHeaderText}
                                  name="whitelabel_header_text"
                                  type="color"
                                  onChange={(e) => {
                                    setWhitelabelHeaderText(e.target.value);
                                  }}
                                />
                              </Col>
                              <Col lg={2} md={8} sm={12}>
                                <p
                                  className="mt-2"
                                  style={{
                                    textAlign: "center",
                                    fontWeight: "bold",
                                    color: whitelabelHeaderText,
                                  }}
                                >
                                  Exemplo
                                </p>
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={4}>
                            <Label className="label-radio">Cor das setas</Label>
                            <Row>
                              <Col lg={4}>
                                <Input
                                  className="input-field-inner  mb-2"
                                  placeholder="Senha atual"
                                  value={arrowsColor}
                                  name="whitelabel_arrows"
                                  type="color"
                                  onChange={(e) => {
                                    setArrowsColor(e.target.value);
                                  }}
                                />
                              </Col>
                              <Col lg={8} md={8} sm={12}>
                                <GoChevronDown
                                  size={40}
                                  color={arrowsColor}
                                  className="ml-4"
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={4}>
                            <Label className="label-radio">Cor do menu</Label>
                            <Row>
                              <Col lg={4}>
                                <Input
                                  className="input-field-inner  mb-2"
                                  placeholder="cor"
                                  name="cor_menu"
                                  value={menuColor}
                                  type="color"
                                  onChange={(e) => {
                                    setMenuColor(e.target.value);
                                  }}
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={4}>
                            <Label className="label-radio">
                              Selecione a cor de fundo do rodapé
                            </Label>
                            <Row>
                              <Col lg={4} md={6} sm={12}>
                                <Input
                                  className="input-field-inner  mb-2"
                                  placeholder=""
                                  name="whitelabel_footer_background"
                                  value={footerColor}
                                  type="color"
                                  onChange={(e) => {
                                    setFooterColor(e.target.value);
                                  }}
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={5}>
                            <Label className="label-radio">
                              Selecione a cor dos textos do rodapé
                            </Label>
                            <Row>
                              <Col lg={4}>
                                <Input
                                  className="input-field-inner  mb-2"
                                  placeholder="Senha atual"
                                  name="whitelabel_footer_text_color"
                                  value={whitelabelFooterText}
                                  type="color"
                                  onChange={(e) => {
                                    setWhitelabelFooterText(e.target.value);
                                  }}
                                />
                              </Col>
                              <Col lg={2} md={8} sm={12}>
                                <p
                                  className="mt-2"
                                  style={{
                                    textAlign: "center",
                                    fontWeight: "bold",
                                    color: whitelabelFooterText,
                                  }}
                                >
                                  Exemplo
                                </p>
                              </Col>
                            </Row>
                          </Col>
                          <Col lg={4}>
                            <Label className="label-radio">
                              Cor do botão 1
                            </Label>
                            <Button
                              color="dark"
                              className={`${btn1Color} btn-pill btn-block`}
                              onClick={() => {
                                toggle(true);
                                setBtnEscolhido("b1");
                              }}
                            >
                              Botão 1
                            </Button>
                          </Col>
                          <Col lg={4}>
                            <Label className="label-radio">
                              Cor do botão 2
                            </Label>
                            <Button
                              color="dark"
                              className={`${btn2Color} btn-pill btn-block`}
                              onClick={() => {
                                toggle(true);
                                setBtnEscolhido("b2");
                              }}
                            >
                              Botão 2
                            </Button>
                          </Col>
                          <Col lg={4}>
                            <Label className="label-radio">
                              Cor do botão 3
                            </Label>
                            <Button
                              color="dark"
                              className={`${btn3Color} btn-pill btn-block`}
                              onClick={() => {
                                toggle(true);
                                setBtnEscolhido("b3");
                              }}
                            >
                              Botão 3
                            </Button>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <hr />
                    <Row className="mb-3">
                      <Col lg={12} md={12} sm={12}>
                        <Label className="label-radio">
                          Links e logo rodapé
                        </Label>
                        <Row>
                          <Col lg={3} sm={12}>
                            <ImagePreview
                              name="imagem_footer"
                              handleDeleteImage={(cb) =>
                                deleteImage("imagem_footer", cb)
                              }
                            />
                          </Col>
                          <Col lg={9} md={6} sm={12}>
                            <Row>
                              <Col lg={4} md={6} sm={12}>
                                <Input
                                  className="input-field-inner  mt-3 mb-2"
                                  placeholder="Facebook"
                                  name="facebook_whitelabel"
                                  type="text"
                                />
                              </Col>
                              <Col lg={4} md={6} sm={12}>
                                <Input
                                  className="input-field-inner  mt-3 mb-2"
                                  placeholder="Instagram"
                                  name="instagram_whitelabel"
                                  type="text"
                                />
                              </Col>
                              <Col lg={4} md={6} sm={12}>
                                <Input
                                  className="input-field-inner  mt-3 mb-2"
                                  placeholder="Linkedin"
                                  name="linkedin_whitelabel"
                                  type="text"
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <hr />
                    <Label className="label-radio">
                      Cores dos textos do sistema
                    </Label>
                    <Row className="mb-3">
                      <Col lg={3} md={3} sm={12}>
                        <Row>
                          <Col lg={6} md={6} sm={12}>
                            <Input
                              className="input-field-inner mb-2"
                              placeholder="Senha atual"
                              name="whitelabel_text_color_1"
                              value={text1Color}
                              type="color"
                              onChange={(e) => {
                                setText1Color(e.target.value);
                              }}
                            />
                          </Col>
                          <Col lg={6} md={6} sm={12}>
                            <p
                              className="mt-2"
                              style={{
                                textAlign: "center",
                                fontWeight: "bold",
                                color: text1Color,
                              }}
                            >
                              Texto 1
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={3} md={3} sm={12}>
                        <Row>
                          <Col lg={6} md={6} sm={12}>
                            <Input
                              className="input-field-inner mb-2"
                              placeholder="Senha atual"
                              name="whitelabel_text_color_2"
                              value={text2Color}
                              type="color"
                              onChange={(e) => {
                                setText2Color(e.target.value);
                              }}
                            />
                          </Col>
                          <Col lg={6} md={6} sm={12}>
                            <p
                              className="mt-2"
                              style={{
                                textAlign: "center",
                                fontWeight: "bold",
                                color: text2Color,
                              }}
                            >
                              Texto 2
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={3} md={3} sm={12}>
                        <Row>
                          <Col lg={6} md={6} sm={12}>
                            <Input
                              className="input-field-inner mb-2"
                              placeholder="Senha atual"
                              name="whitelabel_text_color_3"
                              value={text3Color}
                              type="color"
                              onChange={(e) => {
                                setText3Color(e.target.value);
                              }}
                            />
                          </Col>
                          <Col lg={6} md={6} sm={12}>
                            <p
                              className="mt-2"
                              style={{
                                textAlign: "center",
                                fontWeight: "bold",
                                color: text3Color,
                              }}
                            >
                              Texto 3
                            </p>
                          </Col>
                        </Row>
                      </Col>
                      <Col lg={3} md={3} sm={12}>
                        <Row>
                          <Col lg={6} md={6} sm={12}>
                            <Input
                              className="input-field-inner mb-2"
                              placeholder="Senha atual"
                              name="whitelabel_text_color_4"
                              value={text4Color}
                              type="color"
                              onChange={(e) => {
                                setText4Color(e.target.value);
                              }}
                            />
                          </Col>
                          <Col lg={6} md={6} sm={12}>
                            <p
                              className="mt-2"
                              style={{
                                textAlign: "center",
                                fontWeight: "bold",
                                color: text4Color,
                              }}
                            >
                              Texto 4
                            </p>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </TabPanel>
                  <TabPanel forceRender>
                    <hr />
                    <Row className="mb-3">
                      <Col lg={12} md={12} sm={12}>
                        <Row>
                          <Col lg={6}>
                            <Label className="label-radio">
                              Texto simulador de desconto
                            </Label>
                            <Input
                              className="input-field-inner mt-1 mb-2"
                              placeholder="Texto simulador de desconto"
                              name="texto_desconto"
                              type="text"
                            />
                          </Col>
                          <Col lg={6}>
                            <Label className="label-radio">
                              Selecione a imagem do simulador de descontos{" "}
                              <strong>(L = 400px x A = 430px)</strong>
                            </Label>
                            <ImagePreview
                              name="img_simulador_desconto"
                              handleDeleteImage={(cb) =>
                                deleteImage("img_simulador_desconto", cb)
                              }
                            />
                          </Col>
                        </Row>
                        <Label className="label-radio">
                          Selecione os banners principais da Home{" "}
                          <strong>(L = 1920px x A = 500px)</strong>
                        </Label>
                        <br />
                        <Row>
                          <Col lg={2}>
                            <ImagePreview
                              name="home_banner_1"
                              handleDeleteImage={(cb) =>
                                deleteImage("home_banner_1", cb)
                              }
                            />
                          </Col>
                          <Col lg={2}>
                            <ImagePreview
                              name="home_banner_2"
                              handleDeleteImage={(cb) =>
                                deleteImage("home_banner_2", cb)
                              }
                            />
                          </Col>
                          <Col lg={2}>
                            <ImagePreview
                              name="home_banner_3"
                              handleDeleteImage={(cb) =>
                                deleteImage("home_banner_3", cb)
                              }
                            />
                          </Col>
                          <Col lg={2}>
                            <ImagePreview
                              name="home_banner_4"
                              handleDeleteImage={(cb) =>
                                deleteImage("home_banner_4", cb)
                              }
                            />
                          </Col>
                          <Col lg={2}>
                            <ImagePreview
                              name="home_banner_5"
                              handleDeleteImage={(cb) =>
                                deleteImage("home_banner_5", cb)
                              }
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <hr />
                    <Row className="mb-3">
                      <Col lg={12} md={12} sm={12}>
                        <Label className="label-radio">
                          Selecione a imagem 1{" "}
                          <strong>(L = 650px x A = 100px)</strong>
                        </Label>
                        <br />
                        <Row>
                          <Col lg={4} sm={12}>
                            <ImagePreview
                              name="home_img_1"
                              handleDeleteImage={(cb) =>
                                deleteImage("home_img_1", cb)
                              }
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={12} md={12} sm={12}>
                            <Label className="label-radio mt-2">Texto 1</Label>
                            <SunEditor name="home_texto_1" />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <hr />
                    <Row className="mb-3">
                      <Col lg={12} md={12} sm={12}>
                        <Label className="label-radio">
                          Selecione as imagens do Convênio Farmácia{" "}
                          <strong>(L = 2048px x A = 1295px)</strong>
                        </Label>
                        <br />
                        <Row>
                          <Col lg={6} sm={12}>
                            <ImagePreview
                              name="home_img_convenio_farma"
                              handleDeleteImage={(cb) =>
                                deleteImage("home_img_convenio_farma", cb)
                              }
                            />
                          </Col>
                          <Col lg={6} sm={12}>
                            <ImagePreview
                              name="home_img_beneficio_farma"
                              handleDeleteImage={(cb) =>
                                deleteImage("home_img_beneficio_farma", cb)
                              }
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <hr />
                  </TabPanel>
                  <TabPanel forceRender>
                    <hr />
                    <Row className="mb-3">
                      <Col lg={12} md={12} sm={12}>
                        <Row>
                          <Col lg={5} md={5} sm={12}>
                            <Label className="label-radio">
                              Selecione a quantidade de cartões
                            </Label>
                          </Col>
                          <Col lg={1} md={1} sm={12}>
                            <input
                              className="mb-3"
                              name="card"
                              value="1"
                              checked={qtdCard === 1}
                              type="radio"
                              onChange={(e) => setQtdCard(1)}
                              style={{ cursor: "pointer" }}
                            />
                            <Label style={{ marginLeft: "3px" }}>1</Label>
                          </Col>
                          <Col lg={1} md={1} sm={12}>
                            <input
                              className="mb-3"
                              name="card"
                              value="2"
                              checked={qtdCard === 2}
                              type="radio"
                              onChange={(e) => setQtdCard(2)}
                              style={{ cursor: "pointer" }}
                            />
                            <Label style={{ marginLeft: "3px" }}>2</Label>
                          </Col>
                          <Col lg={1} md={1} sm={12}>
                            <input
                              className="mb-3"
                              name="card"
                              value="3"
                              type="radio"
                              checked={qtdCard === 3}
                              onChange={(e) => setQtdCard(3)}
                              style={{ cursor: "pointer" }}
                            />
                            <Label style={{ marginLeft: "3px" }}>3</Label>
                          </Col>
                          <Col lg={4} md={5} sm={12}>
                            <Label className="label-radio mb-2">
                              Cartão empresarial
                              <strong>(L=1005px x A=650px)</strong>
                            </Label>
                            <ImagePreview
                              name="empresarial"
                              handleDeleteImage={(cb) =>
                                deleteImage("empresarial", cb)
                              }
                            />
                          </Col>
                        </Row>
                        <br />
                        <Label className="label-radio mb-2">
                          Imagens do cartão{" "}
                          <strong>(L=1005px x A=650px)</strong>
                        </Label>
                        <Row>
                          <Scope path="cartao1">
                            <Col lg={4} md={4} sm={12}>
                              <ImagePreview
                                name="imagem"
                                handleDeleteImage={(cb) =>
                                  deleteImageCartao("especial", cb)
                                }
                              />
                              <Row>
                                <Col>
                                  <Input
                                    className="input-field-inner mt-3 mb-2"
                                    placeholder="Nome, Ex: Cartão PoupaClub"
                                    name="nome"
                                    type="text"
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <Input
                                    className="input-field-inner mt-3 mb-2"
                                    placeholder="Tipo, Ex: Especial"
                                    name="tipo"
                                    type="text"
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <Button
                                    color="dark"
                                    className={`${corBtnCard1} btn-pill btn-block`}
                                    onClick={() => {
                                      toggle(true);
                                      setBtnEscolhido("c1");
                                    }}
                                  >
                                    Cor e botão
                                  </Button>
                                </Col>
                              </Row>
                              <Row className="mt-2">
                                <Col>
                                  <Label>Regras:</Label>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <UnformSelect
                                    type="select"
                                    className="input-field-select-inner mb-2"
                                    placeholder="Número de conjuges"
                                    name="conjuge"
                                    options={[
                                      {
                                        value: "",
                                        label: "Possui cônjuge",
                                      },
                                      {
                                        value: "false",
                                        label: "Não",
                                      },
                                      {
                                        value: "rtue",
                                        label: "Sim",
                                      },
                                    ]}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <Input
                                    className="input-field-inner mb-2"
                                    placeholder="Quantidade dependentes"
                                    name="numero_dependentes"
                                    type="number"
                                    min="0"
                                    max="99"
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <UnformSelect
                                    type="select"
                                    placeholder=""
                                    className="input-field-select-inner mb-2"
                                    name="limitar_idade"
                                    options={[
                                      {
                                        value: "",
                                        label: "Limite de idade?",
                                      },
                                      {
                                        value: "true",
                                        label: "Sim",
                                      },
                                      {
                                        value: "false",
                                        label: "Não",
                                      },
                                    ]}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <Input
                                    className="input-field-inner mb-2"
                                    placeholder="Idade limite"
                                    name="idade_limite"
                                    type="number"
                                    min="1"
                                    max="99"
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <Input
                                    className="input-field-inner mb-2"
                                    placeholder="% Desc. Boleto"
                                    name="desconto_boleto"
                                    type="number"
                                    min="1"
                                    max="100"
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <Input
                                    className="input-field-inner mb-2"
                                    placeholder="Valor cartão"
                                    name="valor"
                                    step="any"
                                    type="number"
                                    min="1"
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Scope>

                          <Scope path="cartao2">
                            <Col lg={4} md={4} sm={12} hidden={qtdCard < 2}>
                              <ImagePreview
                                name="imagem"
                                handleDeleteImage={(cb) =>
                                  deleteImageCartao("gold", cb)
                                }
                              />
                              <Row>
                                <Col>
                                  <Input
                                    className="input-field-inner mt-3 mb-2"
                                    placeholder="Nome, Ex: Cartão PoupaClub"
                                    name="nome"
                                    type="text"
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <Input
                                    className="input-field-inner mt-3 mb-2"
                                    placeholder="Tipo, Ex: gold"
                                    name="tipo"
                                    type="text"
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <Button
                                    color="dark"
                                    className={`${corBtnCard2} btn-pill btn-block`}
                                    onClick={() => {
                                      toggle(true);
                                      setBtnEscolhido("c2");
                                    }}
                                  >
                                    Cor e botão
                                  </Button>
                                </Col>
                              </Row>
                              <Row className="mt-2">
                                <Col>
                                  <Label>Regras:</Label>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <UnformSelect
                                    type="select"
                                    className="input-field-select-inner mb-2"
                                    placeholder="Número de conjuges"
                                    name="conjuge"
                                    options={[
                                      {
                                        value: "",
                                        label: "Possui cônjuge",
                                      },
                                      {
                                        value: "false",
                                        label: "Não",
                                      },
                                      {
                                        value: "true",
                                        label: "Sim",
                                      },
                                    ]}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <Input
                                    className="input-field-inner mb-2"
                                    placeholder="Quantidade dependentes"
                                    name="numero_dependentes"
                                    type="number"
                                    min="0"
                                    max="99"
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <UnformSelect
                                    type="select"
                                    placeholder=""
                                    className="input-field-select-inner mb-2"
                                    name="limitar_idade"
                                    options={[
                                      {
                                        value: "",
                                        label: "Limite de idade?",
                                      },
                                      {
                                        value: "true",
                                        label: "Sim",
                                      },
                                      {
                                        value: "false",
                                        label: "Não",
                                      },
                                    ]}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <Input
                                    className="input-field-inner mb-2"
                                    placeholder="Idade limite"
                                    name="idade_limite"
                                    type="number"
                                    min="1"
                                    max="99"
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <Input
                                    className="input-field-inner mb-2"
                                    placeholder="% Desc. Boleto"
                                    name="desconto_boleto"
                                    type="number"
                                    min="1"
                                    max="100"
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <Input
                                    className="input-field-inner mb-2"
                                    placeholder="Valor cartão"
                                    name="valor"
                                    step="any"
                                    type="number"
                                    min="1"
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Scope>

                          <Scope path="cartao3">
                            <Col lg={4} md={4} sm={12} hidden={qtdCard < 3}>
                              <ImagePreview
                                name="imagem"
                                handleDeleteImage={(cb) =>
                                  deleteImageCartao("black", cb)
                                }
                              />
                              <Row>
                                <Col>
                                  <Input
                                    className="input-field-inner mt-3 mb-2"
                                    placeholder="Nome, Ex: Cartão PoupaClub"
                                    name="nome"
                                    type="text"
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <Input
                                    className="input-field-inner mt-3 mb-2"
                                    placeholder="Tipo, Ex: Black"
                                    name="tipo"
                                    type="text"
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <Button
                                    color="dark"
                                    className={`${corBtnCard3} btn-pill btn-block`}
                                    onClick={() => {
                                      toggle(true);
                                      setBtnEscolhido("c3");
                                    }}
                                  >
                                    Cor e botão
                                  </Button>
                                </Col>
                              </Row>
                              <Row className="mt-2">
                                <Col>
                                  <Label>Regras:</Label>
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <UnformSelect
                                    type="select"
                                    className="input-field-select-inner mb-2"
                                    placeholder="Número de conjuges"
                                    name="conjuges_cartao_3"
                                    options={[
                                      {
                                        value: "",
                                        label: "Possui cônjuge",
                                      },
                                      {
                                        value: "false",
                                        label: "Não",
                                      },
                                      {
                                        value: "true",
                                        label: "Sim",
                                      },
                                    ]}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <Input
                                    className="input-field-inner mb-2"
                                    placeholder="Quantidade dependentes"
                                    name="numero_dependentes"
                                    type="number"
                                    min="0"
                                    max="99"
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <UnformSelect
                                    type="select"
                                    placeholder=""
                                    className="input-field-select-inner mb-2"
                                    name="limitar_idade"
                                    options={[
                                      {
                                        value: "",
                                        label: "Limite de idade?",
                                      },
                                      {
                                        value: "true",
                                        label: "Sim",
                                      },
                                      {
                                        value: "false",
                                        label: "Não",
                                      },
                                    ]}
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <Input
                                    className="input-field-inner mb-2"
                                    placeholder="Idade limite"
                                    name="idade_limite"
                                    type="number"
                                    min="1"
                                    max="99"
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <Input
                                    className="input-field-inner mb-2"
                                    placeholder="% Desc. Boleto"
                                    name="desconto_boleto"
                                    type="number"
                                    min="1"
                                    max="100"
                                  />
                                </Col>
                              </Row>
                              <Row>
                                <Col>
                                  <Input
                                    className="input-field-inner mb-2"
                                    step="any"
                                    placeholder="Valor cartão"
                                    name="valor"
                                    type="number"
                                    min="1"
                                  />
                                </Col>
                              </Row>
                            </Col>
                          </Scope>
                        </Row>
                      </Col>
                    </Row>
                  </TabPanel>
                  <TabPanel forceRender>
                    <hr />
                    <Row className="mb-3">
                      <Col lg={12} md={12} sm={12}>
                        <br />
                        <Row>
                          <Col md={4} sm={12} className="mb-2">
                            <Label className="label-radio">
                              Texto e imagem como e onde usar -{" "}
                              <strong>(L = 1405px x A = 746px)</strong>{" "}
                            </Label>
                            <ImagePreview
                              name="home_img_saiba_mais"
                              handleDeleteImage={(cb) =>
                                deleteImage("home_img_saiba_mais", cb)
                              }
                            />
                          </Col>
                          <Col md={8} sm={12}>
                            <Input
                              className="input-field-inner mb-2"
                              placeholder="Titulo como e onde usar"
                              name="onde_como_titulo"
                              type="text"
                              min="1"
                            />
                          </Col>

                          <Col lg={12} md={12} sm={12}>
                            <SunEditor name="onde_como_texto" />
                          </Col>
                        </Row>
                        <hr />
                        <Row className="mb-3">
                          <Col lg={12} md={12} sm={12}>
                            <Label className="label-radio">
                              Textos totalizadores
                            </Label>
                            <br />
                          </Col>
                          <Col lg={12} md={12} sm={12}>
                            <SunEditor name="totalizador_home_1" />
                          </Col>
                          <Col lg={12} md={12} sm={12}>
                            <SunEditor name="totalizador_home_2" />
                          </Col>
                          <Col lg={12} md={12} sm={12}>
                            <SunEditor name="totalizador_home_3" />
                          </Col>
                          <Col lg={12} md={12} sm={12}>
                            <SunEditor name="totalizador_home_4" />
                          </Col>
                        </Row>
                        <hr />
                        <Row className="mb-3">
                          <Col lg={12} md={12} sm={12}>
                            <Label className="label-radio">
                              Texto e imagem sobre/propaganda -{" "}
                              <strong>(L = 1405px x A = 746px)</strong>{" "}
                            </Label>
                            <br />
                          </Col>
                          <Col lg={8} md={3} sm={12}>
                            <SunEditor name="texto_sobre_propaganda" />
                          </Col>
                          <Col lg={4} md={3} sm={12}>
                            <ImagePreview
                              name="img_sobre_propaganda"
                              handleDeleteImage={(cb) =>
                                deleteImage("img_sobre_propaganda", cb)
                              }
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col lg={12} md={12} sm={12}>
                        <Label className="label-radio">
                          Imagem ao lado da tabela
                          <strong> (L = 370x x A = 660px)</strong>{" "}
                        </Label>
                        <br />
                        <Row>
                          <Col lg={3} sm={12}>
                            <ImagePreview
                              name="beneficios_tabela_img"
                              handleDeleteImage={(cb) =>
                                deleteImage("beneficios_tabela_img", cb)
                              }
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col lg={12} md={12} sm={12}>
                        <Label className="label-radio">
                          Ícones e textos tabela Benefícios e Descontos{" "}
                          <strong>(L = 130px x A = 130px)</strong>{" "}
                        </Label>
                        <br />
                        <Row>
                          <Col lg={2} md={2} sm={12}>
                            <ImagePreview
                              name="beneficios_tabela_icone_1"
                              handleDeleteImage={(cb) =>
                                deleteImage("beneficios_tabela_icone_1", cb)
                              }
                            />
                          </Col>
                          <Col lg={5} md={4} sm={12}>
                            <Input
                              className="input-field-inner mt-3 mb-2"
                              placeholder="Texto tabela 1"
                              name="beneficios_tabela_texto_1"
                              type="text"
                            />
                          </Col>
                          <Col lg={5} md={4} sm={12}>
                            <Input
                              className="input-field-inner mt-3 mb-2"
                              placeholder="Texto tabela 2"
                              name="beneficios_tabela_subtexto_1"
                              type="text"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={2} md={2} sm={12}>
                            <ImagePreview
                              name="beneficios_tabela_icone_2"
                              handleDeleteImage={(cb) =>
                                deleteImage("beneficios_tabela_icone_2", cb)
                              }
                            />
                          </Col>
                          <Col lg={5} md={4} sm={12}>
                            <Input
                              className="input-field-inner mt-3 mb-2"
                              placeholder="Texto tabela 1"
                              name="beneficios_tabela_texto_2"
                              type="text"
                            />
                          </Col>
                          <Col lg={5} md={4} sm={12}>
                            <Input
                              className="input-field-inner mt-3 mb-2"
                              placeholder="Texto tabela 2"
                              name="beneficios_tabela_subtexto_2"
                              type="text"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={2} md={2} sm={12}>
                            <ImagePreview
                              name="beneficios_tabela_icone_3"
                              handleDeleteImage={(cb) =>
                                deleteImage("beneficios_tabela_icone_3", cb)
                              }
                            />
                          </Col>
                          <Col lg={5} md={4} sm={12}>
                            <Input
                              className="input-field-inner mt-3 mb-2"
                              placeholder="Texto tabela 1"
                              name="beneficios_tabela_texto_3"
                              type="text"
                            />
                          </Col>
                          <Col lg={5} md={4} sm={12}>
                            <Input
                              className="input-field-inner mt-3 mb-2"
                              placeholder="Texto tabela 2"
                              name="beneficios_tabela_subtexto_3"
                              type="text"
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col lg={2} md={2} sm={12}>
                            <ImagePreview
                              name="beneficios_tabela_icone_4"
                              handleDeleteImage={(cb) =>
                                deleteImage("beneficios_tabela_icone_4", cb)
                              }
                            />
                          </Col>
                          <Col lg={5} md={4} sm={12}>
                            <Input
                              className="input-field-inner mt-3 mb-2"
                              placeholder="Texto tabela 1"
                              name="beneficios_tabela_texto_4"
                              type="text"
                            />
                          </Col>
                          <Col lg={5} md={4} sm={12}>
                            <Input
                              className="input-field-inner mt-3 mb-2"
                              placeholder="Texto tabela 2"
                              name="beneficios_tabela_subtexto_4"
                              type="text"
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row className="mb-3">
                      <Col lg={12} md={12} sm={12}>
                        <Label className="label-radio">
                          Texto e imagem Clube de Descontos -{" "}
                          <strong>(L = 370x x A = 660px)</strong>{" "}
                        </Label>
                        <br />
                      </Col>
                      <Col lg={8} md={3} sm={12}>
                        <SunEditor name="clube_desconto_texto" />
                      </Col>
                      <Col lg={4} md={3} sm={12}>
                        <ImagePreview
                          name="clube_desconto_img"
                          handleDeleteImage={(cb) =>
                            deleteImage("clube_desconto_img", cb)
                          }
                        />
                      </Col>
                    </Row>
                  </TabPanel>
                  <TabPanel forceRender>
                    <hr />
                    <Row className="mb-3">
                      <Col lg={12} md={12} sm={12}>
                        <Label className="label-radio">
                          Imagem e cabeçalho Desconto Farmácia -{" "}
                          <strong>(L = 400px x A = 500px)</strong>{" "}
                        </Label>
                        <br />
                      </Col>
                      <Col lg={4} md={3} sm={12}>
                        <ImagePreview
                          name="desc_farma_image_form"
                          handleDeleteImage={(cb) =>
                            deleteImage("desc_farma_image_form", cb)
                          }
                        />
                      </Col>
                      <Col lg={8} md={3} sm={12}>
                        <SunEditor name="desc_farma_titulo" />
                      </Col>
                    </Row>
                    <hr />
                    <Row className="mb-3">
                      <Col lg={12} md={12} sm={12}>
                        <Label className="label-radio">
                          Selecione a imagem de Desconto Farmácia{" "}
                          <strong>(L = 2048px x A = 1295px)</strong>
                        </Label>
                        <br />
                        <Row>
                          <Col lg={2} md={2} sm={12}>
                            <ImagePreview
                              name="img_banner_desc_farma"
                              handleDeleteImage={(cb) =>
                                deleteImage("img_banner_desc_farma", cb)
                              }
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12} md={2} sm={12}>
                        <Label className="label-radio">
                          Título do card Desconto Farmácia
                        </Label>
                        <Input
                          className="input-field-inner mt-3 mb-2"
                          placeholder="Título"
                          name="titulo_desconto_farma"
                          type="text"
                        />
                      </Col>
                      <Col lg={12} md={12} sm={12}>
                        <Label className="label-radio">
                          Texto do card Desconto Farmácia
                        </Label>
                        <SunEditor name="texto_desconto_farma" />
                      </Col>
                    </Row>
                    <hr />
                    <Row className="mb-3">
                      <Col lg={12} md={12} sm={12}>
                        <Label className="label-radio">
                          Textos Desconto Farmácia
                        </Label>
                        <br />
                      </Col>
                      <Col lg={12} md={12} sm={12}>
                        <Input
                          className="input-field-inner mt-3 mb-2"
                          placeholder="Título benefícios Desconto Farmácia"
                          name="titulo_desc_farma"
                          type="text"
                        />
                      </Col>
                      <Col lg={4} md={4} sm={12}>
                        <Input
                          className="input-field-inner mt-3 mb-2"
                          placeholder="Porcentagem 1"
                          name="porcentagem_1"
                          type="text"
                        />
                      </Col>
                      <Col lg={4} md={4} sm={12}>
                        <Input
                          className="input-field-inner mt-3 mb-2"
                          placeholder="Porcentagem 2"
                          name="porcentagem_2"
                          type="text"
                        />
                      </Col>
                      <Col lg={4} md={4} sm={12}>
                        <Input
                          className="input-field-inner mt-3 mb-2"
                          placeholder="Porcentagem 3"
                          name="porcentagem_3"
                          type="text"
                        />
                      </Col>
                      <Col lg={4} md={4} sm={12}>
                        <Input
                          className="input-field-inner mt-3 mb-2"
                          placeholder="Texto porcentagem 1"
                          name="texto_porcentagem_1"
                          type="text"
                        />
                      </Col>
                      <Col lg={4} md={4} sm={12}>
                        <Input
                          className="input-field-inner mt-3 mb-2"
                          placeholder="Texto porcentagem 2"
                          name="texto_porcentagem_2"
                          type="text"
                        />
                      </Col>
                      <Col lg={4} md={4} sm={12}>
                        <Input
                          className="input-field-inner mt-3 mb-2"
                          placeholder="Texto porcentagem 3"
                          name="texto_porcentagem_3"
                          type="text"
                        />
                      </Col>
                      <Col lg={12} md={12} sm={12}>
                        <Label className="label-radio mt-2">
                          Selecione a imagem dos parceiros{" "}
                          <strong>(L = 960px x A = 100px)</strong>
                        </Label>
                        <br />
                        <Row>
                          <Col lg={2} md={2} sm={12}>
                            <ImagePreview
                              name="imagem_parceiros_desconto"
                              handleDeleteImage={(cb) =>
                                deleteImage("imagem_parceiros_desconto", cb)
                              }
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <hr />
                    <Row className="mb-3">
                      <Col lg={12} md={12} sm={12}>
                        <Label className="label-radio mt-2">
                          Selecione a imagem dos cartões{" "}
                          <strong>(L = 3020px x A = 1665px)</strong>
                        </Label>
                        <br />
                        <Row>
                          <Col lg={3} sm={12}>
                            <ImagePreview
                              name="imagem_cartoes_baixo"
                              handleDeleteImage={(cb) =>
                                deleteImage("imagem_cartoes_baixo", cb)
                              }
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <hr />
                  </TabPanel>
                  <TabPanel forceRender>
                    <Row className="mb-3">
                      <hr />
                      <Col lg={12} md={12} sm={12}>
                        <Label className="label-radio mt-2">
                          Imagem e texto Seja Parceiro{" "}
                          <strong>(L = 370x x A = 660px)</strong>
                        </Label>
                        <br />
                        <Row>
                          <Col lg={2} md={2} sm={12}>
                            <ImagePreview
                              name="imagem_seja_parceiro"
                              handleDeleteImage={(cb) =>
                                deleteImage("imagem_seja_parceiro", cb)
                              }
                            />
                          </Col>
                          <Col lg={10} md={10} sm={12}>
                            <SunEditor name="texto_seja_parceiro" />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </TabPanel>
                  <TabPanel forceRender>
                    <hr />
                    <Row className="mb-3">
                      <Col lg={12} md={12} sm={12}>
                        <Label className="label-radio mt-2">
                          Imagem e texto Seja Revendedor{" "}
                          <strong>(L = 370x x A = 660px)</strong>
                        </Label>
                        <br />
                        <Row>
                          <Col lg={2} md={2} sm={12}>
                            <ImagePreview
                              name="imagem_seja_revendedor"
                              handleDeleteImage={(cb) =>
                                deleteImage("imagem_seja_revendedor", cb)
                              }
                            />
                          </Col>
                          <Col lg={10} md={10} sm={12}>
                            <SunEditor name="texto_seja_revendedor" />
                          </Col>
                          <Col lg={12} md={12} sm={12}>
                            <Input
                              className="input-field-inner mt-3 mb-2"
                              placeholder="Cabeçalho formulário Seja revendedor"
                              name="form_header_seja_revendedor"
                              type="text"
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </TabPanel>
                  <TabPanel forceRender>
                    <Row className="mb-3">
                      <Col lg={12} md={12} sm={12}>
                        <Label className="label-radio mt-2">
                          Imagem e texto Agendamento{" "}
                          <strong>(L = 370x x A = 660px)</strong>
                        </Label>
                        <br />
                        <Row>
                          <Col lg={2} md={2} sm={12}>
                            <ImagePreview
                              name="imagem_agendamento"
                              handleDeleteImage={(cb) =>
                                deleteImage("imagem_agendamento", cb)
                              }
                            />
                          </Col>
                          <Col lg={10} md={10} sm={12}>
                            <SunEditor name="texto_agendamento" />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </TabPanel>
                  <TabPanel forceRender>
                    <Row className="mb-3">
                      <Col lg={12} md={12} sm={12}>
                        <Label className="label-radio mt-2">
                          Imagens de acesso
                          <strong>(L = 750x x A = 310px)</strong>
                        </Label>
                        <br />
                        <Row>
                          <Col lg={4} sm={12}>
                            <label>Cliente</label>
                            <ImagePreview
                              name="imagem_login_cliente"
                              handleDeleteImage={(cb) =>
                                deleteImage("imagem_login_cliente", cb)
                              }
                            />
                          </Col>
                          <Col lg={4} sm={12}>
                            <label>Parceiro</label>
                            <ImagePreview
                              name="imagem_login_parceiro"
                              handleDeleteImage={(cb) =>
                                deleteImage("imagem_login_parceiro", cb)
                              }
                            />
                          </Col>
                          <Col lg={4} sm={12}>
                            <label>Revendedor</label>
                            <ImagePreview
                              name="imagem_login_revendedor"
                              handleDeleteImage={(cb) =>
                                deleteImage("imagem_login_revendedor", cb)
                              }
                            />
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </TabPanel>
                  <TabPanel forceRender>
                    <hr />
                    <Row>
                      <Col className="mb-3">
                        <Label>Sobre</Label>
                        <SunEditor name="texto_sobre_nos" />
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mb-3">
                        <Label>Politica de privacidade</Label>
                        <SunEditor name="texto_privacidade" />
                      </Col>
                    </Row>
                    <Row>
                      <Col className="mb-3">
                        <Label>Termos de uso</Label>
                        <SunEditor name="texto_politica" />
                      </Col>
                    </Row>
                  </TabPanel>
                </Tabs>
              </div>
              <hr />
              <Row className="justify-end">
                <Col lg={2}>
                  {edicao && (
                    <Button
                      color="danger"
                      type="button"
                      className="btn-save w-100"
                      onClick={toggleStatus}
                      block
                    >
                      {status === "A" ? "Inativar" : "Ativar"}
                    </Button>
                  )}
                </Col>
                <Col lg={2}>
                  <Button color="green" className="btn-save w-100">
                    {edicao ? "Editar" : "Cadastrar"}
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </Col>
      </Row>

      <Modal isOpen={modal}>
        <ModalHeader toggle={toggle}>Selecione a cor do botão</ModalHeader>
        <Form ref={formRefModal} onSubmit={handleSubmitModal}>
          <ModalBody>
            <Row>
              <Col lg={12} className="mb-3">
                <UnformRadio
                  name="cor-botao"
                  options={[
                    {
                      id: "whitelabel-color-black",
                      value: "whitelabel-color-black",
                      color: "whitelabel-color-black",
                    },
                    {
                      id: "whitelabel-color-gray-1",
                      value: "whitelabel-color-gray-1",
                      color: "whitelabel-color-gray-1",
                    },
                    {
                      id: "whitelabel-color-gray-2",
                      value: "whitelabel-color-gray-2",
                      color: "whitelabel-color-gray-2",
                    },
                    {
                      id: "whitelabel-color-brown-1",
                      value: "whitelabel-color-brown-1",
                      color: "whitelabel-color-brown-1",
                    },
                    {
                      id: "whitelabel-color-brown-2",
                      value: "whitelabel-color-brown-2",
                      color: "whitelabel-color-brown-2",
                    },
                    {
                      id: "whitelabel-color-blue-1",
                      value: "whitelabel-color-blue-1",
                      color: "whitelabel-color-blue-1",
                    },
                    {
                      id: "whitelabel-color-blue-2",
                      value: "whitelabel-color-blue-2",
                      color: "whitelabel-color-blue-2",
                    },
                    {
                      id: "whitelabel-color-blue-3",
                      value: "whitelabel-color-blue-3",
                      color: "whitelabel-color-blue-3",
                    },
                    {
                      id: "whitelabel-color-cyan",
                      value: "whitelabel-color-cyan",
                      color: "whitelabel-color-cyan",
                    },
                    {
                      id: "whitelabel-color-green-1",
                      value: "whitelabel-color-green-1",
                      color: "whitelabel-color-green-1",
                    },
                    {
                      id: "whitelabel-color-green-2",
                      value: "whitelabel-color-green-2",
                      color: "whitelabel-color-green-2",
                    },
                    {
                      id: "whitelabel-color-green-3",
                      value: "whitelabel-color-green-3",
                      color: "whitelabel-color-green-3",
                    },
                    {
                      id: "whitelabel-color-orange-1",
                      value: "whitelabel-color-orange-1",
                      color: "whitelabel-color-orange-1",
                    },
                    {
                      id: "whitelabel-color-orange-2",
                      value: "whitelabel-color-orange-2",
                      color: "whitelabel-color-orange-2",
                    },
                    {
                      id: "whitelabel-color-orange-3",
                      value: "whitelabel-color-orange-3",
                      color: "whitelabel-color-orange-3",
                    },
                    {
                      id: "whitelabel-color-red-1",
                      value: "whitelabel-color-red-1",
                      color: "whitelabel-color-red-1",
                    },
                    {
                      id: "whitelabel-color-red-2",
                      value: "whitelabel-color-red-2",
                      color: "whitelabel-color-red-2",
                    },
                    {
                      id: "whitelabel-color-red-3",
                      value: "whitelabel-color-red-3",
                      color: "whitelabel-color-red-3",
                    },
                    {
                      id: "whitelabel-color-purple-1",
                      value: "whitelabel-color-purple-1",
                      color: "whitelabel-color-purple-1",
                    },
                    {
                      id: "whitelabel-color-purple-2",
                      value: "whitelabel-color-purple-2",
                      color: "whitelabel-color-purple-2",
                    },
                    {
                      id: "whitelabel-color-purple-3",
                      value: "whitelabel-color-purple-3",
                      color: "whitelabel-color-purple-3",
                    },
                    {
                      id: "whitelabel-color-purple-4",
                      value: "whitelabel-color-purple-4",
                      color: "whitelabel-color-purple-4",
                    },
                    {
                      id: "whitelabel-color-pink-1",
                      value: "whitelabel-color-pink-1",
                      color: "whitelabel-color-pink-1",
                    },
                    {
                      id: "whitelabel-color-pink-2",
                      value: "whitelabel-color-pink-2",
                      color: "whitelabel-color-pink-2",
                    },
                    {
                      id: "whitelabel-color-pink-3",
                      value: "whitelabel-color-pink-3",
                      color: "whitelabel-color-pink-3",
                    },
                  ]}
                  optionsClass="mr-3"
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="green btn-pill" type="submit">
              OK
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default WhiteLabel;

function SunEditor({ name, ...rest }) {
  const suneditorRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  const buttonList = [
    ["undo", "redo", "font", "fontSize", "formatBlock"],
    [
      "blockquote",
      "bold",
      "underline",
      "italic",
      "strike",
      "fontColor",
      "hiliteColor",
      "removeFormat",
      "textStyle",
    ],
    [
      "outdent",
      "indent",
      "align",
      "list",
      "lineHeight",
      "paragraphStyle",
      "blockquote",
    ],
    ["link", "fullScreen"],
  ];

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: suneditorRef.current,
      path: "editor",
      getValue: (ref) => ref.editor.getContents(),
      setValue: (ref, value) => {
        // console.log(ref);
        ref?.editor?.setContents(value || "");
      },
    });
  }, [fieldName, registerField]);

  return (
    <>
      <SunEditorComponent
        ref={suneditorRef}
        id={fieldName}
        defaultValue={defaultValue}
        setOptions={{
          height: "100%",
          buttonList: buttonList,
          font: [
            "Arial",
            "Heebo",
            "tohoma",
            "Courier New,Courier",
            "Verdana",
            "Georgia",
          ],
        }}
        {...rest}
      />

      {error && <small className="error">{error}</small>}
    </>
  );
}
