import React, { useEffect, useRef, useState } from 'react';
import Card from 'react-credit-cards';
import {
  formatCreditCardNumber,
  formatCVC,
  formatExpirationDate,
} from '../utils/payment';

import 'react-credit-cards/es/styles-compiled.css';
import useScript from '../hooks/useScript';
import api from '../services/api';
import { toast } from 'react-toastify';

const scriptJuno = window.location.origin.includes('https://poupaclub.com.br')
  ? 'https://www.boletobancario.com/boletofacil/wro/direct-checkout.min.js'
  : 'https://sandbox.boletobancario.com/boletofacil/wro/direct-checkout.min.js';

export default function CreditCard({ handleSubmit: handleSubmitProp }) {
  useScript(scriptJuno);

  const [number, setNumber] = useState('');
  const [name, setName] = useState('');
  const [publicToken, setPublicToken] = useState('');
  const [expiry, setExpiry] = useState('');
  const [cvc, setCvc] = useState('');
  const [issuer, setIssuer] = useState('');
  const [focused, setFocused] = useState('');
  const formRef = useRef(null);

  const handleCallback = ({ issuer }, isValid) => {
    if (isValid) {
      setIssuer(issuer);
    }
  };

  const handleInputFocus = ({ target }) => {
    setFocused(target.name);
  };

  const handleInputChange = ({ target }) => {
    if (target.name === 'number') {
      setNumber(target.value);
      target.value = formatCreditCardNumber(target.value);
    } else if (target.name === 'expiry') {
      setExpiry(target.value);
      target.value = formatExpirationDate(target.value);
    } else if (target.name === 'cvc') {
      target.value = formatCVC(target.value);
      setCvc(target.value);
    } else {
      setName(target.value);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    let pnToken = publicToken;
    if (!publicToken) {
      try {
        const { data: result } = await api.get('/public-token');
        pnToken = result;
      } catch (err) {
        return toast.warn('Não foi possivel realizar a solicitação de assinatura')
      }
    }
    // eslint-disable-next-line no-undef
    const checkout = new DirectCheckout(pnToken);
    const formData = [...e.target.elements]
      .filter((d) => d.name)
      .reduce((acc, d) => {
        acc[d.name] = d.value;
        return acc;
      }, {});

    const cardData = {
      cardNumber: formData.number.replaceAll(' ', ''),
      holderName: formData.name,
      securityCode: formData.cvc,
      expirationMonth: formData.expiry.split('/')[0],
      expirationYear: `20${formData.expiry.split('/')[1]}`,
    };
    if (!checkout.isValidCardNumber(cardData.cardNumber)) {
      return toast.error('Número de cartão inválido');
    }
    if (
      !checkout.isValidSecurityCode(cardData.cardNumber, cardData.securityCode)
    ) {
      return toast.error('Security code inválido!');
    }
    if (
      !checkout.isValidExpireDate(
        cardData.expirationMonth,
        cardData.expirationYear
      )
    ) {
      return toast.error('Data expiração inválida!');
    }

    checkout.getCardHash(
      cardData,
      function (cardHash) {
        handleSubmitProp(cardHash);
      },
      function (error) {
        console.log('ERROR ==>', error);
      }
    );
  };

  useEffect(() => {
    async function getPublicToken() {
      try {
        const { data: result } = await api.get('/public-token');
        setPublicToken(result);
      } catch (err) {}
    }

    getPublicToken();
  }, []);

  return (
    <div key='Payment'>
      <div className='App-payment'>
        <Card
          number={number}
          name={name}
          expiry={expiry}
          cvc={cvc}
          focused={focused}
          callback={handleCallback}
          placeholders={{
            name: 'SEU NOME',
          }}
        />
        <form className='mt-3' ref={formRef} onSubmit={handleSubmit}>
          <div className='form-group'>
            <input
              type='tel'
              name='number'
              className='form-control input-field'
              placeholder='Número do cartão'
              pattern='[\d| ]{16,22}'
              required
              onChange={handleInputChange}
              onFocus={handleInputFocus}
            />
          </div>
          <div className='form-group'>
            <input
              type='text'
              name='name'
              className='form-control input-field'
              placeholder='Nome do Titular do Cartão'
              required
              onChange={handleInputChange}
              onFocus={handleInputFocus}
            />
          </div>
          <div className='row'>
            <div className='col-6'>
              <input
                type='tel'
                name='expiry'
                className='form-control input-field'
                placeholder='Validade'
                pattern='\d\d/\d\d'
                required
                onChange={handleInputChange}
                onFocus={handleInputFocus}
              />
            </div>
            <div className='col-6'>
              <input
                type='tel'
                name='cvc'
                className='form-control input-field'
                placeholder='CVC'
                pattern='\d{3,4}'
                required
                onChange={handleInputChange}
                onFocus={handleInputFocus}
              />
            </div>
          </div>
          <input type='hidden' name='issuer' value={issuer} />
          <div className='form-actions mt-3'>
            <button className='btn btn-secondary btn-block btn-pill'>
              Pagar
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
