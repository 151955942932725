import React from "react";
import {
  Card,
  CardBody,
  Button,
  Badge,
  CardFooter,
  Row,
  Col,
} from "reactstrap";
import cartaoEmpresarial from "../assets/cartao_empresarial.png";

import { useWhiteLabel } from "../contexts/whitelabel";
import { useHistory } from "react-router";

function CartoesWhiteLabelHome({ cartoes }) {
  const { dadosWhiteLabel } = useWhiteLabel();

  function getPrecoCartao(cartao) {
    return Number(cartao?.valor);
  }
  const history = useHistory();

  return (
    <Row className="justify-center">
      {cartoes
        ?.filter((f) => f.cartao)
        ?.map(({ cartao, id }) => (
          <Col md={3} sm={12} className="mb-60" key={id}>
            <Card className="card-cartoes">
              <CardBody className="card-body-cartoes">
                <div className="card-image-cartoes">
                  <img src={cartao?.imagem?.url} alt={cartao?.nome} />
                </div>
                <div className="justify-start my-3">
                  <strong
                    className={"txt-tertiary"}
                    style={{
                      color: dadosWhiteLabel[`whitelabel_text_color_2`],
                    }}
                  >
                    {cartao.nome}
                  </strong>
                  &nbsp;
                  <Badge
                    pill
                    color="black"
                    className={dadosWhiteLabel[`cor_cartao_${id}`]}
                  >
                    {cartao?.tipo?.toUpperCase()}
                  </Badge>
                </div>
                <div
                  className="txt-tertiary"
                  style={{
                    color: dadosWhiteLabel?.whitelabel_text_color_3,
                  }}
                >
                  <div>
                    <strong>1 (um) usuário titular</strong>
                  </div>
                  {cartao?.conjuge && (
                    <div>
                      <strong>+ 1 Cônjuge</strong>
                    </div>
                  )}
                  {cartao?.numero_dependentes > 0 && (
                    <div>
                      <strong>
                        + {cartao?.numero_dependentes} Dependente(s)
                      </strong>{" "}
                      {cartao?.limitar_idade
                        ? `menor(es) de ${cartao?.idade_limite} anos`
                        : "(sem limite de idade)"}
                    </div>
                  )}
                  <div>Validade de 12 meses</div>
                  <br />
                  <div>
                    À vista de R${" "}
                    {getPrecoCartao(cartao).toLocaleString("pt-BR", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{" "}
                    ({`+ ${cartao?.desconto_boleto}% de desconto`})
                  </div>
                  <div>
                    ou 12x R${" "}
                    {(getPrecoCartao(cartao) / 12).toLocaleString("pt-BR", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{" "}
                    no Cartão de crédito
                  </div>
                  <div>
                    ou 4x R${" "}
                    {(getPrecoCartao(cartao) / 4).toLocaleString("pt-BR", {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}{" "}
                    no Boleto bancário
                  </div>
                  <br />
                </div>
              </CardBody>
              <CardFooter className="card-footer-cartoes">
                <div className="mb-3 justify-center">
                  <div
                    className={
                      dadosWhiteLabel
                        ? "bold price-subtitle-whitelabel"
                        : "bold price-subtitle"
                    }
                    style={{
                      color: dadosWhiteLabel
                        ? dadosWhiteLabel?.whitelabel_text_color_3
                        : "",
                    }}
                  >
                    R$ &nbsp;
                  </div>
                  <div
                    style={{
                      marginBottom: "-5px",
                      display: "flex",
                    }}
                  >
                    <div
                      className={
                        dadosWhiteLabel
                          ? "bold price-title-whitelabel"
                          : "bold price-subtitle"
                      }
                      style={{
                        color: dadosWhiteLabel
                          ? dadosWhiteLabel?.whitelabel_text_color_3
                          : "",
                      }}
                    >
                      {
                        (getPrecoCartao(cartao) / 12)
                          .toLocaleString("pt-BR", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                          ?.split(",")[0]
                      }
                      ,
                    </div>
                    <div
                      className={
                        dadosWhiteLabel
                          ? "bold price-up-whitelabel"
                          : "bold price-up"
                      }
                      style={{
                        color: dadosWhiteLabel
                          ? dadosWhiteLabel?.whitelabel_text_color_3
                          : "",
                      }}
                    >
                      {
                        (getPrecoCartao(cartao) / 12)
                          .toLocaleString("pt-BR", {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                          })
                          ?.split(",")[1]
                      }
                    </div>
                  </div>
                  <div
                    className={
                      dadosWhiteLabel
                        ? "bold price-subtitl-whitelabel"
                        : "bold price-subtitl"
                    }
                    style={{
                      color: dadosWhiteLabel
                        ? dadosWhiteLabel?.whitelabel_text_color_3
                        : "",
                    }}
                  >
                    &nbsp;por mês
                  </div>
                </div>
                <div>
                  <p
                    className={
                      dadosWhiteLabel ? "via-cartao-whitelabel" : "via-cartao"
                    }
                    style={{
                      color: dadosWhiteLabel
                        ? dadosWhiteLabel?.whitelabel_text_color_3
                        : "",
                    }}
                  >
                    via cartão de crédito
                  </p>
                </div>

                <div className="card-footer-cartoes justify-center">
                  <div>
                    <Button
                      className={
                        dadosWhiteLabel
                          ? "btn-pill bold btn-block " +
                            dadosWhiteLabel[`cor_cartao_${id}`]
                          : " btn-pill bold btn-block"
                      }
                      color="black"
                      size="lg"
                      onClick={() => {
                        window.scrollTo(0, 0);
                        history.push(
                          `/${
                            dadosWhiteLabel?.url_whitelabel || "poupa-club"
                          }/cartao-escolhido/${
                            ["especial", "gold", "black"][id - 1]
                          }`
                        );
                      }}
                    >
                      Peça agora
                    </Button>
                  </div>
                </div>
              </CardFooter>
            </Card>
          </Col>
        ))}

      <Col lg={3} md={6} sm={12} className="mb-60">
        <Card className="card-cartoes">
          <CardBody className="card-body-cartoes">
            <div className="card-image-cartoes">
              <img
                src={dadosWhiteLabel?.empresarial_img?.url || cartaoEmpresarial}
                alt="Cartão Black"
              />
            </div>
            <div className="justify-start my-3">
              <strong
                className="txt-tertiary"
                style={{
                  color: dadosWhiteLabel[`whitelabel_text_color_2`],
                }}
              >
                Cartão de descontos
              </strong>
              &nbsp;
              <div>
                <Badge pill color="empresarial" className="bold">
                  EMPRESARIAL
                </Badge>
              </div>
            </div>
            <div className="txt-tertiary">
              <div>
                <strong>Funcionários customizados</strong>
              </div>
              <div>
                <strong>Dependentes customizados</strong>
              </div>
              <div>Validade de 12 meses</div>
              <br />
              <div></div>
              <div></div>
              <div></div>
              <br />
            </div>
          </CardBody>
          <CardFooter className="card-footer-cartoes">
            <div className="card-footer-cartoes justify-center">
              <div className="w-70">
                <Button
                  className="btn-pill bold btn-block"
                  color="empresarial"
                  size="lg"
                  onClick={() => {
                    window.scrollTo(0, 0);
                    history.push(
                      `/${
                        dadosWhiteLabel?.url_whitelabel || "poupa-club"
                      }/cartao-empresarial`
                    );
                  }}
                >
                  Peça agora
                </Button>
              </div>
            </div>
          </CardFooter>
        </Card>
      </Col>
    </Row>
  );
}

export default CartoesWhiteLabelHome;
