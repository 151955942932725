const arr = [
  "whitelabel_logo",
  "home_banner_1",
  "home_banner_2",
  "home_banner_3",
  "home_banner_4",
  "home_banner_5",
  "home_img_1",
  "home_img_convenio_farma",
  "home_img_beneficio_farma",
  "home_img_saiba_mais",
  "img_sobre_propaganda",
  "beneficios_tabela_img",
  "beneficios_tabela_icone_1",
  "beneficios_tabela_icone_2",
  "beneficios_tabela_icone_3",
  "beneficios_tabela_icone_4",
  "clube_desconto_img",
  "desc_farma_image_form",
  "img_banner_desc_farma",
  "imagem_parceiros_desconto",
  "imagem_cartoes_baixo",
  "imagem_seja_parceiro",
  "imagem_seja_revendedor",
  "imagem_agendamento",
  "imagem_login_cliente",
  "imagem_login_parceiro",
  "imagem_login_revendedor",
  "imagem_footer",
  "empresarial",
  "img_simulador_desconto",
];

export default arr;
