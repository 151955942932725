import * as yup from "yup";

export default async function store(data) {
  const schema = yup.object().shape({
    nome: yup.string().required("Obrigatório"),
    email: yup.string().email("E-mail inválido!").required("Obrigatório"),
  });

  await schema.validate(data, {
    abortEarly: false,
  });
}
