import { Col, InputGroup, InputGroupAddon, Row } from 'reactstrap';
import { useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import { Form } from '@unform/web';
import { ValidationError } from 'yup';
import { useHistory, useParams } from 'react-router-dom';

import UnformAsync from '../../../components/unformAsyncSelect';
import ImagePreview from '../../../components/unformImagePreview';
import Input from '../../../components/unformInput';
import UnformSelect from '../../../components/unformSelect';
import validator from '../../../validations/criarCartaoEmpresárial';

import api from '../../../services/api';
import { Scope } from '@unform/core';
import { FaPlus, FaTrash } from 'react-icons/fa';
let timeoutConsulta;

function CriarCartao() {
  const params = useParams();
  const formRef = useRef(null);
  const history = useHistory();
  const [participacoes, setParticipacoes] = useState([]);

  async function handleSubmit({ participacoes: ptc, ...data }) {
    try {
      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });

      let temErro = false;

      data.idade_limite = data.idade_limite || 0;

      await validator(data);

      data.participacoes = [];

      ptc.forEach((m, i) => {
        if (m.usuario || m.participacao) {
          if (!m.participacao) {
            temErro = true;
            formRef.current.setFieldError(
              `participacoes[${i}].participacao`,
              'Obrigatório'
            );
          } else if (!m.usuario) {
            temErro = true;
            formRef.current.setFieldError(
              `participacoes[${i}].usuario`,
              'Obrigatório'
            );
          } else {
            data.participacoes.push({
              participacao: Number(m.participacao),
              usuario: m.usuario.value,
            });
          }
        }
      });

      if (temErro) {
        Swal.close();
        toast.error('Verifique os campos em vermelho!');
        return;
      }

      const formData = new FormData();

      Object.keys(data).forEach((element) => {
        formData.append(
          element,
          typeof data[element] === 'object'
            ? JSON.stringify(data[element])
            : data[element]
        );
      });
      const params1 = new URLSearchParams(window.location.search);
      const id = params1.get('id');
      console.log('x', id);
      console.log('x²', formData);
      if (id) {
        await api.put(`/cartoes/${id}`, formData);
        toast.success('Cartão alterado com sucesso');
      } else {
        await api.post('/cartoes', formData);
        toast.success('Cartão cadastrado com sucesso');
      }
      Swal.close();
      history.push('/acesso/cartoes');
    } catch (err) {
      const validationErrors = {};
      Swal.close();
      if (err instanceof ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
        toast.error('Verifique os campos em vermelho!');
      } else {
        const error =
          err?.response?.data?.error || 'Ocorreu um erro, tente novamente!';
        toast.error(error);
      }
    }
  }

  async function handleDeleteImage(cb) {
    Swal.fire({
      scrollbarPadding: false,
      title: 'Aguarde',
      allowOutsideClick: false,
      showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const params1 = new URLSearchParams(window.location.search);
      const id = params1.get('id');

      await api.delete(`/cartoes-img/${id}`);
      cb();
    } catch (err) {}
    Swal.close();
  }

  const debounceConsultaParceiro = async (body) => {
    return await new Promise((resolve) => {
      clearTimeout(timeoutConsulta);

      timeoutConsulta = setTimeout(() => {
        resolve(filtrarParceiro(body));
      }, 500);
    });
  };

  const debounceConsultaRepresentantes = async (body) => {
    return await new Promise((resolve) => {
      clearTimeout(timeoutConsulta);

      timeoutConsulta = setTimeout(() => {
        resolve(filtrarRepresentantes(body));
      }, 500);
    });
  };

  async function filtrarParceiro({ value, param }) {
    const result = await api.get(`/options/whitelabel?${param}=${value}`);
    return result.data.map((parceiro) => ({
      value: parceiro.id,
      label: parceiro.nome,
    }));
  }

  async function filtrarRepresentantes({ value, param }) {
    const result = await api.get(`options/representantes?${param}=${value}`);

    return result.data.map((m) => ({
      label: m.nome_fantasia,
      value: m.usuario_id,
    }));
  }

  function addParticipacao() {
    setParticipacoes([...participacoes, null]);
  }

  function delParticipacao(index) {
    const arr = [];
    const { participacoes: list, ...rest } = formRef.current.getData();
    rest.participacoes = [list[0]];
    participacoes.forEach((a, i) => {
      if (index !== i) {
        arr.push(a);
        rest.participacoes.push(list[i + 1]);
      }
    });
    setParticipacoes(arr);
    console.log(rest);
    formRef.current.setData(rest);
  }

  useEffect(() => {
    async function getData() {
      const params1 = new URLSearchParams(window.location.search);
      const id = params1.get('id');
      if (id) {
        Swal.fire({
          scrollbarPadding: false,
          title: 'Aguarde',
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });
        try {
          const { data: res } = await api.get(`/cartoes-adm/${id}`);
          if (res.parceiro) {
            res.parceiro = {
              value: res.parceiro.id,
              label: res.parceiro.nome_fantasia,
            };
          } else {
            delete res.parceiro;
          }

          if (res?.participacoes?.length > 1) {
            setParticipacoes(
              Array.from(Array(res?.participacoes?.length - 1).keys())
            );
          }

          formRef.current.setData(res);
        } catch (error) {}
        Swal.close();
      }
    }

    getData();
  }, []);

  return (
    <>
      <Row>
        <Col lg={11} className='mb-1'>
          <div className='justify-between'>
            <h5 className='txt-tertiary'>
              Criar cartão {params?.tipo === 'empresarial' && 'empresarial'}{' '}
            </h5>
          </div>
        </Col>
      </Row>
      <Form onSubmit={handleSubmit} className='card-form' ref={formRef}>
        <Row>
          <Col lg={3}>
            <Row>
              <Col>
                <span className='placholder-top'>Imagem cartão</span>
                <ImagePreview
                  name='file'
                  handleDeleteImage={(cb) => handleDeleteImage(cb)}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <span className='placholder-top'>Parceiro WhiteLabel</span>
                <UnformAsync
                  name='parceiro'
                  placeholder={''}
                  isClearable
                  noOptionsMessage={() => 'Digite para pesquisar'}
                  loadOptions={(value) =>
                    debounceConsultaParceiro({
                      value,
                      param: 'nome',
                    })
                  }
                />
              </Col>
            </Row>
          </Col>
          <Col lg={9}>
            <Row>
              <Col lg={4} className='mb-2'>
                <span className='placholder-top'>Nome do cartão</span>
                <Input
                  className='input-field-inner'
                  placeholder='Nome, Ex: Cartão PoupaClub'
                  name='nome'
                  type='text'
                />
              </Col>
              <Col lg={4} className='mb-2'>
                <span className='placholder-top'>Label</span>
                <Input
                  className='input-field-inner'
                  placeholder='Tipo, Ex: Empresarial'
                  name='tipo'
                  type='text'
                />
              </Col>
              {params?.tipo === 'empresarial' && (
                <Col lg={4} className='mb-2'>
                  <span className='placholder-top'>
                    Quantidade de funcionários
                  </span>
                  <Input
                    className='input-field-inner'
                    placeholder='Quantidade de funcionários'
                    name='numero_funcionarios'
                    type='number'
                  />
                </Col>
              )}
              <Col lg={4} className='mb-2'>
                <span className='placholder-top'>Possui cônjuge</span>
                <UnformSelect
                  type='select'
                  className='input-field-select-inner'
                  name='conjuge'
                  options={[
                    {
                      value: '',
                      label: 'Possui cônjuge',
                    },
                    {
                      value: 'false',
                      label: 'Não',
                    },
                    {
                      value: 'true',
                      label: 'Sim',
                    },
                  ]}
                />
              </Col>
              <Col lg={4} className='mb-2'>
                <span className='placholder-top'>Quantidade dependentes</span>

                <Input
                  className='input-field-inner'
                  placeholder='Quantidade dependentes'
                  name='numero_dependentes'
                  type='number'
                  min='0'
                  max='99'
                />
              </Col>
              <Col lg={4} className='mb-2'>
                <span className='placholder-top'>Limite de idade?</span>

                <UnformSelect
                  type='select'
                  placeholder=''
                  className='input-field-select-inner '
                  name='limitar_idade'
                  options={[
                    {
                      value: '',
                      label: 'Limite de idade?',
                    },
                    {
                      value: 'true',
                      label: 'Sim',
                    },
                    {
                      value: 'false',
                      label: 'Não',
                    },
                  ]}
                />
              </Col>
              <Col lg={4} className='mb-2'>
                <span className='placholder-top'>Idade limite</span>
                <Input
                  className='input-field-inner'
                  placeholder='Idade limite'
                  name='idade_limite'
                  type='number'
                />
              </Col>
              <Col lg={4} className='mb-2'>
                <span className='placholder-top'>% Desc. Boleto</span>
                <Input
                  className='input-field-inner '
                  placeholder='% Desc. Boleto'
                  name='desconto_boleto'
                  type='number'
                  min='0'
                  max='100'
                />
              </Col>
              <Col lg={4} className='mb-2'>
                <span className='placholder-top'>Valor cartão</span>

                <Input
                  className='input-field-inner '
                  placeholder='Valor cartão'
                  name='valor'
                  step='any'
                  type='number'
                  min='1'
                />
              </Col>
            </Row>
            {params?.tipo === 'empresarial' && (
              <Scope path={`participacoes[0]`}>
                <Row>
                  <Col lg={12} className='mb-3'>
                    <h4 className='mt-3 txt-tertiary'>Split customizado</h4>
                    <code>
                      Caso informado o split customizado, o{' '}
                      <b>usuário split comun</b> será ignorado na venda.
                    </code>
                  </Col>
                  <Col lg={4} md={6} sm={8} className='mb-2'>
                    <span className='placholder-top'>Parceiro/revendedor</span>
                    <UnformAsync
                      name='usuario'
                      fullValue
                      placeholder={'Nome'}
                      noOptionsMessage={() => 'Digite para pesquisar'}
                      isClearable
                      loadOptions={(value) =>
                        debounceConsultaRepresentantes({
                          value,
                          param: 'nome',
                        })
                      }
                    />
                  </Col>

                  <Col lg={4} md={4} sm={4} className='mb-2'>
                    <span className='placholder-top'>Participação %</span>
                    <InputGroup>
                      <div className='input--40'>
                        <Input
                          className='input-field-inner height-40'
                          placeholder='%'
                          name='participacao'
                          step='any'
                          type='number'
                          min='0.01'
                        />
                      </div>

                      <InputGroupAddon
                        addonType='prepend'
                        style={{
                          borderTopRightRadius: 5,
                          borderBottomRightRadius: 5,
                          maxHeight: 'calc(1.5em + 0.55rem + 6px) !important',
                        }}
                      >
                        <div className='height-btn-group'>
                          <Button
                            color='primary'
                            size='sm'
                            onClick={addParticipacao}
                            className='h-100'
                          >
                            <FaPlus color='#fff' />
                          </Button>
                        </div>
                      </InputGroupAddon>
                    </InputGroup>
                  </Col>
                </Row>
              </Scope>
            )}

            {participacoes.map((m, i) => (
              <Scope path={`participacoes[${i + 1}]`}>
                <Row>
                  <Col lg={4} md={6} sm={8} className='mb-2'>
                    <span className='placholder-top'>Parceiro/revendedor</span>
                    <UnformAsync
                      name='usuario'
                      fullValue
                      placeholder={'Nome'}
                      noOptionsMessage={() => 'Digite para pesquisar'}
                      isClearable
                      loadOptions={(value) =>
                        debounceConsultaRepresentantes({
                          value,
                          param: 'nome',
                        })
                      }
                    />
                  </Col>

                  <Col lg={4} sm={4} className='mb-2'>
                    <span className='placholder-top'>Participação %</span>
                    <InputGroup>
                      <div>
                        <Input
                          className='input-field-inner height-40'
                          placeholder='%'
                          name='participacao'
                          step='any'
                          typFe='number'
                          min='0.01'
                        />
                      </div>
                      <InputGroupAddon
                        addonType='prepend'
                        style={{
                          borderTopRightRadius: 5,
                          borderBottomRightRadius: 5,
                          height: 'calc(1.5em + 0.55rem + 6px) !important',
                        }}
                      >
                        <div className='height-btn-group'>
                          <Button
                            color='danger'
                            size='sm'
                            onClick={() => delParticipacao(i)}
                            style={{
                              height: '100%',
                            }}
                          >
                            <FaTrash color='#fff' />
                          </Button>
                        </div>
                      </InputGroupAddon>
                    </InputGroup>
                  </Col>
                </Row>
              </Scope>
            ))}
          </Col>
        </Row>

        <Row className='mt-20'>
          <Col className='justify-end'>
            <Button color='green' className='btn-save mb-1'>
              Salvar
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default CriarCartao;
