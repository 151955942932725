import React from "react";
import {
  Row,
  Card,
  CardImg,
  CardBody,
  Button,
  UncontrolledCollapse,
} from "reactstrap";
import { GoChevronDown } from "react-icons/go";
import { useWhiteLabel } from "../contexts/whitelabel";

import CartaoEmpresarialIMG from "../assets/cartao_empresarial_poupa.jpg";

export default function CartaoEmpresarial({ data }) {
  const portalVendas =
    window.location.pathname === "/portal-venda" ||
    window.location.pathname === "/portal-venda/empresarial";

  const { dadosWhiteLabel } = useWhiteLabel();

  return (
    <Card className="cartao border-0">
      <CardImg
        top
        width="47%"
        src={
          dadosWhiteLabel?.empresarial_img?.url ||
          data?.imagem?.url ||
          CartaoEmpresarialIMG
        }
        alt={data?.nome || "Cartao PoupaClub"}
        className="cartao"
      />
      <CardBody>
        <Row>
          <p
            className="cartao-title"
            style={{
              color: dadosWhiteLabel
                ? dadosWhiteLabel?.whitelabel_text_color_1
                : "",
            }}
          >
            {dadosWhiteLabel
              ? data?.nome || "Cartão de descontos"
              : "Cartão PoupaClub"}{" "}
            &nbsp;&nbsp;
          </p>
          <span className="cartao-span-empresarial">{"EMPRESARIAL"}</span>
        </Row>
        <Row>
          <p
            className="detalhes-card cartao-title"
            style={{
              color: dadosWhiteLabel
                ? dadosWhiteLabel?.whitelabel_text_color_3
                : "",
            }}
          >
            Funcionario customizados
          </p>
        </Row>
        <Row>
          <p
            className="cartao-title detalhes-card"
            style={{
              color: dadosWhiteLabel
                ? dadosWhiteLabel?.whitelabel_text_color_3
                : "",
            }}
          >
            Dependentes customizados&nbsp;
          </p>
        </Row>
        <Row>
          <p
            className="detalhes-card"
            style={{
              color: dadosWhiteLabel
                ? dadosWhiteLabel?.whitelabel_text_color_3
                : "",
            }}
          >
            Validade de 12 meses
          </p>
        </Row>
        <br />
        <Row>
          <p className="detalhes-card" />
        </Row>
        <Row>
          <p className="detalhes-card" />
        </Row>
        <Row>
          <p className="detalhes-card" />
        </Row>
        <Row hidden={portalVendas}>
          <Button id="toggler-2" className="btn-benefdesc" size="sm" block>
            <GoChevronDown
              size={35}
              style={{
                color: dadosWhiteLabel
                  ? dadosWhiteLabel?.whitelabel_arrows
                  : "",
              }}
            />
            <span
              style={{
                color: dadosWhiteLabel
                  ? dadosWhiteLabel?.whitelabel_arrows
                  : "",
              }}
            >
              Benefícios e descontos
            </span>
          </Button>
        </Row>
        <Row hidden={portalVendas}>
          <UncontrolledCollapse
            toggler="#toggler-2"
            className="benef_list"
            isOpen={true}
          >
            <ul
              className="striped-list"
              style={{
                color: dadosWhiteLabel
                  ? dadosWhiteLabel?.whitelabel_text_color_3
                  : "",
              }}
            >
              <li>Consultas médicas com até 60% de desconto</li>
              <li>Exames médicos com até 70% de desconto</li>
              <li>
                <span>Até 70% de </span>desconto em medicamentos
              </li>
              <li>
                Descontos em consultas Veterinárias
                <span> e Pet Shop</span>
              </li>
              <li>
                <span>Mensalidades com </span>
                desconto em academias
              </li>
              <li>+ de 500 parceiros com + de 2000 lojas pelo Brasil</li>
            </ul>
          </UncontrolledCollapse>
        </Row>
      </CardBody>
    </Card>
  );
}
