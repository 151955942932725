import React, { useRef } from "react";
import { Container, Col, Row, Button } from "reactstrap";
import { GoChevronDown } from "react-icons/go";
import { Form } from "@unform/web";
import Input from "../../components/unformInput";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import MaskedInput from "../../components/unformMaskedInput";
import { ValidationError } from "yup";
import ReactHtmlParser from "react-html-parser";

import api from "../../services/api";
import { phoneMask, horarioMask } from "../../utils/masks";

import sejaParceiroValidator from "../../validations/sejaParceiroValidator";

import imagemParceiro from "../../assets/sejaparceiro.png";

import { useWhiteLabel } from "../../contexts/whitelabel";

function SejaParceiro() {
  const formRef = useRef(null);
  const history = useHistory();

  const { dadosWhiteLabel } = useWhiteLabel();

  async function handleSubmit(data) {
    try {
      formRef.current.setErrors({});
      await sejaParceiroValidator(data);
      Swal.fire({
        title: "Por favor, aguarde...",
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      data.tipo = "P";
      const url = window.location.pathname.split("/");
      if (url[1] !== "poupa-club") {
        data.whitelabel = url[1];
      }
      await api.post("/email", data);
      Swal.close();
      window.scrollTo(0, 0);
      return history.push(
        `/${
          dadosWhiteLabel?.url_whitelabel || "poupa-club"
        }/obrigado-pelo-interesse`
      );
    } catch (err) {
      const validationErrors = {};
      Swal.close();
      if (err instanceof ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
        toast.error("Verifique os campos em vermelho!");
      } else {
        toast.error("Ocorreu um erro tente novamente mais tarde!");
      }
    }
  }

  return (
    <Container>
      <Row className="mt-60 txt-primary">
        <Col lg={4} className="mb-1">
          <img
            className="img-lado"
            src={
              dadosWhiteLabel?.imagem_seja_parceiro_img?.url
                ? dadosWhiteLabel?.imagem_seja_parceiro_img?.url
                : imagemParceiro
            }
            alt="Seja parceiro"
          />
        </Col>
        <Col lg={4} className="my-1">
          <h2
            className="h2-title"
            style={{
              fontSize: 45,
              color: dadosWhiteLabel
                ? dadosWhiteLabel?.whitelabel_text_color_1
                : "",
            }}
          >
            Vantagens <br /> de ser nosso parceiro(a)
          </h2>
          <GoChevronDown
            style={{
              color: dadosWhiteLabel ? dadosWhiteLabel?.whitelabel_arrows : "",
            }}
            size={40}
            color="orange"
            className="mt-1 mb-1 ml-2"
          />
          {!dadosWhiteLabel ? (
            <p className="txt-poupaclub-2">
              O PoupaClub{" "}
              <strong>
                possui mais de 3 mil clientes ativos que utilizam o cartão de
                descontos
              </strong>
              . É uma ótima alternativa para aumentar suas vendas, atrair e
              fidelizar consumidores. Incentive os compradores de primeira
              viagem a comprar ao mesmo tempo que turbina suas vendas como um
              todo.
              <br />
              <br />
              <strong>
                Sua empresa, organização ou escola pode ganhar um diferencial ao
                oferecer vantagens e descontos exclusivos!
              </strong>
            </p>
          ) : (
            <p className="txt-poupaclub-2">
              {ReactHtmlParser(dadosWhiteLabel?.texto_seja_parceiro)}
            </p>
          )}
        </Col>
        <Col lg={4} className="my-1">
          <Row className="justify-center mb-3">
            <Col>
              <h3
                className="txt-secondary bold txt-solicite"
                style={{
                  color: dadosWhiteLabel
                    ? dadosWhiteLabel?.whitelabel_text_color_4
                    : "",
                }}
              >
                Solicite o contato de nossa equipe
              </h3>
            </Col>
          </Row>
          <Form
            className="form-poupaclub mt-3"
            onSubmit={handleSubmit}
            ref={formRef}
          >
            <Row>
              <Col>
                <Input
                  className="input-field"
                  placeholder="Empresa"
                  name="empresa"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  className="input-field"
                  placeholder="Responsável"
                  name="responsavel"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  className="input-field"
                  placeholder="Cidade"
                  name="cidade"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  className="input-field"
                  placeholder="Segmento"
                  name="segmento"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <MaskedInput
                  className="form-control input-field"
                  placeholder="Telefone / Celular com DDD"
                  mask={phoneMask}
                  name="telefone"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  className="input-field"
                  placeholder="E-mail"
                  name="email"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <MaskedInput
                  className="form-control input-field"
                  placeholder="Qual o melhor horário para entrarmos em contato?"
                  mask={horarioMask}
                  name="horario"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="justify-center my-3">
                  <Button
                    color="secondary"
                    size="sm"
                    className={
                      dadosWhiteLabel
                        ? "btn-pill mb-3 bold btn-enviar-agendamento " +
                          dadosWhiteLabel?.whitelabel_button_3
                        : "btn-pill mb-3 bold btn-enviar-agendamento"
                    }
                  >
                    Enviar
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default SejaParceiro;
