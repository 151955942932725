import pt from 'date-fns/locale/pt-BR';
import { useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { FaChevronLeft, FaChevronRight, FaPlus } from 'react-icons/fa';
import { FiFilter } from 'react-icons/fi';
import Pagination from 'react-paginate';
import {
  Button,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledCollapse,
  UncontrolledDropdown,
  UncontrolledPopover,
} from 'reactstrap';
import Swal from 'sweetalert2';
import api from '../../../services/api';
import formatarDinheiro from '../../../utils/formatarDinheiro';
import monthNames from '../../../utils/nomesMes';
import { periodoDataLabel } from '../../../utils/periodoData';
registerLocale('pt-BR', pt);

const formatDate = (date) => new Intl.DateTimeFormat('en-US').format(date);

function Faturas() {
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [page, setPage] = useState(0);
  const [data, setData] = useState(null);

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  async function gerarSegundaVia(id) {
    try {
      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      const { data: res } = await api.get(`/segunda-via/${id}`);
      Swal.close();
      window.open(res.parcela);
    } catch (err) {
      Swal.fire({
        icon: 'error',
        title: 'Ops!',
        text: err?.response?.data?.error || 'Ocorreu um erro, tente novamente!',
      });
    }
  }

  useEffect(() => {
    async function getParcelas() {
      try {
        let url = `/parcelas?page=${page + 1}`;
        if (startDate && endDate) {
          url += `&startDate=${formatDate(startDate)}&endDate=${formatDate(
            endDate
          )}`;
        }

        const { data: res } = await api.get(url);
        setData(res);
      } catch (error) {}
    }
    getParcelas();
  }, [startDate, endDate, page]);

  return (
    <>
      <Row>
        <Col lg={11} className='mb-1'>
          <div className='justify-between'>
            <h5 className='txt-tertiary'>Parcelas</h5>
          </div>
        </Col>
      </Row>
      <div className='card-form p-0'>
        <div className='p-3 justify-content-between d-flex align-center'>
          <p className='txt-muted'>Total de registros: {data?.total || 0}</p>
          <Button color='muted' id='toggler'>
            <FiFilter /> Filtros
          </Button>
          <div />
        </div>
        <div className='filter-collapse'>
          <UncontrolledCollapse toggler='#toggler'>
            <span className='d-flex'>
              <Button
                id='periodo_parcelas'
                type='button'
                color='link'
                className='ml-1'
              >
                {periodoDataLabel({ startDate, endDate })}
                <span className='small ml-1'>
                  <small>▼</small>
                </span>
              </Button>
              <UncontrolledPopover
                placement='bottom'
                target='#periodo_parcelas'
                trigger='legacy'
                hideArrow
              >
                <DatePicker
                  selected={startDate}
                  onChange={onChange}
                  startDate={startDate}
                  endDate={endDate}
                  locale='pt-BR'
                  selectsRange
                  inline
                />
              </UncontrolledPopover>
            </span>
          </UncontrolledCollapse>
        </div>
        <Table responsive>
          <thead>
            <tr>
              <th>Data</th>
              <th>Status</th>
              <th>R$</th>
              <th className='text-end'>
                <h4 className='m-0 p-0'>...</h4>
              </th>
            </tr>
          </thead>

          <tbody>
            {data?.parcelas?.map((parcela) => {
              const dataParcela = new Date(parcela.data_parcela);
              const dataVencimento = new Date([
                new Date(parcela.data_parcela).toISOString().split('T'),
              ]);
              const dataAtual = new Date([new Date().toISOString().split('T')]);

              const statusParcela =
                parcela.status === 'PAID'
                  ? 'PAID'
                  : dataVencimento.getTime() < dataAtual.getTime()
                  ? 'EXPIRED'
                  : 'ACTIVE';

              return (
                <tr>
                  <td>
                    {monthNames[dataParcela.getMonth()]} /{' '}
                    {dataParcela.getFullYear()}
                  </td>
                  <td>
                    {statusParcela === 'EXPIRED' ? (
                      <div className='txt-danger'>Atrasada</div>
                    ) : statusParcela === 'ACTIVE' ? (
                      <div>Pendente</div>
                    ) : (
                      <div className='txt-primary'>Paga</div>
                    )}
                  </td>
                  <td>{formatarDinheiro.format(parcela.valorparcela)}</td>
                  <td>
                    {statusParcela !== 'PAID' &&
                      parcela?.Pedido?.CondicaoPagamento?.descricao.includes(
                        'BOLETO'
                      ) && (
                        <div className='justify-end'>
                          <UncontrolledDropdown color='link' size='sm'>
                            <DropdownToggle color='link'>
                              <FaPlus color='#707070' />
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                onClick={() => gerarSegundaVia(parcela.id)}
                              >
                                Gerar 2° via de boleto
                              </DropdownItem>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                      )}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </Table>
        <Pagination
          nextLabel={<FaChevronRight />}
          previousLabel={<FaChevronLeft />}
          pageCount={data?.pages || 1}
          onPageChange={({ selected }) => setPage(selected)}
          initialPage={page}
          containerClassName={'pagination justify-end'}
          activeClassName={'active'}
        />
      </div>
    </>
  );
}

export default Faturas;
