import React, { useRef, useState } from 'react';
import { Form } from '@unform/web';
import { Row, Col, Button } from 'reactstrap';
import { ValidationError } from 'yup';
import cep from 'cep-promise';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

import AsyncSelect from '../../../components/AsyncSelect';
import MaskedInput from '../../../components/unformMaskedInput';
import InputUnform from '../../../components/unformInput';
import UnformRadio from '../../../components/unformRadio';

import api from '../../../services/api';

import { cepMask, telefoneCelularMask, cnpjMask } from '../../../utils/masks';

import criarRevendedorValidator from '../../../validations/criarRevendedorValidator';

let ultimoCep = '';

function CriarRevendedor(props) {
  let timeoutConsultaParceiro;
  const [key, setKey] = useState(null);
  const [parceiro, setParceiro] = useState(null);
  const [terceiro, setTerceiro] = useState(null);

  const formRef = useRef(null);

  async function handleSubmit(data) {
    formRef.current.setErrors([]);
    try {
      await criarRevendedorValidator(data);

      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });

      data.tipo = 'R';
      const retorno = await api.post('/valida-email-cpf-cnpj', data);

      if (retorno.data === true) {
        if (parceiro && data.superior === 'terceiro') {
          data.responsavel = parceiro?.value;
        }
        await api.post(`/revendedor`, data);

        Swal.close();
        toast.success('Revendedor cadastrado com sucesso');
        formRef.current.reset();
        functionThatResetsFileds();
      } else {
        Swal.close();
        toast.error(retorno.data.error);
      }
    } catch (err) {
      const validationErrors = {};
      Swal.close();
      if (err instanceof ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
        toast.error('Verifique os campos em vermelho!');
      } else {
        toast.error('Ocorreu um erro tente novamente mais tarde!');
      }
    }
  }

  async function buscaCep(cepValue) {
    if (cepValue) {
      if (ultimoCep !== cepValue) {
        try {
          if (cepValue.replaceAll('_', '').length === 9) {
            const result = await cep(cepValue);

            formRef.current.setData({
              ...formRef.current.getData(),
              bairro: result.neighborhood,
              cidade: result.city,
              estado: result.state,
              logradouro: result.street,
            });
            ultimoCep = cepValue;
          } else if (cepValue.length === 0) {
            formRef.current.setData({
              ...formRef.current.getData(),
              bairro: '',
              cidade: '',
              estado: '',
              logradouro: '',
            });
          }
        } catch (error) {
          formRef.current.setData({
            ...formRef.current.getData(),
            bairro: '',
            cidade: '',
            estado: '',
            logradouro: '',
          });
          toast.warn('CEP não encontrado!');
        }
      }
    }
  }

  function functionThatResetsFileds() {
    let randomString = Math.random().toString(36);

    setKey({
      theInputKey: randomString,
    });
  }

  const debounceConsultaParceiro = async (body) => {
    return await new Promise((resolve) => {
      clearTimeout(timeoutConsultaParceiro);

      timeoutConsultaParceiro = setTimeout(() => {
        resolve(filtrarParceiro(body));
      }, 500);
    });
  };

  async function filtrarParceiro({ value, param }) {
    const result = await api.get(`options/whitelabel?${param}=${value}`);
    return result.data.map((parceiro) => ({
      value: parceiro.id,
      nome: parceiro.nome,
    }));
  }

  return (
    <>
      <Row>
        <Col lg={7}>
          <h5 className='txt-tertiary'>Criar revendedor</h5>
        </Col>
      </Row>
      <div className='card-form'>
        <Form
          onSubmit={(data) => {
            handleSubmit(data);
          }}
          ref={formRef}
        >
          <Row>
            <Col lg={7}>
              <span className='placholder-top'>Razão social</span>
              <InputUnform
                placeholder=''
                type='text'
                name='razao_social'
                className='input-field-inner'
              />
            </Col>
            <Col lg={5}>
              <span className='placholder-top'>Nome fantasia</span>
              <InputUnform
                placeholder=''
                type='text'
                name='nome_fantasia'
                className='input-field-inner'
              />
            </Col>
          </Row>
          <Row>
            <Col lg={5}>
              <span className='placholder-top'>CNPJ</span>
              <MaskedInput
                className='input-field-inner'
                type='text'
                mask={cnpjMask}
                removeEspecial
                name='cnpj'
                key={(key && key.theInputKey) || ''}
              />
            </Col>
            <Col lg={7}>
              <span className='placholder-top'>Nome do responsável</span>
              <InputUnform
                type='text'
                name='nome_responsavel'
                className='input-field-inner'
              />
            </Col>
          </Row>
          <Row>
            <Col lg={3} sm={6} xs={6}>
              <span className='placholder-top mb-2'>                      % Participação venda direta
</span>
              <InputUnform
                placeholder=''
                type='number'
                min={1}
                max={100}
                step='0.01'
                name='percentual'
                className='input-field-inner'
                defaultValue={1}
              />
            </Col>
            <Col lg={3} sm={6} xs={6}>
              <span className='placholder-top mb-2'>
                % de participação split
              </span>
              <InputUnform
                type='number'
                min={1}
                max={100}
                step='0.01'
                name='porcentagem_venda'
                className='input-field-inner'
              />
            </Col>
            <Col lg={3} sm={6} xs={6}>
              <label className='placholder-top w-100 mb-0'>Superior</label>
              <div>
                <UnformRadio
                  name='superior'
                  options={[
                    { id: 'poupa', value: 'poupa', label: ' PoupaClub' },
                    { id: 'terceiro', value: 'terceiro', label: ' Terceiro' },
                  ]}
                  onChange={(e) => setTerceiro(e.target.value)}
                  optionsClass='mr-3'
                />
              </div>
            </Col>
            <Col lg={3} sm={12} hidden={terceiro !== 'terceiro'}>
              <span className='placholder-top'>Responsável</span>
              <AsyncSelect
                loadOptions={(value) =>
                  debounceConsultaParceiro({
                    value,
                    param: 'nome',
                  })
                }
                name='marca'
                marca={true}
                getOptionLabel={(option) => option.nome}
                onChange={setParceiro}
                value={parceiro}
                cacheOptions
                isClearable
                placeholder={'Parceiro WhiteLabel'}
                noOptionsMessage={() => 'Digite para pesquisar'}
              />
            </Col>
          </Row>

          <Row>
            <Col lg={5}>
              <span className='placholder-top'>CEP</span>
              <MaskedInput
                className='input-field-inner'
                mask={cepMask}
                removeEspecial
                name='cep'
                onChange={(e) => {
                  buscaCep(e.target.value);
                }}
                key={(key && key.theInputKey) || ''}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={7}>
              <span className='placholder-top'>Endereço</span>
              <InputUnform
                placeholder=''
                type='text'
                name='logradouro'
                className='input-field-inner'
              />
            </Col>
            <Col lg={2}>
              <span className='placholder-top'>Nº</span>
              <InputUnform
                placeholder=''
                type='text'
                name='numero'
                className='input-field-inner'
              />
            </Col>
            <Col lg={3}>
              <span className='placholder-top'>Complemento</span>
              <InputUnform
                placeholder=''
                type='text'
                name='complemento'
                className='input-field-inner'
              />
            </Col>
          </Row>
          <Row>
            <Col lg={5}>
              <span className='placholder-top'>Bairro</span>
              <InputUnform
                placeholder=''
                type='text'
                name='bairro'
                className='input-field-inner'
              />
            </Col>
            <Col lg={5}>
              <span className='placholder-top'>Cidade</span>
              <InputUnform
                placeholder=''
                type='text'
                name='cidade'
                className='input-field-inner'
              />
            </Col>
            <Col lg={2}>
              <span className='placholder-top'>Estado</span>
              <InputUnform
                placeholder=''
                type='text'
                name='estado'
                className='input-field-inner'
              />
            </Col>
          </Row>
          <Row>
            <Col lg={5}>
              <span className='placholder-top'>E-mail</span>
              <InputUnform
                placeholder=''
                type='text'
                name='email'
                className='input-field-inner'
              />
            </Col>
          </Row>
          <Row>
            <Col lg={5}>
              <span className='placholder-top'>Celular</span>
              <MaskedInput
                placeholder=''
                className='input-field-inner'
                name='telefone_celular'
                mask={telefoneCelularMask}
                removeEspecial
                key={(key && key.theInputKey) || ''}
              />
            </Col>
            <Col lg={5}>
              <span className='placholder-top'>Comercial</span>
              <MaskedInput
                className='input-field-inner'
                name='telefone_comercial'
                mask={telefoneCelularMask}
                removeEspecial
                key={(key && key.theInputKey) || ''}
              />
            </Col>
          </Row>
          <Row className='mt-20'>
            <Col className='justify-end'>
              <Button color='green' className='btn-save mb-1 bold'>
                Criar
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}

export default CriarRevendedor;
