const segmentos = [
  { nome: "Alimentação", url: "alimentacao" },
  { nome: "Bares e Restaurantes", url: "bares-e-restaurantes" },
  { nome: "Buffets e Eventos", url: "buffets-e-eventos" },
  {
    nome: "Clinicas Médicas e Laboratórios",
    url: "clinicas-medicas-e-laboratorios",
  },
  { nome: "Clinicas Odontológicas", url: "clinicas-odontologicas" },
  { nome: "Clinicas Oftalmológicas", url: "clinicas-oftalmologicas" },
  { nome: "Clinicas Veterinárias e Pet", url: "clinicas-veterinarias-e-pet" },
  { nome: "Educação", url: "educacao" },
  { nome: "Indústria", url: "industria" },
  { nome: "Informática e Games", url: "informatica-e-games" },
  { nome: "Joalherias", url: "joalherias" },
  { nome: "Móveis e Decoração", url: "moveis-e-decoracao" },
  { nome: "Roupas e Acessórios", url: "roupas-e-acessorios" },
  { nome: "Saúde e Beleza", url: "saude-e-beleza" },
  { nome: "Serviços", url: "servicos" },
  { nome: "Shows e Eventos", url: "shows-e-eventos" },
  { nome: "Vestuário", url: "vestuario" },
  { nome: "Veículos", url: "veiculos" },
  { nome: "Viagem e Hospedagem", url: "viagem-e-hospedagem" },
  { nome: "Vendas Online", url: "venda-online" },
  { nome: "Material de Construção", url: "material-construcao" },
];
export default segmentos;
