import { Button, Container } from 'reactstrap';
import logo1 from '../assets/logo-1.svg';
import logo2 from '../assets/logo-2.svg';
import { Link, useHistory } from 'react-router-dom';
import Sidebar from '../components/sidebar';
import { useAuth } from '../contexts/auth';
import { useWhiteLabel } from '../contexts/whitelabel';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import {
  FaFacebookF,
  FaInstagram,
  FaWhatsapp,
  FaLinkedin,
} from 'react-icons/fa';

function Default({ children }) {
  const { dadosWhiteLabel, loading } = useWhiteLabel();
  const { uppo } = useAuth();

  if (window.location.pathname.split('/')[1] === 'acesso') {
    return (
      <div className='loader-adm' hidden={!loading}>
        <div class='loader' />
      </div>
    );
  }
  let history = useHistory();
  const { signed, signOut, user } = useAuth();

  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  return (
    <>
      <div class='loader' hidden={!loading} />
      <header style={{ color: dadosWhiteLabel?.cor_menu }} hidden={loading}>
        <Container>
          <div className='blocks-menus'>
            {!dadosWhiteLabel ? (
              <Link to='/' onClick={scrollToTop} className='logo-header'>
                <img src={logo1} className='img-logo' alt='Poupa club' />
              </Link>
            ) : (
              <Link
                to={`/${dadosWhiteLabel?.url_whitelabel}`}
                onClick={scrollToTop}
                className='logo-header'
              >
                <img
                  src={dadosWhiteLabel?.whitelabel_logo_img?.url}
                  className='img-logo'
                  alt='Poupa club'
                />
              </Link>
            )}
            <div className='menu-links'>
              <ul className='menu'>
                <li className='li-desconto'>
                  <div className='txt-menu'>Descontos</div>
                  <ul className={dadosWhiteLabel ? 'submenu-2' : 'submenu'}>
                    <li>
                      <Link
                        to={`/${
                          dadosWhiteLabel?.url_whitelabel || 'poupa-club'
                        }/cartao`}
                        onClick={scrollToTop}
                      >
                        Cartão
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`/${
                          dadosWhiteLabel?.url_whitelabel || 'poupa-club'
                        }/beneficios-descontos`}
                        onClick={scrollToTop}
                      >
                        Benefícios
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`/${
                          dadosWhiteLabel?.url_whitelabel || 'poupa-club'
                        }/desconto-farmacia`}
                        onClick={scrollToTop}
                      >
                        Desconto Farmácia
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`/${
                          dadosWhiteLabel?.url_whitelabel || 'poupa-club'
                        }/beneficios-descontos/#onde-e-como-usar`}
                        onClick={scrollToTop}
                      >
                        Como usar
                      </Link>
                    </li>
                    <li>
                      <Link
                        to={`/${
                          dadosWhiteLabel?.url_whitelabel || 'poupa-club'
                        }/beneficios-descontos/#clube-de-descontos`}
                        onClick={scrollToTop}
                      >
                        Clube de Descontos
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className='li-parceiro'>
                  <div className='txt-menu'>Parceiros</div>
                  <ul className={dadosWhiteLabel ? 'submenu-2' : 'submenu'}>
                    <li>
                      <button
                        className='btn-none'
                        onClick={() => {
                          uppo();
                        }}
                      >
                        Consulte nossos parceiros
                      </button>
                    </li>
                    <li>
                      <Link
                        to={`/${
                          dadosWhiteLabel?.url_whitelabel || 'poupa-club'
                        }/seja-parceiro`}
                        onClick={scrollToTop}
                      >
                        Seja nosso parceiro
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className='li-revendedor'>
                  <div className='txt-menu'>Revendedores</div>
                  <ul className={dadosWhiteLabel ? 'submenu-2' : 'submenu'}>
                    <li>
                      <Link
                        to={`/${
                          dadosWhiteLabel?.url_whitelabel || 'poupa-club'
                        }/seja-revendedor`}
                        onClick={scrollToTop}
                      >
                        Seja nosso revendedor
                      </Link>
                    </li>
                  </ul>
                </li>
                <li className='li-agendamento'>
                  <div className='txt-menu'>Solicite um agendamento</div>
                  <ul className={dadosWhiteLabel ? 'submenu-2' : 'submenu'}>
                    <li>
                      <Link
                        to={`/${
                          dadosWhiteLabel?.url_whitelabel || 'poupa-club'
                        }/agendar-consulta-exame`}
                        onClick={scrollToTop}
                      >
                        Avaliação odontológica e consultas médicas
                      </Link>
                    </li>
                  </ul>
                </li>
                <span
                  className='mr-3'
                  style={{
                    marginTop: -4,
                  }}
                >
                  <Button
                    color='secondary'
                    size='sm'
                    className={`btn-pill bold ${
                      dadosWhiteLabel?.whitelabel_button_1 &&
                      dadosWhiteLabel?.whitelabel_button_1
                    }`}
                    onClick={() => {
                      window.scrollTo(0, 0);
                      history.push(
                        `/${
                          dadosWhiteLabel?.url_whitelabel || 'poupa-club'
                        }/cartao`
                      );
                    }}
                  >
                    Peça Já
                  </Button>
                </span>
                <li className='li-entrar'>
                  {signed ? (
                    <div>
                      <UncontrolledDropdown>
                        <DropdownToggle
                          caret
                          color='link'
                          size='sm'
                          className='btn-pill bold'
                          style={{
                            marginTop: -4,
                            display: 'flex',
                            alignItems: 'center',
                          }}
                        >
                          <div
                            style={{
                              maxWidth: '80px',
                              textOverflow: 'ellipsis',
                              overflow: 'hidden',
                            }}
                          >
                            {user.nome}&nbsp;
                          </div>
                        </DropdownToggle>
                        <DropdownMenu right>
                          <DropdownItem
                            className='btn-pill bold dropdown-secondary'
                            onClick={() => {
                              history.push('/acesso/welcome');
                            }}
                          >
                            Acessos
                          </DropdownItem>
                          <DropdownItem divider />
                          <DropdownItem
                            className='btn-pill bold dropdown-danger'
                            onClick={signOut}
                          >
                            Sair
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                  ) : (
                    <>
                      <div className='txt-menu'>
                        <Link
                          className='login'
                          to={`/${
                            dadosWhiteLabel?.url_whitelabel || 'poupa-club'
                          }/login`}
                        >
                          Entrar
                        </Link>
                      </div>
                    </>
                  )}
                </li>
              </ul>
              <Sidebar
                pageWrapId={'page-wrap'}
                outerContainerId={'root'}
                right
              />
            </div>
          </div>
        </Container>
      </header>
      <main id='page-wrap' hidden={loading}>
        {children}
      </main>
      <footer
        style={{
          backgroundColor: dadosWhiteLabel?.whitelabel_footer_background,
          color: dadosWhiteLabel?.whitelabel_footer_text_color,
        }}
        hidden={loading}
      >
        <Container>
          <div className='footer'>
            {dadosWhiteLabel ? (
              <div>
                <p
                  style={{
                    color: dadosWhiteLabel?.whitelabel_footer_text_color,
                  }}
                >
                  Administrado por:
                </p>
                <div className='d-flex justify-content-center'>
                  <img
                    src={dadosWhiteLabel?.imagem_footer_img?.url || logo2}
                    alt='Poupa club'
                  />
                </div>
              </div>
            ) : (
              <img src={logo2} alt='Poupa club' />
            )}

            <div className='links-uteis'>
              <ul>
                <li>Acesso rápido</li>
                <li>
                  <Link
                    to={`/${dadosWhiteLabel?.url_whitelabel || 'poupa-club'}`}
                    style={{
                      color: dadosWhiteLabel?.whitelabel_footer_text_color,
                    }}
                    onClick={scrollToTop}
                  >
                    Home
                  </Link>
                </li>

                <li>
                  <Link
                    to={`/${
                      dadosWhiteLabel?.url_whitelabel || 'poupa-club'
                    }/cartao`}
                    style={{
                      color: dadosWhiteLabel?.whitelabel_footer_text_color,
                    }}
                    onClick={scrollToTop}
                  >
                    Descontos
                  </Link>
                </li>
                <li>
                  <Link
                    to={`/${
                      dadosWhiteLabel?.url_whitelabel || 'poupa-club'
                    }/agendar-consulta-exame`}
                    style={{
                      color: dadosWhiteLabel?.whitelabel_footer_text_color,
                    }}
                    onClick={scrollToTop}
                  >
                    Agendamento
                  </Link>
                </li>
              </ul>
              <ul>
                <li>
                  <button
                    className='btn-none'
                    style={{
                      color: dadosWhiteLabel?.whitelabel_footer_text_color,
                    }}
                    onClick={() => {
                      uppo();
                    }}
                  >
                    Parceiros
                  </button>
                </li>
              </ul>
              <ul>
                <li>
                  <Link
                    to={`/${
                      dadosWhiteLabel?.url_whitelabel || 'poupa-club'
                    }/seja-revendedor`}
                    style={{
                      color: dadosWhiteLabel?.whitelabel_footer_text_color,
                    }}
                    onClick={scrollToTop}
                  >
                    Revendedores
                  </Link>
                </li>
              </ul>
              <ul>
                <li>
                  <Link
                    to={`/${
                      dadosWhiteLabel?.url_whitelabel || 'poupa-club'
                    }/privacidade`}
                    style={{
                      color: dadosWhiteLabel?.whitelabel_footer_text_color,
                    }}
                    onClick={scrollToTop}
                  >
                    Política de privacidade
                  </Link>
                </li>
              </ul>
              <ul>
                <li>Nossas redes</li>
                {/* <li>
                  <a href="#">Blog</a>
                </li> */}
                <li className='d-flex mt-1'>
                  {dadosWhiteLabel ? (
                    <>
                      {dadosWhiteLabel?.instagram_whitelabel && (
                        <a
                          href={`${dadosWhiteLabel?.instagram_whitelabel}`}
                          target='_blank'
                          rel='noreferrer'
                        >
                          <FaInstagram
                            className='mr-1'
                            style={{
                              color:
                                dadosWhiteLabel?.whitelabel_footer_text_color,
                            }}
                          />
                        </a>
                      )}
                      {dadosWhiteLabel?.facebook_whitelabel && (
                        <a
                          href={`${dadosWhiteLabel?.facebook_whitelabel}`}
                          target='_blank'
                          rel='noreferrer'
                        >
                          <FaFacebookF
                            className='mr-1'
                            style={{
                              color:
                                dadosWhiteLabel?.whitelabel_footer_text_color,
                            }}
                          />
                        </a>
                      )}
                      {dadosWhiteLabel?.linkedin_whitelabel && (
                        <a
                          href={`${dadosWhiteLabel?.linkedin_whitelabel}`}
                          target='_blank'
                          rel='noreferrer'
                        >
                          <FaLinkedin
                            className='mr-1'
                            style={{
                              color:
                                dadosWhiteLabel?.whitelabel_footer_text_color,
                            }}
                          />
                        </a>
                      )}
                    </>
                  ) : (
                    <>
                      <a
                        href='https://www.instagram.com/poupaclub/'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <FaInstagram className='mr-1' />
                      </a>
                      <a
                        href='https://www.facebook.com/PoupaClubOficial/'
                        target='_blank'
                        rel='noreferrer'
                      >
                        <FaFacebookF className='ml-1' />
                      </a>
                    </>
                  )}
                </li>
              </ul>
              <ul>
                <li>Fale conosco</li>
                <li>
                  {dadosWhiteLabel ? (
                    <a
                      href='a'
                      style={{
                        color: dadosWhiteLabel?.whitelabel_footer_text_color,
                      }}
                    >
                      {dadosWhiteLabel?.parceiro_info?.telefone_comercial}
                    </a>
                  ) : (
                    <a href='https://api.whatsapp.com/send?phone=5511992657275&text=Olá%2C%20eu%20vim%20pelo%20site%20e%20gostaria%20de%20saber%20mais%20sobre%20o%20cartão%20PoupaClub.'>
                      11 99265-7275
                    </a>
                  )}
                </li>
                <li>
                  {dadosWhiteLabel ? (
                    <a
                      href={`mailto:${dadosWhiteLabel?.parceiro_info?.email}`}
                      style={{
                        color: dadosWhiteLabel?.whitelabel_footer_text_color,
                      }}
                    >
                      {dadosWhiteLabel?.parceiro_info?.email}
                    </a>
                  ) : (
                    <a href='mailto:contato@poupaclub.com.br?subject=Site%20PoupaClub'>
                      contato@poupaclub.com.br
                    </a>
                  )}
                </li>
                {dadosWhiteLabel ? (
                  <>
                    <li>
                      {dadosWhiteLabel?.parceiro_info?.cidade} -{' '}
                      {dadosWhiteLabel?.parceiro_info?.estado}
                    </li>
                  </>
                ) : (
                  <>
                    <li>Santo André - SP</li>
                    <li>De 2ª a 6ª feira das 8h às 18h</li>
                  </>
                )}
              </ul>
            </div>
          </div>
          <div className='justify-center text-center mt-3 xcoding'>
            Made with &nbsp;
            <span
              style={{
                color: 'red',
              }}
            >
              ❤
            </span>
            &nbsp; by &nbsp;
            <a
              href='https://xcoding.com.br?ref=poupaclub'
              rel='noreferrer'
              target='_blank'
              style={{
                color:
                  dadosWhiteLabel?.whitelabel_footer_text_color || '#151320',
                fontWeight: 600,
              }}
            >
              Xcoding
            </a>
          </div>
          {!dadosWhiteLabel && (
            <a
              href='https://api.whatsapp.com/send?phone=5511992657275&text=Ol%C3%A1%2C%20eu%20vim%20pelo%20site%20e%20gostaria%20de%20saber%20mais%20sobre%20o%20cart%C3%A3o%20PoupaClub.'
              class='float'
              rel='noreferrer'
              target='_blank'
            >
              <FaWhatsapp class='my-float' />
            </a>
          )}
        </Container>
      </footer>
    </>
  );
}

export default Default;
