import { useState, useEffect } from "react";
import {
  Col,
  Row,
  UncontrolledPopover,
  Button,
  Table,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import DatePicker from "react-datepicker";
import { TiExport } from "react-icons/ti";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import {
  startOfYear,
  endOfYear,
  startOfMonth,
  endOfMonth,
  format,
} from "date-fns";
import { ResponsiveLine } from "@nivo/line";
import xlsx from "json-as-xlsx";
import { ResponsiveBar } from "@nivo/bar";

import html2canvas from "html2canvas";
import jsPdf from "jspdf";

import { AiOutlineLineChart } from "react-icons/ai";
import { BsCardList } from "react-icons/bs";

import AsyncSelect from "../../../components/AsyncSelect";

import api from "../../../services/api";

function formatDate(date) {
  var d = new Date(date),
    month = "" + (d.getMonth() + 1),
    day = "" + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = "0" + month;
  if (day.length < 2) day = "0" + day;

  return [year, month, day].join("/");
}

function formatDataChartDeclinio(nnsortedArray) {
  const objectMonth = [
    {
      mes: "Jan.",
    },
    {
      mes: "Fev.",
    },
    {
      mes: "Mar.",
    },
    {
      mes: "Abr.",
    },
    {
      mes: "Mai.",
    },
    {
      mes: "Jun.",
    },
    {
      mes: "Jul.",
    },
    {
      mes: "Ago.",
    },
    {
      mes: "Set.",
    },
    {
      mes: "Out.",
    },
    {
      mes: "Nov.",
    },
    {
      mes: "Dez.",
    },
    {
      keys: [],
    },
  ];
  const array = nnsortedArray.sort((a, b) => a.mes - b.mes);
  array.forEach((item) => {
    objectMonth[item.mes - 1][item.representante] = item.vendas;
    if (!objectMonth[12].keys.includes(item.representante)) {
      objectMonth[12].keys.push(item.representante);
    }
  });

  return objectMonth;
}

function Dashboard() {
  let timeoutConsulta;

  const startMonth = startOfMonth(new Date());
  const endMonth = endOfMonth(new Date());

  const startYear = startOfYear(new Date());
  const endYear = endOfYear(new Date());

  const [periodo, setPeriodo] = useState({
    dataInicio: format(startMonth, "yyyy/MM/dd"),
    dataFim: format(endMonth, "yyyy/MM/dd"),
  });
  const [periodoYear, setPeriodoYear] = useState({
    dataInicio: format(startYear, "yyyy/MM/dd"),
    dataFim: format(endYear, "yyyy/MM/dd"),
  });
  const [modal, setModal] = useState(false);

  const [tipo, setTipo] = useState("");
  const [filtro, setFiltro] = useState(null);
  const [dataRepresentante, setDataRepresentante] = useState(null);

  const [selectedOpt, setSelectedOpt] = useState(null);
  const [dataForGraphs, setDataForGraphs] = useState([]);

  useEffect(() => {
    async function getDataRepresentante() {
      setDataRepresentante(null);
      try {
        Swal.fire({
          title: "Aguarde",
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });
        const body = {
          dataInicio: `'${periodo.dataInicio}'`,
          dataFim: `'${periodo.dataFim}'`,
          user: filtro ? filtro.value : null,
          tipo: tipo !== "" ? tipo : null,
        };
        const { data } = await api.post("/dashboard-representantes", body);
        if (data.length) {
          let dataArray = [];
          data.forEach((element) => {
            dataArray.push({
              name:
                String(element?.representante).length > 20
                  ? String(element?.representante).substring(0, 20) + "..."
                  : String(element?.representante),
              Clientes: Number(element?.clientes),
              ClientesFixed: 2,
              Valor: Number(element?.valor),
              ValorFixed:
                Number(element?.valor) <= 500
                  ? 5
                  : Number(element?.valor) / 100,
              Comissão: Number(element?.comissao),
              ComissaoFixed: 5,
            });
          });
          setDataRepresentante(dataArray);
        } else {
          setDataRepresentante(null);
        }
        Swal.close();
      } catch (err) {
        toast.error("Ocorreu um erro ao buscar dados!");
        Swal.close();
      }
    }
    getDataRepresentante();
  }, [periodo, tipo, filtro]);

  useEffect(() => {
    async function getDataGraphs() {
      setDataForGraphs([]);
      try {
        Swal.fire({
          title: "Aguarde",
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });
        if (selectedOpt === "EV") {
          const body = {
            dataInicio: `'${periodoYear.dataInicio}'`,
            dataFim: `'${periodoYear.dataFim}'`,
            tipo: tipo !== "" ? tipo : null,
            user: filtro ? filtro.value : null,
          };
          const { data } = await api.post(
            "/dashboard-representantes-evolucao",
            body
          );
          if (data) {
            setDataForGraphs(formatDataChartDeclinio(data));
          } else {
            setDataForGraphs([]);
          }
        } else if (selectedOpt === "AN") {
          const body = {
            dataInicio: `'${periodo.dataInicio}'`,
            dataFim: `'${periodo.dataFim}'`,
            tipo: tipo !== "" ? tipo : null,
            user: filtro ? filtro.value : null,
          };
          const { data } = await api.post(
            "/dashboard-representantes-analitico",
            body
          );
          if (data) {
            setDataForGraphs(data);
          } else {
            setDataForGraphs([]);
          }
        }
        Swal.close();
      } catch (e) {
        toast.error("Ocorreu um erro ao buscar dados!");
        Swal.close();
      }
    }
    getDataGraphs();
  }, [selectedOpt, periodo, tipo, filtro, periodoYear]);

  const toggle = (selected) => {
    setSelectedOpt(selected);
    setModal(!modal);
  };

  const onChangePeriodo = (dates) => {
    const [start, end] = dates;

    setPeriodo({
      start,
      end,
      dataInicio: start ? formatDate(start) : format(startMonth, "yyyy/MM/dd"),
      dataFim: end ? formatDate(end) : format(endMonth, "yyyy/MM/dd"),
    });
  };

  const debounceConsulta = async (body) => {
    return await new Promise((resolve) => {
      clearTimeout(timeoutConsulta);

      timeoutConsulta = setTimeout(() => {
        resolve(filtrarConsulta(body));
      }, 500);
    });
  };

  async function filtrarConsulta({ value, param }) {
    if (tipo === "P") {
      const result = await api.get(`options/parceiros?${param}=${value}`);
      return result?.data?.map((parceiro) => ({
        value: parceiro?.user,
        cnpj: parceiro?.cnpj,
        nome: parceiro?.nome,
      }));
    } else if (tipo === "R") {
      const result = await api.get(`options/revendedores?${param}=${value}`);
      return result?.data?.map((revendedor) => ({
        value: revendedor?.user,
        cnpj: revendedor?.cnpj,
        nome: revendedor?.nome,
      }));
    } else {
      const result = await api.get(`options/representantes?${param}=${value}`);
      return result.data.map((revendedor) => ({
        value: revendedor?.usuario_id,
        cnpj: revendedor?.cnpj,
        nome: revendedor?.nome_fantasia,
      }));
    }
  }

  const handleExport = () => {
    const columns = [
      {
        label: "Representante",
        value: (row) => (row.representante ? row.representante : ""),
      },
      {
        label: "Valor",
        value: (row) =>
          row.valor
            ? row.valor.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })
            : "",
      },
      {
        label: "Comissão",
        value: (row) =>
          row.comissao
            ? row.comissao.toLocaleString("pt-BR", {
                style: "currency",
                currency: "BRL",
              })
            : "",
      },
      {
        label: "Status",
        value: (row) => (row.status ? row.status : ""),
      },
      {
        label: "Cartão",
        value: (row) => (row.nome ? row.nome : ""),
      },
      {
        label: "Vendas",
        value: (row) => (row.venda ? row.venda : ""),
      },
    ];

    const settings = {
      sheetName: "FirstSheet",
      fileName: `relatorio-representantes-${format(new Date(), "dd-MM-yyyy")}`,
      extraLength: 3, // A bigger number means that columns should be wider
      writeOptions: {}, // Style options from https://github.com/SheetJS/sheetjs#writing-options
    };

    xlsx(columns, dataForGraphs.singleArray, settings, true);
  };

  const printPDF = () => {
    const domElement = document.getElementById("charts");
    html2canvas(domElement).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPdf({
        orientation: "l",
      });
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);
      pdf.save(`${new Date().toISOString()}.pdf`);
    });
  };

  return (
    <>
      <Row>
        <Col lg={3} className="d-flex justify-between align-center">
          <h5 className="txt-tertiary">Dashboard</h5>
        </Col>
        <Col lg={3} className="py-3">
          <select
            className="input-field-select-inner opts-select"
            name="tipo"
            style={{
              maxHeight: "38px !important",
            }}
            onChange={(e) => setTipo(e.target.value)}
          >
            <option value="">Todos</option>
            <option value="R">Revendedor</option>
            <option value="P">Parceiro</option>
          </select>
        </Col>
        <Col lg={3}>
          <AsyncSelect
            loadOptions={(value) =>
              debounceConsulta({
                value,
                param: "nome",
              })
            }
            getOptionLabel={(option) => option.nome}
            onChange={setFiltro}
            isClearable
            value={filtro}
            placeholder={"Busque pelo nome"}
            noOptionsMessage={() => "Digite o nome"}
            className="mt-3"
          />
        </Col>

        <Col lg={3}>
          <div className="mb-3 d-flex">
            <Button
              id="periodo_emissao"
              type="button"
              color="link"
              className="ml-1 mt-3 btn-block"
              style={{
                maxHeight: 38,
              }}
            >
              Período
            </Button>
            <UncontrolledPopover
              placement="bottom"
              target="#periodo_emissao"
              trigger="legacy"
              hideArrow
            >
              <DatePicker
                selected={periodo?.start}
                onChange={onChangePeriodo}
                startDate={periodo?.start}
                endDate={periodo?.end}
                locale="pt-BR"
                selectsRange
                inline
              />
            </UncontrolledPopover>
            <Button
              color="outline-primary"
              className="btn-save mt-3 ml-3 d-flex"
              onClick={() => printPDF()}
              size="md"
              style={{
                maxHeight: 38,
              }}
            >
              <TiExport size={20} />
              &nbsp;&nbsp;Exportar
            </Button>
          </div>
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="card-form">
            <Row className="justify-center">
              <Col lg={2} xs={12} sm={12}>
                <Button
                  style={{ textAlign: "center" }}
                  id="toggler-4"
                  className="btn-benefdesc"
                  size="sm"
                  onClick={() => {
                    toggle("EV");
                  }}
                  block
                >
                  <AiOutlineLineChart size={20} />
                  &nbsp;&nbsp;Evolução e declínio
                </Button>
              </Col>
              <Col lg={2} xs={12} sm={12}>
                <Button
                  style={{ textAlign: "center" }}
                  id="toggler-4"
                  className="btn-benefdesc"
                  size="sm"
                  onClick={() => {
                    toggle("AN");
                  }}
                  block
                >
                  <BsCardList size={20} />
                  &nbsp;&nbsp;Analítico
                </Button>
              </Col>
            </Row>
            <hr />
            <Row className="mt-3 ml-0">
              {dataRepresentante?.length ? (
                <StackedChart data={dataRepresentante} />
              ) : (
                <Col className="justify-center">
                  <h6 style={{ textAlign: "center", color: "gray" }}>
                    Sem registros nesse período
                  </h6>
                </Col>
              )}
            </Row>
          </div>
        </Col>
      </Row>
      <Modal isOpen={modal} size="xl">
        <ModalHeader toggle={toggle}>
          {selectedOpt === "EV" ? "Evolução e declínio" : "Analítico"}
        </ModalHeader>
        <ModalBody>
          <Row className="justify-end">
            {selectedOpt === "EV" ? (
              <LineChartView data={dataForGraphs} />
            ) : (
              <>
                {dataForGraphs?.singleArray?.length ? (
                  <Button
                    onClick={handleExport}
                    className="btn-save mb-2 d-flex mr-2"
                  >
                    <TiExport size={20} />
                    Exportar tabela
                  </Button>
                ) : (
                  <div></div>
                )}
                <div
                  style={{
                    maxHeight: "300px",
                    overflowY: "auto",
                    width: "100%",
                  }}
                >
                  <Table responsive>
                    <thead>
                      <tr>
                        <th>Representante</th>
                        <th className="justify-center">Valor</th>
                        <th>Comissão</th>
                        <th>Status</th>
                        <th>Cartão</th>
                        <th className="justify-center">Pedido</th>
                      </tr>
                    </thead>
                    <tbody>
                      {dataForGraphs?.singleArray?.map((rep) => (
                        <tr key={Math.random().toString(36).substring(7)}>
                          <td>{rep?.representante}</td>
                          <td className="justify-center">
                            {Number(rep?.valor)?.toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })}
                          </td>
                          <td>
                            {Number(rep?.comissao)?.toLocaleString("pt-BR", {
                              style: "currency",
                              currency: "BRL",
                            })}
                          </td>
                          <td>{rep?.status}</td>
                          <td>{rep?.nome}</td>
                          <td className="justify-center">{rep?.venda}</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </>
            )}
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
}

function StackedChart({ data }) {
  return (
    <>
      <Col className="mb-3 justify-center" lg={12}>
        <span
          style={{
            backgroundColor: "#7367f0",
            marginLeft: "15px",
            marginRight: "5px",
            marginTop: "2px",
            padding: "0px",
            borderRadius: "100px",
            color: "white",
            width: "15px",
            height: "15px",
            textAlign: "center",
            fontWeight: "bold",
          }}
        />
        Clientes
        <span
          style={{
            backgroundColor: "#28c76f",
            marginLeft: "15px",
            marginRight: "5px",
            marginTop: "2px",
            padding: "0px",
            borderRadius: "100px",
            color: "white",
            width: "15px",
            height: "15px",
            textAlign: "center",
            fontWeight: "bold",
          }}
        />
        Valor
        <span
          style={{
            backgroundColor: "#ea5455",
            marginLeft: "15px",
            marginRight: "5px",
            marginTop: "2px",
            padding: "0px",
            borderRadius: "100px",
            color: "white",
            width: "15px",
            height: "15px",
            textAlign: "center",
            fontWeight: "bold",
          }}
        />
        Comissões
      </Col>
      <ResponsiveContainer
        height={data?.length <= 2 ? 180 : data?.length * 90 + 10}
        width="100%"
      >
        <BarChart
          layout="vertical"
          data={data}
          margin={{
            top: 0,
            right: 50,
            left: 50,
            bottom: 50,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis type="number" tick={false} />
          <YAxis type="category" dataKey="name" />
          <Bar dataKey="ClientesFixed" stackId="a" fill="#7367f0">
            {/* <LabelList fill="white" position="insideTop" offset="6">
              Clientes
            </LabelList> */}
            <LabelList
              fill="white"
              dataKey="Clientes"
              position="center"
              offset="7"
            />
          </Bar>
          <Bar dataKey="ValorFixed" stackId="a" fill="#28c76f">
            {/* <LabelList fill="white" position="insideTop" offset="6">
              Valor
            </LabelList> */}
            <LabelList
              fill="white"
              dataKey="Valor"
              position="center"
              offset="7"
              formatter={(datakey) => {
                return datakey?.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                });
              }}
            />
          </Bar>
          <Bar dataKey="ComissaoFixed" stackId="a" fill="#ea5455">
            {/* <LabelList fill="white" position="insideTop" offset="6">
              Comissões
            </LabelList> */}
            <LabelList
              fill="white"
              dataKey="Comissão"
              position="center"
              offset="7"
              formatter={(datakey) => {
                return datakey?.toLocaleString("pt-BR", {
                  style: "currency",
                  currency: "BRL",
                });
              }}
            />
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </>
  );
}

function LineChartView({ data }) {
  return (
    <Col className="container-stacked-bar-chart-evo" lg={12}>
      <ResponsiveBar
        data={data}
        keys={data[12]?.keys}
        indexBy="mes"
        margin={{ top: 50, right: 220, bottom: 80, left: 110 }}
        padding={0.3}
        valueScale={{ type: "linear" }}
        indexScale={{ type: "band", round: true }}
        valueFormat={{ format: "", enabled: false }}
        colors={{ scheme: "nivo" }}
        defs={[
          {
            id: "dots",
            type: "patternDots",
            background: "inherit",
            color: "#38bcb2",
            size: 4,
            padding: 1,
            stagger: true,
          },
          {
            id: "lines",
            type: "patternLines",
            background: "inherit",
            color: "#eed312",
            rotation: -45,
            lineWidth: 6,
            spacing: 10,
          },
        ]}
        borderColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Mês",
          legendPosition: "middle",
          legendOffset: 32,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Vendas",
          legendPosition: "middle",
          legendOffset: -80,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: "color", modifiers: [["darker", 1.6]] }}
        legends={[
          {
            dataFrom: "keys",
            anchor: "bottom-right",
            direction: "column",
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 2,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: "left-to-right",
            itemOpacity: 0.85,
            symbolSize: 20,
            effects: [
              {
                on: "hover",
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </Col>
  );
}

export default Dashboard;
