import { Container } from 'reactstrap';
import logo2 from '../assets/logo-2.svg';
import { Link } from 'react-router-dom';
import { useWhiteLabel } from '../contexts/whitelabel';
import { FaFacebookF, FaInstagram, FaLinkedin } from 'react-icons/fa';
import { useAuth } from '../contexts/auth';

function FooterAcess() {
  const { dadosWhiteLabel, loading } = useWhiteLabel();
  const { uppo } = useAuth();

  function scrollToTop() {
    window.scrollTo(0, 0);
  }

  return (
    <footer
      style={{
        color: 'rgb(98 95 110)',
      }}
      className='footer-inner'
      hidden={loading}
    >
      <Container>
        <div className='footer'>
          {dadosWhiteLabel ? (
            <div>
              <p>Administrado por:</p>
              <div className='d-flex justify-content-center'>
                <img
                  src={dadosWhiteLabel?.imagem_footer_img?.url || logo2}
                  alt='Poupa club'
                  className='gray-img'
                />
              </div>
            </div>
          ) : (
            <div
              className='d-flex justify-content-center mr-1'
              style={{
                marginTop: -35,
              }}
            >
              <img src={logo2} alt='Poupa club' className='gray-img' />
            </div>
          )}

          <div className='links-uteis'>
            <ul>
              <li>Acesso rápido</li>
              <li>
                <Link
                  to={`/${dadosWhiteLabel?.url_whitelabel || 'poupa-club'}`}
                  onClick={scrollToTop}
                >
                  Home
                </Link>
              </li>

              <li>
                <Link
                  to={`/${
                    dadosWhiteLabel?.url_whitelabel || 'poupa-club'
                  }/cartao`}
                  onClick={scrollToTop}
                >
                  Descontos
                </Link>
              </li>
              <li>
                <Link
                  to={`/${
                    dadosWhiteLabel?.url_whitelabel || 'poupa-club'
                  }/agendar-consulta-exame`}
                  onClick={scrollToTop}
                >
                  Agendamento
                </Link>
              </li>
            </ul>
            <ul>
              <li>
                <button
                  className='btn-none'
                  onClick={() => {
                    uppo();
                  }}
                >
                  Parceiros
                </button>
              </li>
            </ul>
            <ul>
              <li>
                <Link
                  to={`/${
                    dadosWhiteLabel?.url_whitelabel || 'poupa-club'
                  }/seja-revendedor`}
                  onClick={scrollToTop}
                >
                  Revendedores
                </Link>
              </li>
            </ul>
            <ul>
              <li>
                <Link
                  to={`/${
                    dadosWhiteLabel?.url_whitelabel || 'poupa-club'
                  }/privacidade`}
                  onClick={scrollToTop}
                >
                  Política de privacidade
                </Link>
              </li>
            </ul>
            <ul>
              <li>Nossas redes</li>
              {/* <li>
              <a href="#">Blog</a>
            </li> */}
              <li className='d-flex mt-1'>
                {dadosWhiteLabel ? (
                  <>
                    {dadosWhiteLabel?.instagram_whitelabel && (
                      <a
                        href={`${dadosWhiteLabel?.instagram_whitelabel}`}
                        target='_blank'
                        rel='noreferrer'
                      >
                        <FaInstagram className='mr-1' />
                      </a>
                    )}
                    {dadosWhiteLabel?.facebook_whitelabel && (
                      <a
                        href={`${dadosWhiteLabel?.facebook_whitelabel}`}
                        target='_blank'
                        rel='noreferrer'
                      >
                        <FaFacebookF className='mr-1' />
                      </a>
                    )}
                    {dadosWhiteLabel?.linkedin_whitelabel && (
                      <a
                        href={`${dadosWhiteLabel?.linkedin_whitelabel}`}
                        target='_blank'
                        rel='noreferrer'
                      >
                        <FaLinkedin className='mr-1' />
                      </a>
                    )}
                  </>
                ) : (
                  <>
                    <a
                      href='https://www.instagram.com/poupaclub/'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <FaInstagram className='mr-1' />
                    </a>
                    <a
                      href='https://www.facebook.com/PoupaClubOficial/'
                      target='_blank'
                      rel='noreferrer'
                    >
                      <FaFacebookF className='ml-1' />
                    </a>
                  </>
                )}
              </li>
            </ul>
            <ul>
              <li>Fale conosco</li>
              <li>
                {dadosWhiteLabel ? (
                  <a href='a'>
                    {dadosWhiteLabel?.parceiro_info?.telefone_comercial}
                  </a>
                ) : (
                  <a href='https://api.whatsapp.com/send?phone=5511992657275&text=Olá%2C%20eu%20vim%20pelo%20site%20e%20gostaria%20de%20saber%20mais%20sobre%20o%20cartão%20PoupaClub.'>
                    11 99265-7275
                  </a>
                )}
              </li>
              <li>
                {dadosWhiteLabel ? (
                  <a href={`mailto:${dadosWhiteLabel?.parceiro_info?.email}`}>
                    {dadosWhiteLabel?.parceiro_info?.email}
                  </a>
                ) : (
                  <a href='mailto:contato@poupaclub.com.br?subject=Site%20PoupaClub'>
                    contato@poupaclub.com.br
                  </a>
                )}
              </li>
              {dadosWhiteLabel ? (
                <>
                  <li>
                    {dadosWhiteLabel?.parceiro_info?.cidade} -{' '}
                    {dadosWhiteLabel?.parceiro_info?.estado}
                  </li>
                </>
              ) : (
                <>
                  <li>Santo André - SP</li>
                  <li>De 2ª a 6ª feira das 8h às 18h</li>
                </>
              )}
            </ul>
          </div>
        </div>
        <div className='justify-center text-center mt-3 xcoding'>
          Made with &nbsp;
          <span
            style={{
              color: 'red',
            }}
          >
            ❤
          </span>
          &nbsp; by &nbsp;
          <a
            href='https://xcoding.com.br?ref=poupaclub'
            rel='noreferrer'
            target='_blank'
            style={{
              color: '#151320',
              fontWeight: 600,
            }}
          >
            Xcoding
          </a>
        </div>
        {/* {!dadosWhiteLabel && (
          <a
            href="https://api.whatsapp.com/send?phone=5511992657275&text=Ol%C3%A1%2C%20eu%20vim%20pelo%20site%20e%20gostaria%20de%20saber%20mais%20sobre%20o%20cart%C3%A3o%20PoupaClub."
            class="float"
            rel="noreferrer"
            target="_blank"
          >
            <FaWhatsapp class="my-float" />
          </a>
        )} */}
      </Container>
    </footer>
  );
}

export default FooterAcess;
