import * as yup from "yup";

export default async function store(data) {
  const schema = yup.object().shape({
    token_juno: yup.string().required("Obrigatório"),
  });

  await schema.validate(data, {
    abortEarly: false,
  });
}
