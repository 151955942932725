import React from "react";
import { Row, Col, Container } from "reactstrap";
import SectionCartoes from "./sectionCartoes";
import { useWhiteLabel } from "../../contexts/whitelabel";

function Cartao() {
  const { dadosWhiteLabel } = useWhiteLabel();
  return (
    <>
      <Container
        className={
          !dadosWhiteLabel
            ? "justify-center title-background mt-60 mb-60"
            : "justify-center mt-60 mb-60"
        }
      >
        <Row>
          <Col>
            <h3
              className="bold txt-primary"
              style={{
                color: dadosWhiteLabel
                  ? dadosWhiteLabel?.whitelabel_text_color_1
                  : "",
              }}
            >
              <span
                className="txt-secondary"
                style={{
                  color: dadosWhiteLabel
                    ? dadosWhiteLabel?.whitelabel_text_color_1
                    : "",
                }}
              >
                Peça já{" "}
              </span>
              e comece a poupar!
            </h3>
          </Col>
        </Row>
      </Container>
      <div className="mb-60  px-5">
        <SectionCartoes />
      </div>
    </>
  );
}

export default Cartao;
