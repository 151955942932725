import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';
import { Link } from 'react-router-dom';
import { useWhiteLabel } from '../contexts/whitelabel';

export default function CheckboxInput({
  name,
  options,
  col,
  initialValue = [],
  ...rest
}) {
  const inputRef = useRef(null);
  const { fieldName, registerField, error, defaultValue = [] } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue: (ref) => {
        return ref.checked ? ref.value : '';
      },
      clearValue: (ref) => {
        ref.checked = false;
      },
      setValue: (ref, value) => {
        ref.checked = value;
      },
    });
  }, [defaultValue, fieldName, registerField]);

  const { dadosWhiteLabel, loading } = useWhiteLabel();

  return (
    <div>
      <label htmlFor='aceito_termos_de_uso'>
        <input
          ref={inputRef}
          value='true'
          type='checkbox'
          id='aceito_termos_de_uso'
          {...rest}
        />
        &nbsp; Ao se cadastrar você concorda que leu e aceita os nossos{' '}
        <a
          target='_blank'
          rel="noreferrer"
          href={`/${dadosWhiteLabel?.url_whitelabel || 'poupa-club'}/termos`}
        >
          Termos de uso
        </a>{' '}
        e{' '}
        <a
          target='_blank'
          rel="noreferrer"
          href={`/${
            dadosWhiteLabel?.url_whitelabel || 'poupa-club'
          }/privacidade`}
        >
          Política de privacidade
        </a>
        .
      </label>
      <br />
      {error && <small className='error'>{error}</small>}
    </div>
  );
}
