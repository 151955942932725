import * as Yup from 'yup';

export default async function store(data) {
  const schema = Yup.object().shape({
    senha: Yup.string().required('Obrigatório').min(6, 'mínimo 6 dígitos'),
    novaSenha: Yup.string().required('Obrigatório').min(6, 'mínimo 6 dígitos'),
    confirmeSenha: Yup.string()
      .required('Obrigatório')
      .min(6, 'mínimo 6 dígitos')
      .oneOf([Yup.ref('novaSenha'), null], 'Senha não confere'),
  });

  await schema.validate(data, { abortEarly: false });
}
