import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Button,
  Row,
  Col,
  Container,
  ModalHeader,
  Modal,
  ModalBody,
  Card,
  CardImg,
  CardBody,
} from "reactstrap";
import cep from "cep-promise";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Form } from "@unform/web";
import Input from "../../components/unformInput";
import MaskedInput from "../../components/unformMaskedInput";
import UnformSelect from "../../components/unformSelect";
import UnformCheckboxTermos from "../../components/unformCheckboxTermos";
import api from "../../services/api";
import criarClientePI from "../../validations/criarClienteProdutoInterno";
import { cepMask, telefoneCelularMask, cnpjMask } from "../../utils/masks";
import formatarDinheiro from "../../utils/formatarDinheiro";
import convertStringDate from "../../utils/convertStringDate";
import useFetch from "../../hooks/useFetch";
import CreditCards from "../../components/creditCards";
import { useWhiteLabel } from "../../contexts/whitelabel";

let ultimoCep = "";
let ultimoCupom = "";
let consultando = false;

function Produto() {
  const params = useParams();
  const { data } = useFetch(`/produto_interno/${params.id.split("&")[0]}`);
  const { dadosWhiteLabel } = useWhiteLabel();
  const location = useLocation();

  const [condicaoPagamento, setCondicaoPagamento] = useState({
    condicaoPagamento: [],
  });
  const [pagamento, setPagamento] = useState("");
  const [modal, setModal] = useState(false);
  const [dadosModal, setDadosModal] = useState(null);
  const formRef = useRef(null);
  const history = useHistory();

  const toggle = () => setModal(!modal);

  function redirectCompra() {
    history.push("/" + dadosWhiteLabel?.url_whitelabel || "poupa-club");
  }

  async function buscaCep(cepValue) {
    if (cepValue) {
      if (ultimoCep !== cepValue) {
        try {
          if (cepValue.replaceAll("_", "").length === 9) {
            const result = await cep(cepValue);

            formRef.current.setData({
              ...formRef.current.getData(),
              bairro: result.neighborhood,
              cidade: `${result.city} / ${result.state}`,
              logradouro: result.street,
            });
            ultimoCep = cepValue;
          } else if (cepValue.length === 0) {
            formRef.current.setData({
              ...formRef.current.getData(),
              bairro: "",
              cidade: "",
              logradouro: "",
            });
          }
        } catch (error) {
          toast.warn("CEP não encontrado!");
        }
      }
    }
  }

  function formataComDesconto(i) {
    const desconto = data?.desconto_boleto
      ? 1 - data?.desconto_boleto / 100
      : 0.85;
    const desc = data?.desconto_boleto || 15;

    const valor = Number(data.valor) * desconto;
    return `${i + 1}x ${formatarDinheiro.format(
      valor
    )} com ${desc}% de desconto`;
  }

  function formataSemDesconto(i) {
    const valor = Number(data.valor) / (i + 1);
    return `${i + 1}x ${formatarDinheiro.format(valor)} sem juros`;
  }

  async function handleSubmit(formData) {
    if (consultando) {
      return;
    }

    const body = {
      ...formData,
      documento: formData.documento.replace(/[^\d]/g, ""),
      cidade: formData.cidade?.split("/")[0]?.trim(),
      estado: formData.cidade?.split("/")[1]?.trim(),
      produto_interno: params.id.split("&")[0],
      vendedor: params?.id?.split("&")[1]?.replace("vend=", ""),
    };

    try {
      formRef.current.setErrors({});
      await criarClientePI(formData);

      Swal.fire({
        title: "Aguarde...",
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });

      body.whiteLabel = dadosWhiteLabel?.url_whitelabel;

      const { data: pedido } = await api.post("/pedido_interno", body);

      const link =
        pedido.link_pagamento || pedido.link || pedido.installmentLink;

      Swal.fire({
        html:
          "Uma janela será aberta com o link para pagamento.<br /> Após a confirmação do pagamento em nosso sitema, um e-mail será enviado com dados para acesso ao sistema",
        icon: "success",
      }).then(() => {
        window.open(link);
        redirectCompra();
      });
    } catch (err) {
      const validationErrors = {};
      Swal.close();
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
        toast.error("Verifique os campos em vermelho!");
      } else {
        // console.log(err);
        const error =
          err?.response?.data?.error || "Ocorreu um erro, tente novamente!";
        toast.error(error);
      }
    }
  }

  const handleSubmitModalCartao = async (creditCardHash) => {
    const query = location.search.substr(1).split("&").filter(Boolean);

    const vend = query.find((f) => f.includes("vend="))?.replace("vend=", "");

    Swal.fire({
      title: "Aguarde...",
      allowOutsideClick: false,
      showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      await api.post(`/clientes-pj?vendedor=${vend || ""}`, {
        ...dadosModal,
        creditCardHash,
      });
      setModal(false);
      Swal.fire({
        html:
          "Assinatura realizada com sucesso, dentro de alguns minutos você receberá um email com informações para acesso a plataforma",
        icon: "success",
      }).then(() => {
        redirectCompra();
      });
    } catch (err) {
      Swal.close();
      const error =
        err?.response?.data?.error || "Ocorreu um erro, tente novamente!";
      toast.error(error);
    }
  };

  function handleChangePagamento(e) {
    const p = condicaoPagamento?.condicaoPagamento?.find(
      (f) => f.descricao === e.target.value
    );
    setPagamento(p);
  }

  useEffect(() => {
    async function getCondicoesPagamento() {
      try {
        const { data: con } = await api.get("/options/condicao-pagamento");
        con.condicaoPagamento = con.condicaoPagamento.filter(
          (m) => m.descricao !== "ASSINATURA"
        );
        setCondicaoPagamento(con);
      } catch (err) {}
    }
    getCondicoesPagamento();
  }, []);

  return (
    <>
      <Container className="mt-6">
        <Row>
          <Col lg={4}>
            <Row>
              <Col lg={12}>
                <p className="cartao-escolhido-title">{data?.nome}</p>

                <Card className="cartao border-0">
                  <CardBody>
                    <br />
                    <Row>
                      <p
                        className="detalhes-card"
                        style={{
                          color: dadosWhiteLabel
                            ? dadosWhiteLabel?.whitelabel_text_color_3
                            : "",
                        }}
                      >
                        À vista: R${" "}
                        {data
                          ? Number(data?.valor).toLocaleString("pt-BR", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : ""}{" "}
                      </p>
                    </Row>
                    <Row>
                      <p
                        className="detalhes-card"
                        style={{
                          color: dadosWhiteLabel
                            ? dadosWhiteLabel?.whitelabel_text_color_3
                            : "",
                        }}
                      >
                        ou 12x R${" "}
                        {data
                          ? (Number(data?.valor) / 12).toLocaleString("pt-BR", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : ""}{" "}
                        no Cartão de crédito
                      </p>
                    </Row>
                    <Row>
                      <p
                        className="detalhes-card"
                        style={{
                          color: dadosWhiteLabel
                            ? dadosWhiteLabel?.whitelabel_text_color_3
                            : "",
                        }}
                      >
                        ou 4x R${" "}
                        {data
                          ? (Number(data?.valor) / 4).toLocaleString("pt-BR", {
                              minimumFractionDigits: 2,
                              maximumFractionDigits: 2,
                            })
                          : ""}{" "}
                        no Boleto bancário
                      </p>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col lg={8}>
            <Form
              className="form-poupaclub mb-1"
              ref={formRef}
              onSubmit={handleSubmit}
            >
              <Row>
                <Col lg={6}>
                  <p className="cartao-escolhido-title">Cadastro:</p>
                </Col>
              </Row>
              <Row>
                <Col md={6} sm={12}>
                  <Row>
                    <Col lg={12}>
                      <Input
                        className="input-field"
                        name="nome"
                        placeholder="Nome"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <Input
                        className="input-field"
                        placeholder="E-mail"
                        name="email"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <Input
                        className="input-field"
                        placeholder="CPF/CNPJ"
                        name="documento"
                      />
                    </Col>
                  </Row>
                </Col>

                <Col md={6} sm={12}>
                  <Row>
                    <Col lg={12}>
                      <MaskedInput
                        className="input-field"
                        placeholder="CEP"
                        mask={cepMask}
                        removeEspecial
                        name="cep"
                        onChange={(e) => {
                          buscaCep(e.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <Input
                        className="input-field"
                        placeholder="Endereço"
                        name="logradouro"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <Input
                        className="input-field"
                        placeholder="Número"
                        name="numero"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <Input
                        className="input-field"
                        placeholder="Complemento"
                        name="complemento"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <Input
                        className="input-field"
                        placeholder="Bairro"
                        name="bairro"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <Input
                        className="input-field"
                        placeholder="Cidade / Estado"
                        name="cidade"
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col lg={6}>
                  <p className="cartao-escolhido-title">Pagamento:</p>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <UnformSelect
                    type="select"
                    className="input-field-select"
                    name="pagamento"
                    onChange={handleChangePagamento}
                    options={[
                      {
                        value: "",
                        label: "Formas de pagamento",
                      },
                    ].concat(
                      condicaoPagamento?.condicaoPagamento?.map((m) => ({
                        label: m.nome,
                        value: m.descricao,
                      }))
                    )}
                  />
                </Col>
                {pagamento?.parcelas > 1 && (
                  <Col lg={6}>
                    <UnformSelect
                      type="select"
                      className="input-field-select mb-3"
                      name="parcela"
                      options={[
                        {
                          value: "",
                          label: "Parcelamento sem juros",
                        },
                      ].concat(
                        [...Array(Number(pagamento.parcelas))].map(
                          (parcela, i) => ({
                            value: i + 1,
                            label:
                              i + 1 === 1 &&
                              pagamento.descricao.includes("BOLETO")
                                ? formataComDesconto(i)
                                : formataSemDesconto(i),
                          })
                        )
                      )}
                    />
                  </Col>
                )}
              </Row>
              <Row>
                <Col lg={12}>
                  <UnformCheckboxTermos name="termos" />
                </Col>
              </Row>
              <Button
                className="btn-pill bold btn-enviar mt-1"
                color="secondary"
                size="lg"
              >
                Enviar
              </Button>
            </Form>
          </Col>
        </Row>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}></ModalHeader>
          <ModalBody>
            <CreditCards handleSubmit={handleSubmitModalCartao} />
          </ModalBody>
        </Modal>
      </Container>
    </>
  );
}

export default Produto;
