import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import { Row, Col, Button } from 'reactstrap';
import { ValidationError } from 'yup';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';

import InputUnform from '../../../components/unformInput';
import ImagePreview from '../../../components/unformImagePreview';

import api from '../../../services/api';
import { useAuth } from '../../../contexts/auth';

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'ml-3 btn btn-success',
    cancelButton: 'btn btn-danger',
  },
  buttonsStyling: false,
});

function AlterarDados() {
  const { changeUserPhoto, setUser } = useAuth();
  const formRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
  }, []);

  async function getData() {
    Swal.fire({
      title: 'Aguarde',
      allowOutsideClick: false,
      showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const { data } = await api.get('/dados-usuario');

      if (data) {
        formRef.current.setData(data);
        Swal.close();
      } else {
        Swal.close();
        toast.error('Não existem dados para serem apresentados');
      }
    } catch (e) {
      Swal.close();
      toast.error('Não foi possível carregar os dados');
    }
  }

  async function handleSubmit(data) {
    try {
      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });

      if (data.foto_perfil) {
        var formData = new FormData();
        formData.append('file', data.foto_perfil);

        try {
          const { data: foto_salva } = await api.post('/imagens', formData);
          data.imagem_id = foto_salva.id;
        } catch (e) {
          toast.error('Erro ao salvar imagem!');
        }
      }

      const {
        data: { image },
      } = await api.patch('/alterar-dados', data);
      changeUserPhoto(image);
      setUser((user) => ({ ...user, email: data.email, nome: data.nome }));
      Swal.close();
      toast.success('Dados alterados com sucesso');
    } catch (err) {
      const validationErrors = {};
      Swal.close();
      if (err instanceof ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
        toast.error('Verifique os campos em vermelho!');
      } else {
        toast.error('Ocorreu um erro tente novamente mais tarde!');
      }
    }
  }

  async function deleteImage(item, cb) {
    swalWithBootstrapButtons
      .fire({
        title: `Tem certeza que deseja excluir esta imagem?`,
        text: 'Esta operação não poderá ser revertida!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          Swal.fire({
            title: 'Aguarde',
            allowOutsideClick: false,
            showConfirmButton: false,
            onBeforeOpen: () => {
              Swal.showLoading();
            },
          });
          api
            .delete(`imagem-user`)
            .then(() => {
              Swal.close();
              toast.success('Excluido com sucesso');
              formRef.current.setFieldValue('foto_perfil', null);
              changeUserPhoto(null);
              cb();
            })
            .catch((error) => {
              const msg =
                error?.response?.data?.error ||
                'Ocorreu um erro, tente novamente mais tarde!';
              swalWithBootstrapButtons.fire('Ops...', msg, 'error');
            });
        }
      });
  }

  return (
    <>
      <Row>
        <Col>
          <h5 className='txt-tertiary'>Alterar dados cadastrais</h5>
        </Col>
      </Row>
      <div className='card-form'>
        <Form
          onSubmit={(data) => {
            handleSubmit(data);
          }}
          ref={formRef}
        >
          <Row>
            <Col lg={2}>
              <Row>
                <Col>
                  <span className='placholder-top'>Foto de perfil</span>
                  <ImagePreview
                    name='foto_perfil'
                    handleDeleteImage={(cb) => deleteImage('foto_perfil', cb)}
                  />
                </Col>
              </Row>
            </Col>
            <Col lg={10}>
              <Row>
                <Col lg={6}>
                  <span className='placholder-top'>Nome</span>
                  <InputUnform
                    type='text'
                    name='nome'
                    className='input-field-inner'
                  />
                </Col>
                <Col lg={6}>
                  <span className='placholder-top'>Email</span>
                  <InputUnform
                    type='text'
                    name='email'
                    className='input-field-inner'
                  />
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className='mt-20'>
            <Col className='justify-end'>
              <Button color='green' className='mb-1 btn-save'>
                Alterar dados
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}

export default AlterarDados;
