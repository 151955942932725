import { useState } from "react";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  Row,
  Col,
} from "reactstrap";
import useFetch from "../../../hooks/useFetch";
import cartaoBlack from "../../../assets/cartao_black.png";
import cartaoGold from "../../../assets/cartao_gold.png";
import cartaoEspecial from "../../../assets/cartao_especial.png";
import formatCpf from "../../../utils/formatCPF";
import formatCNPJ from "../../../utils/formatCNPJ";
import QRCode from "qrcode.react";
import { useWhiteLabel } from "../../../contexts/whitelabel";

const CartaoDigital = (props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const { data } = useFetch("/cartao-digital");
  const { dadosWhiteLabel } = useWhiteLabel();

  const cartoes = {
    especial: dadosWhiteLabel?.cartao1?.imagem?.url || cartaoEspecial,
    black: dadosWhiteLabel?.cartao2?.imagem?.url || cartaoBlack,
    gold: dadosWhiteLabel?.cartao3?.imagem?.url || cartaoGold,
  };

  const next = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === data?.items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? data?.items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const slides = data?.items?.map((item) => {
    return (
      <CarouselItem
        onExiting={() => setAnimating(true)}
        onExited={() => setAnimating(false)}
        key={item.id}
      >
        <Row className="justify-center" key={item.id}>
          <Col lg={8} sm={12}>
            <img
              src={data?.cartao || cartoes[data?.tipo]}
              alt="cartão cliente"
              className="w-100 mt-4"
            />
            <div className="cartao-cliente">
              <div className="d-flex">
                <div className="mr-3">
                  <b className="filter-shadow">
                    {item.titular
                      ? item.cnpj
                        ? "Empresa:"
                        : "TITULAR:"
                      : "DEPENDENTE:"}{" "}
                  </b>
                </div>
                <div className="filter-shadow">{item.nome}</div>
                <div></div>
              </div>
              <div className="d-flex">
                <div className="mr-3">
                  <b className="filter-shadow">INSCRIÇÃO:</b>
                </div>
                <div className="filter-shadow">
                  {item.cnpj
                    ? formatCNPJ(
                        `00000000000000${
                          item.cnpj ?? data?.items[0]?.cnpj
                        }`.slice(-14)
                      )
                    : formatCpf(
                        `00000000000${item.cpf ?? data?.items[0]?.cpf}`.slice(
                          -11
                        )
                      )}
                </div>
              </div>
            </div>
          </Col>
          <Col lg={4} className="hide">
            <div className="qr-code mt-4">
              <div className="d-flex">
                <QRCode
                  value={`${window.location.origin}/${
                    dadosWhiteLabel?.url_whitelabel || "poupa-club"
                  }/consultaqr/${
                    data?.items?.filter((e) => e.titular === true)[0]?.cpf
                  }`}
                  bgColor="#000"
                  fgColor="white"
                  size="120"
                />
              </div>
            </div>
          </Col>
        </Row>
        <div className="hidden-row">
          <Row className="hidden-row">
            <Col lg={12} className="justify-center qr-code-small mb-2">
              <div className="d-flex mt-2">
                <QRCode
                  value={`${window.location.origin}/${
                    dadosWhiteLabel?.url_whitelabel || "poupa-club"
                  }/consultaqr/${
                    data?.items?.filter((e) => e.titular === true)[0]?.cpf
                  }`}
                  bgColor="#000"
                  fgColor="white"
                  size="100"
                />
              </div>
            </Col>
          </Row>
        </div>
      </CarouselItem>
    );
  });

  return (
    <>
      <Row>
        <Col lg={7} className="mb-1">
          <h5 className="txt-tertiary">Cartão digital</h5>
        </Col>
      </Row>
      <div className="card-form py-3">
        <Row className="mt-3">
          <Col
            xl={6}
            lg={8}
            md={10}
            className="offset-md-1 offset-xl-3 offset-lg-2"
          >
            {data && (
              <>
                <Carousel
                  activeIndex={activeIndex}
                  next={next}
                  previous={previous}
                >
                  {slides}
                  <CarouselControl
                    direction="prev"
                    directionText="Previous"
                    onClickHandler={previous}
                  />
                  <CarouselControl
                    direction="next"
                    directionText="Next"
                    onClickHandler={next}
                  />
                </Carousel>
                <h6 className="text-center w-100 txt-primary mt-2">
                  Parceiro aponte a câmera para o QrCode para validar o cliente
                </h6>
              </>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
};

export default CartaoDigital;
