import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useField } from '@unform/core';
import { toast } from 'react-toastify';

export default function ImagePreview({
  name,
  className,
  handleDeleteImage,
  ...rest
}) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  const [preview, setPreview] = useState(defaultValue);
  const handlePreview = useCallback((e) => {
    const file = e.target.files?.[0];
    if (!file) {
      return setPreview(null);
    } else if (file.size > 1572864) {
      return toast.error('Imagem deve conter tamanho máximo de 1,5MB');
    }
    const previewURL = URL.createObjectURL(file);
    setPreview(previewURL);
  }, []);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      setValue: (ref, value) => {
        setPreview(value);
      },
      getValue: (ref) => ref?.files[0],
    });
  }, [fieldName, registerField]);

  return (
    <div class='box'>
      <div
        class='js--image-preview'
        style={{
          backgroundImage: preview && `url(${preview})`,
        }}
      />
      <div class='upload-options'>
        <label hidden={preview && !preview.includes('blob:http')}>
          <input
            type='file'
            accept='.png, .jpg, .jpeg'
            className={`${className ? className : ''} image-upload ${
              error ? 'error' : ''
            }`.trim()}
            ref={inputRef}
            defaultValue={defaultValue}
            onChange={handlePreview}
            {...rest}
          />
        </label>
        <span
          hidden={
            !preview ||
            (typeof preview === 'string' && preview.includes('blob:http'))
          }
        >
          <button
            className='btn-block btn btn-danger'
            type='button'
            onClick={() => handleDeleteImage(() => setPreview(null))}
          >
            Excluir
          </button>
        </span>
      </div>
      {error && <small className='error'>{error}</small>}
    </div>
  );
}
