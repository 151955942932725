import { endOfMonth, startOfMonth } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import { useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { AiOutlineLoading } from 'react-icons/ai';
import { FaPlus } from 'react-icons/fa';
import { toast } from 'react-toastify';
import {
  Button,
  Col,
  Row,
  Table,
  UncontrolledCollapse,
  UncontrolledPopover,
} from 'reactstrap';
import cartaoBlack from '../../../assets/cartao_black.png';
import cartaoEspecial from '../../../assets/cartao_especial.png';
import cartaoGold from '../../../assets/cartao_gold.png';
import iconeData from '../../../assets/icone_data.png';
import { useWhiteLabel } from '../../../contexts/whitelabel';
import useFecth from '../../../hooks/useFetch';
import api from '../../../services/api';
import formatarDinheiro from '../../../utils/formatarDinheiro';
import monthNames from '../../../utils/nomesMes';
import { periodoDataLabel } from '../../../utils/periodoData';
registerLocale('pt-BR', pt);

const inicioMes = startOfMonth(new Date());
const fimMes = endOfMonth(new Date());

const capitalize = (s) => {
  if (typeof s !== 'string') return '';
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase();
};

function RelatorioParceiro() {
  const [dateComissao, setDateComissao] = useState({
    start: inicioMes,
    end: fimMes,
  });

  const [dateVenda, setDateVenda] = useState({
    start: inicioMes,
    end: fimMes,
  });

  const [comissoes, setComissoes] = useState({
    receber: 0,
    recebidos: 0,
  });

  const [vendas, setVendas] = useState([]);

  const { data: ultimasVendas, mutate } = useFecth('/ultimas-vendas');
  const { dadosWhiteLabel } = useWhiteLabel();
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);

  const onChangeComissao = (dates) => {
    const [start, end] = dates;
    setDateComissao({ start, end });
  };
  const onChangeVenda = (dates) => {
    const [start, end] = dates;
    setDateVenda({ start, end });
  };

  async function buscaMaisVenda() {
    if (!loading) {
      try {
        setLoading(true);
        const { data: res } = await api.get(`/ultimas-vendas?page=${page + 1}`);
        setPage(page + 1);
        mutate(
          {
            pages: res.pages,
            vendas: [...ultimasVendas.vendas, ...res.vendas],
          },
          false
        );
      } catch (err) {
        toast.error('Ocorreu um erro tente novamente');
      } finally {
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    async function getComissoes() {
      try {
        const [dataInicio] = new Date(dateComissao.start)
          .toISOString()
          .split('T');
        const [dataFim] = new Date(dateComissao.end).toISOString().split('T');

        const { data } = await api.get(
          `/comissoes?startDate=${dataInicio}&endDate=${dataFim}`
        );

        setComissoes(data);
      } catch (err) {
        console.log('ERROR', err);
      }
    }
    if (dateComissao.start && dateComissao.end) {
      getComissoes();
    }
  }, [dateComissao.start, dateComissao.end]);

  useEffect(() => {
    async function getVendasPorCartao() {
      try {
        const [dataInicio] = new Date(dateVenda.start).toISOString().split('T');
        const [dataFim] = new Date(dateVenda.end).toISOString().split('T');

        const { data } = await api.get(
          `/vendas-por-cartao?startDate=${dataInicio}&endDate=${dataFim}`
        );

        setVendas(data);
      } catch (err) {
        console.log('ERROR', err);
      }
    }
    if (dateVenda.start && dateVenda.end) {
      getVendasPorCartao();
    }
  }, [dateVenda.start, dateVenda.end]);

  return (
    <>
      <Row>
        <Col lg={7} className='mb-1'>
          <h5 className='txt-tertiary'>Relatório</h5>
        </Col>
      </Row>

      <div className='card-form'>
        <Row>
          <Col lg={12}>
            <h5 className='txt-tertiary mb-3'>Últimas vendas</h5>
          </Col>

          <Table responsive className='table-accordion'>
            <thead>
              <tr>
                <th>Cliente</th>
                <th>Data</th>
                <th>Cartão</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody>
              {ultimasVendas?.vendas?.map((venda) => {
                const dataParcela = new Date(venda.created_at);

                return (
                  <>
                    <tr>
                      <td>
                        <Button
                          color='link'
                          size='small'
                          id={`pedido_${venda.id}`}
                          className='text-collpase'
                        >
                          {venda?.ClientePf?.nome ||
                            venda?.ClientePj?.nome_fantasia}{' '}
                          <span className='small'>
                            <small>▼</small>
                          </span>
                        </Button>
                      </td>
                      <td className='w-20'>
                        {monthNames[dataParcela.getMonth()]} /{' '}
                        {dataParcela.getFullYear()}
                      </td>
                      <td className='text-capitalize w-20'>
                        {venda?.Produto?.descricao}
                      </td>
                      <td
                        className={`w-20 ${
                          venda?.status === 'INADIMPLENTE' && 'text-danger'
                        }`}
                      >
                        {capitalize(venda?.status)}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={4} className='td-collapse'>
                        <UncontrolledCollapse
                          toggler={`#pedido_${venda.id}`}
                          className='px-10'
                        >
                          <Table responsive>
                            <tbody>
                              {venda?.pedido_parcela?.map((parcela) => (
                                <tr>
                                  <td>
                                    Parcela {parcela.parcela}/
                                    {venda?.total_parcelas}
                                  </td>
                                  <td className='w-20'>
                                    {new Date(
                                      parcela.data_parcela
                                    ).toLocaleDateString()}
                                  </td>
                                  <td className='w-20'></td>
                                  <td className='w-20 pl-3'>
                                    &nbsp;&nbsp;
                                    {parcela.status === 'PAID' ? (
                                      'Paga'
                                    ) : new Date(
                                        parcela.data_parcela
                                      ).getTime() < new Date().getTime() ? (
                                      <span className='txt-danger'>
                                        Vencida
                                      </span>
                                    ) : (
                                      'A vencer'
                                    )}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </UncontrolledCollapse>
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
            {ultimasVendas?.pages > page && (
              <tfoot>
                <tr>
                  <td colSpan={4}>
                    <Button
                      onClick={buscaMaisVenda}
                      size='sm'
                      color='link'
                      disabled={loading}
                      className='ml-1'
                    >
                      {loading ? (
                        <AiOutlineLoading
                          color='var(--text-header)'
                          className='spin'
                          size={18}
                        />
                      ) : (
                        <FaPlus color='var(--text-header)' />
                      )}
                    </Button>
                  </td>
                </tr>
              </tfoot>
            )}
          </Table>
        </Row>
      </div>
      <div className='card-form my-6'>
        <Row>
          <Col lg={9}>
            <div className='justify-between align-center'>
              <h5 className='txt-tertiary '>Comissões</h5>
              <span className='d-flex align-center'>
                <img
                  src={iconeData}
                  alt='icone de data'
                  className='mr-1'
                  style={{
                    maxHeight: '34px',
                  }}
                />
                <Button
                  id='periodo_comissao'
                  type='button'
                  color='link'
                  className='ml-1'
                >
                  {periodoDataLabel(dateComissao)}
                  <span className='small ml-1'>
                    <small>▼</small>
                  </span>
                </Button>
                <UncontrolledPopover
                  placement='bottom'
                  target='#periodo_comissao'
                  trigger='legacy'
                  hideArrow
                >
                  <DatePicker
                    selected={dateComissao.start}
                    onChange={onChangeComissao}
                    startDate={dateComissao.start}
                    endDate={dateComissao.end}
                    locale='pt-BR'
                    selectsRange
                    inline
                  />
                </UncontrolledPopover>
              </span>
            </div>
          </Col>
          <Col lg={12} className='my-6'>
            <Row>
              <Col lg={6} className='mb-3'>
                <h5 className='txt-secondary'>
                  <b>A receber</b>
                </h5>
                <br />
                <h4 className='txt-primary'>
                  <b>{formatarDinheiro.format(comissoes.receber)}</b>
                </h4>
              </Col>
              <Col lg={6} className='mb-3'>
                <h5 className='txt-secondary'>
                  <b>Recebidas</b>
                </h5>
                <br />
                <h4 className='txt-primary'>
                  <b>{formatarDinheiro.format(comissoes.recebidos)}</b>
                </h4>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className='card-form'>
        <Row>
          <Col lg={9}>
            <div className='justify-between align-center'>
              <h5 className='txt-tertiary '>Quantidade de vendas por cartão</h5>
              <span className='d-flex align-center'>
                <img
                  src={iconeData}
                  alt='icone de data'
                  className='mr-1'
                  style={{
                    maxHeight: '34px',
                  }}
                />
                <Button
                  id='periodo_vendas'
                  type='button'
                  color='link'
                  className='ml-1'
                >
                  {periodoDataLabel(dateVenda)}
                  <span className='small ml-1'>
                    <small>▼</small>
                  </span>
                </Button>
                <UncontrolledPopover
                  placement='bottom'
                  target='#periodo_vendas'
                  trigger='legacy'
                  hideArrow
                >
                  <DatePicker
                    selected={dateVenda.start}
                    onChange={onChangeVenda}
                    startDate={dateVenda.start}
                    endDate={dateVenda.end}
                    locale='pt-BR'
                    selectsRange
                    inline
                  />
                </UncontrolledPopover>
              </span>
            </div>
          </Col>
          <Col
            lg={12}
            className='div-relatorio my-6'
            style={{
              backgroundColor: '#fff',
            }}
          >
            {dadosWhiteLabel ? (
              <Row className='mb-0'>
                {dadosWhiteLabel?.cartao1 && (
                  <Col md={4}>
                    <div className='justify-center'>
                      <img
                        src={dadosWhiteLabel?.cartao1?.imagem?.url}
                        alt={dadosWhiteLabel?.cartao1?.nome}
                      />
                    </div>
                    <br />
                    <div className='justify-center'>
                      <h4 className='txt-primary'>
                        <b>
                          {vendas.find(
                            (f) => f.produto.descricao === 'especial'
                          )?.quantidade || 0}
                        </b>
                      </h4>
                    </div>
                  </Col>
                )}
                {dadosWhiteLabel?.cartao2 && (
                  <Col md={4}>
                    <div className='justify-center'>
                      <img
                        src={
                          dadosWhiteLabel?.cartao2?.imagem?.url || cartaoBlack
                        }
                        alt={dadosWhiteLabel?.cartao2?.nome}
                      />
                    </div>

                    <br />
                    <div className='justify-center'>
                      <h4 className='txt-primary'>
                        <b>
                          {vendas.find((f) => f.produto.descricao === 'black')
                            ?.quantidade || 0}
                        </b>
                      </h4>
                    </div>
                  </Col>
                )}
                {dadosWhiteLabel?.cartao3 && (
                  <Col md={4}>
                    <div className='justify-center'>
                      <img
                        src={
                          dadosWhiteLabel?.cartao3?.imagem?.url || cartaoGold
                        }
                        alt={dadosWhiteLabel?.cartao3?.nome}
                      />
                    </div>
                    <br />
                    <div className='justify-center'>
                      <h4 className='txt-primary'>
                        <b>
                          {vendas.find((f) => f.produto.descricao === 'gold')
                            ?.quantidade || 0}
                        </b>
                      </h4>
                    </div>
                  </Col>
                )}
              </Row>
            ) : (
              <Row className='mb-0'>
                <Col md={4}>
                  <div className='justify-center'>
                    <img src={cartaoEspecial} alt='Cartão PoupaClub Especial' />
                  </div>
                  <br />
                  <div className='justify-center'>
                    <h4 className='txt-primary'>
                      <b>
                        {vendas.find((f) => f.produto.descricao === 'especial')
                          ?.quantidade || 0}
                      </b>
                    </h4>
                  </div>
                </Col>

                <Col md={4}>
                  <div className='justify-center'>
                    <img src={cartaoGold} alt='Cartão PoupaClub Gold' />
                  </div>
                  <br />
                  <div className='justify-center'>
                    <h4 className='txt-primary'>
                      <b>
                        {vendas.find((f) => f.produto.descricao === 'gold')
                          ?.quantidade || 0}
                      </b>
                    </h4>
                  </div>
                </Col>

                <Col md={4}>
                  <div className='justify-center'>
                    <img src={cartaoBlack} alt='Cartão PoupaClub Black' />
                  </div>

                  <br />
                  <div className='justify-center'>
                    <h4 className='txt-primary'>
                      <b>
                        {vendas.find((f) => f.produto.descricao === 'black')
                          ?.quantidade || 0}
                      </b>
                    </h4>
                  </div>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
}

export default RelatorioParceiro;
