import React, { useEffect, useState } from "react";
import { Container, Row, Card } from "reactstrap";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import {
  IoIosCheckmarkCircleOutline,
  IoIosCloseCircleOutline,
} from "react-icons/io";
import Swal from "sweetalert2";

import api from "../../services/api";

function ConsultaQR() {
  const [data, setData] = useState();
  const location = useLocation();

  const cpf = location.pathname.split("/").slice(-1)[0];

  useEffect(() => {
    async function getData() {
      try {
        Swal.fire({
          title: "Aguarde",
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });
        const { data } = await api.get(`/consultaqr/${cpf}`);
        setData(data);
        Swal.close();
      } catch (err) {
        toast.error("Erro ao buscar cliente");
        Swal.close();
      }
    }
    getData();
  }, [cpf]);

  return (
    <main>
      <Container>
        {data ? (
          <Row className="row-obrigado">
            <Card
              className={
                data?.status === "A"
                  ? "txt-ativo card-obrigado"
                  : "txt-inativo card-obrigado"
              }
            >
              <h3 className="mt-5">
                {data?.status === "A" ? (
                  <IoIosCheckmarkCircleOutline className="mr-2 mb-1" />
                ) : (
                  <IoIosCloseCircleOutline className="mr-2 mb-1" />
                )}
                CLIENTE {data?.status === "A" ? "ATIVO" : "INATIVO"}
              </h3>
              <hr />
              <h4 className="obrigado-txt-inside">{data?.nome}</h4>
              <hr />
              {data?.dependentes.length > 0 && (
                <>
                  <h4>DEPENDENTES</h4>
                  <br />
                </>
              )}
              {data?.dependentes.length > 0 &&
                data?.dependentes?.map((dependente) => (
                  <h5 key={dependente?.nome} className="obrigado-txt-inside">
                    {dependente?.nome}
                  </h5>
                ))}
            </Card>
          </Row>
        ) : (
          <Row className="row-obrigado">
            <Card>
              <hr />
              <h3 className="mt-5 mb-5">CLIENTE NÃO CADASTRADO</h3>
              <hr />
            </Card>
          </Row>
        )}
      </Container>
    </main>
  );
}

export default ConsultaQR;
