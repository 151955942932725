const parentesco = [
  {
    value: '',
    label: 'Parentesco',
  },
  {
    value: 'Enteado(a)',
    label: 'Enteado(a)',
  },
  {
    value: 'Filho(a)',
    label: 'Filho(a)',
  },
  {
    value: 'Irmão(ã)',
    label: 'Irmão(ã)',
  },
  {
    value: 'Outros',
    label: 'Outros',
  },
];

export default parentesco;
