import React from "react";
import {
  Row,
  Card,
  CardImg,
  CardBody,
  Button,
  UncontrolledCollapse,
} from "reactstrap";
import { GoChevronDown } from "react-icons/go";
import { useWhiteLabel } from "../contexts/whitelabel";

import CartaoGoldIMG from "../assets/cartao_gold.png";

export default function CartaoGold({ data }) {
  const portalVendas =
    window.location.pathname === "/portal-venda" ||
    window.location.pathname === "/portal-venda/gold";

  const { dadosWhiteLabel } = useWhiteLabel();

  return (
    <Card className="cartao border-0">
      <CardImg
        top
        width="50%"
        src={data?.imagem?.url || CartaoGoldIMG}
        alt={data?.nome || "Cartao PoupaClub"}
        className="cartao"
      />
      <CardBody>
        <Row>
          <p
            className="cartao-title"
            style={{
              color: dadosWhiteLabel
                ? dadosWhiteLabel?.whitelabel_text_color_1
                : "",
            }}
          >
            {dadosWhiteLabel ? data?.nome : "Cartão PoupaClub"} &nbsp;&nbsp;
          </p>
          <span
            className={
              dadosWhiteLabel
                ? "cartao-span-gold " + dadosWhiteLabel?.cor_cartao_2
                : "cartao-span-gold"
            }
          >
            {dadosWhiteLabel ? data?.tipo?.toUpperCase() : "GOLD"}
          </span>
        </Row>
        {!dadosWhiteLabel ? (
          <>
            <Row>
              <p className="detalhes-card">Até 5 usuários</p>
            </Row>
            <Row>
              <p className="cartao-title detalhes-card">1 titular</p>
            </Row>
            <Row>
              <p className="cartao-title detalhes-card">+ 1 Cônjuge</p>
            </Row>
            <Row>
              <p className="cartao-title detalhes-card">
                + 3 Dependentes&nbsp;
              </p>
              <p className="detalhes-card">menores de 18 anos</p>
            </Row>
          </>
        ) : (
          <Row>
            <p
              className="cartao-title detalhes-card"
              style={{
                color: dadosWhiteLabel
                  ? dadosWhiteLabel?.whitelabel_text_color_3
                  : "",
              }}
            >
              1 (um) usuário titular
            </p>
          </Row>
        )}
        {data?.conjuge && (
          <Row>
            <strong
              style={{
                color: dadosWhiteLabel?.whitelabel_text_color_3,
              }}
            >
              + 1 Cônjuge
            </strong>
          </Row>
        )}
        {data?.numero_dependentes > 0 && (
          <Row
            style={{
              color: dadosWhiteLabel
                ? dadosWhiteLabel?.whitelabel_text_color_3
                : "",
            }}
          >
            <strong>+ {data?.numero_dependentes} Dependente(s)&nbsp;</strong>
            {data?.limitar_idade
              ? `menor(es) de ${data?.idade_limite} anos`
              : "(sem limite de idade)"}
          </Row>
        )}
        <Row>
          <p
            className="detalhes-card"
            style={{
              color: dadosWhiteLabel
                ? dadosWhiteLabel?.whitelabel_text_color_3
                : "",
            }}
          >
            Validade de 12 meses
          </p>
        </Row>
        <br />
        <Row>
          <p
            className="detalhes-card"
            style={{
              color: dadosWhiteLabel
                ? dadosWhiteLabel?.whitelabel_text_color_3
                : "",
            }}
          >
            À vista: R${" "}
            {data
              ? Number(data?.valor).toLocaleString("pt-BR", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : ""}{" "}
            (
            {dadosWhiteLabel
              ? `+ ${data?.desconto_boleto}% de desconto`
              : "+ 15% de desconto"}
            )
          </p>
        </Row>
        <Row>
          <p
            className="detalhes-card"
            style={{
              color: dadosWhiteLabel
                ? dadosWhiteLabel?.whitelabel_text_color_3
                : "",
            }}
          >
            ou 12x R${" "}
            {data
              ? (Number(data?.valor) / 12).toLocaleString("pt-BR", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : ""}{" "}
            no Cartão de crédito
          </p>
        </Row>
        <Row>
          <p
            className="detalhes-card"
            style={{
              color: dadosWhiteLabel
                ? dadosWhiteLabel?.whitelabel_text_color_3
                : "",
            }}
          >
            ou 4x R${" "}
            {data
              ? (Number(data?.valor) / 4).toLocaleString("pt-BR", {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2,
                })
              : ""}{" "}
            no Boleto bancário
          </p>
        </Row>
        <Row hidden={portalVendas}>
          <Button id="toggler-2" className="btn-benefdesc" size="sm" block>
            <GoChevronDown
              size={35}
              style={{
                color: dadosWhiteLabel
                  ? dadosWhiteLabel?.whitelabel_arrows
                  : "",
              }}
            />
            <span
              style={{
                color: dadosWhiteLabel
                  ? dadosWhiteLabel?.whitelabel_arrows
                  : "",
              }}
            >
              Benefícios e descontos
            </span>
          </Button>
        </Row>
        <Row hidden={portalVendas}>
          <UncontrolledCollapse
            toggler="#toggler-2"
            className="benef_list"
            isOpen={true}
          >
            <ul
              className="striped-list"
              style={{
                color: dadosWhiteLabel
                  ? dadosWhiteLabel?.whitelabel_text_color_3
                  : "",
              }}
            >
              <li>Consultas médicas com até 60% de desconto</li>
              <li>Exames médicos com até 70% de desconto</li>
              <li>
                <span>Até 70% de </span>desconto em medicamentos
              </li>
              <li>
                Descontos em consultas Veterinárias
                <span> e Pet Shop</span>
              </li>
              <li>
                <span>Mensalidades com </span>
                desconto em academias
              </li>
              <li>+ de 500 parceiros com + de 2000 lojas pelo Brasil</li>
            </ul>
          </UncontrolledCollapse>
        </Row>
      </CardBody>
    </Card>
  );
}
