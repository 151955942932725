import React, { useRef } from "react";
import { Container, Col, Row, Button } from "reactstrap";
import { TiChevronRight } from "react-icons/ti";
import { Form } from "@unform/web";
import Input from "../../components/unformInput";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import MaskedInput from "../../components/unformMaskedInput";
import { ValidationError } from "yup";
import UnformSelect from "../../components/unformSelect";
import img_drog_sao_paulo from "../../assets/drogaria-sao-paulo.png";
import img_drog_pacheco from "../../assets/drogaria_pacheco.png";
import Cartoes from "../../assets/cartoes-poupaclub.png";
import ReactHtmlParser from "react-html-parser";

import api from "../../services/api";
import { phoneMask, cpfMask, dateMask } from "../../utils/masks";

import descontofarmaValidator from "../../validations/descontoFarmaValidator";

import { useWhiteLabel } from "../../contexts/whitelabel";

function DescontoFarmacia() {
  const formRef = useRef(null);
  const history = useHistory();

  const { dadosWhiteLabel } = useWhiteLabel();

  async function handleSubmit(data) {
    try {
      formRef.current.setErrors({});
      await descontofarmaValidator(data);
      Swal.fire({
        title: "Por favor, aguarde...",
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      const url = window.location.pathname.split("/");
      if (url[1] !== "poupa-club") {
        data.whitelabel = url[1];
      }
      await api.post("/email-desc-farma", data);
      Swal.close();
      return history.push(
        `${
          dadosWhiteLabel?.url_whitelabel || "poupa-club"
        }/obrigado-pelo-interesse`
      );
    } catch (err) {
      const validationErrors = {};
      Swal.close();
      if (err instanceof ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
        toast.error("Verifique os campos em vermelho!");
      } else {
        toast.error("Ocorreu um erro tente novamente mais tarde!");
      }
    }
  }

  return (
    <>
      <div className="bg-gray">
        <div>
          <Container>
            <Row className="mt-30 pt-60 txt-primary">
              <Col lg={6} className="justify-start mt-1  mb-6">
                {dadosWhiteLabel ? (
                  <img
                    src={dadosWhiteLabel?.desc_farma_image_form_img?.url}
                    width="auto"
                    height="100%"
                    style={{
                      maxHeight: '600px'
                    }}
                    alt="Desconto farmácia"
                  />
                ) : (
                  <h1 className="h1-title-3">
                    O melhor
                    <br /> Convênio
                    <br /> Farmácia
                    <br />
                    <span className="com-ate">Com até</span>
                    <br />
                    <span className="pctgm">75%</span>
                    <br />
                    <span className="dedesco">
                      de desconto com nossos parceiros
                    </span>
                    <br />
                    <img
                      src={img_drog_sao_paulo}
                      className="img-drogarias"
                      alt="Drogarias São Paulo"
                    />
                    <img
                      src={img_drog_pacheco}
                      className="img-drogarias"
                      alt="Drogarias Pacheco"
                    />
                    <br />
                    <span className="desc-small">
                      Descontos para as lojas físicas e também nos APPs
                    </span>
                  </h1>
                )}
              </Col>
              <Col lg={6} className="mt-1">
                <Row className="mb-3">
                  <Col lg={12} className="d-flex">
                    <TiChevronRight
                      size={35}
                      color="orange"
                      className="mr-3"
                      style={{
                        color: dadosWhiteLabel
                          ? dadosWhiteLabel?.whitelabel_arrows
                          : "",
                      }}
                    />
                    {dadosWhiteLabel ? (
                      <h4 className="">
                        {ReactHtmlParser(dadosWhiteLabel?.desc_farma_titulo)}
                      </h4>
                    ) : (
                      <h4 className="title-form">
                        <bold>Cadastre-se gratuitamente</bold> para obter
                        <br /> até 75% de desconto no Convênio Farmácia
                      </h4>
                    )}
                  </Col>
                </Row>
                <Form
                  className="form-poupaclub mt-3 form-desconto"
                  onSubmit={handleSubmit}
                  ref={formRef}
                >
                  <Row>
                    <Col>
                      <Input
                        className="input-field"
                        placeholder="Nome completo"
                        name="nome"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <MaskedInput
                        className="input-field"
                        placeholder="CPF"
                        name="cpf"
                        mask={cpfMask}
                        removeEspecial
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <MaskedInput
                        className="input-field"
                        placeholder="Data de Nascimento"
                        name="data_nascimento"
                        mask={dateMask}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Input
                        className="input-field"
                        placeholder="E-mail"
                        name="email"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <MaskedInput
                        className="input-field"
                        name="telefone_celular"
                        placeholder="Telefone ou Celular com DDD"
                        mask={phoneMask}
                        removeEspecial
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <UnformSelect
                        type="select"
                        placeholder="Possui plano de saúde?"
                        className="input-field-select"
                        name="planosaude"
                        options={[
                          {
                            value: "",
                            label: "Possui plano de saúde?",
                          },
                          {
                            value: "sim",
                            label: "Sim",
                          },
                          {
                            value: "nao",
                            label: "Não",
                          },
                        ]}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <div className="justify-center my-3 btn-form">
                        <Button
                          color="secondary"
                          size="lg"
                          className={
                            dadosWhiteLabel
                              ? "btn-pill mb-3 bold btn-quero-meus-desc " +
                                dadosWhiteLabel?.whitelabel_button_3
                              : "btn-pill mb-3 bold btn-quero-meus-desc"
                          }
                        >
                          Cadastre-se gratuitamente
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>

        <div
          className="info-farmacias"
          style={{
            backgroundImage: dadosWhiteLabel
              ? `url(
            ${dadosWhiteLabel?.img_banner_desc_farma_img?.url}
          )`
              : "",
          }}
        >
          <Row>
            <Col lg={3}></Col>
            <Col lg={9}>
              <Container className="farmacia-card mb-5 mt-5">
                {!dadosWhiteLabel ? (
                  <>
                    <Row className="mt-20 txt-primary justify-center">
                      <h4 className="text-center font-400 mr-ml-15">
                        Realizando o cadastro acima,{" "}
                        <strong>
                          você já ganha até 30% de desconto em medicamentos
                        </strong>
                        {" "} para utilizar nas farmácias{" "}
                        <strong>Drogaria São Paulo e Drogarias Pacheco</strong>.
                      </h4>
                    </Row>
                    <Row className="mt-20 txt-primary">
                      <h6 className="h6-title-2 mr-ml-15">
                        Como aplicar o desconto
                      </h6>
                      <h5 className="font-400 subtit-farma mr-ml-15 mb-5">
                        Ao finalizar sua compra em qualquer unidade da Drogaria
                        São Paulo ou Drogarias Pacheco, informe ao atendente que{" "}
                        <strong>você é cliente PoupaClub</strong>, além do
                        número de CPF.
                        <br />
                        <br />
                        <br />
                        Seu desconto será validado e você já economiza! Fácil e
                        rápido!
                      </h5>
                    </Row>
                  </>
                ) : (
                  <>
                    <Row className="mt-20 txt-primary justify-center">
                      <h6 className="h6-title-2 mr-ml-15">
                        {dadosWhiteLabel?.titulo_desconto_farma}
                      </h6>
                    </Row>
                    <Row className="mt-20 txt-primary">
                      <h5 className="font-400 subtit-farma mr-ml-15 mb-5">
                        {ReactHtmlParser(dadosWhiteLabel?.texto_desconto_farma)}
                      </h5>
                    </Row>
                  </>
                )}
              </Container>
            </Col>
          </Row>
        </div>

        <div className="mt-120">
          <Container>
            <Row className="txt-primary justify-center">
              <div
                className="text-pill"
                style={{
                  marginTop: "-45px",
                }}
              >
                {dadosWhiteLabel?.titulo_desc_farma ? (
                  <span
                    style={{
                      color: dadosWhiteLabel
                        ? dadosWhiteLabel?.whitelabel_text_color_2
                        : "",
                    }}
                  >
                    {dadosWhiteLabel?.titulo_desc_farma}
                  </span>
                ) : (
                  <span>
                    Ao fazer parte de nosso clube você terá acesso a descontos
                    exclusivos,
                    <br /> economizando a cada compra.
                  </span>
                )}
              </div>
            </Row>

            {!dadosWhiteLabel ? (
              <Row className="mt-60 txt-primary justify-around">
                <div className="mb-6">
                  <h5>a partir de</h5>
                  <h1 className="pctgm-2">30%</h1>
                  <h2 className="tipo-desc">
                    medicamento <br />
                    genérico <br /> tarjado
                  </h2>
                </div>
                <div className="mb-6">
                  <h5>a partir de</h5>
                  <h1 className="pctgm-2">15%</h1>
                  <h2 className="tipo-desc">
                    medicamento <br />
                    marca e similar <br /> tarjado
                  </h2>
                </div>
                <div>
                  <h5>a partir de</h5>
                  <h1 className="pctgm-2">10%</h1>
                  <h2 className="tipo-desc">
                    medicamento <br />
                    OTC
                  </h2>
                </div>
              </Row>
            ) : (
              <Container>
                <Row className="text-center justify-center">
                  <Col
                    lg={3}
                    md={6}
                    className="my-3 mw-dados-responsive-26"
                    style={{
                      color: dadosWhiteLabel
                        ? dadosWhiteLabel?.whitelabel_text_color_2
                        : "",
                    }}
                  >
                    <h2
                      className="pctgm-whitelabel"
                      style={{
                        color: dadosWhiteLabel
                          ? dadosWhiteLabel?.whitelabel_text_color_1
                          : "",
                      }}
                    >
                      {dadosWhiteLabel?.porcentagem_1}
                    </h2>
                    <h3>{dadosWhiteLabel?.texto_porcentagem_1}</h3>
                  </Col>
                  <Col
                    lg={3}
                    md={6}
                    className="my-3 mw-dados-responsive-26"
                    style={{
                      color: dadosWhiteLabel
                        ? dadosWhiteLabel?.whitelabel_text_color_2
                        : "",
                    }}
                  >
                    <h2
                      className="pctgm-whitelabel"
                      style={{
                        color: dadosWhiteLabel
                          ? dadosWhiteLabel?.whitelabel_text_color_1
                          : "",
                      }}
                    >
                      {dadosWhiteLabel?.porcentagem_2}
                    </h2>
                    <h3>{dadosWhiteLabel?.texto_porcentagem_2}</h3>
                  </Col>
                  <Col
                    lg={3}
                    md={6}
                    className="my-3 mw-dados-responsive-26"
                    style={{
                      color: dadosWhiteLabel
                        ? dadosWhiteLabel?.whitelabel_text_color_2
                        : "",
                    }}
                  >
                    <h2
                      className="pctgm-whitelabel"
                      style={{
                        color: dadosWhiteLabel
                          ? dadosWhiteLabel?.whitelabel_text_color_1
                          : "",
                      }}
                    >
                      {dadosWhiteLabel?.porcentagem_3}
                    </h2>
                    <h3>{dadosWhiteLabel?.texto_porcentagem_3}</h3>
                  </Col>
                </Row>
              </Container>
            )}

            <Row className="mt-60 text-center txt-primary justify-center">
              <div className="mt-3">
                {!dadosWhiteLabel ? (
                  <>
                    <span className="dedesco">
                      <strong>Nossos parceiros:</strong>{" "}
                    </span>

                    <img
                      src={img_drog_sao_paulo}
                      className="img-drogarias-2"
                      alt="Drogarias São Paulo"
                    />
                    <img
                      src={img_drog_pacheco}
                      className="img-drogarias-2"
                      alt="Drogarias Pacheco"
                    />
                  </>
                ) : (
                  <img
                    src={dadosWhiteLabel?.imagem_parceiros_desconto_img?.url}
                    alt="Parceiros"
                    width="100%"
                    height="100%"
                  />
                )}
              </div>
            </Row>
          </Container>
        </div>
      </div>

      <Container>
        <Row className="mt-60 txt-primary justify-center">
          <Col className="text-center">
            <h4>
              <strong
                style={{
                  color: dadosWhiteLabel
                    ? dadosWhiteLabel?.whitelabel_text_color_1
                    : "",
                }}
              >
                Conheça nossos cartões de descontos
              </strong>
            </h4>
          </Col>
        </Row>
        <Row className="mt-20 mb-0 txt-primary justify-center">
          <img
            top
            width
            src={dadosWhiteLabel?.imagem_cartoes_baixo_img?.url || Cartoes}
            alt="Adquira agora seu cartão"
            className="cartoes-conheca"
            onClick={() => {
              window.scrollTo(0, 0);
              history.push(
                `${dadosWhiteLabel?.url_whitelabel || "poupa-club"}/cartao`
              );
            }}
          />
        </Row>
      </Container>
    </>
  );
}

export default DescontoFarmacia;
