import React, { useEffect, useRef, useState } from 'react';
import { Form } from '@unform/web';
import {
  Row,
  Col,
  Button,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import { ValidationError } from 'yup';
import cep from 'cep-promise';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { FaLock } from 'react-icons/fa';

import MaskedInput from '../../../components/unformMaskedInput';
import InputUnform from '../../../components/unformInput';

import formatCEP from '../../../utils/formatCEP';
import formatDate from '../../../utils/formatDate';
import formatCPF from '../../../utils/formatCPF';
import formatCNPJ from '../../../utils/formatCNPJ';

import api from '../../../services/api';

import { cepMask, telefoneCelularMask } from '../../../utils/masks';

import alteraPFvalidator from '../../../validations/alteraDadosPFValidator';
import alteraPJvalidator from '../../../validations/alteraDadosPJValidator';

import ImagePreview from '../../../components/unformImagePreview';

import { useAuth } from '../../../contexts/auth';

let ultimoCep = '';

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'ml-3 btn btn-success',
    cancelButton: 'btn btn-danger',
  },
  buttonsStyling: false,
});

function AlterarDados() {
  const { changeUserPhoto } = useAuth();
  const [user, setUser] = useState();
  const [tipoPessoa, setTipoPessoa] = useState();
  const formRef = useRef(null);

  useEffect(() => {
    window.scrollTo(0, 0);
    getData();
  }, []);

  async function getData() {
    Swal.fire({
      title: 'Aguarde',
      allowOutsideClick: false,
      showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      const { data } = await api.get('/dados-usuario');

      if (data) {
        setUser(data);
        if (data?.foto_perfil) {
          formRef.current.setFieldValue('foto_perfil', data?.foto_perfil);
        }
        // console.log(data);
        if (data.cpf) {
          setTipoPessoa('PF');
        } else {
          setTipoPessoa('PJ');
        }
        Swal.close();
      } else {
        Swal.close();
        toast.error('Não existem dados para serem apresentados');
      }
    } catch (e) {
      Swal.close();
      toast.error('Não foi possível carregar os dados');
    }
  }

  async function handleSubmit(data) {
    try {
      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });

      if (tipoPessoa === 'PF') {
        await alteraPFvalidator(data);
      } else if (tipoPessoa === 'PJ') {
        await alteraPJvalidator(data);
      }

      if (data.foto_perfil) {
        var formData = new FormData();
        formData.append('file', data.foto_perfil);

        try {
          const { data: foto_salva } = await api.post('/imagens', formData);
          data.imagem_id = foto_salva.id;
        } catch (e) {
          toast.error('Erro ao salvar imagem!');
        }
      }

      let body;
      if (tipoPessoa === 'PJ') {
        body = {
          complemento: data.complemento,
          email: data.email,
          telefone_celular: data.celular && Number(data.celular),
          telefone: data.celular && Number(data.telefone),
          tipo: tipoPessoa,
          imagem_id: data.imagem_id,
        };
      } else {
        body = {
          email: data.email,
          telefone_celular: Number(data.celular),
          telefone: Number(data.telefone),
          tipo: tipoPessoa,
          imagem_id: data.imagem_id,
        };
      }

      const { data: { image } } = await api.patch('/alterar-dados', body);

      changeUserPhoto(image);
      Swal.close();
      toast.success('Dados alterados com sucesso');
    } catch (err) {
      const validationErrors = {};
      Swal.close();
      if (err instanceof ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
        toast.error('Verifique os campos em vermelho!');
      } else {
        toast.error('Ocorreu um erro tente novamente mais tarde!');
      }
    }
  }

  async function buscaCep(cepValue) {
    if (cepValue) {
      if (ultimoCep !== cepValue) {
        try {
          if (cepValue?.replaceAll('_', '')?.length === 9) {
            const result = await cep(cepValue);

            formRef.current.setData({
              ...formRef.current.getData(),
              bairro: result.neighborhood,
              cidade: result.city,
              estado: result.state,
              endereco: result.street,
            });
            ultimoCep = cepValue;
          } else if (cepValue?.length === 0) {
            formRef.current.setData({
              ...formRef.current.getData(),
              bairro: '',
              cidade: '',
              estado: '',
              endereco: '',
            });
          }
        } catch (error) {
          formRef.current.setData({
            ...formRef.current.getData(),
            bairro: '',
            cidade: '',
            estado: '',
            endereco: '',
          });
          toast.warn('CEP não encontrado!');
        }
      }
    }
  }

  async function deleteImage(item, cb) {
    swalWithBootstrapButtons
      .fire({
        title: `Tem certeza que deseja excluir esta imagem?`,
        text: 'Esta operação não poderá ser revertida!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          Swal.fire({
            title: 'Aguarde',
            allowOutsideClick: false,
            showConfirmButton: false,
            onBeforeOpen: () => {
              Swal.showLoading();
            },
          });
          api
            .delete(`imagem-user`)
            .then(() => {
              Swal.close();
              toast.success('Excluido com sucesso');
              formRef.current.setFieldValue('foto_perfil', null);
              changeUserPhoto(null);
              cb();
            })
            .catch((error) => {
              const msg =
                error?.response?.data?.error ||
                'Ocorreu um erro, tente novamente mais tarde!';
              swalWithBootstrapButtons.fire('Ops...', msg, 'error');
            });
        }
      });
  }

  return (
    <>
      <Row>
        <Col>
          <h5 className='txt-tertiary'>Alterar dados cadastrais</h5>
        </Col>
      </Row>
      <div className='card-form'>
        <Form
          onSubmit={(data) => {
            handleSubmit(data);
          }}
          ref={formRef}
        >
          <Row>
            <Col lg={2}>
              <Row>
                <Col>
                  <span className='placholder-top'>Foto de perfil</span>
                  <ImagePreview
                    name='foto_perfil'
                    handleDeleteImage={(cb) => deleteImage('foto_perfil', cb)}
                  />
                </Col>
              </Row>
            </Col>
            <Col lg={10}>
              <Row>
                {user && user.nome ? (
                  <Col lg={12}>
                    <span className='placholder-top'>Nome completo</span>
                    <InputGroup>
                      <Input
                        placeholder=''
                        type='text'
                        name='nome_completo'
                        className='input-field-inner'
                        defaultValue={user && (user.nome ? user.nome : '')}
                        disabled
                      />
                      <InputGroupAddon addonType='append'>
                        <InputGroupText>
                          <FaLock />
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </Col>
                ) : (
                  <>
                    <Col lg={7}>
                      <span className='placholder-top'>Razão social</span>
                      <InputGroup>
                        <Input
                          placeholder=''
                          type='text'
                          name='razao_social'
                          className='input-field-inner'
                          defaultValue={
                            user && (user.razao_social ? user.razao_social : '')
                          }
                          disabled
                        />
                        <InputGroupAddon addonType='append'>
                          <InputGroupText>
                            <FaLock />
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </Col>
                    <Col lg={5}>
                      <span className='placholder-top'>Nome fantasia</span>
                      <InputGroup>
                        <Input
                          placeholder=''
                          type='text'
                          name='nome_fantasia'
                          className='input-field-inner'
                          defaultValue={
                            user &&
                            (user.nome_fantasia ? user.nome_fantasia : '')
                          }
                          disabled
                        />
                        <InputGroupAddon addonType='append'>
                          <InputGroupText>
                            <FaLock />
                          </InputGroupText>
                        </InputGroupAddon>
                      </InputGroup>
                    </Col>
                  </>
                )}
                {user && user.cpf ? (
                  <Col lg={4}>
                    <span className='placholder-top'>CPF</span>
                    <InputGroup>
                      <Input
                        placeholder=''
                        type='text'
                        name='cpf'
                        className='input-field-inner'
                        defaultValue={
                          user && (user.cpf ? formatCPF(user.cpf) : '')
                        }
                        disabled
                      />
                      <InputGroupAddon addonType='append'>
                        <InputGroupText>
                          <FaLock />
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </Col>
                ) : (
                  <Col lg={4}>
                    <span className='placholder-top'>CNPJ</span>
                    <InputGroup>
                      <Input
                        placeholder=''
                        type='text'
                        name='cnpj'
                        className='input-field-inner'
                        defaultValue={
                          user &&
                          (user?.cnpj
                            ? formatCNPJ(
                                `00000000000000${user?.cnpj}`.slice(-14)
                              )
                            : '')
                        }
                        disabled
                      />
                      <InputGroupAddon addonType='append'>
                        <InputGroupText>
                          <FaLock />
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </Col>
                )}
                {user && user?.cpf && (
                  <Col lg={3}>
                    <span className='placholder-top'>Data de nascimento</span>
                    <InputGroup>
                      <Input
                        placeholder=''
                        type='text'
                        name='data_nasc'
                        className='input-field-inner'
                        defaultValue={
                          user &&
                          (user.nascimento ? formatDate(user.nascimento) : '')
                        }
                        disabled
                      />
                      <InputGroupAddon addonType='append'>
                        <InputGroupText>
                          <FaLock />
                        </InputGroupText>
                      </InputGroupAddon>
                    </InputGroup>
                  </Col>
                )}
              </Row>
            </Col>
          </Row>

          <Row>
            <Col lg={2}>
              <span className='placholder-top'>CEP</span>
              <InputGroup>
                <MaskedInput
                  className='input-field-inner'
                  placeholder=''
                  mask={cepMask}
                  removeEspecial
                  name='cep'
                  disabled
                  onChange={(e) => {
                    buscaCep(e.target.value);
                  }}
                  value={
                    user &&
                    (user.cep ? formatCEP(`00000000${user.cep}`.slice(-8)) : '')
                  }
                />
                <InputGroupAddon addonType='append'>
                  <InputGroupText>
                    <FaLock />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Col>
            <Col lg={5}>
              <span className='placholder-top'>Endereço</span>
              <InputGroup>
                <InputUnform
                  placeholder=''
                  type='text'
                  name='endereco'
                  className='input-field-inner'
                  defaultValue={
                    user && (user.logradouro ? user.logradouro : '')
                  }
                  disabled
                />
                <InputGroupAddon addonType='append'>
                  <InputGroupText>
                    <FaLock />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Col>
            <Col lg={2}>
              <span className='placholder-top'>Nº</span>
              <InputGroup>
                <InputUnform
                  placeholder=''
                  type='text'
                  name='numero'
                  className='input-field-inner'
                  disabled
                  defaultValue={user && (user.numero ? user.numero : '')}
                />
                <InputGroupAddon addonType='append'>
                  <InputGroupText>
                    <FaLock />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Col>
            <Col lg={3}>
              <span className='placholder-top'>Complemento</span>
              {tipoPessoa === 'PF' ? (
                <InputGroup>
                  <InputUnform
                    placeholder=''
                    type='text'
                    name='complemento'
                    className='input-field-inner'
                    defaultValue={user?.complemento || ''}
                    disabled
                  />
                  <InputGroupAddon addonType='append'>
                    <InputGroupText>
                      {tipoPessoa === 'PF' && <FaLock />}
                    </InputGroupText>
                  </InputGroupAddon>
                </InputGroup>
              ) : (
                <InputUnform
                  placeholder=''
                  type='text'
                  name='complemento'
                  className='input-field-inner'
                  defaultValue={user?.complemento || ''}
                />
              )}
            </Col>
          </Row>
          <Row>
            <Col lg={5}>
              <span className='placholder-top'>Bairro</span>
              <InputGroup>
                <InputUnform
                  placeholder=''
                  type='text'
                  name='bairro'
                  className='input-field-inner'
                  defaultValue={user && (user.bairro ? user.bairro : '')}
                  disabled
                />
                <InputGroupAddon addonType='append'>
                  <InputGroupText>
                    <FaLock />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Col>
            <Col lg={5}>
              <span className='placholder-top'>Cidade</span>
              <InputGroup>
                <InputUnform
                  placeholder=''
                  type='text'
                  name='cidade'
                  className='input-field-inner'
                  defaultValue={user && (user.cidade ? user.cidade : '')}
                  disabled
                />
                <InputGroupAddon addonType='append'>
                  <InputGroupText>
                    <FaLock />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Col>
            <Col lg={2}>
              <span className='placholder-top'>Estado</span>
              <InputGroup>
                <InputUnform
                  placeholder=''
                  type='text'
                  name='estado'
                  className='input-field-inner'
                  defaultValue={user && (user.estado ? user.estado : '')}
                  disabled
                />
                <InputGroupAddon addonType='append'>
                  <InputGroupText>
                    <FaLock />
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={4}>
              <span className='placholder-top'>E-mail</span>
              <InputUnform
                placeholder=''
                type='text'
                name='email'
                className='input-field-inner'
                defaultValue={user && (user.email ? user.email : '')}
              />
            </Col>
            <Col lg={4}>
              <span className='placholder-top'>Celular</span>
              <MaskedInput
                className='input-field-inner'
                name='celular'
                mask={telefoneCelularMask}
                removeEspecial
                value={user && (user.celular ? user.celular : '')}
              />
            </Col>
            {user && user.cpf ? (
              <Col lg={4}>
                <span className='placholder-top'>Residencial</span>
                <MaskedInput
                  className='input-field-inner'
                  name='telefone'
                  mask={telefoneCelularMask}
                  removeEspecial
                  value={user && (user.fixo ? user.fixo : '')}
                />
              </Col>
            ) : (
              <Col lg={4}>
                <span className='placholder-top'>Comercial</span>
                <MaskedInput
                  className='input-field-inner'
                  name='telefone'
                  mask={telefoneCelularMask}
                  removeEspecial
                  value={
                    user &&
                    (user.telefone_comercial ? user.telefone_comercial : '')
                  }
                />
              </Col>
            )}
          </Row>
          <Row className='mt-20'>
            <Col className='justify-end'>
              <Button color='green' className='mb-1 btn-save'>
                Alterar dados
              </Button>
            </Col>
          </Row>
        </Form>
      </div>
    </>
  );
}

export default AlterarDados;
