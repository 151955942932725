import React, { useRef } from 'react';
import { Col, Row, InputGroup, InputGroupAddon, Button } from 'reactstrap';
import { TiTabsOutline } from 'react-icons/ti';
import { toast } from 'react-toastify';
import SectionCartoes from '../../descontos/sectionCartoes';
import { useAuth } from '../../../contexts/auth';
import { useWhiteLabel } from '../../../contexts/whitelabel';

function LinkVenda() {
  const { user } = useAuth();

  const textRef = useRef(null);

  function copyToClipboard(e) {
    textRef.current.select();
    document.execCommand('copy');
    e.target.focus();
    toast.success('Link copiado!');
  }

  const { dadosWhiteLabel } = useWhiteLabel();

  return (
    <>
      <Row>
        <Col lg={7} className='mb-1'>
          <h5 className='txt-tertiary'>Portal de vendas</h5>
        </Col>
      </Row>
      <div className='card-form'>
        <Row>
          <Col className='offset-md-1 pt-3' md={10}>
            <SectionCartoes />
          </Col>
        </Row>
      </div>
      <br />
    </>
  );
}

export default LinkVenda;
