export default function formatCNPJExibicao(unformattedCNPJ) {
  const cnpjSplitted = unformattedCNPJ.split("");
  const cnpj =
    cnpjSplitted[0] +
    cnpjSplitted[1] +
    "." +
    cnpjSplitted[2] +
    cnpjSplitted[3] +
    cnpjSplitted[4] +
    "." +
    cnpjSplitted[5] +
    cnpjSplitted[6] +
    cnpjSplitted[7] +
    "/" +
    cnpjSplitted[8] +
    cnpjSplitted[9] +  
    cnpjSplitted[10] +
    cnpjSplitted[11] +
    "-" +
    cnpjSplitted[12] +
    cnpjSplitted[13];

  return cnpj;
}
