import React, { useEffect } from 'react';
import { Row, Col, Container } from 'reactstrap';
import cardFooter from '../../assets/card-footer.png';
import { useWhiteLabel } from '../../contexts/whitelabel';
function Sobre() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { dadosWhiteLabel } = useWhiteLabel();

  return (
    <>
      <div
        className={`justify-center ${
          !dadosWhiteLabel && 'title-background'
        } mt-60 mb-60`}
      >
        <Container>
          <Row className='mb-3'>
            <Col>
              <div className='w-100'>
                <h3
                  className='text-start bold txt-secondary'
                  style={{
                    color: dadosWhiteLabel
                      ? dadosWhiteLabel?.whitelabel_text_color_1
                      : '',
                  }}
                >
                  Quem somos
                </h3>
              </div>
            </Col>
          </Row>
          <br />
        </Container>
      </div>
      <Container className='container-sobre'>
        <Row className='mt-60'>
          <Col className='txt-tertiary'>
            {dadosWhiteLabel ? (
              <div
                className='txt-1'
                dangerouslySetInnerHTML={{
                  __html: dadosWhiteLabel.texto_sobre_nos,
                }}
              />
            ) : (
              <div className='txt-1'>
                O PoupaClub é uma empresa que disponibiliza o Cartão de
                Descontos e Benefícios exclusivos para você e sua família. São
                três modalidades de cartões. Cada um atende um tipo de
                necessidade.
                <br />
                <br />
                Com uma pequena mensalidade (via boleto ou cartão de crédito)
                você terá acesso a consultas* médicas,odontológicas e
                veterinárias, e também vários tipos de exames por preços que
                cabem no seu bolso. Não há carência ou limite de uso e nossos
                parceiros da área de saúde prestam serviços de acompanhamento e
                prevenção.
                <br />
                <br />
                *Não está incluso Internação e nem Emergência.
              </div>
            )}
          </Col>
          <Col>
            <img
              src={dadosWhiteLabel?.img_sobre_propaganda_img?.url || cardFooter}
              alt='Sobre nós'
              width='100%'
              className='img-sobre'
            />
          </Col>
        </Row>
      </Container>{' '}
    </>
  );
}

export default Sobre;
