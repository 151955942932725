import React, { useEffect, useState } from 'react';
import { Row, Col, Button, Input } from 'reactstrap';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import formatarDinheiro from '../../../utils/formatarDinheiro';
import api from '../../../services/api';
import iconeFiltro from '../../../assets/icone_filtro.png';
import AsyncSelect from '../../../components/AsyncSelect';

const statusAssinatura = {
  ACTIVE: 'Ativa',
  INACTIVE: 'Inativa',
  CANCELED: 'Cancelada',
  FAILED: "Falhou",
  undefined: '',
};

const returnDate = (date) =>
  date ? new Date(`${date}  03:00:00`).toLocaleDateString() : '';

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'ml-3 btn btn-success',
    cancelButton: 'btn btn-danger',
  },
  buttonsStyling: false,
});

function Assinatura() {
  let timeoutConsulta;
  const [cliente, setCliente] = useState(null);
  const [tipo, setTipo] = useState('PF');
  const [assinatura, setAssinatura] = useState(null);

  async function cancelarAssinatura() {
    try {
      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });

      const { data } = await api.put(`/cancelar-assinatura/${cliente.value}`);
      setAssinatura(data);

      Swal.close();
    } catch (err) {
      Swal.close();
      const error =
        err?.response?.data?.error || 'Ocorreu um erro, tente novamente!';

      toast.error(error);
    }
  }

  async function confirmaCancelamento() {
    swalWithBootstrapButtons
      .fire({
        title: `Confirma o cancelamento da assinatura do cliente?`,
        text:
          'O cliente será inativado. A assinatura só poderá ser ativada novamente por ele',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        reverseButtons: true,
      })
      .then(async (result) => {
        if (result.value) {
          await cancelarAssinatura();
        }
      });
  }

  const debounceConsulta = async (body) => {
    return await new Promise((resolve) => {
      clearTimeout(timeoutConsulta);

      timeoutConsulta = setTimeout(() => {
        resolve(filtrarConsulta(body));
      }, 500);
    });
  };

  async function filtrarConsulta({ value, param }) {
    const result = await api.get(
      `options/clientes?${param}=${value}&tipo=${tipo}`
    );
    return result.data.map((cliente) => ({
      ...cliente,
      value: cliente.id,
    }));
  }

  useEffect(() => {
    async function getCliente() {
      try {
        Swal.fire({
          title: 'Aguarde',
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });
        const { data } = await api.get(`/assinatura/${cliente.value}`);
        setAssinatura(data);
        Swal.close();
      } catch (err) {
        Swal.close();
        const error =
          err?.response?.data?.error || 'Ocorreu um erro, tente novamente!';
        toast.error(error);
      }
    }

    if (cliente) {
      getCliente();
    }
  }, [cliente]);

  return (
    <>
      <Row>
        <Col lg={11} className='mb-1'>
          <div className='justify-between'>
            <h5 className='txt-tertiary'>Assinatura</h5>
          </div>
        </Col>
      </Row>
      <div className='card-form'>
        <Row>
          <Col lg={3} sm={3} className='mb-1'>
            <select
              onChange={(e) => setTipo(e.target.value)}
              value={tipo}
              className='input-field-select-inner opts-select'
            >
              <option value='PF'>Pessoa física</option>
              <option value='PJ'>Pessoa jurídica</option>
            </select>
          </Col>

          <Col sm={9}>
            <Row>
              <Col lg={5} sm={6} className='mb-1'>
                <AsyncSelect
                  loadOptions={(value) =>
                    debounceConsulta({
                      value,
                      param: 'nome',
                    })
                  }
                  getOptionLabel={(option) =>
                    option.nome || option.nome_fantasia
                  }
                  onChange={setCliente}
                  value={cliente}
                  cacheOptions
                  isClearable
                  placeholder={
                    tipo === 'PF'
                      ? 'Busque pelo nome do cliente'
                      : 'Busque pelo nome fantasia'
                  }
                  noOptionsMessage={() => 'Digite o nome'}
                />
              </Col>

              <Col lg={5} sm={6} className='mb-1'>
                <AsyncSelect
                  loadOptions={(value) =>
                    debounceConsulta({
                      value,
                      param: 'cpf',
                    })
                  }
                  cacheOptions
                  onChange={setCliente}
                  value={cliente}
                  isClearable
                  getOptionLabel={(option) => option.cpf || option.cnpj}
                  placeholder={'Documento do cliente'}
                  noOptionsMessage={() => 'Digite o documento'}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col lg={4}>
            <span className='placholder-top'>Status</span>
            <Input
              className='input-field-inner'
              disabled
              value={statusAssinatura[assinatura?.status] || assinatura?.status}
            />
          </Col>
          <Col lg={4}>
            <span className='placholder-top'>Dia de cobrança</span>
            <Input
              className='input-field-inner'
              disabled
              value={assinatura?.dia_vencimento}
            />
          </Col>
          <Col lg={4}>
            <span className='placholder-top'>Valor</span>
            <Input
              className='input-field-inner'
              disabled
              value={
                assinatura?.plano?.valor &&
                formatarDinheiro.format(Number(assinatura?.plano?.valor))
              }
            />
          </Col>
        </Row>
        <Row>
          <Col lg={4}>
            <span className='placholder-top'>Último pagamento</span>
            <Input
              className='input-field-inner'
              disabled
              value={returnDate(assinatura?.ultimo_pagamento)}
            />
          </Col>
          <Col lg={4}>
            <span className='placholder-top'>Próximo pagamento</span>
            <Input
              className='input-field-inner'
              disabled
              value={returnDate(assinatura?.proximo_pagamento)}
            />
          </Col>
          <Col lg={4}>
            <br />
            {assinatura && assinatura.status === 'ACTIVE' && (
              <Button
                block
                color='danger'
                className='btn-save'
                onClick={confirmaCancelamento}
              >
                Cancelar assinatura
              </Button>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
}

export default Assinatura;
