import { Col, Row, Button, Label } from 'reactstrap';
import { Form } from '@unform/web';
import { MultiSelect } from 'react-multi-select-component';
import { toast } from 'react-toastify';
import validator from '../../../validations/alterarSenha';
import Swal from 'sweetalert2';
import { ValidationError } from 'yup';
import api from '../../../services/api';
import { useEffect, useRef, useState } from 'react';
import iconeFiltro from '../../../assets/icone_filtro.png';

import AsyncSelect from '../../../components/AsyncSelect';

function BloqueioParceiros() {
  const formRef = useRef(null);
  let timeoutConsulta;

  const [filtro, setFiltro] = useState(null);
  const [selected, setSelected] = useState([]);
  const [options, setOptions] = useState([]);

  useEffect(() => {
    window.scrollTo(0, 0);
    setSelected([]);
    async function getParceiros() {
      try {
        if (filtro?.value) {
          const result = await api.get('/all-marcas-parceiros');
          const resultBlacklist = await api.get(
            `/parceiros-wl/${filtro?.value}`
          );
          if (resultBlacklist?.data) {
            // console.log(resultBlacklist?.data);
            setSelected(
              resultBlacklist.data.map((marca) => ({
                value: marca.marca_id,
                label: marca?.marca?.nome,
              }))
            );
          }
          setOptions(
            result.data.map((marca) => ({
              value: marca.id,
              label: marca.nome,
            }))
          );
        }
      } catch (e) {
        toast.error('Erro ao buscar dados!');
      }
    }
    getParceiros();
  }, [filtro]);

  async function handleSubmit() {
    try {
      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      await api.post(`/save-blacklist/${filtro?.value}`, selected);

      Swal.close();
      toast.success('Operação realizada com sucesso');
    } catch (err) {
      Swal.close();
      const error =
        err?.response?.data?.error || 'Ocorreu um erro, tente novamente!';
      toast.error(error);
    }
  }

  const debounceConsulta = async (body) => {
    return await new Promise((resolve) => {
      clearTimeout(timeoutConsulta);

      timeoutConsulta = setTimeout(() => {
        resolve(filtrarConsulta(body));
      }, 500);
    });
  };

  async function filtrarConsulta({ value, param }) {
    const result = await api.get(`options/parceiros?${param}=${value}`);
    return result.data.map((parceiro) => ({
      value: parceiro.id,
      cnpj: parceiro.cnpj,
      nome: parceiro.nome,
    }));
  }

  return (
    <>
      <Row>
        <Col lg={12}>
          <h5 className='txt-tertiary'>
            Bloqueio de marcas de parceiros para Whitelabel
          </h5>
        </Col>
      </Row>
      <div className='card-form'>
        <Row>
          <Col lg={12}>
            <Row>
              <Col lg={4} sm={6} className='mb-1'>
                <AsyncSelect
                  loadOptions={(value) =>
                    debounceConsulta({
                      value,
                      param: 'nome',
                    })
                  }
                  getOptionLabel={(option) => option.nome}
                  onChange={setFiltro}
                  value={filtro}
                  cacheOptions
                  isClearable
                  placeholder={'Busque pelo nome'}
                  noOptionsMessage={() => 'Digite o nome'}
                />
              </Col>
              <Col lg={4} sm={6} className='mb-1'>
                <AsyncSelect
                  loadOptions={(value) =>
                    debounceConsulta({
                      value,
                      param: 'cnpj',
                    })
                  }
                  cacheOptions
                  onChange={setFiltro}
                  value={filtro}
                  isClearable
                  getOptionLabel={(option) => option.cnpj}
                  placeholder={'CNPJ'}
                  noOptionsMessage={() => 'Digite o CNPJ'}
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <hr />
        <Row>
          <Col lg={12}>
            <Form onSubmit={(data) => handleSubmit(data)} ref={formRef}>
              <div className='mb-3'>
                <Label className='label-radio'>
                  Selecione as marcas que deseja bloquear para:
                  <strong> {filtro?.nome}</strong>
                </Label>
                <MultiSelect
                  options={options}
                  value={selected}
                  onChange={setSelected}
                  labelledBy='Select'
                  overrideStrings={{
                    allItemsAreSelected: 'Todos os itens estão selecionados.',
                    clearSearch: 'Limpar busca',
                    noOptions: 'Sem opções',
                    search: 'Procurar',
                    selectAll: 'Selecionar todos',
                    selectSomeItems: 'Selecione...',
                  }}
                  disabled={!filtro?.value}
                />
              </div>
              <div className='justify-end'>
                <Button
                  color='green'
                  className='btn-save mt-1'
                  disabled={!filtro?.value}
                  onClick={handleSubmit}
                >
                  Bloquear
                </Button>
              </div>
            </Form>
          </Col>
        </Row>
      </div>
    </>
  );
}

export default BloqueioParceiros;
