import * as Yup from 'yup';


const regexData = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/
const regexCpf = /^([0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2}|[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}\-?[0-9]{2})$/

export default async function store(data) {
  const {
    conjuge,
    dependente1,
    dependente2,
    dependente3,
    dependente4,
    ...titular
  } = data;

  const schema = Yup.object().shape({
    nome: Yup.string().required('Obrigatório'),
    telefone_celular: Yup.string().required('Obrigatório'),
    email: Yup.string().email('Email inválido').required('Obrigatório'),
    cpf: Yup.string()
      .matches(regexCpf, 'CPF inválido')
      .required('Obrigatório'),
    data_nascimento: Yup.string().matches(regexData, 'Data inválida').required('Obrigatório'),
    sexo: Yup.string().required('Obrigatório'),
    cep: Yup.string().required('Obrigatório'),
    logradouro: Yup.string().required('Obrigatório'),
    numero: Yup.string().required('Obrigatório'),
    bairro: Yup.string().required('Obrigatório'),
    cidade: Yup.string().required('Obrigatório'),
    termos: Yup.string().required('Obrigatório'),
    pagamento: Yup.string().required('Obrigatório'),
    complemento: Yup.string(),
  });

  await schema.validate(titular, { abortEarly: false });
}
