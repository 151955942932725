import * as Yup from 'yup';

const regexCnpj = /^(\d{2}\.?\d{3}\.?\d{3}\/?\d{4}-?\d{2})$/;

export default async function store(data) {
  const schema = Yup.object().shape({
    razao_social: Yup.string().required('Obrigatório'),
    nome_fantasia: Yup.string().required('Obrigatório'),
    nome_responsavel: Yup.string().required('Obrigatório'),
    cnpj: Yup.string()
      .matches(regexCnpj, 'CNPJ inválido')
      .required('Obrigatório'),
    email: Yup.string().email('E-mail inválido!').required('Obrigatório'),
    cidade: Yup.string().required('Obrigatório'),
    cep: Yup.string().required('Obrigatório'),
    endereco: Yup.string().required('Obrigatório'),
    numero: Yup.string().required('Obrigatório'),
    bairro: Yup.string().required('Obrigatório'),
    estado: Yup.string().required('Obrigatório'),
    telefone: Yup.string().required('Obrigatório'),
  });

  await schema.validate(data, { abortEarly: false });
}
