import React, { useRef, useEffect } from 'react';
import AsyncSelect from 'react-select/async';
import { useField } from '@unform/core';
import { defaultTheme } from 'react-select';
const { colors } = defaultTheme;

const Svg = (p) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    focusable='false'
    role='presentation'
    {...p}
  />
);

const DropdownIndicator = () => (
  <div style={{ color: colors.neutral20, height: 24, width: 32 }}>
    <Svg>
      <path
        d='M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </Svg>
  </div>
);

const Select = ({ name, fullValue, ...rest }) => {
  const selectRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: selectRef.current,
      getValue: (ref) => {
        if (rest.isMulti) {
          if (!ref.state.value) {
            return [];
          }
          return ref.select.state?.value?.map((option) => option?.value);
        }
        if(fullValue) {
          return ref.select?.state?.value;
        }
        return ref.select?.state?.value?.value || ''; 
      },
      setValue: (ref, value) => {
        if (typeof value !== 'string') {
          ref.select.state.value = value;
          ref.state.value = value;
        } else {
          ref.select.state.value = { value, label: value };
          ref.state.value = { value, label: value };
        }
        ref.updater.enqueueForceUpdate(ref);
      },
    });
  }, [fieldName, registerField, rest.isMulti, fullValue]);

  const marcaStyle = {
    control: (styles, { isFocused }) => ({
      ...styles,
      borderRadius: '5px',
      boxShadow: isFocused && '0 0 4px 0px rgba(52, 141, 85, 0.75)',
      borderColor: error
        ? 'rgba(255, 0, 60, 0.75)'
        : isFocused
        ? 'rgba(52, 141, 85, .4) !important'
        : '#9fa2a1 !important;',
    }),
  };

  return (
    <>
      <AsyncSelect
        defaultValue={defaultValue}
        ref={selectRef}
        isSearchable={true}
        components={{ DropdownIndicator, IndicatorSeparator: null }}
        styles={marcaStyle}
        classNamePrefix={'react-select'}
        {...rest}
      />
      {error && <small className='error'>{error}</small>}
    </>
  );
};
export default Select;
