export default function convertStringDate(date) {
  if (date) {
    const arr = date.split('/');
    if (arr.length === 3) {
      return new Date(`${arr[2]}-${arr[1]}-${arr[0]}T00:00`);
    }
  }

  return null;
}
