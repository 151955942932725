import * as yup from "yup";

export default async function store(data) {
  const schema = yup.object().shape({
    empresa: yup.string().required("Obrigatório"),
    responsavel: yup.string().required("Obrigatório"),
    telefone: yup.string().required("Obrigatório"),
    email: yup.string().email("E-mail inválido!").required("Obrigatório"),
    numero_funcionários: yup
      .number()
      .required("Obrigatório")
      .typeError("Obrigatório"),
  });

  await schema.validate(data, {
    abortEarly: false,
  });
}
