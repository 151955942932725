import React, { useRef, useEffect } from "react";
import { useField } from "@unform/core";

export default function Textarea({ name, rows = 5, cols, ...rest }) {
  const textareaRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: textareaRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);

  return (
    <>
      <textarea
        ref={textareaRef}
        id={fieldName}
        defaultValue={defaultValue}
        rows={rows}
        cols={cols}
        {...rest}
      />

      {error && <small className="error">{error}</small>}
    </>
  );
}
