import { elastic as Menu } from 'react-burger-menu';
import { Link } from 'react-router-dom';
import { Button, UncontrolledCollapse } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useAuth } from '../contexts/auth';
import { FaChevronDown } from 'react-icons/fa';
import { useState } from 'react';
import { useWhiteLabel } from '../contexts/whitelabel';

const Sidebar = (props) => {
  const history = useHistory();
  const { signed, signOut, user, uppo } = useAuth();
  const [isOpen, setIsOpen] = useState(false);

  const { dadosWhiteLabel } = useWhiteLabel();

  // const openMenu = () => setIsOpen(true);
  const closeMenu = () => setIsOpen(false);

  const url = dadosWhiteLabel?.url_whitelabel || 'poupa-club';

  return (
    <Menu
      {...props}
      isOpen={isOpen}
      onStateChange={(state) => setIsOpen(state.isOpen)}
    >
      <div>
        <Button color='link' id='menu_descontos' className='bold'>
          Descontos
        </Button>
        <UncontrolledCollapse toggler='#menu_descontos'>
          <Link to={`/${url}/cartao`} onClick={closeMenu}>
            Cartão
          </Link>
          <Link to={`/${url}/beneficios-descontos`} onClick={closeMenu}>
            Benefícios
          </Link>
          <Link to={`/${url}/desconto-farmacia`} onClick={closeMenu}>
            Desconto Farmácia
          </Link>
          <Link to={`/${url}/beneficios-descontos`} onClick={closeMenu}>
            Como usar
          </Link>
          <Link to={`/${url}/beneficios-descontos`} onClick={closeMenu}>
            Onde usar
          </Link>
          <Link to={`/${url}/beneficios-descontos`} onClick={closeMenu}>
            Clube de Descontos
          </Link>
        </UncontrolledCollapse>
      </div>
      <div>
        <Button color='link' id='menu_parceiros' className='bold'>
          Parceiros
        </Button>
        <UncontrolledCollapse toggler='#menu_parceiros'>
          <button
            className='btn-none'
            onClick={() => {
              uppo();
              closeMenu();
            }}
          >
            Consulte nossos parceiros
          </button>
          <Link to={`/${url}/seja-parceiro`} onClick={closeMenu}>
            Seja nosso parceiro
          </Link>
        </UncontrolledCollapse>
      </div>
      <div>
        <Button color='link' id='menu_revendedores' className='bold'>
          Revendedores
        </Button>
        <UncontrolledCollapse toggler='#menu_revendedores'>
          <Link to={`/${url}/seja-revendedor`} onClick={closeMenu}>
            Seja nosso revendedor
          </Link>
        </UncontrolledCollapse>
      </div>
      <div>
        <Button color='link' id='menu_agendamento' className='bold'>
          Solicite um agendamento
        </Button>
        <UncontrolledCollapse toggler='#menu_agendamento'>
          <Link to={`/${url}/agendar-consulta-exame`} onClick={closeMenu}>
            Agendar exame
          </Link>
        </UncontrolledCollapse>
      </div>
      <span>
        <Button
          color='secondary'
          size='sm'
          className={`btn-pill ${
            dadosWhiteLabel?.whitelabel_button_1 &&
            dadosWhiteLabel?.whitelabel_button_1
          }`}
          onClick={() => {
            window.scrollTo(0, 0);
            closeMenu();
            history.push(`/${url}/cartao`);
          }}
        >
          Peça Já
        </Button>
      </span>
      {signed ? (
        <div>
          <Button color='link' id='menu_usuario' className='bold'>
            {user.nome}&nbsp; <FaChevronDown />
          </Button>
          <UncontrolledCollapse toggler='#menu_usuario'>
            <Link to='/acesso/welcome' onClick={closeMenu}>
              Acessos
            </Link>
            <Button
              color='link-orange'
              size='sm'
              className='btn-pill bold'
              onClick={() => {
                closeMenu();
                signOut();
              }}
            >
              Sair
            </Button>
          </UncontrolledCollapse>
        </div>
      ) : (
        <div>
          <Link
            to={`/${dadosWhiteLabel?.url_whitelabel || 'poupa-club'}/login`}
            onClick={closeMenu}
          >
            Entrar
          </Link>
        </div>
      )}
    </Menu>
  );
};

export default Sidebar;
