import React from 'react';
import AsyncSelect from 'react-select/async';
import { defaultTheme } from 'react-select';
const { colors } = defaultTheme;

const Svg = (p) => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    focusable='false'
    role='presentation'
    {...p}
  />
);

const DropdownIndicator = () => (
  <div style={{ color: colors.neutral20, height: 24, width: 32 }}>
    <Svg>
      <path
        d='M16.436 15.085l3.94 4.01a1 1 0 0 1-1.425 1.402l-3.938-4.006a7.5 7.5 0 1 1 1.423-1.406zM10.5 16a5.5 5.5 0 1 0 0-11 5.5 5.5 0 0 0 0 11z'
        fill='currentColor'
        fillRule='evenodd'
      />
    </Svg>
  </div>
);

const Select = ({ name, marca = false, error, ...rest }) => {
  const colourStyles = {
    control: (styles, { isFocused }) => ({
      ...styles,
      borderRadius: '5px',
      boxShadow: isFocused && '0 0 4px 0px rgba(52, 141, 85, 0.75)',
      borderColor: isFocused ? 'rgba(52, 141, 85, .4) !important' : '#DEE2E6',
    }),
    // input: (styles) => ({
    //   ...styles,
    //   color: 'var(--theme-menu-text-color-active)',
    // }),
    // singleValue: (styles) => ({
    //   ...styles,
    //   color: 'var(--theme-menu-text-color-active)',
    // }),
    // menu: (styles) => ({
    //   ...styles,
    //   color: 'var(--theme-menu-text-color-active)',
    //   backgroundColor: 'var(--bg-placeholder)',
    // }),
    // option: (provided, state) => ({
    //   ...provided,
    //   color: state.isSelected ? '#fff' : state.isFocused && '#000',
    // }),
    // container: (styles) => ({ ...styles, flex: '1 1 auto' }),
  };

  const marcaStyle = {
    control: (styles, { isFocused }) => ({
      ...styles,
      borderRadius: '5px',
      boxShadow: isFocused && '0 0 4px 0px rgba(52, 141, 85, 0.75)',
      borderColor: error
        ? 'rgba(255, 0, 60, 0.75)'
        : isFocused
        ? 'rgba(52, 141, 85, .4) !important'
        : '#9fa2a1 !important;',
    }),
  };

  return (
    <>
      <AsyncSelect
        isSearchable={true}
        components={{ DropdownIndicator, IndicatorSeparator: null }}
        classNamePrefix='react-select'
        styles={marcaStyle}
        {...rest}
      />
    </>
  );
};
export default Select;
