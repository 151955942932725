import React, { useRef, useEffect, useState } from 'react';
import { Col, Row, Button } from 'reactstrap';
import { toast } from 'react-toastify';
import { Form } from '@unform/web';
import Swal from 'sweetalert2';
import { ValidationError } from 'yup';
import { Link } from 'react-router-dom';

import Input from '../../../components/unformInput';
import api from '../../../services/api';
import tokenValidator from '../../../validations/tokenValidator';

function CadastroTokenJuno() {
  const formRef = useRef(null);
  const [alterar, setAlterar] = useState(false);

  useEffect(() => {
    async function getToken() {
      Swal.fire({
        title: 'Por favor, aguarde...',
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      const { data } = await api.get('/consulta-token');
      if (data) {
        formRef.current.setFieldValue('token_juno', data);
        setAlterar(true);
      }
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 500);
      Swal.close();
    }
    getToken();
  }, []);

  async function handleSubmit(data) {
    try {
      formRef.current.setErrors({});
      await tokenValidator(data);
      Swal.fire({
        title: 'Por favor, aguarde...',
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      if (alterar === true) {
        await api.put('/altera-token', data);
      } else {
        await api.post('/cadastro-token', data);
      }
      Swal.close();
      window.scrollTo(0, 0);
      toast.success(
        `Token ${alterar ? 'alterado' : 'cadastrado'} com sucesso!`
      );
    } catch (err) {
      const validationErrors = {};
      Swal.close();
      if (err instanceof ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
        toast.error('Verifique os campos em vermelho!');
      } else {
        toast.error('Ocorreu um erro tente novamente mais tarde!');
      }
    }
  }

  return (
    <>
      <h5 className='txt-tertiary copy-title'>
        {alterar ? 'Alterar' : 'Cadastrar'} Token Juno
      </h5>

      <div className='card-form'>
        <Form
          className='form-poupaclub mt-3'
          onSubmit={handleSubmit}
          ref={formRef}
        >
          <Row className='mt-2'>
            <Col lg={12}>
              <div>
                <Row>
                  <Col>
                    <Input
                      className='input-field-inner'
                      placeholder='Token Juno'
                      name='token_juno'
                    />
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <p className='text-copy-paragraph'>
                É necessário informar o <strong>Token Privado</strong> da sua
                conta <strong>Juno</strong>, para realização de pagamentos das
                suas comissões automaticamente.
              </p>
            </Col>
            <Col lg={12}>
              <p className='text-copy-paragraph'>
                Caso <strong>não possua</strong> uma conta no JUNO,{' '}
                <a href='https://app.juno.com.br/#/register/data'>
                  {' '}
                  clique aqui{' '}
                </a>{' '}
                e cria sua conta. Após a criação de sua conta, no menu lateral
                do sistema da Juno, clique em <strong>Integração</strong>, em
                seguida procure pelo campo <strong>TOKEN PRIVADO</strong>,
                clique no botão <strong>Gerar token privado</strong>, siga os
                passos para a criação e então copie e cole seu token no campo
                acima.
              </p>
            </Col>
            <Col lg={12}>
              <p className='text-copy-paragraph'>
                Caso ainda tenha dúvidas sobre o procedimento{' '}
                <Link to='/falar-suporte'>fale com nossa equipe</Link>.
              </p>
            </Col>
          </Row>

          <Col>
            <div className='justify-end '>
              <Button color='green' className='btn-save btn-lg'>
                {alterar ? 'Alterar' : 'Cadastrar'}
              </Button>
            </div>
          </Col>
        </Form>
      </div>
    </>
  );
}

export default CadastroTokenJuno;
