import useSWR from "swr";
import api from "../services/api";

import { useWhiteLabel } from "../contexts/whitelabel";

export default function useFetch(url, options) {
  const { parceirosBlacklist } = useWhiteLabel();

  const { data, error, mutate } = useSWR(
    url,
    async (url) => {
      const response = await api.get(url);

      return response.data;
    },
    {
      revalidateOnFocus: false,
      ...options,
    }
  );

  return { data, error, mutate };
}
