import * as Yup from "yup";

export default async function store(data) {
  const schema = Yup.object().shape({
    nome: Yup.string().required("Obrigatório"),
    email: Yup.string().email("Email inválido").required("Obrigatório"),
    documento: Yup.string().required("Obrigatório"),
    cep: Yup.string().required("Obrigatório"),
    logradouro: Yup.string().required("Obrigatório"),
    numero: Yup.string().required("Obrigatório"),
    bairro: Yup.string().required("Obrigatório"),
    cidade: Yup.string().required("Obrigatório"),
    termos: Yup.string().required("Obrigatório"),
    complemento: Yup.string(),
  });

  await schema.validate(data, { abortEarly: false });
}
