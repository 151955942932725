import { FaSearch } from "react-icons/fa";
import { Link, useHistory } from "react-router-dom";
import { useAuth } from "../contexts/auth";
import { useWhiteLabel } from "../contexts/whitelabel";
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Input,
  Button,
  Row,
  Col,
  InputGroup,
  InputGroupAddon,
  Label,
} from "reactstrap";
import {
  AiOutlineLogout,
  AiOutlineUser,
  AiOutlineDribbble,
  AiOutlineLock,
} from "react-icons/ai";
import defaultProfileImg from "../assets/default_user.jpg";
import { BrowserView, MobileView } from "react-device-detect";
import { useState } from "react";

function HeaderAcess({ toggle }) {
  let history = useHistory();
  const { signed, signOut, user, userImage } = useAuth();
  const { dadosWhiteLabel, loading } = useWhiteLabel();
  const [cpfCnpj, setCpfCnpj] = useState("");

  function verCliente() {
    history.push(
      `/${
        dadosWhiteLabel?.url_whitelabel || "poupa-club"
      }/consultaqr/${cpfCnpj}`
    );
  }

  return (
    <header className="header-inner" hidden={loading}>
      <div className="blocks-menus">
        <div className="menu-links">
          {toggle}
          <ul className="menu-intern h-100">
            {user.perfil !== "U" && (
              <BrowserView>
                <li className="li-entrar mr-3 h-100">
                  <Row>
                    <Col>
                      <InputGroup>
                        <Label
                          className="mt-4 mr-2"
                          style={{ fontSize: "13px", marginTop: "2px" }}
                        >
                          Consultar clientes
                        </Label>
                        <Input
                          className="input-field-search mt-3"
                          placeholder="Digite o CPF/CNPJ"
                          onChange={(e) =>
                            setCpfCnpj(
                              e.target.value
                                .replaceAll(".", "")
                                .replaceAll("-", "")
                            )
                          }
                        />
                        <InputGroupAddon addonType="prepend">
                          <Button className="mt-3 btn-sm" onClick={verCliente}>
                            <FaSearch size={12} />
                          </Button>
                        </InputGroupAddon>
                      </InputGroup>
                    </Col>
                  </Row>
                </li>
              </BrowserView>
            )}
            <li className="li-entrar mr-3 h-100">
              {signed ? (
                <UncontrolledDropdown className="h-100">
                  <DropdownToggle
                    caret
                    color="white"
                    size="sm"
                    className="bold h-100 align-items-center"
                    style={{
                      marginTop: "0px",
                      display: "flex",
                      alignItems: "center",
                      boxShadow: "none",
                    }}
                  >
                    <Link
                      className="txt-header-site"
                      to={`/${dadosWhiteLabel?.url_whitelabel || ""}`}
                    >
                      <AiOutlineDribbble size={14} className="icon-fix" />
                      &nbsp;Site
                    </Link>
                    <div
                      style={{
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        alignItems: "center",
                        display: "flex",
                      }}
                      className="h-100"
                    >
                      <BrowserView>{user?.nome}</BrowserView>
                      <MobileView>
                        {user?.nome?.length > 10
                          ? user?.nome?.slice(0, 10) + "..."
                          : user?.nome}
                      </MobileView>
                      <img
                        class="avatar"
                        src={userImage ? userImage : defaultProfileImg}
                        alt="Imagem perfil"
                      />
                    </div>
                  </DropdownToggle>
                  <DropdownMenu right>
                    <DropdownItem
                      className="bold dropdown-secondary"
                      onClick={() => {
                        history.push("/acesso/dados-cadastrais");
                      }}
                    >
                      <AiOutlineUser />
                      &nbsp;&nbsp; Perfil
                    </DropdownItem>
                    <DropdownItem
                      className="bold dropdown-secondary"
                      onClick={() => {
                        history.push(`/acesso/trocar-senha`);
                      }}
                    >
                      <AiOutlineLock />
                      &nbsp;&nbsp; Alterar senha
                    </DropdownItem>
                    {/* <DropdownItem
                      className="bold dropdown-secondary"
                      onClick={() => {
                        history.push(
                          `/${dadosWhiteLabel?.url_whitelabel || ""}`
                        );
                      }}
                    >
                      <AiOutlineDribbble />
                      &nbsp;&nbsp; Site
                    </DropdownItem> */}
                    <DropdownItem divider />
                    <DropdownItem
                      className="bold dropdown-danger"
                      onClick={signOut}
                    >
                      <AiOutlineLogout />
                      &nbsp;&nbsp; Sair
                    </DropdownItem>
                  </DropdownMenu>
                </UncontrolledDropdown>
              ) : (
                <>
                  <div className="txt-menu">
                    <Link
                      className="txt-header-site"
                      to={`/${dadosWhiteLabel?.url_whitelabel || ""}`}
                    >
                      <AiOutlineDribbble size={14} className="icon-fix" />
                      &nbsp;Site
                    </Link>
                    <Link
                      to={`/${
                        dadosWhiteLabel?.url_whitelabel || "poupa-club"
                      }/login`}
                    >
                      Entrar
                    </Link>
                  </div>
                </>
              )}
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
}

export default HeaderAcess;
