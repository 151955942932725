import React, { useState, useRef, useEffect } from "react";
import { Container, Col, Row, Button } from "reactstrap";
import { GoChevronDown } from "react-icons/go";
import { useHistory } from "react-router-dom";
import { Form } from "@unform/web";
import Input from "../../components/unformInput";
import UnformSelect from "../../components/unformSelect";
import Swal from "sweetalert2";
import MaskedInput from "../../components/unformMaskedInput";
import { toast } from "react-toastify";
import { ValidationError } from "yup";
import ReactHtmlParser from "react-html-parser";

import api from "../../services/api";
import { phoneMask, cpfMask } from "../../utils/masks";

import Cartoes from "../../assets/cartoes-poupaclub-removebg.png";

import agendaExameValidator from "../../validations/agendaExameValidator";

import imagemAgendar from "../../assets/agendaconsulta.png";

import { useWhiteLabel } from "../../contexts/whitelabel";

function AgendarAvaliacao() {
  const formRef = useRef(null);
  let history = useHistory();

  const { dadosWhiteLabel } = useWhiteLabel();

  const [segmentoId, setSegmentoId] = useState(null);

  const [phone, setPhone] = useState(null);
  const [cpf, setCpf] = useState(null);

  const [optionsCidades, setOptionsCidades] = useState([
    {
      value: "",
      label: "Cidade",
    },
  ]);

  const [optionsParceiros, setOptionsParceiros] = useState([
    {
      value: "",
      label: "Parceiros",
    },
  ]);

  async function handleSubmit(data) {
    try {
      formRef.current.setErrors({});
      await agendaExameValidator(data);
      Swal.fire({
        title: "Por favor, aguarde...",
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      data.cpf = cpf;
      data.telefone = phone;
      data.tipo = "C";
      const url = window.location.pathname.split("/");
      if (url[1] !== "poupa-club") {
        data.whitelabel = url[1];
      }
      await api.post("/email-agendamento", data);
      Swal.close();
      window.scrollTo(0, 0);
      return history.push(
        `/${dadosWhiteLabel?.url_whitelabel}/obrigado-pelo-interesse`
      );
    } catch (err) {
      const validationErrors = {};
      Swal.close();
      if (err instanceof ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
        toast.error("Verifique os campos em vermelho!");
      } else {
        toast.error("Ocorreu um erro tente novamente mais tarde!");
      }
    }
  }

  async function trigger(value) {
    setOptionsParceiros([
      {
        value: "",
        label: "Parceiros",
      },
    ]);
    setOptionsCidades([
      {
        value: "",
        label: "Cidade",
      },
    ]);
    if (value) {
      setSegmentoId(value);
      try {
        Swal.fire({
          title: "Por favor, aguarde...",
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });
        const { data } = await api.get(`/cidades-parceiros/${value}`);
        if (data.length) {
          let cidadeArray = [
            {
              value: "",
              label: "Cidade",
            },
          ];
          data.forEach((e) => {
            cidadeArray.push({
              value: e["cidade"],
              label: e["cidade"],
            });
          });
          let dups = [];
          let cidadeArrayUnique = cidadeArray.filter(function (el) {
            if (dups.indexOf(el.value) === -1) {
              dups.push(el.value);
              return true;
            }
            return false;
          });
          setOptionsCidades(cidadeArrayUnique);
          Swal.close();
        } else {
          toast.error("Não encontramos parceiros!");
          Swal.close();
        }
      } catch (error) {
        // console.log(error);
        toast.error("Ocorreu um erro interno!");
        Swal.close();
      }
    }
  }

  async function pesquisaParceiro(cidade) {
    if (cidade) {
      try {
        Swal.fire({
          title: "Por favor, aguarde...",
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });
        const { data } = await api.get(
          `/parceiros-cidade/${cidade}/${segmentoId}`
        );
        if (data.length) {
          let parceirosArray = [
            {
              value: "",
              label: "Parceiros",
            },
          ];
          data.forEach((e) => {
            parceirosArray.push({
              value: e["nome_fantasia"],
              label: e["nome_fantasia"],
            });
          });
          setOptionsParceiros(parceirosArray);
          Swal.close();
        } else {
          toast.error("Não encontramos parceiros!");
          Swal.close();
        }
      } catch (error) {
        toast.error("Ocorreu um erro interno!");
        Swal.close();
      }
    }
  }

  return (
    <Container>
      <Row className="mt-60 txt-primary">
        <Col lg={4} className="img-lado">
          <Row>
            <Col lg={12}>
              <img
                className="img-lado"
                src={
                  dadosWhiteLabel?.imagem_agendamento_img?.url
                    ? dadosWhiteLabel?.imagem_agendamento_img?.url
                    : imagemAgendar
                }
                alt="Agendar"
              />
              {!dadosWhiteLabel && (
                <>
                  <div className="txt-lado-cartao">
                    <p>
                      Para ter acesso aos agendamentos
                      <br /> de consultas e exames com descontos <br />{" "}
                      exclusivos, é necessário adquirir o<br />{" "}
                      <strong>Cartão de descontos PoupaClub</strong>
                    </p>
                  </div>
                  <div className="img-lado-cartao">
                    <img src={Cartoes} alt="Agendar" />
                  </div>
                </>
              )}
            </Col>
            <Col
              lg={12}
              className={
                dadosWhiteLabel
                  ? "mt-5 justify-center"
                  : "mt-120 justify-center"
              }
            >
              <Button
                color="secondary"
                size="lg"
                className={
                  dadosWhiteLabel
                    ? "btn-pill bold " + dadosWhiteLabel?.whitelabel_button_2
                    : "btn-pill bold"
                }
                onClick={() => {
                  window.scrollTo(0, 0);
                  history.push(
                    `/${
                      dadosWhiteLabel
                        ? dadosWhiteLabel.url_whitelabel
                        : "poupa-club"
                    }/cartao`
                  );
                }}
              >
                Peça agora
              </Button>
            </Col>
          </Row>{" "}
        </Col>
        <Col lg={4} className="mt-1 pr-3">
          <h2
            className="h2-title"
            style={{
              fontSize: 45,
              color: dadosWhiteLabel
                ? dadosWhiteLabel?.whitelabel_text_color_1
                : "",
            }}
          >
            Como <br /> funciona o agendamento
          </h2>
          <GoChevronDown
            size={40}
            color="orange"
            className="mt-1 mb-1 align-center-responsive"
            style={{
              color: dadosWhiteLabel ? dadosWhiteLabel?.whitelabel_arrows : "",
            }}
          />
          {!dadosWhiteLabel ? (
            <p className="txt-poupaclub-2 mt-1">
              Nós te auxiliamos na busca
              <br /> pelo serviço desejado. &nbsp;
              <strong>
                Basta
                <br /> preencher os dados abaixo e<br /> nossa equipe entrará em
                <br /> contato&nbsp;
              </strong>
              com as informações
              <br /> para o agendamento de sua
              <br /> consulta ou exame. Nosso
              <br /> contato é feito em até 48
              <br /> horas.
            </p>
          ) : (
            <p className="txt-poupaclub-2">
              {ReactHtmlParser(dadosWhiteLabel?.texto_agendamento)}
            </p>
          )}
        </Col>
        <Col lg={4} className="mt-1">
          <Row className="justify-center mb-3">
            <Col>
              <h3
                className="txt-secondary bold txt-solicite"
                style={{
                  color: dadosWhiteLabel
                    ? dadosWhiteLabel?.whitelabel_text_color_4
                    : "",
                }}
              >
                Nossa equipe irá direcionar o seu contato para o nosso parceiro
              </h3>
            </Col>
          </Row>
          <Form
            className="form-poupaclub mt-3"
            onSubmit={handleSubmit}
            ref={formRef}
          >
            <Row>
              <Col>
                <Input
                  className="input-field"
                  placeholder="Seu nome"
                  name="nome"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <MaskedInput
                  className="form-control input-field"
                  placeholder="CPF do titular do Cartão"
                  mask={cpfMask}
                  name="cpf"
                  onBlur={(e) => {
                    setCpf(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <MaskedInput
                  className="form-control input-field"
                  placeholder="Celular/WhatsApp com DDD"
                  mask={phoneMask}
                  name="telefone"
                  onBlur={(e) => {
                    setPhone(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  className="input-field"
                  placeholder="E-mail"
                  name="email"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <UnformSelect
                  type="select"
                  placeholder="O que você deseja?"
                  className="input-field-select"
                  name="deseja"
                  onChange={(e) => {
                    trigger(e.target.value);
                  }}
                  options={[
                    {
                      value: "",
                      label: "O que você deseja?",
                    },
                    {
                      value: "5",
                      label: "Avaliação odontológica",
                    },
                    {
                      value: "4",
                      label: "Consulta médica",
                    },
                  ]}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <UnformSelect
                  type="select"
                  placeholder="O que você deseja?"
                  className="input-field-select"
                  name="cidade"
                  options={optionsCidades}
                  onChange={(e) => {
                    pesquisaParceiro(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <UnformSelect
                  type="select"
                  placeholder="O que você deseja?"
                  className="input-field-select"
                  name="parceiros"
                  options={optionsParceiros}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="justify-center my-3">
                  <Button
                    color="secondary"
                    size="sm"
                    className={
                      dadosWhiteLabel
                        ? "btn-pill mb-3 bold btn-enviar-agendamento " +
                          dadosWhiteLabel?.whitelabel_button_3
                        : "btn-pill mb-3 bold btn-enviar-agendamento"
                    }
                  >
                    Enviar
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default AgendarAvaliacao;
