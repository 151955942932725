import React from "react";
import { Container, Row, Card } from "reactstrap";

import Obrigadointeresse from "../../assets/atendimento.png";

function ObrigadoPeloInteresse() {
  return (
    <main>
      <Container>
        <Row className="row-obrigado justify-center">
          <Card className="card-obrigado justify-center">
            <img
              className="obg-interesse"
              src={Obrigadointeresse}
              alt="Obrigado"
            />
            <div className="text-obg">
              <h6 className="txt-white bold">Obrigado pelo seu interesse</h6>

              <h6 className="txt-white obrigado-txt-inside white">
                Dentro de 48 horas, entraremos em
                <br /> contato via telefone. Caso não haja
                <br /> resposta, enviaremos um e-mail.
              </h6>
            </div>
          </Card>
        </Row>
      </Container>
    </main>
  );
}

export default ObrigadoPeloInteresse;
