import { useEffect, useRef, useState } from "react";
import {
  Col,
  Row,
  Button,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
} from "reactstrap";
import { Form } from "@unform/web";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { ValidationError } from "yup";
import iconeFiltro from "../../../assets/icone_filtro.png";

import api from "../../../services/api";
import formatCNPJ from "../../../utils/formatCNPJ";
import InputUnform from "../../../components/unformInput";
import AsyncSelect from "../../../components/AsyncSelect";

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: "ml-3 btn btn-success",
    cancelButton: "btn btn-danger",
  },
  buttonsStyling: false,
});

function VinculoRepresentante() {
  const formRef = useRef(null);
  let timeoutConsulta;

  const [modal, setModal] = useState(false);
  const [disabled, setDisabled] = useState(true);

  const [filtro1, setFiltro1] = useState(null);
  const [filtro2, setFiltro2] = useState(null);

  const [data, setData] = useState(null);
  const [idRepresentanteSuperior, setIdRepresentanteSuperior] = useState(null);

  useEffect(() => {
    if (filtro1 && !filtro2) {
      async function getVinculo() {
        // Swal.fire({
        //   title: "Aguarde",
        //   allowOutsideClick: false,
        //   showConfirmButton: false,
        //   onBeforeOpen: () => {
        //     Swal.showLoading();
        //   },
        // });
        try {
          const { data: representantes } = await api.get(
            `/listar-vinculos/${filtro1?.value}`
          );
          setData(representantes?.length ? representantes : null);
          setIdRepresentanteSuperior(filtro1?.value);
          Swal.close();
        } catch (e) {
          toast.error("Ocorreu um erro ao buscar representantes.");
          Swal.close();
        }
      }
      setDisabled(false);
      getVinculo();
    } else if (filtro1 && filtro2) {
      if (
        filtro1?.value === filtro2?.value ||
        filtro1?.cnpj === filtro2?.cnpj ||
        filtro1?.nome === filtro2?.nome
      ) {
        toast.error("Não é possível atribuir o revendedor a ele mesmo.");
        setFiltro2(null);
      } else if (data?.some((r) => r?.id_usuario === filtro2?.value)) {
        toast.error("Esse representante já está vinculado");
        setFiltro2(null);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtro1, filtro2]);

  const toggle = () => {
    formRef?.current?.reset();
    setModal(!modal);
  };

  async function handleSubmit(d) {
    formRef.current.setErrors({});
    try {
      // await validator(body);
      Swal.fire({
        title: "Aguarde",
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      const body = {
        id_representante_superior: filtro1?.value,
        percentual_participacao: d?.percentual,
      };

      await api.post(`/criar-vinculo/${filtro2?.value}`, body);

      setData([
        ...(data || []),
        {
          id: filtro2?.value,
          nome: filtro2?.nome,
          cnpj: filtro2?.cnpj,
          porcentagem: d?.percentual,
        },
      ]);
      toast.success("Representante vinculado com sucesso!");
      setFiltro2(null);
      Swal.close();
      toggle();
      // formRef.current.reset();
    } catch (err) {
      setFiltro2(null);
      const validationErrors = {};
      Swal.close();
      if (err instanceof ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
        toast.error("Verifique os campos em vermelho!");
      } else {
        const error =
          err?.response?.data?.error || "Ocorreu um erro, tente novamente!";
        toast.error(error);
      }
    }
  }

  const debounceConsulta = async (body) => {
    return await new Promise((resolve) => {
      clearTimeout(timeoutConsulta);

      timeoutConsulta = setTimeout(() => {
        resolve(filtrarConsulta(body));
      }, 500);
    });
  };

  async function filtrarConsulta({ value, param }) {
    const result = await api.get(`options/representantes?${param}=${value}`);
    return result.data.map((revendedor) => ({
      value: revendedor.usuario_id,
      cnpj: revendedor.cnpj,
      nome: revendedor.nome_fantasia,
    }));
  }

  async function handeDelete(id) {
    swalWithBootstrapButtons
      .fire({
        title: `Tem certeza que deseja excluir este vínculo?`,
        text: "Esta operação não poderá ser revertida!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          Swal.fire({
            title: "Aguarde",
            allowOutsideClick: false,
            showConfirmButton: false,
            onBeforeOpen: () => {
              Swal.showLoading();
            },
          });
          api
            .delete(`deletar-vinculo/${id}`, {
              data: {
                id_representante_superior: idRepresentanteSuperior,
              },
            })
            .then(() => {
              Swal.close();
              setData(data.filter((r) => r.id !== id));
              toast.success("Excluido com sucesso");
            })
            .catch((error) => {
              const msg =
                error?.response?.data?.error ||
                "Ocorreu um erro, tente novamente mais tarde!";

              swalWithBootstrapButtons.fire("Ops...", msg, "error");
            });
        }
      });
  }

  return (
    <>
      <Row>
        <Col lg={7}>
          <h5 className="txt-tertiary">Vínculo representante</h5>
        </Col>
      </Row>
      <div className="card-form">
        <Row>
          <Col lg={12}>
            <Row>
              {/* <Col lg={3} className='justify-between'>
                <h3 className='mt-1 txt-gray'>Busca</h3>
                <div className='d-flex txt-gray ml-1 my-auto'>
                  <h6 className='my-auto'>Filtros</h6>
                </div>
              </Col> */}
              <Col lg={4} sm={6} className="mb-1">
                <AsyncSelect
                  loadOptions={(value) =>
                    debounceConsulta({
                      value,
                      param: "nome",
                    })
                  }
                  getOptionLabel={(option) => option.nome}
                  onChange={setFiltro1}
                  value={filtro1 || null}
                  cacheOptions
                  isClearable
                  placeholder={"Busque pelo nome"}
                  noOptionsMessage={() => "Digite o nome"}
                />
              </Col>
              {/* <Col lg={4} sm={6} className="mb-1" hidden={true}>
                <AsyncSelect
                  loadOptions={(value) =>
                    debounceConsulta({
                      value,
                      param: "cnpj",
                    })
                  }
                  cacheOptions
                  onChange={setFiltro1}
                  value={filtro1 || null}
                  isClearable
                  getOptionLabel={(option) => option.cnpj}
                  placeholder={"CNPJ"}
                  noOptionsMessage={() => "Digite o CNPJ"}
                  key={2}
                />
              </Col> */}
            </Row>
          </Col>
        </Row>

        <hr />
        <Row>
          <Col lg={4}>
            <span className="placholder-top">Revendedor</span>
            <Input
              placeholder=""
              type="text"
              name="revendedor"
              className="input-field-inner"
              value={filtro1?.nome && filtro1.nome}
              disabled
            />
          </Col>
          <Col lg={3}>
            <span className="placholder-top">CNPJ</span>
            <Input
              placeholder=""
              type="text"
              name="cnpj"
              className="input-field-inner"
              value={
                filtro1?.cnpj &&
                formatCNPJ(`00000000000000${filtro1?.cnpj}`.slice(-14))
              }
              disabled
            />
          </Col>
        </Row>
      </div>
      <div className="card-form p-0">
        <Table responsive>
          <thead>
            <tr>
              <th>Representante</th>
              {/* <th>CNPJ</th> */}
              <th className="text-center">Participação(%)</th>
              <th className="text-center">Ação</th>
            </tr>
          </thead>
          <tbody>
            {data?.length ? (
              data.map((r) => (
                <tr key={r?.id}>
                  <td>{r?.nome}</td>
                  {/* <td>
                  {r?.cnpj && formatCNPJ(`00000000000000${r?.cnpj}`.slice(-14))}
                </td> */}
                  <td className="text-center">{r?.porcentagem}</td>
                  <td className="text-center">
                    <Button
                      color="link"
                      size="sm"
                      className="link-danger btn-save"
                      onClick={() => handeDelete(r?.id)}
                    >
                      Excluir
                    </Button>
                  </td>
                </tr>
              ))
            ) : (
              <tr></tr>
            )}
            <tr>
              <td>Adicionar representante</td>
              <td></td>
              <td className="text-center">
                <Button
                  color="link"
                  size="sm"
                  className="link-primary btn-save"
                  onClick={toggle}
                  disabled={disabled}
                >
                  Adicionar
                </Button>
              </td>
            </tr>
          </tbody>
        </Table>
      </div>
      <Modal isOpen={modal}>
        <ModalHeader toggle={toggle}>Vincular representante</ModalHeader>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <ModalBody>
            <Row>
              <Col lg={12} sm={12} className="mb-1">
                <span className="placholder-top mb-2">Representante</span>
                <AsyncSelect
                  loadOptions={(value) =>
                    debounceConsulta({
                      value,
                      param: "nome",
                    })
                  }
                  getOptionLabel={(option) => option.nome}
                  onChange={setFiltro2}
                  value={filtro2 || null}
                  cacheOptions
                  isClearable
                  placeholder={"Busque pelo nome"}
                  noOptionsMessage={() => "Digite o nome"}
                  key={3}
                />
              </Col>
              <Col lg={12} sm={12} className="mb-1" hidden={true}>
                <span className="placholder-top mb-2">CNPJ</span>
                <AsyncSelect
                  loadOptions={(value) =>
                    debounceConsulta({
                      value,
                      param: "cnpj",
                    })
                  }
                  cacheOptions
                  onChange={setFiltro2}
                  value={filtro2 || null}
                  isClearable
                  getOptionLabel={(option) => option.cnpj}
                  placeholder={"Busque pelo CNPJ"}
                  noOptionsMessage={() => "Digite o CNPJ"}
                  key={4}
                />
              </Col>
            </Row>
            <Row className="justify-center">
              <Col lg={12} sm={12} xs={12}>
                <span className="placholder-top mb-2">                      % Participação venda direta
</span>
                <InputUnform
                  placeholder=""
                  type="number"
                  min={1}
                  max={100}
                  step="0.1"
                  name="percentual"
                  className="input-field-inner"
                  defaultValue={1}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button color="green btn-save">Vincular</Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default VinculoRepresentante;
