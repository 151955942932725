import React, { useEffect, useRef } from "react";
import { useField } from "@unform/core";

export default function CustomInput({
  name,
  className,
  fieldlogin,
  min,
  max,
  ...rest
}) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: "value",
    });
  }, [fieldName, registerField]);

  return (
    <>
      <input
        // className={`form-control ${className}`.trim()}
        className={`${className ? className : ""} form-control ${
          error ? "error" : ""
        }`.trim()}
        ref={inputRef}
        defaultValue={defaultValue}
        min="0"
        {...rest}
      />
      {error && (
        <small className={`${!fieldlogin ? "error" : "errorLogin"}`}>
          {error}
        </small>
      )}
    </>
  );
}
