import React, { useRef } from "react";
import { Col, Row, InputGroup, InputGroupAddon, Button } from "reactstrap";
import { TiTabsOutline } from "react-icons/ti";
import { toast } from "react-toastify";
import { useAuth } from "../../../contexts/auth";

import { useWhiteLabel } from "../../../contexts/whitelabel";

function LinkVenda() {
  const { user } = useAuth();

  const textRef = useRef(null);

  function copyToClipboard(e) {
    textRef.current.select();
    document.execCommand("copy");
    e.target.focus();
    toast.success("Link copiado!");
  }

  const { dadosWhiteLabel } = useWhiteLabel();

  return (
    <>
      <Row>
        <Col lg={7} className="mb-1">
          <h5 className="txt-tertiary">Link de vendas</h5>
        </Col>
      </Row>
      <Row>
        <Col lg={12}>
          <div className="card-form">
            <form>
              <Row>
                <Col lg={12}>
                  <p className="text-copy-paragraph">
                    Envie para o novo comprador
                  </p>

                  <InputGroup className="responsive-copy">
                    <input
                      type="text"
                      className="input-field-inner text-copy"
                      ref={textRef}
                      defaultValue={`${window.location.origin}/${
                        dadosWhiteLabel
                          ? dadosWhiteLabel?.url_whitelabel
                          : "poupa-club"
                      }/cartao?vend=${user.id}`}
                      style={{
                        width: "calc(100% - 42px)",
                      }}
                    />
                    <InputGroupAddon addonType="prepend">
                      {document.queryCommandSupported("copy") && (
                        <div>
                          <Button
                            className="btn-copy w-100 border-radius-left-0"
                            onClick={copyToClipboard}
                            block
                          >
                            <TiTabsOutline size={15} />
                          </Button>
                        </div>
                      )}
                    </InputGroupAddon>
                  </InputGroup>
                </Col>
              </Row>
            </form>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default LinkVenda;
