const toString = (date) =>
  new Date(date)
    ?.toISOString()
    ?.split('T')?.[0]
    ?.split('-')
    ?.reverse()
    ?.join('/');

export function periodoDataLabel(obj) {
  if (obj?.start && obj?.end) {
    return (
      <small className='bold'>{`De ${toString(obj.start)} à ${toString(
        obj.end
      )}`}</small>
    );
  }

  return <>Selecione o período &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</>;
}
