import { Table, Col, Row, Button, UncontrolledCollapse } from 'reactstrap';
import AsyncSelect from '../../../components/AsyncSelect';
import api from '../../../services/api';
import React, { useEffect, useState } from 'react';
import formatCpf from '../../../utils/formatCPF';
import iconeFiltro from '../../../assets/icone_filtro.png';
import Swal from 'sweetalert2';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import Pagination from 'react-paginate';
import { toast } from 'react-toastify';
import { FiFilter } from 'react-icons/fi';

function Clientes() {
  const mostraTitulo = window.location.pathname === '/acesso/clientes';
  const [page, setPage] = useState(0);
  const [titurales, setData] = useState(null);
  const [filtro, setFiltro] = useState(null);
  let timeoutConsulta;

  const debounceConsulta = async (body) => {
    return await new Promise((resolve) => {
      clearTimeout(timeoutConsulta);

      timeoutConsulta = setTimeout(() => {
        resolve(filtrarConsulta(body));
      }, 500);
    });
  };

  async function filtrarConsulta({ value, param }) {
    const result = await api.get(`options/clientes?${param}=${value}`);
    return result.data.map((cliente) => ({
      value: cliente.id,
      cpf: cliente.cpf,
      nome: cliente.nome,
    }));
  }

  async function gerarSegundaVia(id) {
    try {
      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      const { data: pedido } = await api.get(`/cliente/segunda-via/${id}`);
      const link = pedido.link || pedido.installmentLink;
      if (link) {
        window.open(link);
      } else {
        toast.warn('Nenhuma parcela em aberto');
      }
    } catch (err) {
      const error =
        err?.response?.data?.error || 'Ocorreu um erro, tente novamente!';
      toast.error(error);
    } finally {
      Swal.close();
    }
  }

  // useEffect(() => {
  //   async function getTitular() {
  //     if (filtro) {
  //       const { data: result } = await api.get(`/titular/${filtro.value}`);
  //       setData(result);
  //     } else {
  //       setData(null);
  //     }
  //   }
  //   setTimeout(() => {
  //     window.scrollTo(0, 0);
  //   }, 500);
  //   getTitular();
  // }, [filtro]);

  useEffect(() => {
    async function getTitulares() {
      try {
        if (filtro) {
          const { data: result } = await api.get(`/titular/${filtro.value}`);
          setData({
            total: 1,
            pages: 1,
            rows: [result],
          });
        } else {
          let url = `/titulares?page=${page + 1}`;
          const { data: res } = await api.get(url);
          setData(res);
        }
        window.scrollTo(0, 0);
      } catch (error) {}
    }
    getTitulares();
  }, [filtro, page]);

  return (
    <>
      <Row>
        <Col lg={12}>
          {mostraTitulo && (
            <h5 className='txt-tertiary mb-3'>Consultar clientes</h5>
          )}
        </Col>
      </Row>

      <div className='card-form  p-0'>
        <div className='p-3 justify-content-between d-flex align-center'>
          <p className='txt-muted'>
            Total de registros: {titurales?.total || 0}
          </p>
          <Button color='muted' id='toggler'>
            <FiFilter /> Filtros
          </Button>
          <div />
        </div>
        <div className='filter-collapse'>
          <UncontrolledCollapse toggler='#toggler'>
            <Row className='mt-3'>
              <Col sm={6}>
                <AsyncSelect
                  loadOptions={(value) =>
                    debounceConsulta({
                      value,
                      param: 'nome',
                    })
                  }
                  getOptionLabel={(option) => option.nome}
                  onChange={setFiltro}
                  value={filtro}
                  cacheOptions
                  isClearable
                  placeholder={'Busque pelo nome'}
                  noOptionsMessage={() => 'Digite o nome'}
                />
              </Col>
              <Col sm={6}>
                <AsyncSelect
                  loadOptions={(value) =>
                    debounceConsulta({
                      value,
                      param: 'cpf',
                    })
                  }
                  cacheOptions
                  onChange={setFiltro}
                  value={filtro}
                  isClearable
                  getOptionLabel={(option) => option.cpf}
                  placeholder={'CPF'}
                  noOptionsMessage={() => 'Digite o CPF'}
                />
              </Col>
            </Row>
          </UncontrolledCollapse>
        </div>

        <Table responsive className='table-accordion'>
          <thead>
            <tr>
              <th>Nome do titular</th>
              <th>CPF</th>
              <th>Status</th>
              <th>2° via de boleto</th>
            </tr>
          </thead>
          <tbody>
            {titurales?.rows?.map((data) => (
              <React.Fragment key={data.id}>
                <tr>
                  <td>
                    {data?.dependentes?.length ? (
                      <Button
                        color='link'
                        size='small'
                        id={`cliente_${data?.id}`}
                        className='text-collpase'
                      >
                        {data?.nome}{' '}
                        <span className='small ml-1'>
                          <small>▼</small>
                        </span>
                      </Button>
                    ) : (
                      <span className='text-collpase'>{data?.nome}</span>
                    )}
                  </td>

                  <td className='w-20'>
                    {data?.cpf &&
                      formatCpf(`00000000000${data?.cpf}`.slice(-11))}
                  </td>
                  <td className='w-20'>
                    {data?.status === 'A' ? (
                      <div className='txt-primary'> {data?.status}</div>
                    ) : (
                      <div className='txt-danger'> {data?.status}</div>
                    )}
                  </td>
                  <td
                    className='w-20'
                    style={{
                      padding: 8,
                    }}
                  >
                    {data && (
                      <Button
                        color='link'
                        size='sm'
                        onClick={() => gerarSegundaVia(data?.id)}
                      >
                        <div className='txt-primary'>Gerar</div>
                      </Button>
                    )}
                  </td>
                </tr>

                {data?.dependentes?.length > 0 && (
                  <tr>
                    <td colSpan={4} className='td-collapse'>
                      <UncontrolledCollapse
                        toggler={`#cliente_${data?.id}`}
                        className='px-10'
                      >
                        <Table responsive>
                          <tbody>
                            {data?.dependentes?.map((dependente) => (
                              <tr key={dependente.id}>
                                <td>{dependente.nome}</td>
                                <td className='w-20'>
                                  {dependente?.cpf &&
                                    formatCpf(
                                      `00000000000${dependente?.cpf}`.slice(-11)
                                    )}
                                </td>
                                <td className='w-20'></td>
                                <td className='w-20'></td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </UncontrolledCollapse>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </Table>
        <div>
          <Pagination
            nextLabel={<FaChevronRight />}
            previousLabel={<FaChevronLeft />}
            pageCount={titurales?.pages || 1}
            onPageChange={({ selected }) => setPage(selected)}
            initialPage={page}
            containerClassName={'pagination justify-end'}
            activeClassName={'active'}
          />
        </div>
      </div>
    </>
  );
}

export default Clientes;
