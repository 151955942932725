import React, { useRef, useEffect } from 'react';
import ReactInputMask from 'react-text-mask';
import { useField } from '@unform/core';
import createNumberMask from 'text-mask-addons/dist/createNumberMask';

const defaultMaskOptions = {
  prefix: 'R$ ',
  thousandsSeparatorSymbol: '.',
  allowDecimal: true,
  decimalSymbol: ',',
  decimalLimit: 2,
};

const InputMask = ({
  name,
  className,
  removeEspecial,
  maskOptions,
  ...rest
}) => {
  const inputRef = useRef(null);
  const { fieldName, registerField, defaultValue, error } = useField(name);
  const currencyMask = createNumberMask({
    ...defaultMaskOptions,
    ...maskOptions,
  });

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
      getValue: (ref) => {
        if (removeEspecial) {
          return ref.textMaskInputElement.state.previousConformedValue.replace(
            /[^A-Z0-9]/gi,
            ''
          );
        }
        return ref.textMaskInputElement.state.previousConformedValue;
      },
    });
  }, [fieldName, registerField, removeEspecial]);
  return (
    <>
      <ReactInputMask
        ref={inputRef}
        defaultValue={defaultValue}
        className={`${className ? className : ''} form-control ${
          error ? 'error' : ''
        }`.trim()}
        mask={currencyMask}
        {...rest}
      />
      {error && <small className='error'>{error}</small>}
    </>
  );
};
export default InputMask;
