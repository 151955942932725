import { Col, InputGroup, InputGroupAddon, Row } from 'reactstrap';
import { useEffect, useRef, useState } from 'react';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import { Button } from 'reactstrap';
import { Form } from '@unform/web';
import { ValidationError } from 'yup';
import { useHistory } from 'react-router-dom';
import { FaPlus, FaTrash } from 'react-icons/fa';
import Input from '../../../components/unformInput';
import UnformAsync from '../../../components/unformAsyncSelect';

import api from '../../../services/api';
import { Scope } from '@unform/core';

function CriarProdutoInterno() {
  let timeoutConsulta;
  const formRef = useRef(null);
  const history = useHistory();
  const [participacoes, setParticipacoes] = useState([]);

  async function handleSubmit({ participacoes: ptc, ...data }) {
    try {
      const errors = {};
      let temErro = false;
      if (!data.nome) {
        temErro = true;
        errors.nome = 'Obrigatório';
      }
      if (!data.valor) {
        temErro = true;
        errors.valor = 'Obrigatório';
      }
      if (!data.vendedor) {
        data.vendedor = null;
      }

      formRef.current.setErrors(errors);

      data.participacoes = [];

      ptc.forEach((m, i) => {
        if (m.usuario || m.participacao) {
          if (!m.participacao) {
            temErro = true;
            formRef.current.setFieldError(
              `participacoes[${i}].participacao`,
              'Obrigatório'
            );
          } else if (!m.usuario) {
            temErro = true;
            formRef.current.setFieldError(
              `participacoes[${i}].usuario`,
              'Obrigatório'
            );
          } else {
            data.participacoes.push({
              participacao: Number(m.participacao),
              usuario: m.usuario.value,
            });
          }
        }
      });

      if (temErro) {
        toast.error('Verifique os campos em vermelho!');
        return;
      }

      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });

      const params1 = new URLSearchParams(window.location.search);
      const id = params1.get('id');
      if (id) {
        await api.put(`/produto_interno/${id}`, data);
        toast.success('Produto alterado com sucesso');
      } else {
        await api.post('/produto_interno', data);
        toast.success('Produto cadastrado com sucesso');
      }
      Swal.close();
      history.push('/acesso/produto_interno');
    } catch (err) {
      const validationErrors = {};
      Swal.close();
      if (err instanceof ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
        toast.error('Verifique os campos em vermelho!');
      } else {
        const error =
          err?.response?.data?.error || 'Ocorreu um erro, tente novamente!';
        toast.error(error);
      }
    }
  }

  useEffect(() => {
    async function getData() {
      const params1 = new URLSearchParams(window.location.search);
      const id = params1.get('id');
      if (id) {
        Swal.fire({
          scrollbarPadding: false,
          title: 'Aguarde',
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });
        try {
          const { data: res } = await api.get(`/produto_interno/${id}`);
          if (res.vendedor) {
            res.vendedor = {
              value: res.vendedor.id,
              label: res.vendedor.nome,
            };
          } else {
            delete res.vendedor;
          }

          if (res?.participacoes?.length > 1) {
            setParticipacoes(
              Array.from(Array(res?.participacoes?.length - 1).keys())
            );
          }

          formRef.current.setData(res);
        } catch (error) {}
        Swal.close();
      }
    }

    getData();
  }, []);

  const debounceConsultaRepresentantes = async (body) => {
    return await new Promise((resolve) => {
      clearTimeout(timeoutConsulta);

      timeoutConsulta = setTimeout(() => {
        resolve(filtrarRepresentantes(body));
      }, 500);
    });
  };

  async function filtrarRepresentantes({ value, param }) {
    const result = await api.get(`options/representantes?${param}=${value}`);

    return result.data.map((m) => ({
      label: m.nome_fantasia,
      value: m.usuario_id,
    }));
  }

  function addParticipacao() {
    setParticipacoes([...participacoes, null]);
  }

  function delParticipacao(index) {
    const arr = [];
    const { participacoes: list, ...rest } = formRef.current.getData();
    rest.participacoes = [list[0]];
    participacoes.forEach((a, i) => {
      if (index !== i) {
        arr.push(a);
        rest.participacoes.push(list[i + 1]);
      }
    });
    setParticipacoes(arr);
    console.log(rest);
    formRef.current.setData(rest);
  }

  return (
    <>
      <Row>
        <Col lg={11} className='mb-1'>
          <div className='justify-between'>
            <h5 className='txt-tertiary'>Criar produto interno</h5>
          </div>
        </Col>
      </Row>
      <Form onSubmit={handleSubmit} className='card-form' ref={formRef}>
        <Row>
          <Col xl={3} lg={4} md={6} sm={8} className='mb-2'>
            <span className='placholder-top'>Nome do produto</span>
            <Input
              className='input-field-inner'
              placeholder='Nome'
              name='nome'
              type='text'
            />
          </Col>
          <Col xl={2} lg={3} md={4} sm={4} className='mb-2'>
            <span className='placholder-top'>Valor</span>

            <Input
              className='input-field-inner '
              placeholder='Valor'
              name='valor'
              step='any'
              type='number'
              min='1'
            />
          </Col>
          <Col xl={3} lg={4} md={6} sm={8} className='mb-2'>
            <span className='placholder-top'>Usuário split comun</span>
            <UnformAsync
              name='vendedor'
              placeholder={'Parceiro/revendedor'}
              noOptionsMessage={() => 'Digite para pesquisar'}
              loadOptions={(value) =>
                debounceConsultaRepresentantes({
                  value,
                  param: 'nome',
                })
              }
            />
          </Col>
        </Row>

        <Scope path={`participacoes[0]`}>
          <Row>
            <Col lg={12} className='mb-3'>
              <h4 className='mt-3 txt-tertiary'>Split customizado</h4>
              <code>
                Caso informado o split customizado, o <b>usuário split comun</b>{' '}
                será ignorado na venda.
              </code>
            </Col>
            <Col xl={3} lg={4} md={6} sm={8} className='mb-2'>
              <span className='placholder-top'>Parceiro/revendedor</span>
              <UnformAsync
                name='usuario'
                fullValue
                placeholder={'Nome'}
                noOptionsMessage={() => 'Digite para pesquisar'}
                isClearable
                loadOptions={(value) =>
                  debounceConsultaRepresentantes({
                    value,
                    param: 'nome',
                  })
                }
              />
            </Col>

            <Col xl={2} lg={3} md={4} sm={4} className='mb-2'>
              <span className='placholder-top'>Participação %</span>
              <InputGroup>
                <div className='input--40'>
                  <Input
                    className='input-field-inner height-40'
                    placeholder='%'
                    name='participacao'
                    step='any'
                    type='number'
                    min='0.01'
                  />
                </div>

                <InputGroupAddon
                  addonType='prepend'
                  style={{
                    borderTopRightRadius: 5,
                    borderBottomRightRadius: 5,
                    maxHeight: 'calc(1.5em + 0.55rem + 6px) !important',
                  }}
                >
                  <div className='height-btn-group'>
                    <Button
                      color='primary'
                      size='sm'
                      onClick={addParticipacao}
                      className='h-100'
                    >
                      <FaPlus color='#fff' />
                    </Button>
                  </div>
                </InputGroupAddon>
              </InputGroup>
            </Col>
          </Row>
        </Scope>

        {participacoes.map((m, i) => (
          <Scope path={`participacoes[${i + 1}]`}>
            <Row>
              <Col xl={3} lg={4} md={6} sm={8} className='mb-2'>
                <span className='placholder-top'>Parceiro/revendedor</span>
                <UnformAsync
                  name='usuario'
                  fullValue
                  placeholder={'Nome'}
                  noOptionsMessage={() => 'Digite para pesquisar'}
                  isClearable
                  loadOptions={(value) =>
                    debounceConsultaRepresentantes({
                      value,
                      param: 'nome',
                    })
                  }
                />
              </Col>

              <Col xl={2} lg={3} md={4} sm={4} className='mb-2'>
                <span className='placholder-top'>Participação %</span>
                <InputGroup>
                  <div>
                    <Input
                      className='input-field-inner height-40'
                      placeholder='%'
                      name='participacao'
                      step='any'
                      type='number'
                      min='0.01'
                    />
                  </div>
                  <InputGroupAddon
                    addonType='prepend'
                    style={{
                      borderTopRightRadius: 5,
                      borderBottomRightRadius: 5,
                      height: 'calc(1.5em + 0.55rem + 6px) !important',
                    }}
                  >
                    <div className='height-btn-group'>
                      <Button
                        color='danger'
                        size='sm'
                        onClick={() => delParticipacao(i)}
                        style={{
                          height: '100%',
                        }}
                      >
                        <FaTrash color='#fff' />
                      </Button>
                    </div>
                  </InputGroupAddon>
                </InputGroup>
              </Col>
            </Row>
          </Scope>
        ))}

        <Row className='mt-20'>
          <Col className='justify-end'>
            <Button color='green' className='btn-save mb-1'>
              Salvar
            </Button>
          </Col>
        </Row>
      </Form>
    </>
  );
}

export default CriarProdutoInterno;
