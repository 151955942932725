import { useState, useEffect } from "react";
import { Col, Row, Button, Card, CardBody } from "reactstrap";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { TiExport } from "react-icons/ti";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

import html2canvas from "html2canvas";
import jsPdf from "jspdf";

import api from "../../../services/api";

function formatDataChatsMonth(obj) {
  const objectMonth = [
    {
      name: "Jan.",
    },
    {
      name: "Fev.",
    },
    {
      name: "Mar.",
    },
    {
      name: "Abr.",
    },
    {
      name: "Mai.",
    },
    {
      name: "Jun.",
    },
    {
      name: "Jul.",
    },
    {
      name: "Ago.",
    },
    {
      name: "Set.",
    },
    {
      name: "Out.",
    },
    {
      name: "Nov.",
    },
    {
      name: "Dez.",
    },
  ];
  const projetado = obj?.projetado;
  const realizado = obj?.realizado;
  const pendente = obj?.pendente;
  projetado.forEach((item) => {
    objectMonth[item.mes_vencimento - 1].Projetado = Number(item?.valor_total);
  });
  realizado.forEach((item) => {
    objectMonth[item.mes_vencimento - 1].Realizado = Number(item?.valor_total);
  });
  pendente.forEach((item) => {
    objectMonth[item.mes_vencimento - 1].Pendente = Number(item?.valor_total);
  });
  return objectMonth;
}

function formatDataChartAnual(obj) {
  let teste = {};
  Object.keys(obj).forEach((item) => {
    obj[item].forEach((f) => {
      if (teste[f.ano_vencimento]) {
        new Date().toLocaleDateString();
        teste[f.ano_vencimento] = {
          ...teste[f.ano_vencimento],
          [item]: f.valor_total,
        };
      } else {
        teste[f.ano_vencimento] = {
          [item]: f.valor_total,
        };
      }
    });
  });
  let data = Object.keys(teste).map((m) => ({
    name: m,
    ...teste[m],
  }));

  return data;
}

function Dashboard() {
  const [filtro, setFiltro] = useState(new Date().getFullYear());
  const [tipo, setTipo] = useState(null);

  const [dataMensal, setDataMensal] = useState([]);
  const [dataAnual, setDataAnual] = useState([]);
  const [dataTotalizadores, setDataTotalizadores] = useState();

  const printPDF = () => {
    const element = document.getElementById("remove_card_form_1");
    const element2 = document.getElementById("remove_card_form_2");
    const element3 = document.getElementById("remove_total_1");
    const element4 = document.getElementById("remove_total_2");
    const element5 = document.getElementById("remove_total_3");
    const element6 = document.getElementById("remove_total_4");
    element.classList.remove("card-form");
    element2.classList.remove("card-form");
    element3.classList.remove("totalizadores");
    element4.classList.remove("totalizadores");
    element5.classList.remove("totalizadores");
    element6.classList.remove("totalizadores");

    const domElement = document.getElementById("charts");
    html2canvas(domElement).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPdf({
        orientation: "l",
      });
      const imgProps = pdf.getImageProperties(imgData);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;

      pdf.addImage(imgData, "JPEG", 0, 0, pdfWidth, pdfHeight);
      pdf.save(`${new Date().toISOString()}.pdf`);
      element.classList.add("card-form");
      element2.classList.add("card-form");
      element3.classList.add("totalizadores");
      element4.classList.add("totalizadores");
      element5.classList.add("totalizadores");
      element6.classList.add("totalizadores");
    });
  };

  useEffect(() => {
    async function getChartData() {
      try {
        Swal.fire({
          title: "Aguarde",
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });
        const body = {
          ano: filtro,
          tipo,
        };
        const { data } = await api.post("/dashboard-financeiro", body);
        if (data) {
          const formattedData = formatDataChatsMonth(data);
          setDataMensal(formattedData);
        }
        Swal.close();
      } catch (e) {
        toast.error("Ocorreu um erro ao buscar dados!");
        Swal.close();
      }
    }
    async function getTotalizadoresData() {
      try {
        Swal.fire({
          title: "Aguarde",
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });
        const body = {
          ano: filtro,
        };
        const { data } = await api.post("/dashboard-vendas", body);
        if (data) {
          setDataTotalizadores({
            geral: data?.total,
            parceiros: data?.parceiros,
            revendedores: data?.revendedores,
            minhasVendas: data?.poupa,
          });
        }
        Swal.close();
      } catch (e) {
        toast.error("Ocorreu um erro ao buscar dados!");
        Swal.close();
      }
    }
    async function getChardAnual() {
      try {
        Swal.fire({
          title: "Aguarde",
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });
        const body = {
          tipo,
        };
        const { data } = await api.post("/dashboard-financeiro-anual", body);
        if (data) {
          setDataAnual(formatDataChartAnual(data));
        }
        Swal.close();
      } catch (e) {
        toast.error("Ocorreu um erro ao buscar dados!");
        Swal.close();
      }
    }
    getTotalizadoresData();
    getChardAnual();
    getChartData();
  }, [filtro, tipo]);

  return (
    <>
      <Row className="mb-3">
        <Col lg={8} className="d-flex justify-between align-center">
          <h5 className="txt-tertiary">Dashboard</h5>
        </Col>
        <Col lg={2}>
          <select
            className="input-field-select-inner opts-select"
            defaultValue={filtro}
            name="tipo"
            style={{
              height: 38,
            }}
            onChange={(e) => {
              setFiltro(e.target.value);
            }}
          >
            {[...new Array(5)].map((m, i) => {
              const ano = new Date().getFullYear() - 5 + (i + 1);

              return (
                <option key={i} value={ano}>
                  {ano}
                </option>
              );
            })}
            {[...new Array(5)].map((m, i) => {
              const ano = new Date().getFullYear() + (i + 1);

              return (
                <option key={i} value={ano}>
                  {ano}
                </option>
              );
            })}
          </select>
        </Col>
        <Col lg={2} className="d-flex justify-content-end align-center">
          <div className="w-100">
            <Button
              color="outline-primary"
              className="btn-save btn-block"
              onClick={() => printPDF()}
              size="md"
            >
              <TiExport size={26} />
              &nbsp;&nbsp;Exportar
            </Button>
          </div>
        </Col>
      </Row>
      <div id="charts">
        <Row>
          <TotalizadoresRender
            totalizadores={dataTotalizadores}
            setTipo={setTipo}
          />
        </Row>
        <Row>
          <Col lg={6}>
            <div className="card-form w-100 my-6" id="remove_card_form_1">
              <Row>
                <BarCharts data={dataMensal} title="Mensal" />
              </Row>
            </div>
          </Col>
          <Col lg={6}>
            <div className="card-form w-100 my-6" id="remove_card_form_2">
              <Row>
                <BarCharts data={dataAnual} title="Anual" />
              </Row>
            </div>
          </Col>
        </Row>
      </div>
    </>
  );
}

function BarCharts({ data, title }) {
  return (
    <Col className="container-bar-chart" lg={12}>
      <div className="d-flex justify-center">
        <h6 className="txt-tertiary mb-3">{title}</h6>
      </div>

      <ResponsiveContainer width="90%" height="90%">
        <BarChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis
            tickFormatter={(tick) => {
              return tick.toLocaleString("pt-BR");
            }}
          />
          <Tooltip />
          <Legend />
          <Bar dataKey="Projetado" fill="#7367f0" />
          <Bar dataKey="Realizado" fill="#28c76f" />
          <Bar dataKey="Pendente" fill="#ea5455" />
        </BarChart>
      </ResponsiveContainer>
    </Col>
  );
}

function TotalizadoresRender({ totalizadores, setTipo }) {
  const options = {
    lg: 3,
    md: 6,
    sm: 12,
  };

  return (
    <>
      <Col {...options}>
        <Card
          className="totalizadores"
          onClick={() => setTipo(null)}
          id="remove_total_1"
        >
          <CardBody>
            <h6 className="text-center">Vendas geral</h6>
            <h4 className="text-center">{totalizadores?.geral}</h4>
          </CardBody>
        </Card>
      </Col>
      <Col {...options}>
        <Card
          className="totalizadores"
          onClick={() => setTipo("P")}
          id="remove_total_2"
        >
          <CardBody>
            <h6 className="text-center">Vendas parceiros</h6>
            <h4 className="text-center">{totalizadores?.parceiros}</h4>
          </CardBody>
        </Card>
      </Col>
      <Col {...options}>
        <Card
          className="totalizadores"
          onClick={() => setTipo("R")}
          id="remove_total_3"
        >
          <CardBody>
            <h6 className="text-center">Vendas revendedores</h6>
            <h4 className="text-center">{totalizadores?.revendedores}</h4>
          </CardBody>
        </Card>
      </Col>
      <Col {...options}>
        <Card
          className="totalizadores"
          onClick={() => setTipo("POUPA")}
          id="remove_total_4"
        >
          <CardBody>
            <h6 className="text-center">Minhas vendas</h6>
            <h4 className="text-center">{totalizadores?.minhasVendas}</h4>
          </CardBody>
        </Card>
      </Col>
    </>
  );
}

export default Dashboard;
