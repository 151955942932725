export default function formatCEPExibicao(unformattedCep) {
  const cepSplitted = unformattedCep.split("");
  const cep =
    cepSplitted[0] +
    cepSplitted[1] +
    cepSplitted[2] +
    cepSplitted[3] +
    cepSplitted[4] +
    "-" +
    cepSplitted[5] +
    cepSplitted[6] +
    cepSplitted[7];

  return cep;
}
