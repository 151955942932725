import { useRef, useState } from "react";
import {
  Table,
  Col,
  Row,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { Form } from "@unform/web";
import { FaPlus, FaMinus } from "react-icons/fa";
import Input from "../../../components/unformInput";
import UnformSelect from "../../../components/unformSelect";
import MaskedInput from "../../../components/unformMaskedInput";
import { phoneMask, cpfMask, dateMask } from "../../../utils/masks";
import validator from "../../../validations/criarClienteDependente";
import Swal from "sweetalert2";
import { ValidationError } from "yup";
import { toast } from "react-toastify";
import api from "../../../services/api";
import useFetch from "../../../hooks/useFetch";
import convertStringDate from "../../../utils/convertStringDate";
import { differenceInCalendarDays } from "date-fns";
import parentesco from "../../../utils/parentesco";

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: "ml-3 btn btn-success",
    cancelButton: "btn btn-danger",
  },
  buttonsStyling: false,
});

function Dependentes() {
  const [modal, setModal] = useState(false);
  const [tipoDependente, setTipoDependente] = useState(false);
  const formRef = useRef(null);

  const { data: result, mutate } = useFetch("/dependentedotitular");

  const toggle = (tipo = false) => {
    setTipoDependente(tipo);
    formRef?.current?.reset();
    setModal(!modal);
  };

  async function handleSubmit(data) {
    formRef.current.setErrors({});
    try {
      Swal.fire({
        title: "Aguarde",
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });

      await validator(data);
      if (
        result?.cartao?.limitar_idade &&
        result?.cartao?.idade_limite &&
        tipoDependente
      ) {
        const diferencaDias = differenceInCalendarDays(
          new Date(),
          convertStringDate(data.data_nascimento)
        );

        const diferencaDiasIdade = differenceInCalendarDays(
          new Date(),
          new Date().setFullYear(
            new Date().getFullYear() - result?.cartao?.idade_limite
          )
        );

        if (diferencaDias >= diferencaDiasIdade) {
          formRef.current.setFieldError(
            "data_nascimento",
            `Idade deve ser menor que ${result?.cartao?.idade_limite} anos`
          );
          toast.error("Verifique os campos em vermelho!");
          Swal.close();
          return;
        }
      }

      const { data: dependente } = await api.post("/dependente", {
        ...data,
        data_nascimento: convertStringDate(data.data_nascimento),
        cpf: data.cpf ?? null,
      });

      mutate(
        {
          tipo: result.tipo,
          dependentes: [...result.dependentes, dependente],
          cartao: result.cartao,
        },
        false
      );
      await api.post("/email-dependente", data);
      toggle();
      Swal.close();
    } catch (err) {
      const validationErrors = {};
      Swal.close();
      if (err instanceof ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
        toast.error("Verifique os campos em vermelho!");
      } else {
        const error =
          err?.response?.data?.error || "Ocorreu um erro, tente novamente!";
        toast.error(error);
      }
    }
  }

  // function handeDelete(dependente) {
  //   swalWithBootstrapButtons
  //     .fire({
  //       title: `Tem certeza que deseja excluir ${dependente.nome} dos dependentes?`,
  //       text: "Esta operação não poderá ser revertida!",
  //       icon: "warning",
  //       showCancelButton: true,
  //       confirmButtonText: "Sim",
  //       cancelButtonText: "Não",
  //       reverseButtons: true,
  //     })
  //     .then((res) => {
  //       if (res.value) {
  //         Swal.fire({
  //           title: "Aguarde",
  //           allowOutsideClick: false,
  //           showConfirmButton: false,
  //           onBeforeOpen: () => {
  //             Swal.showLoading();
  //           },
  //         });
  //         api
  //           .delete(`dependente/${dependente.id}`)
  //           .then(async () => {
  //             Swal.close();
  //             mutate(
  //               {
  //                 tipo: result.tipo,
  //                 dependentes: result.dependentes.filter(
  //                   (f) => f.id !== dependente.id
  //                 ),
  //                 cartao: result.cartao,
  //               },
  //               false
  //             );
  //             await api.post("/email-dependente-excluir", dependente);
  //           })
  //           .catch((error) => {
  //             Swal.close();
  //             const msg =
  //               error?.response?.data?.error ||
  //               "Ocorreu um erro, tente novamente mais tarde!";

  //             swalWithBootstrapButtons.fire("Ops...", msg, "error");
  //           });
  //       }
  //     });
  // }

  return (
    <>
      <Row>
        <Col lg={12}>
          <h5 className="txt-tertiary mb-3">Dependentes</h5>
          <div className="card-form p-0">
            <Table responsive>
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Data de Nascimento</th>
                  <th></th>
                </tr>
              </thead>

              <tbody>
                {result?.cartao?.conjuge &&
                  !result?.dependentes?.some(
                    (f) => f.parentesco === "Conjuge"
                  ) && (
                    <tr>
                      <td style={{ color: "gray" }}>Conjuge</td>
                      <td></td>
                      <td>
                        <Button
                          color="link"
                          size="sm"
                          className="link-primary"
                          onClick={() => toggle(false)}
                        >
                          <FaPlus />
                          &nbsp;&nbsp;Adicionar
                        </Button>
                      </td>
                    </tr>
                  )}

                {result?.dependentes?.map((dependente) => (
                  <>
                    <tr key={dependente.id}>
                      <td>{dependente.nome}</td>
                      <td>
                        {new Date(
                          dependente.data_nascimento
                        ).toLocaleDateString()}
                      </td>
                      <td></td>
                      {/* <td>
                        <Button
                          color="link"
                          size="sm"
                          className="link-danger"
                          onClick={() => handeDelete(dependente)}
                        >
                          <FaMinus />
                          &nbsp;&nbsp;Excluir
                        </Button>
                      </td> */}
                    </tr>
                  </>
                ))}

                {result?.cartao?.numero_dependentes &&
                  result?.dependentes?.filter((f) => f.parentesco !== "Conjuge")
                    ?.length < result?.cartao?.numero_dependentes &&
                  [
                    ...Array(
                      result?.cartao?.numero_dependentes -
                        result?.dependentes?.filter(
                          (f) => f.parentesco !== "Conjuge"
                        ).length
                    ),
                  ].map((_, i) => (
                    <tr key={i}>
                      <td style={{ color: "gray" }}>Dependente</td>
                      <td></td>
                      <td>
                        <Button
                          color="link"
                          size="sm"
                          className="link-primary"
                          onClick={() => toggle(true)}
                        >
                          <FaPlus />
                          &nbsp;&nbsp;Adicionar
                        </Button>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </Table>
          </div>
        </Col>
      </Row>
      <Modal isOpen={modal}>
        <ModalHeader toggle={toggle}>Cadastro dependente</ModalHeader>
        <Form ref={formRef} onSubmit={handleSubmit}>
          <ModalBody>
            <Row>
              <Col lg={12} className="mb-3">
                <Input
                  className="input-field-inner"
                  placeholder="Nome completo"
                  name="nome"
                />
              </Col>
            </Row>
            <Row>
              <Col lg={6} className="mb-3">
                <UnformSelect
                  type="select"
                  className="input-field-inner"
                  placeholder="Parentesco"
                  name="parentesco"
                  options={
                    tipoDependente
                      ? parentesco.filter((f) => f.value !== "Conjuge")
                      : [
                          {
                            value: "Conjuge",
                            label: "Conjuge",
                          },
                        ]
                  }
                />
              </Col>
              <Col lg={6} className="mb-3">
                <MaskedInput
                  mask={dateMask}
                  className="input-field-inner"
                  placeholder="Data nascimento 00/00/0000"
                  name="data_nascimento"
                />
              </Col>
            </Row>
            <Row>
              <Col lg={6} className="mb-3">
                <MaskedInput
                  mask={cpfMask}
                  removeEspecial
                  className="input-field-inner"
                  placeholder="CPF"
                  name="cpf"
                />
              </Col>

              <Col lg={6} className="mb-3">
                <MaskedInput
                  mask={phoneMask}
                  removeEspecial
                  className="input-field-inner"
                  placeholder="Celular DDD"
                  name="telefone_celular"
                />
              </Col>
            </Row>
            <Row></Row>
          </ModalBody>
          <ModalFooter>
            <Button color="green btn-save">Cadastrar</Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default Dependentes;
