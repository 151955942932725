import React, { useState, useEffect } from 'react';
import {
  Col,
  Row,
  Table,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import Swal from 'sweetalert2';
import Pagination from 'react-paginate';
import { FaChevronLeft, FaChevronRight, FaEllipsisH } from 'react-icons/fa';
import { GrEdit, GrHide, GrFormView } from 'react-icons/gr';
import { useHistory, Link } from 'react-router-dom';
import api from '../../../services/api';

function ListarProdutosInternos() {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const history = useHistory();

  useEffect(() => {
    async function getData() {
      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });

      const { data } = await api.get(`/voucher?page=${page + 1}`);
      setData(data);
      window.scrollTo(0, 0);

      Swal.close();
    }
    getData();
  }, [page]);

  function handleEdit(produto) {
    history.push(`/acesso/criar_voucher?id=${produto.id}`);
  }

  async function handleToggleStatus(id) {
    const { data: res } = await api.get(`/toggle-status/voucher/${id}`);

    setData({
      ...data,
      rows: data.rows.map((m) => {
        if (m.id === id) {
          return {
            ...m,
            status: res.status || res,
          };
        }

        return m;
      }),
    });
  }

  return (
    <>
      <Row>
        <Col lg={12}>
          <h5 className='txt-tertiary mb-3'>Vouchers</h5>
        </Col>
      </Row>

      <div className='card-form  p-0'>
        <div className='p-3 justify-content-between d-flex align-center'>
          <p className='txt-muted'>Total de registros: {data?.total || 0}</p>
          {/* <Button color="muted" id="toggler">
            <FiFilter /> Filtros
          </Button> */}
          <div />
          <Link
            to='/acesso/criar_voucher'
            color='green'
            className='btn-save btn btn-green'
          >
            Adicionar
          </Link>
        </div>
        {/* <div className="filter-collapse">
          <UncontrolledCollapse toggler="#toggler">
            <Row className="mt-3">
             
            </Row>
          </UncontrolledCollapse>
        </div> */}

        <Table responsive>
          <thead>
            <tr>
              <th>Cupom</th>
              <th>% Desconto</th>
              <th>Status</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {data?.rows?.map((data) => (
              <tr key={data.id}>
                <td>
                  <span>{data?.codigo}</span>
                </td>
                <td>
                  <span>{Number(data?.desconto)}%</span>
                </td>
                <td>
                  <span>{data?.status}</span>
                </td>
                <td>
                  <UncontrolledButtonDropdown size='xs'>
                    <DropdownToggle color='link link-gray' size='xs'>
                      <FaEllipsisH />
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem
                        className='justify-start'
                        onClick={() => handleEdit(data)}
                      >
                        <GrEdit />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Editar
                      </DropdownItem>
                      <DropdownItem
                        className='justify-start'
                        onClick={() => handleToggleStatus(data.id)}
                      >
                        {data?.status === 'A' ? (
                          <>
                            <GrHide />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Inativar
                          </>
                        ) : (
                          <>
                            <GrFormView />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ativar
                          </>
                        )}
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledButtonDropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <Pagination
          nextLabel={<FaChevronRight />}
          previousLabel={<FaChevronLeft />}
          pageCount={Number(data?.pages) || 1}
          onPageChange={({ selected }) => setPage(selected)}
          initialPage={page}
          containerClassName={'pagination justify-end'}
          activeClassName={'active'}
        />
      </div>
    </>
  );
}

export default ListarProdutosInternos;
