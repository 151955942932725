import { Col, Row } from "reactstrap";
import { useAuth } from "../../../contexts/auth";

import saude from "../../../assets/saude-sempre.png";
import adm from "../../../assets/adm.png";
import par from "../../../assets/parceiro.png";
import rep from "../../../assets/representante.png";
import { useWhiteLabel } from "../../../contexts/whitelabel";

function Welcome() {
  const { user } = useAuth();
  const { dadosWhiteLabel } = useWhiteLabel();

  return (
    <Row className="">
      <Col
        md={6}
        className="offset-md-3 offset-sm-0 justify-center dashboard-img mt-60"
      >
        {user.perfil === "U" && (
          <img
            className="shadow-img"
            src={dadosWhiteLabel?.imagem_login_cliente_img?.url || saude}
            alt="Acesso cliente"
          />
        )}
        {user.perfil === "R" && (
          <img
            className="shadow-img"
            src={dadosWhiteLabel?.imagem_login_revendedor_img?.url || rep}
            alt="Acesso representante"
          />
        )}
        {user.perfil === "P" && (
          <img
            className="shadow-img"
            src={dadosWhiteLabel?.imagem_login_parceiro_img?.url || par}
            alt="Acesso parceiro"
          />
        )}
        {user.perfil === "A" && !dadosWhiteLabel && (
          <img src={adm} alt="Acesso admin" className="shadow-img" />
        )}
      </Col>
    </Row>
  );
}

export default Welcome;
