import React, { useState, useRef } from "react";
import { Container, Col, Row, Button } from "reactstrap";
import { GoChevronDown } from "react-icons/go";
import { Form } from "@unform/web";
import Input from "../../components/unformInput";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";
import MaskedInput from "../../components/unformMaskedInput";
import { toast } from "react-toastify";
import { ValidationError } from "yup";
import ReactHtmlParser from "react-html-parser";

import api from "../../services/api";
import { phoneMask } from "../../utils/masks";

import SejaRevendedorValidator from "../../validations/sejaRevendedorValidator";

import imagemRevendedor from "../../assets/sejarevendedor.png";

import { useWhiteLabel } from "../../contexts/whitelabel";

function SejaRevendedor() {
  const formRef = useRef(null);
  let history = useHistory();

  const [phone, setPhone] = useState(null);

  const { dadosWhiteLabel } = useWhiteLabel();

  async function handleSubmit(data) {
    try {
      formRef.current.setErrors({});
      await SejaRevendedorValidator(data);
      Swal.fire({
        title: "Por favor, aguarde...",
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      data.telefone = phone;
      data.tipo = "R";
      const url = window.location.pathname.split("/");
      if (url[1] !== "poupa-club") {
        data.whitelabel = url[1];
      }
      await api.post("/email", data);
      Swal.close();
      window.scrollTo(0, 0);
      return history.push(
        `/${
          dadosWhiteLabel?.url_whitelabel || "poupa-club"
        }/obrigado-pelo-interesse`
      );
    } catch (err) {
      const validationErrors = {};
      Swal.close();
      if (err instanceof ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
        toast.error("Verifique os campos em vermelho!");
      } else {
        toast.error("Ocorreu um erro tente novamente mais tarde!");
      }
    }
  }
  return (
    <Container>
      <Row className="mt-60 txt-primary">
        <Col lg={4} className="mb-1">
          <img
            className="img-lado"
            src={
              dadosWhiteLabel?.imagem_seja_revendedor_img?.url
                ? dadosWhiteLabel?.imagem_seja_revendedor_img?.url
                : imagemRevendedor
            }
            alt="Seja revendedor"
          />
        </Col>
        <Col lg={4} className="my-1">
          <h2
            className="h2-title"
            style={{
              fontSize: 45,
              color: dadosWhiteLabel
                ? dadosWhiteLabel?.whitelabel_text_color_1
                : "",
            }}
          >
            Vantagens <br /> de ser nosso revendedor(a)
          </h2>
          <GoChevronDown
            style={{
              color: dadosWhiteLabel ? dadosWhiteLabel?.whitelabel_arrows : "",
            }}
            size={40}
            color="orange"
            className="mt-1 mb-1 ml-2"
          />
          {!dadosWhiteLabel ? (
            <p className="txt-poupaclub-2">
              <strong>
                Essa é sua chance de obter
                <br /> renda extra sendo
                <br /> revendedor
              </strong>{" "}
              do Cartão
              <br /> PoupaClub. Você terá
              <br /> flexibilidade em seu horário e<br /> o que é mais
              importante,
              <br />
              poderá oferecer um produto
              <br /> de qualidade a seus clientes.
              <br />
              <br />
              Faça você mesmo seu
              <br /> lucro! Entre agora em contato
              <br /> conosco.
            </p>
          ) : (
            <p className="txt-poupaclub-2">
              {ReactHtmlParser(dadosWhiteLabel?.texto_seja_revendedor)}
            </p>
          )}
        </Col>
        <Col lg={4} className="my-1">
          <Row className="justify-center mb-3">
            <Col>
              <h4
                className="txt-secondary bold txt-solicite"
                style={{
                  color: dadosWhiteLabel
                    ? dadosWhiteLabel?.whitelabel_text_color_4
                    : "",
                }}
              >
                {!dadosWhiteLabel
                  ? "Solicite o contato de nossa equipe para se tornar um Revendedor PoupaClub"
                  : dadosWhiteLabel?.form_header_seja_revendedor}
              </h4>
            </Col>
          </Row>
          <Form
            className="form-poupaclub"
            onSubmit={handleSubmit}
            ref={formRef}
          >
            <Row>
              <Col>
                <Input
                  className="input-field"
                  placeholder="Seu nome"
                  name="nome"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  className="input-field"
                  placeholder="Cidade"
                  name="cidade"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <MaskedInput
                  className="form-control input-field"
                  placeholder="Telefone / Celular com DDD"
                  mask={phoneMask}
                  name="telefone"
                  onChange={(e) => {
                    setPhone(e.target.value);
                  }}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Input
                  className="input-field"
                  placeholder="E-mail"
                  name="email"
                  type="email"
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="justify-center my-3">
                  <Button
                    color="secondary"
                    size="sm"
                    className={
                      dadosWhiteLabel
                        ? "btn-pill mb-3 bold btn-enviar-agendamento " +
                          dadosWhiteLabel?.whitelabel_button_3
                        : "btn-pill mb-3 bold btn-enviar-agendamento"
                    }
                  >
                    Enviar
                  </Button>
                </div>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    </Container>
  );
}

export default SejaRevendedor;
