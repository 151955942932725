import * as Yup from "yup";
const regexData = /^(?:(?:31(\/|-|\.)(?:0?[13578]|1[02]))\1|(?:(?:29|30)(\/|-|\.)(?:0?[13-9]|1[0-2])\2))(?:(?:1[6-9]|[2-9]\d)?\d{2})$|^(?:29(\/|-|\.)0?2\3(?:(?:(?:1[6-9]|[2-9]\d)?(?:0[48]|[2468][048]|[13579][26])|(?:(?:16|[2468][048]|[3579][26])00))))$|^(?:0?[1-9]|1\d|2[0-8])(\/|-|\.)(?:(?:0?[1-9])|(?:1[0-2]))\4(?:(?:1[6-9]|[2-9]\d)?\d{2})$/;
const regexCpf = /^([0-9]{3}\.?[0-9]{3}\.?[0-9]{3}\-?[0-9]{2}|[0-9]{2}\.?[0-9]{3}\.?[0-9]{3}\/?[0-9]{4}\-?[0-9]{2})$/;

export default async function store(data) {
  const schema = Yup.object().shape({
    nome: Yup.string().required("Obrigatório"),
    cpf: data.cpf
      ? Yup.string().matches(regexCpf, "CPF inválido")
      : Yup.string(),
    data_nascimento: Yup.string()
      .matches(regexData, "Data inválida")
      .required("Obrigatório"),
    parentesco: Yup.string().required("Obrigatório"),
  });
  await schema.validate(data, { abortEarly: false });
}
