import { endOfMonth, startOfMonth } from 'date-fns';
import pt from 'date-fns/locale/pt-BR';
import { useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { IoInformationCircleOutline } from 'react-icons/io5';
import {
  Button,
  Col,
  Row,
  UncontrolledPopover,
  UncontrolledTooltip,
} from 'reactstrap';
import cartaoBlack from '../../../assets/cartao_black.png';
import cartaoEspecial from '../../../assets/cartao_especial.png';
import cartaoGold from '../../../assets/cartao_gold.png';
import iconeData from '../../../assets/icone_data.png';
import { useWhiteLabel } from '../../../contexts/whitelabel';
import useFecth from '../../../hooks/useFetch';
import api from '../../../services/api';
import formatarDinheiro from '../../../utils/formatarDinheiro';
import { periodoDataLabel } from '../../../utils/periodoData';
registerLocale('pt-BR', pt);

const inicioMes = startOfMonth(new Date());
const fimMes = endOfMonth(new Date());

const calculaConversao = (faturado, emAberto) => {
  if (faturado && emAberto) {
    const numFaturado = Number(faturado);

    const esperado = Number(emAberto);

    const conversao = (numFaturado / esperado || 0) * 100;

    return conversao;
  }

  return 0;
};

const calculaConversaoPeriodo = (faturado, esperado) => {
  if (faturado && esperado) {
    const conversao = (Number(faturado) / Number(esperado) || 0) * 100;

    return conversao.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
  }

  return 0;
};

function RelatorioAdmin() {
  const [dateFaturamento, setDateFaturamento] = useState({
    start: inicioMes,
    end: fimMes,
  });
  const [dateVenda, setDateVenda] = useState({
    start: inicioMes,
    end: fimMes,
  });
  const [faturamentoPeriodo, setFaturamentoPeriodo] = useState({
    cobrancas: 0,
    cobrancasPagas: 0,
    faturamentoEsperado: { valor: '' },
    faturamentoReal: { valor: '' },
  });

  const [vendas, setVendas] = useState([]);

  const { data: faturamento } = useFecth('/faturamento');
  const { dadosWhiteLabel } = useWhiteLabel();

  const onChangeFaturamento = (dates) => {
    const [start, end] = dates;
    setDateFaturamento({ start, end });
  };

  const onChangeVenda = (dates) => {
    const [start, end] = dates;
    setDateVenda({ start, end });
  };

  useEffect(() => {
    async function getFaturamento() {
      try {
        const [dataInicio] = new Date(dateFaturamento.start)
          .toISOString()
          .split('T');
        const [dataFim] = new Date(dateFaturamento.end)
          .toISOString()
          .split('T');

        const { data } = await api.get(
          `/faturamento-periodo?startDate=${dataInicio}&endDate=${dataFim}`
        );

        setFaturamentoPeriodo(data);
      } catch (err) {
        console.log('ERROR', err);
      }
    }
    if (dateFaturamento.start && dateFaturamento.end) {
      getFaturamento();
    }
  }, [dateFaturamento.start, dateFaturamento.end]);

  useEffect(() => {
    async function getVendasPorCartao() {
      try {
        const [dataInicio] = new Date(dateVenda.start).toISOString().split('T');
        const [dataFim] = new Date(dateVenda.end).toISOString().split('T');

        const { data } = await api.get(
          `/vendas-por-cartao?startDate=${dataInicio}&endDate=${dataFim}`
        );

        setVendas(data);
      } catch (err) {
        console.log('ERROR', err);
      }
    }
    if (dateVenda.start && dateVenda.end) {
      getVendasPorCartao();
    }
  }, [dateVenda.start, dateVenda.end]);

  return (
    <>
      <div className='card-form'>
        <Row>
          <Col lg={12} className='div-relatorio'>
            <Row>
              <Col lg={7} className='grid-relatorio'>
                <Row>
                  <Col>
                    <h5 className='txt-tertiary'>
                      <b>Faturamento</b>
                    </h5>
                  </Col>
                </Row>

                <Row>
                  <Col md={6} className='mb-3'>
                    <h6 className='txt-green'>
                      <b>Últimos 7 dias</b>
                    </h6>
                    <br />
                    <h5 className='txt-secondary'>
                      <b>
                        {formatarDinheiro.format(
                          Number(faturamento?.utimos7dias?.valor) || 0
                        )}
                      </b>
                    </h5>
                  </Col>

                  <Col md={6} className='mb-3'>
                    <h6 className='txt-green'>
                      <b>Últimos 30 dias</b>
                    </h6>
                    <br />
                    <h5 className='txt-secondary'>
                      <b>
                        {formatarDinheiro.format(
                          Number(faturamento?.utimos30dias?.valor) || 0
                        )}
                      </b>
                    </h5>
                  </Col>
                </Row>

                <Row>
                  <Col md={6} className='mb-3'>
                    <h6 className='txt-green'>
                      <b>Deste mês</b>
                    </h6>
                    <br />
                    <h5 className='txt-secondary'>
                      <b>
                        {formatarDinheiro.format(
                          Number(faturamento?.esteMes?.valor) || 0
                        )}
                      </b>
                    </h5>
                  </Col>

                  <Col md={6} className='mb-3'>
                    <h6 className='txt-green'>
                      <b>Do mês anterior</b>
                    </h6>
                    <br />
                    <h5 className='txt-secondary'>
                      <b>
                        {formatarDinheiro.format(
                          Number(faturamento?.mesAnterior?.valor) || 0
                        )}
                      </b>
                    </h5>
                  </Col>
                </Row>

                <Row>
                  <Col md={6} className='mb-3'>
                    <h6 className='txt-green'>
                      <b>Deste ano</b>
                    </h6>
                    <br />
                    <h5 className='txt-secondary'>
                      <b>
                        {formatarDinheiro.format(
                          Number(faturamento?.esteAno?.valor) || 0
                        )}
                      </b>
                    </h5>
                  </Col>

                  <Col md={6} className='mb-3'>
                    <h6 className='txt-green'>
                      <b>Faturamento total</b>
                    </h6>
                    <br />
                    <h5 className='txt-secondary'>
                      <b>
                        {formatarDinheiro.format(
                          Number(faturamento?.total?.valor) || 0
                        )}
                      </b>
                    </h5>
                  </Col>
                </Row>

                <Row>
                  <Col md={6}>
                    <h6 className='txt-green d-flex'>
                      <b>Faturamento previsto</b>
                      <IoInformationCircleOutline
                        className='text-primary ml-1'
                        size='1.2em'
                        id='faturamento_previsto'
                      />
                      <UncontrolledTooltip
                        placement='bottom'
                        target='faturamento_previsto'
                      >
                        <div className='mx-1 my-1'>
                          Suas cobranças em aberto multiplicado pela sua taxa de
                          conversão. Valores estimados.
                        </div>
                      </UncontrolledTooltip>
                    </h6>
                    <br />
                    <h5 className='txt-secondary'>
                      <b>
                        {formatarDinheiro.format(
                          (calculaConversao(
                            faturamento?.faturado?.valor,
                            faturamento?.esperado?.valor
                          ) /
                            100) *
                            (faturamento?.cobrancasEmAberto?.valor || 0)
                        )}
                      </b>
                    </h5>
                  </Col>
                </Row>
              </Col>

              <Col lg={5} className='grid-relatorio'>
                <Row>
                  <Col>
                    <h5 className='txt-tertiary'>
                      <b>Outros</b>
                    </h5>
                  </Col>
                </Row>

                <Row>
                  <Col lg={12} className='mb-3'>
                    <h6 className='txt-green'>
                      <b>Cobranças pagas</b>
                    </h6>
                    <br />
                    <h5 className='txt-secondary'>
                      <b>{faturamento?.cobrancasPagas || 0}</b>
                    </h5>
                  </Col>
                </Row>

                <Row>
                  <Col lg={12} className='mb-3'>
                    <h6 className='txt-green d-flex'>
                      <b>Conversão</b>
                      <IoInformationCircleOutline
                        className='text-primary ml-1'
                        size='1.2em'
                        id='faturamento_conversao'
                      />
                      <UncontrolledTooltip
                        placement='bottom'
                        target='faturamento_conversao'
                      >
                        <div className='mx-1 my-1'>
                          Percentual das cobranças vencidas até ontem que foram
                          pagas.
                        </div>
                      </UncontrolledTooltip>
                    </h6>
                    <br />
                    <h5 className='txt-secondary'>
                      <b>
                        {calculaConversao(
                          faturamento?.faturado?.valor,
                          faturamento?.esperado?.valor
                        ).toLocaleString('pt-BR', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2,
                        })}
                        %
                      </b>
                    </h5>
                  </Col>
                </Row>

                <Row>
                  <Col lg={12} className='mb-3'>
                    <h6 className='txt-green d-flex'>
                      <b>Ticket médio</b>
                      <IoInformationCircleOutline
                        className='text-primary ml-1'
                        size='1.2em'
                        id='faturamento_ticket'
                      />
                      <UncontrolledTooltip
                        placement='bottom'
                        target='faturamento_ticket'
                      >
                        <div className='mx-1 my-1'>
                          Média das cobranças pagas.
                        </div>
                      </UncontrolledTooltip>
                    </h6>
                    <br />
                    <h5 className='txt-secondary'>
                      <b>
                        {formatarDinheiro.format(
                          Number(faturamento?.total?.valor || 0) /
                            faturamento?.cobrancasPagas || 0
                        )}
                      </b>
                    </h5>
                  </Col>
                </Row>

                <Row>
                  <Col lg={12}>
                    <h6 className='txt-green d-flex'>
                      <b>Cobranças em aberto</b>
                      <IoInformationCircleOutline
                        className='text-primary ml-1'
                        size='1.2em'
                        id='faturamento_aberto'
                      />
                      <UncontrolledTooltip
                        placement='bottom'
                        target='faturamento_aberto'
                      >
                        <div className='mx-1 my-1'>
                          Valores de pagamento pendente que ainda não venceram.
                        </div>
                      </UncontrolledTooltip>
                    </h6>
                    <br />
                    <h5 className='txt-secondary'>
                      <b>
                        {formatarDinheiro.format(
                          Number(faturamento?.cobrancasEmAberto?.valor) || 0
                        )}
                      </b>
                    </h5>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className='card-form my-6'>
        <Row>
          <Col lg={12}>
            <div className='d-flex align-center'>
              <span className='d-flex align-center'>
                <img
                  src={iconeData}
                  alt='icone de data'
                  className='mr-1'
                  style={{
                    maxHeight: '34px',
                  }}
                />
                <Button
                  id='periodo_secundario'
                  type='button'
                  color='link'
                  className='ml-1'
                >
                  {periodoDataLabel(dateFaturamento)}
                  <span className='small ml-1'>
                    <small>▼</small>
                  </span>
                </Button>
                <UncontrolledPopover
                  placement='bottom'
                  target='#periodo_secundario'
                  trigger='legacy'
                  hideArrow
                >
                  <DatePicker
                    selected={dateFaturamento.start}
                    onChange={onChangeFaturamento}
                    startDate={dateFaturamento.start}
                    endDate={dateFaturamento.end}
                    locale='pt-BR'
                    selectsRange
                    inline
                  />
                </UncontrolledPopover>
              </span>
            </div>
          </Col>

          <Col lg={12} className='div-relatorio mt-6'>
            <Row>
              <Col lg={8}>
                <Row>
                  <Col md={6} className='mb-3 pr-0'>
                    <h6 className='txt-green d-flex'>
                      <b>Faturamento esperado</b>
                      <IoInformationCircleOutline
                        className='text-primary ml-1'
                        size='1.2em'
                        id='faturamento_esperado'
                      />
                      <UncontrolledTooltip
                        placement='bottom'
                        target='faturamento_esperado'
                      >
                        <div className='mx-1 my-1'>
                          Valor esperado para receber no período.
                        </div>
                      </UncontrolledTooltip>
                    </h6>
                    <br />
                    <h5 className='txt-secondary'>
                      <b>
                        {formatarDinheiro.format(
                          Number(
                            faturamentoPeriodo?.faturamentoEsperado?.valor
                          ) || 0
                        )}
                      </b>
                    </h5>
                  </Col>

                  <Col md={6} className='mb-3'>
                    <h6 className='txt-green d-flex'>
                      <b>Faturamento real</b>
                      <IoInformationCircleOutline
                        className='text-primary ml-1'
                        size='1.2em'
                        id='faturamento_real'
                      />
                      <UncontrolledTooltip
                        placement='bottom'
                        target='faturamento_real'
                      >
                        <div className='mx-1 my-1'>
                          Valor recebido no período.
                        </div>
                      </UncontrolledTooltip>
                    </h6>
                    <br />
                    <h5 className='txt-secondary'>
                      <b>
                        {formatarDinheiro.format(
                          Number(faturamentoPeriodo?.faturamentoReal?.valor) ||
                            0
                        )}
                      </b>
                    </h5>
                  </Col>
                </Row>
              </Col>

              <Col lg={4}>
                <Row>
                  <Col lg={12} className='mb-3'>
                    <h6 className='txt-green d-flex'>
                      <b>Conversão</b>
                      <IoInformationCircleOutline
                        className='text-primary ml-1'
                        size='1.2em'
                        id='faturamento_conversao2'
                      />
                      <UncontrolledTooltip
                        placement='bottom'
                        target='faturamento_conversao2'
                      >
                        <div className='mx-1 my-1'>
                          Percentual das cobranças emitidas que foram pagas.
                        </div>
                      </UncontrolledTooltip>
                    </h6>
                    <br />
                    <h5 className='txt-secondary'>
                      <b>
                        {calculaConversaoPeriodo(
                          faturamentoPeriodo?.faturamentoReal?.valor,
                          faturamentoPeriodo?.faturamentoEsperado?.valor
                        )}
                        %
                      </b>
                    </h5>
                  </Col>
                </Row>
              </Col>
            </Row>
            <Row>
              <Col lg={8}>
                <Row>
                  <Col md={6} className='mb-3'>
                    <h6 className='txt-green d-flex'>
                      <b>Valor médio</b>
                      <IoInformationCircleOutline
                        className='text-primary ml-1'
                        size='1.2em'
                        id='faturamento_media'
                      />
                      <UncontrolledTooltip
                        placement='bottom'
                        target='faturamento_media'
                      >
                        <div className='mx-1 my-1'>
                          Média de valores das cobranças pagas.
                        </div>
                      </UncontrolledTooltip>
                    </h6>
                    <br />
                    <h5 className='txt-secondary'>
                      <b>
                        {formatarDinheiro.format(
                          Number(faturamentoPeriodo?.faturamentoReal?.valor) /
                            faturamentoPeriodo?.cobrancasPagas || 0
                        )}
                      </b>
                    </h5>
                  </Col>

                  <Col md={6} className='mb-3'>
                    <h6 className='txt-green d-flex'>
                      <b>Cobranças pagas</b>
                      <IoInformationCircleOutline
                        className='text-primary ml-1'
                        size='1.2em'
                        id='faturamento_cobrancasPagas'
                      />
                      <UncontrolledTooltip
                        placement='bottom'
                        target='faturamento_cobrancasPagas'
                      >
                        <div className='mx-1 my-1'>
                          Cobranças emitidas e pagas.
                        </div>
                      </UncontrolledTooltip>
                    </h6>
                    <br />
                    <h5 className='txt-secondary'>
                      <b>{faturamentoPeriodo?.cobrancasPagas || 0}</b>
                    </h5>
                  </Col>
                </Row>
              </Col>

              <Col lg={4}>
                <Row>
                  <Col lg={12} className='mb-3'>
                    <h6 className='txt-green d-flex'>
                      <b>Cobranças</b>
                      <IoInformationCircleOutline
                        className='text-primary ml-1'
                        size='1.2em'
                        id='faturamento_cobrancasMes'
                      />
                      <UncontrolledTooltip
                        placement='bottom'
                        target='faturamento_cobrancasMes'
                      >
                        <div className='mx-1 my-1'>
                          Cobranças com vencimento nesse período.
                        </div>
                      </UncontrolledTooltip>
                    </h6>

                    <br />
                    <h5 className='txt-secondary'>
                      <b>{faturamentoPeriodo?.cobrancas || 0}</b>
                    </h5>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className='card-form'>
        <Row>
          <Col lg={12}>
            <div className='justify-between align-center'>
              <h5 className='txt-tertiary '>Quantidade de vendas por cartão</h5>
              <span className='d-flex align-center'>
                <img
                  src={iconeData}
                  alt='icone de data'
                  className='mr-1'
                  style={{
                    maxHeight: '34px',
                  }}
                />
                <Button
                  id='periodo_vendas'
                  type='button'
                  color='link'
                  className='ml-1'
                >
                  {periodoDataLabel(dateVenda)}
                  <span className='small ml-1'>
                    <small>▼</small>
                  </span>
                </Button>
                <UncontrolledPopover
                  placement='bottom'
                  target='#periodo_vendas'
                  trigger='legacy'
                  hideArrow
                >
                  <DatePicker
                    selected={dateVenda.start}
                    onChange={onChangeVenda}
                    startDate={dateVenda.start}
                    endDate={dateVenda.end}
                    locale='pt-BR'
                    selectsRange
                    inline
                  />
                </UncontrolledPopover>
              </span>
            </div>
          </Col>
          <Col lg={12} className='div-relatorio my-6'>
            {dadosWhiteLabel ? (
              <Row className='mb-0'>
                {dadosWhiteLabel?.cartao1 && (
                  <Col md={4}>
                    <div className='justify-center'>
                      <img
                        src={dadosWhiteLabel?.cartao1?.imagem?.url}
                        alt={dadosWhiteLabel?.cartao1?.nome}
                      />
                    </div>
                    <br />
                    <div className='justify-center'>
                      <h4 className='txt-primary'>
                        <b>
                          {vendas.find(
                            (f) => f.produto.descricao === 'especial'
                          )?.quantidade || 0}
                        </b>
                      </h4>
                    </div>
                  </Col>
                )}
                {dadosWhiteLabel?.cartao2 && (
                  <Col md={4}>
                    <div className='justify-center'>
                      <img
                        src={
                          dadosWhiteLabel?.cartao2?.imagem?.url || cartaoBlack
                        }
                        alt={dadosWhiteLabel?.cartao2?.nome}
                      />
                    </div>

                    <br />
                    <div className='justify-center'>
                      <h4 className='txt-primary'>
                        <b>
                          {vendas.find((f) => f.produto.descricao === 'black')
                            ?.quantidade || 0}
                        </b>
                      </h4>
                    </div>
                  </Col>
                )}
                {dadosWhiteLabel?.cartao3 && (
                  <Col md={4}>
                    <div className='justify-center'>
                      <img
                        src={
                          dadosWhiteLabel?.cartao3?.imagem?.url || cartaoGold
                        }
                        alt={dadosWhiteLabel?.cartao3?.nome}
                      />
                    </div>
                    <br />
                    <div className='justify-center'>
                      <h4 className='txt-primary'>
                        <b>
                          {vendas.find((f) => f.produto.descricao === 'gold')
                            ?.quantidade || 0}
                        </b>
                      </h4>
                    </div>
                  </Col>
                )}
              </Row>
            ) : (
              <Row className='mb-0'>
                <Col md={4}>
                  <div className='justify-center'>
                    <img src={cartaoEspecial} alt='Cartão PoupaClub Especial' />
                  </div>
                  <br />
                  <div className='justify-center'>
                    <h4 className='txt-primary'>
                      <b>
                        {vendas.find((f) => f.produto.descricao === 'especial')
                          ?.quantidade || 0}
                      </b>
                    </h4>
                  </div>
                </Col>
                <Col md={4}>
                  <div className='justify-center'>
                    <img src={cartaoGold} alt='Cartão PoupaClub Gold' />
                  </div>
                  <br />
                  <div className='justify-center'>
                    <h4 className='txt-primary'>
                      <b>
                        {vendas.find((f) => f.produto.descricao === 'gold')
                          ?.quantidade || 0}
                      </b>
                    </h4>
                  </div>
                </Col>

                <Col md={4}>
                  <div className='justify-center'>
                    <img src={cartaoBlack} alt='Cartão PoupaClub Black' />
                  </div>

                  <br />
                  <div className='justify-center'>
                    <h4 className='txt-primary'>
                      <b>
                        {vendas.find((f) => f.produto.descricao === 'black')
                          ?.quantidade || 0}
                      </b>
                    </h4>
                  </div>
                </Col>
              </Row>
            )}
          </Col>
        </Row>
      </div>
    </>
  );
}

export default RelatorioAdmin;
