import { Form } from '@unform/web';
import { useEffect, useRef, useState } from 'react';
import DatePicker from 'react-datepicker';
import {
  FaChevronLeft,
  FaChevronRight,
  FaHandHoldingUsd,
  FaMoneyBill,
  FaPlus,
  FaRegCreditCard,
} from 'react-icons/fa';
import { FiFilter } from 'react-icons/fi';
import {
  IoCheckmarkCircleOutline,
  IoCloseCircleOutline,
  IoTimeOutline,
  IoWarningOutline,
} from 'react-icons/io5';
import { RiBarcodeFill } from 'react-icons/ri';
import Pagination from 'react-paginate';
import {
  Button,
  ButtonGroup,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Table,
  UncontrolledCollapse,
  UncontrolledPopover,
  UncontrolledTooltip,
} from 'reactstrap';
import { ReactComponent as Pix } from '../../../assets/pix.svg';
import TooltipCustom from '../../../components/TooltipCustom';
import CheckboxInput from '../../../components/unformCheckbox';
import useFecth from '../../../hooks/useFetch';
import api from '../../../services/api';
import formatarDinheiro from '../../../utils/formatarDinheiro';
import { periodoDataLabel } from '../../../utils/periodoData';

const statusIcon = {
  ACTIVE: (date, i) =>
    new Date(date) >
    new Date(new Date().toISOString().slice(0, 10) + ' 12:00:00') ? (
      <>
        <TooltipCustom title='Pendente' id={i}>
          <IoTimeOutline size='1.8em' color='orange' />
        </TooltipCustom>
      </>
    ) : (
      <>
        <TooltipCustom title='Vencida' id={i}>
          <IoWarningOutline size='1.8em' color='orangered' />
        </TooltipCustom>
      </>
    ),
  PAID: (date, i) => (
    <>
      <TooltipCustom title='Paga' id={i}>
        <IoCheckmarkCircleOutline size='1.8em' color='green' />
      </TooltipCustom>
    </>
  ),
  CANCELLED: (date, i) => (
    <>
      <TooltipCustom title='Cancelada' id={i}>
        <IoCloseCircleOutline size='1.8em' color='red' />
      </TooltipCustom>
    </>
  ),
  MANUAL_RECONCILIATION: (date, i) => (
    <>
      <TooltipCustom title='Pago manualmente' id={i}>
        <FaHandHoldingUsd size='1.8em' color='darkorange' />
      </TooltipCustom>
    </>
  ),
};

const pagamentoIcons = {
  CREDIT_CARD: (nome, i) => (
    <>
      <TooltipCustom title={nome} id={`pagamento_${i}`}>
        <FaRegCreditCard size='1.8em' />
      </TooltipCustom>
    </>
  ),
  ASSINATURA: (nome, i) => (
    <>
      <TooltipCustom title={nome} id={`pagamento_${i}`}>
        <FaRegCreditCard size='1.8em' />
      </TooltipCustom>
    </>
  ),
  BOLETO: (nome, i) => (
    <>
      <TooltipCustom title={nome} id={`pagamento_${i}`}>
        <RiBarcodeFill size='1.8em' />
      </TooltipCustom>
    </>
  ),
  PIX: (nome, i) => (
    <>
      <TooltipCustom title={nome} id={`pagamento_${i}`}>
        <Pix width='1.8em' heigth='1.8em' />
      </TooltipCustom>
    </>
  ),
  BOLETO_PIX: (nome, i) => (
    <>
      <TooltipCustom title={nome} id={`pagamento_${i}`}>
        <Pix width='1.8em' heigth='1.8em' />
      </TooltipCustom>
    </>
  ),
};

const pagamentoIcon = (pagamento, i) => {
  const icon = pagamentoIcons[pagamento?.descricao];
  return icon ? (
    icon(pagamento.nome, i)
  ) : (
    <>
      <FaMoneyBill size='1.8em' id={`pagamento_${i}`} />
      <UncontrolledTooltip placement='bottom' target={`pagamento_${i}`}>
        {pagamento?.nome}
      </UncontrolledTooltip>
    </>
  );
};

const NomeCliente = (nome, i) => (
  <>
    <div className='crop' id={`cliente_nome_${i}`}>
      {nome?.toLowerCase()}
    </div>
    <UncontrolledTooltip placement='bottom' target={`cliente_nome_${i}`}>
      {nome}
    </UncontrolledTooltip>
  </>
);

const NomeProduto = (nome, i) => (
  <>
    <div className='crop' id={`produto_nome_${i}`}>
      {nome?.toLowerCase()}
    </div>
    <UncontrolledTooltip placement='bottom' target={`produto_nome_${i}`}>
      {nome}
    </UncontrolledTooltip>
  </>
);

function RelatorioParceiro() {
  let timeoutConsulta;
  const tableRef = useRef();

  const refNome = useRef(null);
  const refNomeFantasia = useRef(null);
  const refCpf = useRef(null);
  const refCnpj = useRef(null);

  const [dateEmissao, setDateEmissao] = useState(null);
  const [dateVencimento, setDateVencimento] = useState(null);
  const [datePagamento, setDatePagamento] = useState(null);

  const [idParcelas, setIdParcelas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filtros, setFiltros] = useState({
    tipo: 'todos',
  });
  const [modal, setModal] = useState(false);

  const { data: cobrancas, mutate } = useFecth('/pedido_interno');

  const toggle = () => setModal(!modal);

  function changeTipo(tipo) {
    if (filtros.tipo !== tipo) {
      setFiltros({
        ...filtros,
        page: 1,
        tipo,
      });
    }
  }

  useEffect(() => {
    async function getParcelas() {
      try {
        let filtroConsulta = '';
        Object.entries(filtros).forEach(([key, value]) => {
          if (['string', 'number'].includes(typeof value)) {
            filtroConsulta += `${key}=${value}&`;
          } else {
            if (value) {
              if (value?.length === undefined) {
                Object.entries(value).forEach(([key2, value2]) => {
                  filtroConsulta += `${key}${key2}=${
                    new Date(value2).toISOString().split('T')[0]
                  }&`;
                });
              } else {
                filtroConsulta += `${key}=[${value.toString()}]&`;
              }
            }
          }
        });
        const { data } = await api.get(`/pedido_interno?${filtroConsulta}`);
        mutate(data, false);
      } catch (err) {}
    }
    if (loading) {
      getParcelas();
    } else {
      setLoading(true);
    }
  }, [filtros]);

  const debounceConsulta = async (body) => {
    return await new Promise((resolve) => {
      clearTimeout(timeoutConsulta);

      timeoutConsulta = setTimeout(() => {
        setIdParcelas([]);

        resolve(setFiltros({ ...filtros, ...body, page: 1 }));
      }, 500);
    });
  };

  const onChangeEmissao = (dates) => {
    const [start, end] = dates;
    setDateEmissao({ start, end });
  };
  const onChangeVencimento = (dates) => {
    const [start, end] = dates;
    setDateVencimento({ start, end });
  };
  const onChangePagamento = (dates) => {
    const [start, end] = dates;
    setDatePagamento({ start, end });
  };

  function resetPeriodo() {
    setDatePagamento(null);
    setDateVencimento(null);
    setDateEmissao(null);

    setFiltros({ tipo: 'todos' });
    setIdParcelas([]);
  }

  return (
    <>
      <Row>
        <Col lg={12}>
          <h5 className='txt-tertiary mb-3'>Cobranças internas</h5>
        </Col>
      </Row>

      <div className='card-form  p-0'>
        <div className='p-3 justify-content-between d-flex align-center'>
          <p className='txt-muted'>
            Total de registros: {cobrancas?.total || 0}
          </p>
          <Button color='muted' id='toggler'>
            <FiFilter /> Filtros
          </Button>
          <div />
        </div>
        <div className='filter-collapse'>
          <UncontrolledCollapse toggler='#toggler'>
            <Row className='mt-3'>
              <Col lg={4} className='mb-3' md={3}>
                <input
                  placeholder='Nome cliente'
                  className='input-field-inner form-control'
                  ref={refNome}
                  onChange={() =>
                    debounceConsulta({
                      nome: refNome?.current?.value,
                    })
                  }
                />
              </Col>
              <Col lg={4} className='mb-3' md={3}>
                <input
                  placeholder='Documento'
                  className='input-field-inner form-control'
                  ref={refCpf}
                  onChange={() =>
                    debounceConsulta({
                      documento: refCpf?.current?.value,
                    })
                  }
                />
              </Col>
              <Col lg={4} className='mb-3' md={4}>
                <Button
                  id='periodo_filtros'
                  type='button'
                  color='link'
                  block
                  className='mx-1'
                  onClick={toggle}
                >
                  <FaPlus className='mr-3' />
                  Filtros personalizados
                </Button>
              </Col>
            </Row>
          </UncontrolledCollapse>
        </div>
        <Row>
          <Col>
            <ButtonGroup className='w-100 not-rounded'>
              <Button
                color='tabs-secondary'
                active={filtros.tipo === 'todos'}
                onClick={() => changeTipo('todos')}
              >
                Todas
              </Button>
              <Button
                color='tabs-secondary'
                active={filtros.tipo === '1x'}
                onClick={() => changeTipo('1x')}
              >
                À vista
              </Button>
              <Button
                color='tabs-secondary'
                active={filtros.tipo === '2x'}
                onClick={() => changeTipo('2x')}
              >
                Parceladas
              </Button>
            </ButtonGroup>
          </Col>
        </Row>
        <Table responsive className='table-cobrancas' innerRef={tableRef}>
          <thead>
            <tr>
              <th>Status</th>
              <th>Forma</th>
              <th>Comprador</th>
              <th className='text-center'>Produto</th>
              <th>Valor</th>
              <th>Emissão</th>
              <th>Vencimento</th>
              <th>Pagamento</th>
            </tr>
          </thead>
          <tbody>
            {cobrancas?.rows?.map((parcela, i) => (
              <tr>
                <td>
                  <div className='pl-1'>
                    {statusIcon[parcela?.status](parcela?.data_parcela, i)}
                  </div>
                </td>
                <td>
                  <div className='pl-1'>
                    {pagamentoIcon(
                      parcela?.PedidoInterno?.CondicaoPagamento,
                      i
                    )}
                  </div>
                </td>
                <td className='td-nome'>
                  {NomeCliente(parcela?.PedidoInterno?.nome, i)}
                </td>
                <td>
                  <div className='justify-center'>
                    {NomeProduto(
                      parcela?.PedidoInterno?.ProdutoInterno?.nome,
                      i
                    )}{' '}
                  </div>
                </td>
                {parcela?.PedidoInterno?.total_parcelas > 1 ? (
                  <td className='td-cobranca'>
                    <div>
                      ({parcela?.parcela}/
                      {parcela?.PedidoInterno?.total_parcelas})
                      <br />
                      {formatarDinheiro.format(parcela?.valorparcela)}
                    </div>
                  </td>
                ) : (
                  <td>{formatarDinheiro.format(parcela?.valorparcela)}</td>
                )}

                <td>{new Date(parcela?.createdAt).toLocaleDateString()}</td>
                <td>{new Date(parcela?.data_parcela).toLocaleDateString()}</td>
                <td className='td-cobranca'>
                  {parcela?.valor_pagamento ? (
                    <div>
                      {formatarDinheiro.format(parcela?.valor_pagamento)}
                      <br />
                      {new Date(parcela?.data_pagamento).toLocaleDateString()}
                    </div>
                  ) : (
                    <div>
                      -
                      <br />-{' '}
                    </div>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        <div className='justify-between ml-3'>
          <span></span>
          <Pagination
            nextLabel={<FaChevronRight />}
            previousLabel={<FaChevronLeft />}
            pageCount={cobrancas?.pages || 1}
            onPageChange={({ selected }) =>
              setFiltros({
                ...filtros,
                page: selected + 1,
              })
            }
            forcePage={filtros.page - 1 || 0}
            // initialPage={}
            containerClassName={'pagination justify-end mb-0'}
            activeClassName={'active'}
          />
        </div>
      </div>
      <Modal isOpen={modal} toggle={toggle} size='lg' className='modal-filtros'>
        <Form
          initialData={filtros}
          className='form-filtro'
          onSubmit={(data) => {
            setIdParcelas([]);
            setFiltros({
              ...filtros,
              ...data,
              datePagamento,
              dateEmissao,
              dateVencimento,
              page: 1,
            });
          }}
        >
          <ModalHeader toggle={toggle} className='pl-9'>
            Filtro personalizado
          </ModalHeader>
          <ModalBody btn-save>
            <Row>
              <Col lg={6}>
                <div className='txt-label'>Data de emissão</div>
              </Col>
              <Col lg={6}>
                <Button
                  id='periodo_emissao'
                  type='button'
                  color='link'
                  className='ml-1'
                >
                  {periodoDataLabel(dateEmissao)}
                  <span className='small ml-1'>
                    <small>▼</small>
                  </span>
                </Button>
                <UncontrolledPopover
                  placement='bottom'
                  target='#periodo_emissao'
                  trigger='legacy'
                  hideArrow
                >
                  <DatePicker
                    selected={dateEmissao?.start}
                    onChange={onChangeEmissao}
                    startDate={dateEmissao?.start}
                    endDate={dateEmissao?.end}
                    locale='pt-BR'
                    selectsRange
                    inline
                  />
                </UncontrolledPopover>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <div className='txt-label'>Data de vencimento</div>
              </Col>
              <Col lg={6}>
                <Button
                  id='periodo_vencimento'
                  type='button'
                  color='link'
                  className='ml-1'
                >
                  {periodoDataLabel(dateVencimento)}
                  <span className='small ml-1'>
                    <small>▼</small>
                  </span>
                </Button>
                <UncontrolledPopover
                  placement='bottom'
                  target='#periodo_vencimento'
                  trigger='legacy'
                  hideArrow
                >
                  <DatePicker
                    selected={dateVencimento?.start}
                    onChange={onChangeVencimento}
                    startDate={dateVencimento?.start}
                    endDate={dateVencimento?.end}
                    locale='pt-BR'
                    selectsRange
                    inline
                  />
                </UncontrolledPopover>
              </Col>
            </Row>
            <Row>
              <Col lg={6}>
                <div className='txt-label'>Data de pagamento</div>
              </Col>
              <Col lg={6}>
                <Button
                  id='periodo_pagamento'
                  type='button'
                  color='link'
                  className='ml-1'
                >
                  {periodoDataLabel(datePagamento)}
                  <span className='small ml-1'>
                    <small>▼</small>
                  </span>
                </Button>
                <UncontrolledPopover
                  placement='bottom'
                  target='#periodo_pagamento'
                  trigger='legacy'
                  hideArrow
                >
                  <DatePicker
                    selected={datePagamento?.start}
                    onChange={onChangePagamento}
                    startDate={datePagamento?.start}
                    endDate={datePagamento?.end}
                    locale='pt-BR'
                    selectsRange
                    inline
                  />
                </UncontrolledPopover>
              </Col>
            </Row>

            <hr className='primary' />

            <Row
              style={{
                paddingBottom: '0',
                paddingTop: '.6em',
              }}
            >
              <CheckboxInput
                initialValue={filtros?.status}
                name='status'
                col
                options={[
                  { id: 'opt_paga', value: 'paga', label: 'Pagas' },
                  { id: 'opt_!paga', value: '!paga', label: 'Não pagas' },
                  { id: 'opt_vencida', value: 'vencida', label: 'Vencidas' },
                  {
                    id: 'opt_!vencida',
                    value: '!vencida',
                    label: 'Não vencidas',
                  },
                  {
                    id: 'opt_cancelado',
                    value: 'cancelado',
                    label: 'Cancelados',
                  },
                  {
                    id: 'opt_!cancelado',
                    value: '!cancelado',
                    label: 'Não cancelados',
                  },
                ]}
              />
            </Row>

            <hr className='primary' />
            <h6
              style={{
                paddingBottom: '.3em',
              }}
            >
              Forma de pagamento
            </h6>
            <Row>
              <CheckboxInput
                name='pagamento'
                col
                initialValue={filtros?.pagamento}
                options={[
                  {
                    id: 'opt_boleto',
                    value: 'BOLETO',
                    label: 'Boleto bancário',
                  },
                  {
                    id: 'opt_boleto_pix',
                    value: 'BOLETO_PIX',
                    label: 'PIX',
                  },
                  {
                    id: 'opt_credito',
                    value: 'CREDIT_CARD',
                    label: 'Cartão de crédito',
                  },
                ]}
              />
            </Row>
          </ModalBody>
          <ModalFooter className='justify-between'>
            <Button
              color='outline-primary'
              className='btn-save'
              type='reset'
              onClick={() => {
                resetPeriodo();
                toggle();
              }}
            >
              Limpar filtros
            </Button>
            <Button
              color='primary'
              className='btn-save'
              type='submit'
              onClick={toggle}
            >
              Aplicar
            </Button>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default RelatorioParceiro;
