import React, { createContext, useContext, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import api from '../services/api';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [userImage, setUserImage] = useState(null);
  const [loading, setLoading] = useState(true);

  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    const { token, usuario, access, user_img } = localStorage;
    if (token && usuario) {
      const _user = JSON.parse(usuario);
      const _user_img = user_img === '' ? null : user_img;
      api.defaults.headers.Authorization = `Bearer ${token}`;
      api.interceptors.response.use(
        function (response) {
          return response;
        },
        function (error) {
          if (401 === error.response.status || error.status === 401) {
            setUser(null);
            localStorage.clear();
            return Promise.reject(error);
          } else {
            return Promise.reject(error);
          }
        }
      );
      if (access) {
        _user.access = JSON.parse(atob(access));
      }
      setUser(_user);
      setUserImage(_user_img);
      getAcessos(_user);
      setLoading(false);
    }
    setLoading(false);
  }, []);

  async function getAcessos(usuario) {
    try {
      const { data } = await api.get('/acesso-perfil');
      setUser({ ...usuario, access: data });
    } catch (err) {}
  }

  async function uppo() {
    try {
      if (!user) {
        const [loc] = window.location.pathname.split('/');
        const whiteLabel = ['poupa-club', 'acesso', '', 'login'].includes(loc)
          ? 'poupa-club'
          : loc;
        return history.push(`/${whiteLabel}/login`);
      }
      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      const { data } = await api.get('/magic-link');
      Swal.close();
      window.open(data.magicLink || data.link, '_blank');
    } catch (err) {
      Swal.close();
      const error = err.response.data.error;
      toast.error(
        typeof error === 'string' ? error : 'Link indisponível no momento'
      );
    }
  }

  async function signIn({ senha, email, aceita_termos }) {
    try {
      setLoading(true);
      const res = await api.post(`/sessions`, {
        email,
        senha,
        aceita_termos,
      });

      const { user, token } = res.data;

      api.defaults.headers.Authorization = `Bearer ${token}`;
      api.interceptors.response.use(
        function (response) {
          return response;
        },
        function (error) {
          if (401 === error.response.status || error.status === 401) {
            setUser(null);
            localStorage.clear();
            return Promise.reject(error);
          } else {
            return Promise.reject(error);
          }
        }
      );

      localStorage.setItem(
        'usuario',
        JSON.stringify({
          email,
          id: user.id,
          nome: user.nome,
          perfil: user.perfil,
        })
      );
      user.image
        ? localStorage.setItem('user_img', user.image)
        : localStorage.setItem('user_img', '');
      localStorage.setItem('token', token);
      localStorage.setItem('access', btoa(JSON.stringify(user.acessos)));

      setUser({
        email,
        id: user.id,
        nome: user.nome,
        perfil: user.perfil,
        access: user.acessos,
        responsavel: user.responsavel,
      });

      setUserImage(user.image || null);

      history.push('/acesso/welcome');
      window.scrollTo(0, 0);
    } catch (error) {
      setLoading(false);

      return Promise.reject(error);
    }
  }

  function signOut() {
    localStorage.clear();
    setUser(null);
    history.push('/');
  }

  function changeUserPhoto(newImg) {
    if (!newImg) {
      if (userImage) {
        setUserImage(null);
        localStorage.setItem('user_img', '');
      }
    } else {
      setUserImage(newImg.url);
      localStorage.setItem('user_img', newImg.url);
    }
  }

  return (
    <AuthContext.Provider
      value={{
        signed: !!user,
        user,
        loading,
        signIn,
        signOut,
        setUser,
        changeUserPhoto,
        userImage,
        uppo,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth() {
  const context = useContext(AuthContext);

  return context;
}
