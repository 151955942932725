export default function formatarCPFExibicaoApenas(v) {
  if (v !== undefined) {
    if (v.length <= 14) {
      v = v.replace(/(\d{3})(\d)/, "$1.$2");
      v = v.replace(/(\d{3})(\d)/, "$1.$2");
      v = v.replace(/(\d{3})(\d{1,2})$/, "$1-$2");
      return v;
    }
  }
}
