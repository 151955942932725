import { useState, useRef, useEffect } from "react";
import { Col, Row, Button, Label } from "reactstrap";
import { Form } from "@unform/web";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { ValidationError } from "yup";
import AsyncSelect from "../../../components/AsyncSelect";
import { useField } from "@unform/core";
import SunEditorComponent from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import iconeFiltro from "../../../assets/icone_filtro.png";
import api from "../../../services/api";

function WhiteLabel() {
  const formRef = useRef(null);
  const [filtro, setFiltro] = useState(null);
  const [id, setId] = useState(null);
  let timeoutConsulta;

  async function handleSubmit(data) {
    if (id && filtro) {
      formRef.current.setErrors([]);

      try {
        Swal.fire({
          title: "Aguarde",
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });

        if (id) {
          await api.put(`/whitelabel-dados/${id}`, data);
          toast.success("WhiteLabel alterado com sucesso");
        }

        Swal.close();
      } catch (err) {
        const validationErrors = {};
        Swal.close();
        if (err instanceof ValidationError) {
          err.inner.forEach((error) => {
            validationErrors[error.path] = error.message;
          });
          formRef.current.setErrors(validationErrors);
          toast.error("Verifique os campos em vermelho!");
        } else {
          toast.error("Ocorreu um erro tente novamente mais tarde!");
        }
      }
    } else {
      toast.error("Escolha um parceiro!");
    }
  }

  const debounceConsulta = async (body) => {
    return await new Promise((resolve) => {
      clearTimeout(timeoutConsulta);

      timeoutConsulta = setTimeout(() => {
        resolve(filtrarConsulta(body));
      }, 500);
    });
  };

  async function filtrarConsulta({ value, param }) {
    const result = await api.get(`options/parceiros?${param}=${value}`);
    return result.data.map((parceiro) => ({
      value: parceiro.id,
      cnpj: parceiro.cnpj,
      nome: parceiro.nome,
    }));
  }

  useEffect(() => {
    async function getData() {
      setId(0);
      formRef.current.reset();
      if (filtro?.value) {
        try {
          Swal.fire({
            title: "Aguarde",
            allowOutsideClick: false,
            showConfirmButton: false,
            onBeforeOpen: () => {
              Swal.showLoading();
            },
          });
          const { data } = await api.get(`/whitelabel-dados/${filtro?.value}`);
          if (data) {
            setId(data?.id);

            formRef.current.setData(data);
          }
        } catch (err) {
          // console.log(err);
          toast.error("Ocorreu um erro ao buscar dados!");
        }
      }
      Swal.close();
    }
    getData();
  }, [filtro]);

  return (
    <>
      <Row>
        <Col lg={12}>
          <h5 className="txt-tertiary">Dados WhiteLabel</h5>
          <div className="card-form">
            <Row>
              <Col lg={12}>
                <Row>
                  <Col
                    lg={4}
                    sm={6}
                    className="mb-1"
                    style={{
                      zIndex: 10,
                    }}
                  >
                    <AsyncSelect
                      loadOptions={(value) =>
                        debounceConsulta({
                          value,
                          param: "nome",
                        })
                      }
                      getOptionLabel={(option) => option.nome}
                      onChange={setFiltro}
                      value={filtro}
                      cacheOptions
                      isClearable
                      placeholder={"Busque pelo nome"}
                      noOptionsMessage={() => "Digite o nome"}
                    />
                  </Col>
                  <Col lg={4} sm={6} className="mb-1">
                    <AsyncSelect
                      loadOptions={(value) =>
                        debounceConsulta({
                          value,
                          param: "cnpj",
                        })
                      }
                      cacheOptions
                      onChange={setFiltro}
                      value={filtro}
                      isClearable
                      getOptionLabel={(option) => option.cnpj}
                      placeholder={"CNPJ"}
                      noOptionsMessage={() => "Digite o CNPJ"}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
            <hr />
            <Form onSubmit={(data) => handleSubmit(data)} ref={formRef}>
              <div className="mb-3">
                <Row>
                  <Col className="mb-3">
                    <Label>Sobre</Label>
                    <SunEditor name="texto_sobre_nos" />
                  </Col>
                </Row>
                <Row>
                  <Col className="mb-3">
                    <Label>Politica de privacidade</Label>
                    <SunEditor name="texto_privacidade" />
                  </Col>
                </Row>
                <Row>
                  <Col className="mb-3">
                    <Label>Termos de uso</Label>
                    <SunEditor name="texto_politica" />
                  </Col>
                </Row>
              </div>
              <Row className="justify-end">
                <Col lg={6} className="justify-end">
                  <Button color="green" className="btn-save" disabled={!id}>
                    {"Editar"}
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default WhiteLabel;

const buttonList = [
  ["undo", "redo", "font", "fontSize", "formatBlock"],
  [
    "blockquote",
    "bold",
    "underline",
    "italic",
    "strike",
    "fontColor",
    "hiliteColor",
    "removeFormat",
    "textStyle",
  ],
  [
    "outdent",
    "indent",
    "align",
    "list",
    "lineHeight",
    "paragraphStyle",
    "blockquote",
  ],
  ["link", "fullScreen"],
];

const SunEditor = ({ name, ...rest }) => {
  const suneditorRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: suneditorRef.current,
      getValue: (ref) => ref.editor.getContents(),
      setValue: (ref, value) => {
        // console.log(ref);
        ref?.editor?.setContents(value || "");
      },
    });
  }, [fieldName, registerField]);

  return (
    <>
      <SunEditorComponent
        ref={suneditorRef}
        id={fieldName}
        defaultValue={defaultValue}
        setOptions={{
          height: "100%",
          buttonList: buttonList,
          font: [
            "Arial",
            "Heebo",
            "tohoma",
            "Courier New,Courier",
            "Verdana",
            "Georgia",
          ],
        }}
        {...rest}
      />

      {error && <small className="error">{error}</small>}
    </>
  );
};
