import * as yup from "yup";

const regexCnpj = /^\d{2}\.\d{3}\.\d{3}\/\d{4}\-\d{2}$/;

export default async function store(data) {
  const schema = yup.object().shape({
    razao_social: yup.string().required("Obrigatório"),
    nome_fantasia: yup.string().required("Obrigatório"),
    cnpj: yup.string().required("Obrigatório"),
    nome_responsavel: yup.string().required("Obrigatório"),
    // cnpj: yup
    //   .string()
    //   .matches(regexCnpj, "CNPJ inválido")
    //   .required("Obrigatório"),
    email: yup.string().email("E-mail inválido!").required("Obrigatório"),
    cidade: yup.string().required("Obrigatório"),
    cep: yup.string().required("Obrigatório"),
    endereco: yup.string().required("Obrigatório"),
    numero: yup.string().required("Obrigatório"),
    bairro: yup.string().required("Obrigatório"),
    estado: yup.string().required("Obrigatório"),
    telefone: yup.string().required("Obrigatório"),
    percentual: yup.string().required("Obrigatório"),
  });

  await schema.validate(data, {
    abortEarly: false,
  });
}
