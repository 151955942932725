import React, { useEffect, useRef } from "react";
import { useField } from "@unform/core";
import { Row, Col } from "reactstrap";

export default function RadioInput({
  name,
  options,
  optionsClass = "",
  defaultChecked,
  ...rest
}) {
  const inputRefs = useRef([]);
  const { fieldName, registerField, error, defaultValue = "" } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue: (refs) => {
        return refs?.find((ref) => ref?.checked)?.value || "";
      },
      setValue: (refs, value) => {
        const inputRef = refs?.find((ref) => ref?.value === value);
        if (inputRef) {
          inputRef.checked = true;
          // console.log(inputRef.ref, inputRef.innerRef);
        }
      },
      clearValue: (refs) => {
        const inputRef = refs?.find((ref) => ref?.checked === true);
        if (inputRef) inputRef.checked = false;
      },
    });
  }, [defaultChecked, fieldName, registerField, defaultValue]);

  useEffect(() => {
    inputRefs.current.map((m) => {
      if (defaultChecked && m && m.value === defaultChecked) {
        m.checked = true;
      }

      return m;
    });
  }, [defaultChecked]);
  return (
    <>
      {options.map((option) => (
        <label
          htmlFor={option.id}
          key={option.id}
          className={("mb-0 mt-2 " + optionsClass).trim()}
        >
          {!option.color ? (
            <input
              ref={(ref) => inputRefs.current.push(ref)}
              id={option.id}
              type="radio"
              name={name}
              defaultChecked={defaultChecked === option.value}
              value={option.value}
              {...rest}
            />
          ) : (
            <Row>
              <Col lg={1} md={1}>
                <input
                  ref={(ref) => inputRefs.current.push(ref)}
                  id={option.id}
                  type="radio"
                  name={name}
                  defaultChecked={defaultChecked === option.value}
                  value={option.value}
                  {...rest}
                />
              </Col>
              <Col lg={1} md={1}>
                <div className={"color-box " + option.color}></div>
              </Col>
            </Row>
          )}
          {option.label}
        </label>
      ))}
      {error && <small className="error">{error}</small>}
    </>
  );
}
