import React from 'react';
import { Col, Row } from 'reactstrap';
import { useAuth } from '../../../contexts/auth';
import CartãoEscolhido from '../../cartaoEscolhido/index';

function LinkVenda() {
  const { user } = useAuth();

  return (
    <>
      <Row>
        <Col lg={7} className='mb-1'>
          <h5 className='txt-tertiary'>Cartão escolhido</h5>
        </Col>
      </Row>
      <div className='card-form'>
        <Row>
          <Col className='offset-md-1 pt-3' md={10}>
            <CartãoEscolhido vendedor={user?.id} />
          </Col>
        </Row>
      </div>
      <br />
    </>
  );
}

export default LinkVenda;
