import {
  Button,
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";
import { Form } from "@unform/web";
import UnformSelect from "../../../components/unformSelect";
import UnformCurrencyInput from "../../../components/unformCurrency";
import UnformInput from "../../../components/unformInput";
import UnformMaskedInput from "../../../components/unformMaskedInput";
import { useEffect, useRef, useState } from "react";
import api from "../../../services/api";
import { dateMask } from "../../../utils/masks";
import convertStringDate from "../../../utils/convertStringDate";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { ValidationError } from "yup";
import validator from "../../../validations/gerarCobranca";

function ModalCobranca({ toggle, isOpen, cliente, tipo = "PF", adm = true }) {
  const [boleto, setBoleto] = useState();
  const formRef = useRef();

  const handleSubmit = async (data) => {
    try {
      formRef.current.setErrors({});
      await validator(data);
      const body = {
        cliente,
        tipo,
      };
      body.valor = Number(
        data.valor.replaceAll(".", "").replace(",", ".").replace("R$ ", "")
      );
      body.descricao = data.descricao;
      body.parcela = Number(data.parcelas);
      if (data.data_vencimento) {
        body.dueDate = convertStringDate(data.data_vencimento)
          .toISOString()
          .slice(0, 10);
      }
      Swal.fire({
        title: "Aguarde",
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      const { data: pedido } = await api.post("/pedido", body);

      if (pedido && adm === false) {
        body.boleto = "GERAÇÃO";
        await api.post("/email-gera-cancela-boleto", body);
      }

      const link =
        pedido.link_pagamento || pedido.link || pedido.installmentLink;
      toggle();
      Swal.close();
      window.open(link);
    } catch (err) {
      const validationErrors = {};
      Swal.close();
      if (err instanceof ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
        toast.error("Verifique os campos em vermelho!");
      } else {
        const error =
          err?.response?.data?.error || "Ocorreu um erro, tente novamente!";
        toast.error(error);
      }
    }
  };

  useEffect(() => {
    async function getPagamento() {
      try {
        const { data: result } = await api.get("/condicao-pagamento/boleto");
        setBoleto(result);
      } catch (err) {}
    }
    getPagamento();
  }, []);

  return (
    <Modal isOpen={isOpen}>
      <Form onSubmit={(data) => handleSubmit(data)} ref={formRef}>
        <ModalHeader toggle={toggle}>Gerar cobrança</ModalHeader>
        <ModalBody>
          <Row>
            <Col lg={4} className="mt-3">
              <FormGroup>
                <Label>Parcelamento</Label>
                <UnformSelect
                  name="parcelas"
                  className="input-field-select-inner"
                  options={[...Array(Number(boleto?.parcelas || 0))].map(
                    (m, i) => ({
                      value: i + 1,
                      label: `${i + 1}x`,
                    })
                  )}
                />
              </FormGroup>
            </Col>
            <Col lg={4} className="mt-3">
              <FormGroup>
                <Label>Valor</Label>
                <UnformCurrencyInput
                  name="valor"
                  className="input-field-inner"
                  placeholder="R$ 0,00"
                />
              </FormGroup>
            </Col>
            <Col lg={4} className="mt-3">
              <FormGroup>
                <Label>Data Vencimento</Label>
                <UnformMaskedInput
                  name="data_vencimento"
                  className="input-field-inner"
                  mask={dateMask}
                />
              </FormGroup>
            </Col>
            <Col lg={12} className="mt-3">
              <FormGroup>
                <Label>Descrição cobrança</Label>
                <UnformInput name="descricao" className="input-field-inner" />
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter className="justify-between px-3">
          <Button
            color="outline-primary"
            className="btn-save"
            type="reset"
            onClick={toggle}
          >
            Cancelar
          </Button>
          <Button color="primary" className="btn-save px-6" type="submit">
            Gerar
          </Button>
        </ModalFooter>
      </Form>
    </Modal>
  );
}

export default ModalCobranca;
