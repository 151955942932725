import React, { useState, useEffect } from 'react';
import {
  Col,
  Row,
  Table,
  UncontrolledButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import Swal from 'sweetalert2';
import Pagination from 'react-paginate';
import { FaChevronLeft, FaChevronRight, FaEllipsisH } from 'react-icons/fa';
import { GrCart, GrEdit } from 'react-icons/gr';
import { useHistory, Link } from 'react-router-dom';
import api from '../../../services/api';
import defaultImg from '../../../assets/default.jpg';

function ConsultarTodosAdm(props) {
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const history = useHistory();

  useEffect(() => {
    async function getData() {
      Swal.fire({
        title: 'Aguarde',
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });

      const { data } = await api.get(`/cartoes-adm?page=${page + 1}`);
      setData(data);
      window.scrollTo(0, 0);

      Swal.close();
    }
    getData();
  }, [page]);

  function handleEdit(cartao) {
    history.push(`/acesso/cartoes/${cartao.descricao}?id=${cartao.id}`);
  }

  return (
    <>
      <Row>
        <Col lg={12}>
          <h5 className='txt-tertiary mb-3'>Cartões</h5>
        </Col>
      </Row>

      <div className='card-form  p-0'>
        <div className='p-3 justify-content-between d-flex align-center'>
          <p className='txt-muted'>Total de registros: {data?.total || 0}</p>
          {/* <Button color="muted" id="toggler">
            <FiFilter /> Filtros
          </Button> */}
          <div />
          <Link
            to='/acesso/cartoes/empresarial'
            color='green'
            className='btn-save btn btn-green'
          >
            Adicionar empresarial
          </Link>
        </div>
        {/* <div className="filter-collapse">
          <UncontrolledCollapse toggler="#toggler">
            <Row className="mt-3">
             
            </Row>
          </UncontrolledCollapse>
        </div> */}

        <Table responsive>
          <thead>
            <tr>
              <th>Nome</th>
              <th>Valor</th>
              <th>Ações</th>
            </tr>
          </thead>
          <tbody>
            {data?.rows?.map((data) => (
              <tr key={data.id}>
                <td>
                  <img
                    src={data?.imagem?.url || defaultImg}
                    alt={data.nome}
                    className='img-table mr-3'
                  />
                  <span>
                    {data?.nome} - {data?.tipo}
                  </span>
                </td>
                <td>
                  <span>
                    {Number(data?.valor || 0)?.toLocaleString('pt-BR', {
                      style: 'currency',
                      currency: 'BRL',
                    })}
                  </span>
                </td>
                <td>
                  <UncontrolledButtonDropdown size='xs'>
                    <DropdownToggle color='link link-gray' size='xs'>
                      <FaEllipsisH />
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem
                        className='justify-start'
                        onClick={() => handleEdit(data)}
                      >
                        <GrEdit />
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Editar
                      </DropdownItem>
                      {data?.descricao === 'empresarial' && (
                        <DropdownItem
                          className='justify-start'
                          onClick={() =>
                            window.open(
                              `/${
                                data?.parceiro?.WhiteLabel?.url_whitelabel ||
                                'poupaclub'
                              }/cartao-empresarial/${data?.id}`
                            )
                          }
                        >
                          <GrCart />
                          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Gerar link de venda
                        </DropdownItem>
                      )}
                    </DropdownMenu>
                  </UncontrolledButtonDropdown>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>

        <Pagination
          nextLabel={<FaChevronRight />}
          previousLabel={<FaChevronLeft />}
          pageCount={Number(data?.pages) || 1}
          onPageChange={({ selected }) => setPage(selected)}
          initialPage={page}
          containerClassName={'pagination justify-end'}
          activeClassName={'active'}
        />
      </div>
    </>
  );
}

export default ConsultarTodosAdm;
