import React, { useEffect, useRef } from 'react';
import { useField } from '@unform/core';

export default function CustomInput({ name, className, options, ...rest }) {
  const inputRef = useRef(null);
  const { fieldName, defaultValue, registerField, error } = useField(name);
  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      path: 'value',
    });
  }, [fieldName, registerField]);

  return (
    <>
      <select
        className={`${className ? className : ''} form-control ${error ? "error" :  ''}`.trim()}
        ref={inputRef}
        defaultValue={defaultValue}
        {...rest}
      >
        {options.map((m, i) => (
          <option key={i} value={m.value}>
            {m.label}
          </option>
        ))}
      </select>
      {error && <small className='error'>{error}</small>}
    </>
  );
}
