import { useState } from 'react';
import { Col, Row, Button, Label } from 'reactstrap';
import { Form } from '@unform/web';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import Swal from 'sweetalert2';

import Layout from './index';
import api from '../../../services/api';
import { useAuth } from '../../../contexts/auth';

function FalarComEquipe() {
  const { user } = useAuth();
  let history = useHistory();
  const [opcao, setOpcao] = useState('');

  const [whiteLabel, setWhiteLabel] = useState('poupa-club');

  async function handleSubmit() {
    if (opcao) {
      try {
        Swal.fire({
          title: 'Aguarde',
          allowOutsideClick: false,
          showConfirmButton: false,
          onBeforeOpen: () => {
            Swal.showLoading();
          },
        });
        const body = {
          usuario: user.nome,
          escolhido: opcao,
        };

        await api.post('/solicitar-contato', body);

        toast.success('Contato solicitado');
        Swal.close();
        window.scrollTo(0, 0);
        history.push(`/${whiteLabel}/obrigado-pelo-interesse`);
      } catch (e) {
        Swal.close();
        toast.error('Não foi possível solicitar');
      }
    } else {
      Swal.close();
      toast.error('Selecione um assunto');
    }
  }

  return (
    <>
      <Row>
        <Col lg={12}>
          <h5 className='txt-tertiary'>Fale com nossa equipe</h5>
          <div className='card-form'>
            <Form onSubmit={() => handleSubmit()}>
              {user.perfil === 'U' ? (
                <>
                  <Row>
                    <Col lg={12} xs={12} sm={12}>
                      <input
                        className='mb-3'
                        name='escolha_assunto'
                        value='segunda_via'
                        type='radio'
                        onClick={(e) => {
                          setOpcao(e.target.value);
                        }}
                      />
                      <Label className='label-radio' for='segunda_via'>
                        &nbsp; Solicitar segunda via de cartão
                      </Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} xs={12} sm={12}>
                      <input
                        className='mb-3'
                        name='escolha_assunto'
                        value='parceiros_descontos'
                        type='radio'
                        onClick={(e) => {
                          setOpcao(e.target.value);
                        }}
                      />
                      <Label className='label-radio' for='parceiros_descontos'>
                        &nbsp; Solicitar contato referente a parceiros e
                        descontos
                      </Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} xs={12} sm={12}>
                      <input
                        className='mb-3'
                        name='escolha_assunto'
                        value='dependentes'
                        type='radio'
                        onClick={(e) => {
                          setOpcao(e.target.value);
                        }}
                      />
                      <Label className='label-radio' for='dependentes'>
                        &nbsp; Solicitar contato referente a dependentes
                      </Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} xs={12} sm={12}>
                      <input
                        className='mb-3'
                        name='escolha_assunto'
                        value='cancelamento'
                        type='radio'
                        onClick={(e) => {
                          setOpcao(e.target.value);
                        }}
                      />
                      <Label className='label-radio' for='dependentes'>
                        &nbsp; Solicitar cancelamento da assinatura
                      </Label>
                    </Col>
                  </Row>
                </>
              ) : user.perfil === 'P' ? (
                <>
                  <Row>
                    <Col lg={6} xs={12} sm={12}>
                      <input
                        className='mb-3'
                        name='escolha_assunto'
                        value='solicitar_troca_desconto'
                        type='radio'
                        onClick={(e) => {
                          setOpcao(e.target.value);
                        }}
                      />
                      <Label
                        className='label-radio'
                        for='solicitar_troca_desconto'
                      >
                        &nbsp; Solicitar troca de desconto
                      </Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} xs={12} sm={12}>
                      <input
                        className='mb-3'
                        name='escolha_assunto'
                        value='marketing'
                        type='radio'
                        onClick={(e) => {
                          setOpcao(e.target.value);
                        }}
                      />
                      <Label className='label-radio' for='marketing'>
                        &nbsp; Solicitar contato referente a marketing
                      </Label>
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <Row>
                    <Col lg={6} xs={12} sm={12}>
                      <input
                        className='mb-3'
                        name='escolha_assunto'
                        value='material_treinamento'
                        type='radio'
                        onClick={(e) => {
                          setOpcao(e.target.value);
                        }}
                      />
                      <Label className='label-radio' for='material_treinamento'>
                        &nbsp; Solicitar material para treinamento
                      </Label>
                    </Col>
                  </Row>
                </>
              )}
              <Row>
                <Col lg={6} xs={12} sm={12}>
                  <input
                    className='mb-3'
                    name='escolha_assunto'
                    value='financas'
                    type='radio'
                    onClick={(e) => {
                      setOpcao(e.target.value);
                    }}
                  />
                  <Label className='label-radio' for='financas'>
                    &nbsp; Solicitar contato referente a finanças
                  </Label>
                </Col>
              </Row>
              <Row>
                <Col lg={6} xs={12} sm={12}>
                  <input
                    className='mb-3'
                    name='escolha_assunto'
                    value='dados_cadastrais'
                    type='radio'
                    onClick={(e) => {
                      setOpcao(e.target.value);
                    }}
                  />
                  <Label className='label-radio' for='dados_cadastrais'>
                    &nbsp; Solicitar contato referente a dados cadastrais
                  </Label>
                </Col>
              </Row>

              <Row>
                <Col lg={6} xs={12} sm={12}>
                  <input
                    className='mb-3'
                    name='escolha_assunto'
                    value='outros'
                    type='radio'
                    onClick={(e) => {
                      setOpcao(e.target.value);
                    }}
                  />
                  <Label className='label-radio' for='outros'>
                    &nbsp; Outros assuntos
                  </Label>
                </Col>
              </Row>
              <div className='justify-end'>
                <Button color='green' className='btn-save btn-lg'>
                  Enviar
                </Button>
              </div>
            </Form>
          </div>
        </Col>
      </Row>
    </>
  );
}

export default FalarComEquipe;
