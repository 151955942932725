import axios from "axios";

const api = axios.create({
  baseURL: window.location.origin.includes("http://localhost")
    ? "http://localhost:3333"
    : window.location.origin.includes("https://app.digitalclinic.com.br")
    ? "https://api.digitalclinic.com.br"
    : "https://ipa.poupaclub.com.br",
});

// const api = axios.create({
//   baseURL: "https://api.digitalclinic.com.br",
// });

// const api3 = axios.create({
//   baseURL: "https://ipa.poupaclub.com.br",
// });

export default api;
