import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Button,
  Row,
  Col,
  Container,
  ModalHeader,
  Modal,
  ModalBody,
} from 'reactstrap';
import cep from 'cep-promise';
import { Scope } from '@unform/core';
import Swal from 'sweetalert2';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { differenceInCalendarDays } from 'date-fns';

import CartaoGold from '../../components/cartaoGold';
import CartaoBlack from '../../components/cartaoBlack';
import CartaoEspecial from '../../components/cartaoEspecial';
import { Form } from '@unform/web';
import Input from '../../components/unformInput';
import MaskedInput from '../../components/unformMaskedInput';
import UnformSelect from '../../components/unformSelect';
import UnformCheckboxTermos from '../../components/unformCheckboxTermos';
import api from '../../services/api';
import criarCliente from '../../validations/criarCliente';
import {
  phoneMask,
  cpfMask,
  cepMask,
  dateMask,
  telefoneCelularMask,
} from '../../utils/masks';
import formatarDinheiro from '../../utils/formatarDinheiro';
import convertStringDate from '../../utils/convertStringDate';
import useFetch from '../../hooks/useFetch';
import parentesco from '../../utils/parentesco';
import CreditCards from '../../components/creditCards';
import criarClienteDependenteCartao from '../../validations/criarClienteDependenteCartao';
import { useWhiteLabel } from '../../contexts/whitelabel';

let ultimoCep = '';
let ultimoCupom = '';
let consultando = false;

function CartaoEscolhido({ vendedor }) {
  const { dadosWhiteLabel } = useWhiteLabel();
  const portalVendas = window.location.pathname === '/acesso/portal-venda';
  const { data: cartoes } = useFetch('/cartoes');
  const params = useParams();
  const location = useLocation();
  const [condicaoPagamento, setCondicaoPagamento] = useState({
    condicaoPagamento: [],
  });
  const [pagamento, setPagamento] = useState('');
  const [modal, setModal] = useState(false);
  const [dadosModal, setDadosModal] = useState(null);
  const tipoDoCartao = params.tipo;
  const formRef = useRef(null);
  const history = useHistory();

  const toggle = () => setModal(!modal);

  function redirectCompra() {
    if (vendedor) {
      history.push('/acesso/portal-venda');
    } else {
      history.push('/' + dadosWhiteLabel?.url_whitelabel || 'poupa-club');
    }
  }

  async function buscaCep(cepValue) {
    if (cepValue) {
      if (ultimoCep !== cepValue) {
        try {
          if (cepValue.replaceAll('_', '').length === 9) {
            const result = await cep(cepValue);

            formRef.current.setData({
              ...formRef.current.getData(),
              bairro: result.neighborhood,
              cidade: `${result.city} / ${result.state}`,
              logradouro: result.street,
            });
            ultimoCep = cepValue;
          } else if (cepValue.length === 0) {
            formRef.current.setData({
              ...formRef.current.getData(),
              bairro: '',
              cidade: '',
              logradouro: '',
            });
          }
        } catch (error) {
          toast.warn('CEP não encontrado!');
        }
      }
    }
  }

  function validarDadosDependentes(dados) {
    const comValor = Object.keys(dados).some((field) => !!dados[field]);
    const semValor = Object.keys(dados)
      .filter((f) => f !== 'cpf' && f !== 'telefone_celular')
      .some((field) => !dados[field]);
    return comValor && semValor;
  }

  function formataComDesconto(i) {
    const cartaoDesc = getCartao(tipoDoCartao);

    const desconto = cartaoDesc?.desconto_boleto
      ? 1 - cartaoDesc?.desconto_boleto / 100
      : 0.85;
    const desc = cartaoDesc?.desconto_boleto || 15;

    const valor = Number(cartaoDesc.valor) * desconto;
    return `${i + 1}x ${formatarDinheiro.format(
      valor
    )} com ${desc}% de desconto`;
  }

  function formataSemDesconto(i) {
    const valor = Number(getCartao(tipoDoCartao).valor) / (i + 1);
    return `${i + 1}x ${formatarDinheiro.format(valor)} sem juros`;
  }

  async function handleSubmit(formData) {
    if (consultando) {
      return;
    }
    const { conjuge, ...rest } = formData;

    const arrDependentes = Object.keys(rest).filter((f) =>
      f.includes('dependente')
    );
    const arrRest = Object.keys(rest).filter((f) => !f.includes('dependente'));

    const data = {};
    const dependentes = { conjuge };

    arrDependentes.forEach((dep) => {
      dependentes[dep] = rest[dep];
    });
    arrRest.forEach((dep) => {
      data[dep] = rest[dep];
    });

    if (
      differenceInCalendarDays(
        new Date(),
        convertStringDate(data.data_nascimento)
      ) < 6575
    ) {
      return Swal.fire({
        title: 'Ops...',
        text: 'Não é possível o titular do cartão ter menos que 18 anos!',
        icon: 'error',
      });
    }

    if (data.pagamento === 'ASSINATURA') {
      return handleSubmitAssinatura({
        dependentes,
        data,
        arrDependentes,
        conjuge,
      });
    }

    const query = location.search.substr(1).split('&').filter(Boolean);

    const vend =
      query.find((f) => f.includes('vend='))?.replace('vend=', '') || vendedor;

    const body = {
      ...data,
      data_nascimento: convertStringDate(data.data_nascimento),
      cidade: data.cidade?.split('/')[0]?.trim(),
      estado: data.cidade?.split('/')[1]?.trim(),
      grupo_clientes: tipoDoCartao,
    };

    try {
      formRef.current.setErrors({});
      await criarCliente(data);

      if (arrDependentes.length) {
        const depComErro = [];

        arrDependentes.forEach((item) => {
          if (validarDadosDependentes(dependentes[item])) {
            depComErro.push([dependentes[item], item]);
          }
        });

        if (depComErro.length) {
          await criarClienteDependenteCartao(depComErro);
        }

        if (tipoDoCartao === 'gold' || dadosWhiteLabel) {
          const anos = [];
          const {
            idade_limite: limiteIdade,
            limitar_idade: limitarIdade,
            tipo,
          } = getCartao(tipoDoCartao);

          const configSwal = {
            title: 'Ops...',
            text: `Não é possível ter dependentes maiores de ${limiteIdade} anos em nosso cartão ${tipo}.`,
            icon: 'error',
          };

          arrDependentes.forEach((item) => {
            if (dependentes[item].data_nascimento) {
              anos.push(
                differenceInCalendarDays(
                  new Date(),
                  convertStringDate(dependentes[item].data_nascimento)
                )
              );
            }
          });

          const difDays = differenceInCalendarDays(
            new Date(),
            new Date().setFullYear(new Date().getFullYear() - limiteIdade)
          );
          if (limitarIdade) {
            if (anos.some((s) => s >= difDays)) {
              return Swal.fire(configSwal);
            }
          }
        }

        arrDependentes.forEach((item) => {
          body[item] = !!dependentes[item]?.nome && {
            ...dependentes[item],
            data_nascimento: convertStringDate(
              dependentes[item]?.data_nascimento
            ),
          };
        });
      }

      if (conjuge?.nome) {
        body.conjuge = {
          ...conjuge,
          data_nascimento: convertStringDate(conjuge?.data_nascimento),
        };
      }
      Swal.fire({
        title: 'Aguarde...',
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });

      body.whiteLabel = dadosWhiteLabel?.url_whitelabel;

      const { data: res } = await api.post(
        `/clientes?vendedor=${vend || ''}`,
        body
      );

      Swal.fire({
        html: 'Uma janela será aberta com o link para pagamento.<br /> Após a confirmação do pagamento em nosso sitema, um e-mail será enviado com dados para acesso ao sistema',
        icon: 'success',
      }).then(() => {
        window.open(res.pedido.link_pagamento);
        redirectCompra();
      });
    } catch (err) {
      const validationErrors = {};
      Swal.close();
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
        toast.error('Verifique os campos em vermelho!');
      } else {
        // console.log(err);
        const error =
          err?.response?.data?.error || 'Ocorreu um erro, tente novamente!';
        toast.error(error);
      }
    }
  }

  const handleSubmitAssinatura = async ({
    dependentes,
    data,
    arrDependentes,
    conjuge,
  }) => {
    const body = {
      ...data,
      data_nascimento: convertStringDate(data.data_nascimento),
      cidade: data.cidade?.split('/')[0]?.trim(),
      estado: data.cidade?.split('/')[1]?.trim(),
      grupo_clientes: tipoDoCartao,
    };

    body.whiteLabel = dadosWhiteLabel?.url_whitelabel;

    try {
      formRef.current.setErrors({});
      await criarCliente(data);

      if (arrDependentes.length) {
        const depComErro = [];

        arrDependentes.forEach((item) => {
          if (validarDadosDependentes(dependentes[item])) {
            depComErro.push([dependentes[item], item]);
          }
        });

        if (depComErro.length) {
          await criarClienteDependenteCartao(depComErro);
        }

        if (tipoDoCartao === 'black' || dadosWhiteLabel) {
          const anos = [];
          const {
            idade_limite: limiteIdade,
            limitar_idade: limitarIdade,
            tipo,
          } = getCartao(tipoDoCartao);

          const configSwal = {
            title: 'Ops...',
            text: `Não é possível ter dependentes maiores de ${limiteIdade} anos em nosso cartão ${tipo}.`,
            icon: 'error',
          };

          arrDependentes.forEach((item) => {
            if (dependentes[item].data_nascimento) {
              anos.push(
                differenceInCalendarDays(
                  new Date(),
                  convertStringDate(dependentes[item].data_nascimento)
                )
              );
            }
          });

          const difDays = differenceInCalendarDays(
            new Date(),
            new Date().setFullYear(new Date().getFullYear() - limiteIdade)
          );

          if (limitarIdade) {
            if (anos.some((s) => s >= difDays)) {
              return Swal.fire(configSwal);
            }
          }
        }

        arrDependentes.forEach((item) => {
          body[item] = !!dependentes[item]?.nome && {
            ...dependentes[item],
            data_nascimento: convertStringDate(
              dependentes[item]?.data_nascimento
            ),
          };
        });
      }

      if (conjuge?.nome) {
        body.conjuge = {
          ...conjuge,
          data_nascimento: convertStringDate(conjuge?.data_nascimento),
        };
      }

      setDadosModal(body);
      setModal(true);
    } catch (err) {
      console.log(err);
      const validationErrors = {};
      Swal.close();
      if (err instanceof Yup.ValidationError) {
        err.inner.forEach((error) => {
          validationErrors[error.path] = error.message;
        });
        formRef.current.setErrors(validationErrors);
        toast.error('Verifique os campos em vermelho!');
      } else {
        const error =
          err?.response?.data?.error || 'Ocorreu um erro, tente novamente!';
        toast.error(error);
      }
    }
  };

  const handleSubmitModalCartao = async (creditCardHash) => {
    const query = location.search.substr(1).split('&').filter(Boolean);

    const vend =
      query.find((f) => f.includes('vend='))?.replace('vend=', '') || vendedor;

    Swal.fire({
      title: 'Aguarde...',
      allowOutsideClick: false,
      showConfirmButton: false,
      onBeforeOpen: () => {
        Swal.showLoading();
      },
    });
    try {
      await api.post(`/clientes?vendedor=${vend || ''}`, {
        ...dadosModal,
        creditCardHash,
      });
      setModal(false);
      Swal.fire({
        html: 'Assinatura realizada com sucesso, dentro de alguns minutos você receberá um email com informações para acesso a plataforma',
        icon: 'success',
      }).then(() => {
        redirectCompra();
      });
    } catch (err) {
      Swal.close();
      const error =
        err?.response?.data?.error || 'Ocorreu um erro, tente novamente!';
      toast.error(error);
    }
  };

  function handleChangePagamento(e) {
    const p = condicaoPagamento?.condicaoPagamento?.find(
      (f) => f.descricao === e.target.value
    );
    setPagamento(p);
    getValorDescVoucher();
  }

  const retornoCartao = (tipo, data = {}) => {
    const cartoes = {
      especial: (props) => <CartaoEspecial {...props} />,
      black: (props) => <CartaoBlack {...props} />,
      gold: (props) => <CartaoGold {...props} />,
    };

    if (['especial', 'black', 'gold'].includes(tipo)) {
      return cartoes[tipo](data);
    }

    return null;
  };

  function getCartao() {
    if (dadosWhiteLabel) {
      const tipoCartoes = {
        especial: 1,
        gold: 2,
        black: 3,
      };
      return dadosWhiteLabel[`cartao${tipoCartoes[tipoDoCartao]}`];
    } else {
      return cartoes?.find((f) => f.descricao === tipoDoCartao);
    }
  }

  async function consultarCupom(codigo) {
    consultando = true;
    if (ultimoCupom === codigo) {
      return;
    }
    ultimoCupom = codigo;
    if (!codigo) {
      return;
    }

    try {
      const cupom = await api.get(`/cupom/${codigo}`);

      if (cupom?.data) {
        Swal.fire({
          title: 'Cupom aplicado',
          icon: 'success',
          text: `Cupom de ${cupom?.data.desconto}% de desconto aplicado`,
        });
      } else {
        throw new Error('Cupom inválido');
      }
      formRef.current.setFieldValue('valor_desconto_voucher', cupom?.data.desconto);
      getValorDescVoucher();
    } catch (err) {
      Swal.fire({
        title: 'Cupom inválido',
        icon: 'error',
        text: `Cupom não existe ou expirado`,
      });
      formRef.current.setFieldValue('total_desc_voucher', ``);
      formRef.current.setFieldValue('valor_desconto_voucher', ``);
    } finally {
      consultando = false;
    }
  }

  async function getValorDescVoucher() {
    const cartaoDesc = getCartao(tipoDoCartao);
    const parcelaSelecionada = Number(
      formRef.current.getFieldValue('parcela')
    );
    const desconto = Number(
      formRef.current.getFieldValue('valor_desconto_voucher')
    ) / 100;
    const valorCartao = Number(cartaoDesc.valor);
    const descontoBoleto = cartaoDesc?.desconto_boleto
    ? 1 - cartaoDesc?.desconto_boleto / 100
    : 0.15;

    const descontoPag =
      pagamento?.descricao?.toUpperCase()?.includes('BOLETO') &&
      parcelaSelecionada === 1
        ? descontoBoleto
        : 0;

    const valorComDesc = (1 - (desconto + descontoPag))  * valorCartao;

    formRef.current.setFieldValue(
      'total_desc_voucher',
      `Valor final ${valorComDesc.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
      })}`
    );
  }

  useEffect(() => {
    async function getCondicoesPagamento() {
      try {
        const { data } = await api.get('/options/condicao-pagamento');
        setCondicaoPagamento(data);
      } catch (err) {}
    }
    getCondicoesPagamento();
  }, []);

  const retornaDependentes = useCallback(() => {
    function getCartaoWhiteLabel() {
      const tipoCartoes = {
        especial: 1,
        gold: 2,
        black: 3,
      };
      return dadosWhiteLabel[`cartao${tipoCartoes[tipoDoCartao]}`];
    }
    const cartao = getCartaoWhiteLabel();

    return (
      <div className='mb-6'>
        {cartao.conjuge && (
          <Scope path='conjuge'>
            <Row className='row-dependentes'>
              <Col lg={4} md={7}>
                <Input
                  className='input-field'
                  placeholder='Cônjuge | Nome completo'
                  name='nome'
                />
              </Col>
              <Col lg={2} md={5}>
                <UnformSelect
                  type='select'
                  className='input-field-select'
                  placeholder='Parentesco'
                  name='parentesco'
                  options={[
                    {
                      value: 'Conjuge',
                      label: 'Conjuge',
                    },
                  ]}
                />
              </Col>
              <Col lg={2} md={4}>
                <MaskedInput
                  mask={dateMask}
                  className='input-field'
                  placeholder='Data nascimento 00/00/0000'
                  name='data_nascimento'
                />
              </Col>
              <Col lg={2} md={4}>
                <MaskedInput
                  mask={phoneMask}
                  removeEspecial
                  className='input-field'
                  placeholder='Celular DDD'
                  name='telefone_celular'
                />
              </Col>
              <Col lg={2} md={4}>
                <MaskedInput
                  mask={cpfMask}
                  removeEspecial
                  className='input-field'
                  placeholder='CPF'
                  name='cpf'
                />
              </Col>
            </Row>
          </Scope>
        )}
        {[...Array(cartao.numero_dependentes || 0)].map((m, i) => (
          <Scope path={`dependente${i + 1}`} key={i}>
            <Row className='row-dependentes'>
              <Col lg={4} md={7}>
                <Input
                  className='input-field'
                  placeholder={`Dependente ${i + 1} | Nome completo`}
                  name='nome'
                />
              </Col>
              <Col lg={2} md={5}>
                <UnformSelect
                  type='select'
                  className='input-field-select'
                  placeholder='Parentesco'
                  name='parentesco'
                  options={parentesco}
                />
              </Col>
              <Col lg={2} md={4}>
                <MaskedInput
                  mask={dateMask}
                  className='input-field'
                  placeholder='Data nascimento 00/00/0000'
                  name='data_nascimento'
                />
              </Col>
              <Col lg={2} md={4}>
                <MaskedInput
                  mask={phoneMask}
                  removeEspecial
                  className='input-field'
                  placeholder='Celular DDD'
                  name='telefone_celular'
                />
              </Col>
              <Col lg={2} md={4}>
                <MaskedInput
                  mask={cpfMask}
                  removeEspecial
                  className='input-field'
                  placeholder='CPF'
                  name='cpf'
                />
              </Col>
            </Row>
          </Scope>
        ))}
      </div>
    );
  }, [dadosWhiteLabel, tipoDoCartao]);

  return (
    <>
      <Container className='mt-6'>
        <Row>
          <Col lg={4}>
            <Row>
              <Col lg={12}>
                <p
                  className='cartao-escolhido-title'
                  style={{
                    color: dadosWhiteLabel
                      ? dadosWhiteLabel?.whitelabel_text_color_3
                      : '',
                  }}
                >
                  {portalVendas ? 'Escolhido' : 'Você escolheu'}:
                </p>
                <div className='peca-agora-responsive'>
                  {retornoCartao(tipoDoCartao, { data: getCartao() })}
                </div>
              </Col>
            </Row>
            <div className='responsive-cel-footer'>
              {retornoCartao(tipoDoCartao, { data: getCartao() })}
            </div>
          </Col>
          <Col lg={8}>
            <Form
              className='form-poupaclub mb-1'
              ref={formRef}
              onSubmit={handleSubmit}
            >
              <Row>
                <Col lg={6}>
                  <p
                    className='cartao-escolhido-title'
                    style={{
                      color: dadosWhiteLabel
                        ? dadosWhiteLabel?.whitelabel_text_color_3
                        : '',
                    }}
                  >
                    Cadastro:
                  </p>
                </Col>
              </Row>
              <Row>
                <Col md={6} sm={12}>
                  <Row>
                    <Col lg={12}>
                      <Input
                        className='input-field'
                        name='nome'
                        placeholder={
                          !portalVendas
                            ? 'Titular'
                            : 'Titular | Seu nome completo'
                        }
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <MaskedInput
                        className='input-field'
                        name='telefone_celular'
                        placeholder='Telefone ou Celular com DDD'
                        mask={telefoneCelularMask}
                        removeEspecial
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <Input
                        className='input-field'
                        placeholder='E-mail'
                        name='email'
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <MaskedInput
                        className='input-field'
                        placeholder='CPF'
                        name='cpf'
                        mask={cpfMask}
                        removeEspecial
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <MaskedInput
                        className='input-field'
                        placeholder='Data de Nascimento'
                        name='data_nascimento'
                        mask={dateMask}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <UnformSelect
                        name='sexo'
                        className='input-field-select'
                        options={[
                          {
                            value: '',
                            label: 'Gênero',
                          },
                          {
                            value: 'masculino',
                            label: 'Masculino',
                          },
                          {
                            value: 'feminino',
                            label: 'Feminino',
                          },
                          {
                            value: 'outros',
                            label: 'Outros',
                          },
                        ]}
                      />
                    </Col>
                  </Row>
                </Col>

                <Col md={6} sm={12}>
                  <Row>
                    <Col lg={12}>
                      <MaskedInput
                        className='input-field'
                        placeholder='CEP'
                        mask={cepMask}
                        removeEspecial
                        name='cep'
                        onChange={(e) => {
                          buscaCep(e.target.value);
                        }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <Input
                        className='input-field'
                        placeholder='Endereço'
                        name='logradouro'
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <Input
                        className='input-field'
                        placeholder='Número'
                        name='numero'
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <Input
                        className='input-field'
                        placeholder='Complemento'
                        name='complemento'
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <Input
                        className='input-field'
                        placeholder='Bairro'
                        name='bairro'
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12}>
                      <Input
                        className='input-field'
                        placeholder='Cidade / Estado'
                        name='cidade'
                      />
                    </Col>
                  </Row>
                </Col>
              </Row>

              {dadosWhiteLabel
                ? retornaDependentes()
                : tipoDoCartao !== 'especial' && (
                    <div className='mb-6'>
                      {tipoDoCartao === 'gold' && (
                        <Scope path='conjuge'>
                          <Row className='row-dependentes'>
                            <Col lg={4} md={7}>
                              <Input
                                className='input-field'
                                placeholder='Cônjuge | Nome completo'
                                name='nome'
                              />
                            </Col>
                            <Col lg={2} md={5}>
                              <UnformSelect
                                type='select'
                                className='input-field-select'
                                placeholder='Parentesco'
                                name='parentesco'
                                options={[
                                  {
                                    value: 'Conjuge',
                                    label: 'Conjuge',
                                  },
                                ]}
                              />
                            </Col>
                            <Col lg={2} md={4}>
                              <MaskedInput
                                mask={dateMask}
                                className='input-field'
                                placeholder='Data nascimento 00/00/0000'
                                name='data_nascimento'
                              />
                            </Col>
                            <Col lg={2} md={4}>
                              <MaskedInput
                                mask={phoneMask}
                                removeEspecial
                                className='input-field'
                                placeholder='Celular DDD'
                                name='telefone_celular'
                              />
                            </Col>
                            <Col lg={2} md={4}>
                              <MaskedInput
                                mask={cpfMask}
                                removeEspecial
                                className='input-field'
                                placeholder='CPF'
                                name='cpf'
                              />
                            </Col>
                          </Row>
                        </Scope>
                      )}
                      <Scope path='dependente1'>
                        <Row className='row-dependentes'>
                          <Col lg={4} md={7}>
                            <Input
                              className='input-field'
                              placeholder='Dependente 1 | Nome completo'
                              name='nome'
                            />
                          </Col>
                          <Col lg={2} md={5}>
                            <UnformSelect
                              type='select'
                              className='input-field-select'
                              placeholder='Parentesco'
                              name='parentesco'
                              options={parentesco}
                            />
                          </Col>
                          <Col lg={2} md={4}>
                            <MaskedInput
                              mask={dateMask}
                              className='input-field'
                              placeholder='Data nascimento 00/00/0000'
                              name='data_nascimento'
                            />
                          </Col>
                          <Col lg={2} md={4}>
                            <MaskedInput
                              mask={phoneMask}
                              removeEspecial
                              className='input-field'
                              placeholder='Celular DDD'
                              name='telefone_celular'
                            />
                          </Col>
                          <Col lg={2} md={4}>
                            <MaskedInput
                              mask={cpfMask}
                              removeEspecial
                              className='input-field'
                              placeholder='CPF'
                              name='cpf'
                            />
                          </Col>
                        </Row>
                      </Scope>
                      <Scope path='dependente2'>
                        <Row className='row-dependentes'>
                          <Col lg={4} md={7}>
                            <Input
                              className='input-field'
                              placeholder='Dependente 2 | Nome completo'
                              name='nome'
                            />
                          </Col>
                          <Col lg={2} md={5}>
                            <UnformSelect
                              type='select'
                              className='input-field-select'
                              placeholder='Parentesco'
                              name='parentesco'
                              options={parentesco}
                            />
                          </Col>
                          <Col lg={2} md={4}>
                            <MaskedInput
                              mask={dateMask}
                              className='input-field'
                              placeholder='Data nascimento 00/00/0000'
                              name='data_nascimento'
                            />
                          </Col>
                          <Col lg={2} md={4}>
                            <MaskedInput
                              mask={phoneMask}
                              removeEspecial
                              className='input-field'
                              placeholder='Celular DDD'
                              name='telefone_celular'
                            />
                          </Col>
                          <Col lg={2} md={4}>
                            <MaskedInput
                              mask={cpfMask}
                              removeEspecial
                              className='input-field'
                              placeholder='CPF'
                              name='cpf'
                            />
                          </Col>
                        </Row>
                      </Scope>
                      <Scope path='dependente3'>
                        <Row className='row-dependentes'>
                          <Col lg={4} md={7}>
                            <Input
                              className='input-field'
                              placeholder='Dependente 3 | Nome completo'
                              name='nome'
                            />
                          </Col>
                          <Col lg={2} md={5}>
                            <UnformSelect
                              type='select'
                              className='input-field-select'
                              placeholder='Parentesco'
                              name='parentesco'
                              options={parentesco}
                            />
                          </Col>
                          <Col lg={2} md={4}>
                            <MaskedInput
                              mask={dateMask}
                              className='input-field'
                              placeholder='Data nascimento 00/00/0000'
                              name='data_nascimento'
                            />
                          </Col>
                          <Col lg={2} md={4}>
                            <MaskedInput
                              mask={phoneMask}
                              removeEspecial
                              className='input-field'
                              placeholder='Celular DDD'
                              name='telefone_celular'
                            />
                          </Col>
                          <Col lg={2} md={4}>
                            <MaskedInput
                              mask={cpfMask}
                              removeEspecial
                              className='input-field'
                              placeholder='CPF'
                              name='cpf'
                            />
                          </Col>
                        </Row>
                      </Scope>

                      {tipoDoCartao === 'black' && (
                        <Scope path='dependente4'>
                          <Row className='row-dependentes'>
                            <Col lg={4} md={7}>
                              <Input
                                className='input-field'
                                placeholder='Dependente 4 | Nome completo'
                                name='nome'
                              />
                            </Col>
                            <Col lg={2} md={5}>
                              <UnformSelect
                                type='select'
                                className='input-field-select'
                                placeholder='Parentesco'
                                name='parentesco'
                                options={parentesco}
                              />
                            </Col>
                            <Col lg={2} md={4}>
                              <MaskedInput
                                mask={dateMask}
                                className='input-field'
                                placeholder='Data nascimento 00/00/0000'
                                name='data_nascimento'
                              />
                            </Col>
                            <Col lg={2} md={4}>
                              <MaskedInput
                                mask={phoneMask}
                                removeEspecial
                                className='input-field'
                                placeholder='Celular DDD'
                                name='telefone_celular'
                              />
                            </Col>
                            <Col lg={2} md={4}>
                              <MaskedInput
                                mask={cpfMask}
                                removeEspecial
                                className='input-field'
                                placeholder='CPF'
                                name='cpf'
                              />
                            </Col>
                          </Row>
                        </Scope>
                      )}
                    </div>
                  )}
              <Row>
                <Col lg={6}>
                  <p className='cartao-escolhido-title'>Pagamento:</p>
                </Col>
              </Row>
              <Row>
                <Col lg={pagamento?.parcelas > 1 ? 6 : 4}>
                  <UnformSelect
                    type='select'
                    className='input-field-select'
                    name='pagamento'
                    onChange={handleChangePagamento}
                    options={[
                      {
                        value: '',
                        label: 'Formas de pagamento',
                      },
                    ].concat(
                      condicaoPagamento?.condicaoPagamento?.map((m) => ({
                        label: m.nome,
                        value: m.descricao,
                      }))
                    )}
                  />
                </Col>

                {pagamento?.parcelas > 1 && (
                  <Col lg={6}>
                    <UnformSelect
                      type='select'
                      className='input-field-select mb-3'
                      name='parcela'
                      onChange={getValorDescVoucher}
                      options={[
                        {
                          value: '',
                          label: 'Parcelamento sem juros',
                        },
                      ].concat(
                        [...Array(Number(pagamento.parcelas))].map(
                          (parcela, i) => ({
                            value: i + 1,
                            label:
                              i + 1 === 1 &&
                              pagamento.descricao.includes('BOLETO')
                                ? formataComDesconto(i)
                                : formataSemDesconto(i),
                          })
                        )
                      )}
                    />
                  </Col>
                )}

                {pagamento?.descricao && pagamento?.descricao !== 'ASSINATURA' && (
                  <>
                    <Col lg={pagamento?.parcelas > 1 ? 6 : 4}>
                      <Input
                        className='input-field text-upper'
                        placeholder='CUPOM DE DESCONTO'
                        name='voucher'
                        defaultvalue=''
                        onBlur={(e) => consultarCupom(e.target.value)}
                      />
                    </Col>
                    <Col lg={pagamento?.parcelas > 1 ? 6 : 4}>
                      <Input
                        className='input-field text-upper'
                        placeholder='Valor final'
                        name='total_desc_voucher'
                        defaultvalue=''
                        disabled
                      />
                    </Col>
                  </>
                )}
              </Row>
              <Col hidden>
                <Input
                  className='input-field text-upper'
                  placeholder='% Desconto voucher'
                  name='valor_desconto_voucher'
                  defaultvalue=''
                  disabled
                />
              </Col>
              <Row>
                <Col lg={12}>
                  <UnformCheckboxTermos name='termos' />
                </Col>
              </Row>
              <Button
                className='btn-pill bold btn-enviar mt-1'
                color='secondary'
                size='lg'
              >
                Enviar
              </Button>
            </Form>
          </Col>
        </Row>
        <Modal isOpen={modal} toggle={toggle}>
          <ModalHeader toggle={toggle}></ModalHeader>
          <ModalBody>
            <CreditCards handleSubmit={handleSubmitModalCartao} />
          </ModalBody>
        </Modal>
      </Container>
    </>
  );
}

export default CartaoEscolhido;
