import {
  Container,
  Col,
  Row,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Button,
  CardImg,
} from 'reactstrap';

import { FaSearch, FaGlobe, FaWhatsapp } from 'react-icons/fa';
import { GrFacebook, GrInstagram, GrLinkedin, GrMail } from 'react-icons/gr';
import useFetch from '../../hooks/useFetch';
import api from '../../services/api';
import Placeholder from './PlaceholderParceiro';
import { Link, useHistory, useParams } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import segmentos from '../../utils/segmentos';
import { useEffect, useRef, useState } from 'react';
import Carousel from 'react-elastic-carousel';
import ReactHtmlParser from 'react-html-parser';
import iconeFiltro from '../../assets/icone_filtro.png';
import estados from '../../utils/estadosBrasileiros';
import cidades from '../../utils/cidadesBrasileiras';
import { useAuth } from '../../contexts/auth';

import formatPhone from '../../utils/formatPhone';

import { useWhiteLabel } from '../../contexts/whitelabel';

function SegmentosParceiro() {
  const { signed } = useAuth();
  const history = useHistory();
  const params = useParams();
  const searchRef = useRef(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [estado, setEstado] = useState(null);
  const [cidade, setCiade] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  const { dadosWhiteLabel } = useWhiteLabel();

  const { data, mutate } = useFetch(
    `/${Object.entries(params)[1][0]}s/${Object.entries(params)[1][1]}?wl=${
      dadosWhiteLabel?.id || ''
    }`
  );

  let consultaGenerica;

  async function buscarParceiros(value) {
    return await new Promise((resolve) => {
      clearTimeout(consultaGenerica);

      consultaGenerica = setTimeout(() => {
        resolve(filtrarConsulta(value));
      }, 300);
    });
  }

  async function filtrarConsulta(value) {
    const query = `nome=${value || ''}&page=1&estado=${estado || ''}&cidade=${
      cidade || ''
    }`;

    const { data: res } = await api.get(
      `/${Object.entries(params)[1][0]}s/${
        Object.entries(params)[1][1]
      }?${query}&wl=${dadosWhiteLabel?.id || ''}`
    );
    mutate(res, false);
    setCurrentPage(1);
  }

  async function consultarParceiros() {
    const nome = searchRef.current.value;
    const query = `nome=${nome || ''}&page=${currentPage + 1}&estado=${
      estado || ''
    }&cidade=${cidade || ''}`;

    const { data: res } = await api.get(
      `/${Object.entries(params)[1][0]}s/${
        Object.entries(params)[1][1]
      }?${query}&wl=${dadosWhiteLabel?.id || ''}`
    );
    if (res) {
      mutate(
        {
          ...data,
          parceiros: data.parceiros.concat(res.parceiros),
        },
        false
      );
    }
    setCurrentPage(currentPage + 1);
  }

  const showMap = ({ coords }) => {
    localStorage.setItem(
      'coords',
      JSON.stringify({
        latitude: coords.latitude,
        longitude: coords.longitude,
      })
    );

    history.push(`/poupa-club/proximos-de-mim`);
  };

  const getLocation = () => navigator.geolocation.getCurrentPosition(showMap);

  useEffect(() => {
    if (isLoaded) {
      filtrarConsulta(searchRef?.current?.value);
    } else {
      setIsLoaded(true);
    }
  }, [estado, cidade]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <main>
      {signed ? (
        <Container>
          <Row className='mt-3 align-flex-end'>
            {/* <Col lg={1} className="mb-3">
              <div className="d-flex align-center">
                <span className="d-flex align-center txt-tertiary">
                  <img
                    src={iconeFiltro}
                    alt="icone de filtros"
                    className="mr-1"
                    style={{
                      maxHeight: "34px",
                    }}
                  />
                  <h6
                    style={{
                      marginBottom: 0,
                    }}
                  >
                    Filtros
                  </h6>
                </span>
              </div>
            </Col> */}
            <Col lg={2} className='mb-3'>
              <Input
                type='select'
                className='input-field-select'
                onChange={(e) => {
                  if (e.target.value) {
                    history.push(
                      `/${
                        dadosWhiteLabel?.url_whitelabel || 'poupa-club'
                      }/parceiros/segmento/${e.target.value}`
                    );
                  } else {
                    history.push(
                      `/${
                        dadosWhiteLabel?.url_whitelabel || 'poupa-club'
                      }/parceiros`
                    );
                  }
                }}
              >
                <option value=''>Segmento</option>
                {segmentos.map((segmento) => (
                  <option
                    value={segmento.url}
                    selected={window.location.pathname.includes(segmento.url)}
                  >
                    {segmento.nome}
                  </option>
                ))}
              </Input>
            </Col>
            <Col lg={3} className='mb-3'>
              <InputGroup>
                <InputGroupAddon addonType='prepend'>
                  <InputGroupText
                    className={
                      dadosWhiteLabel
                        ? 'access-icon-generic mb-0'
                        : 'access-icon mb-0'
                    }
                  >
                    <FaSearch
                      className={
                        dadosWhiteLabel ? 'icon-color-generic' : 'icon-color'
                      }
                    />
                  </InputGroupText>
                </InputGroupAddon>
                <input
                  placeholder='Busque pelo nome do parceiro'
                  type='text'
                  className={
                    dadosWhiteLabel
                      ? 'access-input-generic font-400 form-control'
                      : 'access-input font-400 form-control'
                  }
                  ref={searchRef}
                  onChange={(e) => buscarParceiros(e.target.value)}
                />
              </InputGroup>
            </Col>
            <Col lg={2} className='mb-3'>
              <Input
                type='select'
                className='input-field-select'
                onChange={(e) => {
                  setEstado(e.target.value);
                  setCiade(null);
                }}
              >
                <option value='' selected>
                  Estado
                </option>
                {estados.map((estado) => (
                  <option value={estado.value}>{estado.label}</option>
                ))}
              </Input>
            </Col>
            <Col lg={2} className='mb-3'>
              <Input
                type='select'
                className='input-field-select'
                onChange={(e) => setCiade(e.target.value)}
              >
                <option value='' selected>
                  Cidade
                </option>
                {estado &&
                  cidades
                    .find((f) => f.estado === estado)
                    ?.cidades?.map((cidade) => (
                      <option value={cidade}>{cidade}</option>
                    ))}
              </Input>
            </Col>
            <Col lg={3} className='mb-3' block>
              <Button
                color='outline-secondary btn-pill'
                className={`btn-pill bold ${
                  dadosWhiteLabel?.whitelabel_button_1 &&
                  dadosWhiteLabel?.whitelabel_button_1
                }`}
                onClick={getLocation}
              >
                Parceiros próximos a mim
              </Button>
            </Col>
          </Row>

          <Row className='mb-30 busca-segmento'>
            <Col lg={12} className='d-flex align-center'>
              <h4
                className='bold txt-primary'
                style={{
                  color: dadosWhiteLabel
                    ? dadosWhiteLabel?.whitelabel_text_color_1
                    : '',
                }}
              >
                {data?.tipo || ''}
              </h4>
              <div className='hr' />
            </Col>
            <Col lg={10} md={12} className='offset-lg-1 offset-md-0'>
              <Col
                lg={12}
                md={12}
                sm={12}
                className='offset-lg-4 offset-md-5 offset-sm-5 sm-hidden mt-3'
              >
                <h4
                  className='bold txt-primary'
                  style={{
                    color: dadosWhiteLabel
                      ? dadosWhiteLabel?.whitelabel_text_color_1
                      : '',
                  }}
                >
                  Unidades
                </h4>
              </Col>
            </Col>
            <Col lg={10} md={12} className='offset-lg-1 offset-md-0 mt-3'>
              {data ? (
                <>
                  <InfiniteScroll
                    dataLength={data?.parceiros?.length} //This is important field to render the next data
                    next={consultarParceiros}
                    hasMore={data?.pages > currentPage}
                    loader={<Placeholder />}
                  >
                    {data?.parceiros?.map((m, i) => (
                      <>
                        <Row className='mt-60 mb-30' key={i}>
                          <Col lg={5} sm={12}>
                            <h5 className='txt-tertiary bold text-center mb-3'>
                              {m.descricao}
                            </h5>
                            <Carousel itemsToShow={1}>
                              {m.imagem?.url && (
                                <div className='img-parceiro-segmento align-end'>
                                  <span>
                                    <CardImg
                                      src={m.imagem.url}
                                      alt={m.nome_fantasia}
                                    />
                                  </span>
                                </div>
                              )}
                              {m.imagem_parceiro_1_id?.url && (
                                <div className='img-parceiro-segmento align-end'>
                                  <span>
                                    <CardImg
                                      src={m.imagem_parceiro_1_id?.url}
                                      alt={m.nome_fantasia}
                                    />
                                  </span>
                                </div>
                              )}
                              {m.imagem_parceiro_2_id?.url && (
                                <div className='img-parceiro-segmento align-end'>
                                  <span>
                                    <CardImg
                                      src={m.imagem_parceiro_2_id?.url}
                                      alt={m.nome_fantasia}
                                    />
                                  </span>
                                </div>
                              )}
                              {m.imagem_parceiro_3_id?.url && (
                                <div className='img-parceiro-segmento align-end'>
                                  <span>
                                    <CardImg
                                      src={m.imagem_parceiro_3_id?.url}
                                      alt={m.nome_fantasia}
                                    />
                                  </span>
                                </div>
                              )}
                            </Carousel>
                            <hr />
                            <Row>
                              <Col lg={12} md={12} sm={12}>
                                <h5
                                  className='txt-secondary bold'
                                  style={{
                                    color: dadosWhiteLabel
                                      ? dadosWhiteLabel?.whitelabel_text_color_2
                                      : '',
                                  }}
                                >
                                  Contatos
                                </h5>
                                <h6 className='txt-tertiary'>
                                  {m.website && (
                                    <>
                                      {' '}
                                      <strong>
                                        <FaGlobe />{' '}
                                      </strong>
                                      <a
                                        href={m.website}
                                        target='_blank'
                                        rel='noreferrer'
                                        style={{ color: 'gray' }}
                                      >
                                        {m.website}
                                      </a>
                                      <br />{' '}
                                    </>
                                  )}
                                  {(Number(m.telefone_comercial) > 0 ||
                                    Number(m.telefone_celular) > 0) && (
                                    <>
                                      <strong>
                                        <FaWhatsapp />{' '}
                                      </strong>
                                      {Number(m.telefone_celular) > 0 && (
                                        <a
                                          href={`https://api.whatsapp.com/send?phone=+55${m.telefone_celular}`}
                                          rel='noreferrer'
                                          target='_blank'
                                          style={{ color: 'gray' }}
                                        >
                                          {formatPhone(m.telefone_celular)}
                                        </a>
                                      )}
                                      {Number(m.telefone_comercial) > 0 &&
                                        Number(m.telefone_celular) > 0 && (
                                          <> / </>
                                        )}
                                      {Number(m.telefone_comercial) > 0 &&
                                        formatPhone(m.telefone_comercial)}{' '}
                                      <br />
                                    </>
                                  )}
                                  {m.email && (
                                    <>
                                      <strong>
                                        <GrMail />{' '}
                                      </strong>
                                      {m.email}
                                      <br />
                                    </>
                                  )}
                                  <div className='mt-3'>
                                    {m.facebook && (
                                      <>
                                        <a
                                          href={m.facebook}
                                          target='_blank'
                                          rel='noreferrer'
                                          style={{
                                            color: 'gray',
                                            textDecoration: 'none',
                                          }}
                                        >
                                          <strong>
                                            <GrFacebook />
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                          </strong>
                                        </a>
                                      </>
                                    )}
                                    {m.instagram && (
                                      <>
                                        <a
                                          href={m.instagram}
                                          target='_blank'
                                          rel='noreferrer'
                                          style={{
                                            color: 'gray',
                                            textDecoration: 'none',
                                          }}
                                        >
                                          <strong>
                                            <GrInstagram />
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                          </strong>
                                        </a>
                                      </>
                                    )}
                                    {m.linkedin && (
                                      <>
                                        <a
                                          href={m.linkedin}
                                          target='_blank'
                                          rel='noreferrer'
                                          style={{
                                            color: 'gray',
                                            textDecoration: 'none',
                                          }}
                                        >
                                          <strong>
                                            <GrLinkedin />
                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                          </strong>
                                        </a>
                                      </>
                                    )}
                                  </div>
                                </h6>
                              </Col>
                            </Row>
                            {m?.cep && (
                              <Row>
                                <Col lg={12} md={12} sm={12}>
                                  <h5
                                    className='txt-secondary bold mt-3'
                                    style={{
                                      color: dadosWhiteLabel
                                        ? dadosWhiteLabel?.whitelabel_text_color_2
                                        : '',
                                    }}
                                  >
                                    Localização
                                  </h5>
                                  <h6 className='txt-tertiary'>
                                    {m.logradouro}, {m.numero} {m.complemento}
                                    <br /> {m.bairro} - {m.cidade} - {m.estado}
                                    <br /> CEP: {m.cep}
                                  </h6>
                                </Col>
                              </Row>
                            )}
                          </Col>

                          <Col lg={7} md={7} sm={12}>
                            <div className='mb-1 esconto-parceiro'>
                              <h5
                                className='txt-secondary bold text-center'
                                style={{
                                  color: dadosWhiteLabel
                                    ? dadosWhiteLabel?.whitelabel_text_color_2
                                    : '',
                                }}
                              >
                                {m.subtitulo}
                              </h5>
                            </div>
                            <div
                              className={`mb-5 desconto-parceiro`}
                              style={{
                                backgroundColor:
                                  dadosWhiteLabel?.whitelabel_footer_background,
                                color:
                                  dadosWhiteLabel?.whitelabel_footer_text_color,
                              }}
                            >
                              <h5
                                className='txt-secondary bold text-center'
                                style={{
                                  color: dadosWhiteLabel
                                    ? dadosWhiteLabel?.whitelabel_footer_text_color
                                    : '',
                                }}
                              >
                                {m.beneficio}
                              </h5>
                            </div>
                            <h5 className='txt-tertiary bold text-center mt-3'>
                              Sobre o estabelecimento
                            </h5>
                            <h6 className='txt-tertiary mb-5'>
                              {ReactHtmlParser(m?.sobre_estabelecimento)}
                            </h6>
                            <h5 className='txt-tertiary bold text-center mt-3'>
                              Regras de utilização
                            </h5>
                            <h6 className='txt-tertiary mb-5'>
                              {ReactHtmlParser(m?.regras_utilizacao)}
                            </h6>
                          </Col>
                        </Row>
                        <hr />
                      </>
                    ))}
                  </InfiniteScroll>
                </>
              ) : (
                <Placeholder />
              )}
            </Col>
          </Row>
        </Container>
      ) : (
        <div className='mt-5'>
          <Container className='w-100 d-flex justify-content-center'>
            <Row>
              <Col lg={12} md={12} sm={12}>
                <h5
                  className='bold txt-tertiary'
                  style={{
                    color: dadosWhiteLabel
                      ? dadosWhiteLabel?.whitelabel_text_color_1
                      : '',
                  }}
                >
                  Para acessar as informações dos parceiros, faça seu{' '}
                  <Button
                    color='secondary'
                    size='sm'
                    className={`btn-pill bold ${
                      dadosWhiteLabel?.whitelabel_button_1 &&
                      dadosWhiteLabel?.whitelabel_button_1
                    }`}
                    onClick={() => {
                      window.scrollTo(0, 0);
                      history.push(
                        `/${
                          dadosWhiteLabel?.url_whitelabel || 'poupa-club'
                        }/login`
                      );
                    }}
                  >
                    login
                  </Button>
                </h5>
              </Col>
            </Row>
          </Container>
          <Container className='w-100 d-flex justify-content-center'>
            <Row>
              <Col>
                <h5
                  className='bold txt-secondary'
                  style={{
                    color: dadosWhiteLabel
                      ? dadosWhiteLabel?.whitelabel_text_color_2
                      : '',
                  }}
                >
                  ou
                </h5>
              </Col>
            </Row>
          </Container>
          <Container className='w-100 d-flex justify-content-center'>
            <Row>
              <Col>
                <h5
                  className='bold txt-tertiary'
                  style={{
                    color: dadosWhiteLabel
                      ? dadosWhiteLabel?.whitelabel_text_color_1
                      : '',
                  }}
                >
                  <Button
                    color='secondary'
                    size='sm'
                    className={`btn-pill bold ${
                      dadosWhiteLabel?.whitelabel_button_1 &&
                      dadosWhiteLabel?.whitelabel_button_1
                    }`}
                    onClick={() => {
                      window.scrollTo(0, 0);
                      history.push(
                        `/${
                          dadosWhiteLabel?.url_whitelabel || 'poupa-club'
                        }/cartao`
                      );
                    }}
                  >
                    Peça agora
                  </Button>{' '}
                  seu cartão e comece a poupar
                </h5>
              </Col>
            </Row>
          </Container>
        </div>
      )}
    </main>
  );
}

export default SegmentosParceiro;
