import React, { useState, useEffect } from "react";
import {
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Row,
  Table,
  UncontrolledButtonDropdown,
  UncontrolledCollapse,
  Button,
} from "reactstrap";
import Swal from "sweetalert2";
import Pagination from "react-paginate";
import { FaChevronLeft, FaChevronRight, FaEllipsisH } from "react-icons/fa";
import { FiFilter } from "react-icons/fi";
import { Link } from "react-router-dom";
import { GrTrash, GrEdit } from "react-icons/gr";

import AsyncSelect from "../../../components/AsyncSelect";

import api from "../../../services/api";

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: "ml-3 btn btn-success",
    cancelButton: "btn btn-danger",
  },
  buttonsStyling: false,
});

function ConsultarMarca() {
  let timeoutConsulta;

  const [filtro, setFiltro] = useState(null);

  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);

  useEffect(() => {
    async function getData() {
      Swal.fire({
        title: "Aguarde",
        allowOutsideClick: false,
        showConfirmButton: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      if (!filtro) {
        const { data: response } = await api.get(
          `/apenas-marcas?page=${page + 1}`
        );
        setData(response);
      } else {
        const { data: response } = await api.get(
          `/apenas-marcas?id=${filtro.value}`
        );
        setData(response);
      }
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 400);
      Swal.close();
    }
    getData();
  }, [page, filtro]);

  const debounceConsulta = async (body) => {
    return await new Promise((resolve) => {
      clearTimeout(timeoutConsulta);

      timeoutConsulta = setTimeout(() => {
        resolve(filtrarConsulta(body));
      }, 500);
    });
  };

  async function filtrarConsulta({ value, param }) {
    const result = await api.get(`options/marca-parceiros?${param}=${value}`);
    return result.data.map((marcaParceiro) => ({
      value: marcaParceiro.id,
      nome: marcaParceiro.nome,
    }));
  }

  async function handleDelete(id) {
    swalWithBootstrapButtons
      .fire({
        title: `Tem certeza que deseja excluir esta marca?`,
        icon: "warning",
        text: "Esta operação não poderá ser revertida!",
        showCancelButton: true,
        confirmButtonText: "Sim",
        cancelButtonText: "Não",
        reverseButtons: true,
      })
      .then((result) => {
        if (result.value) {
          Swal.fire({
            title: "Aguarde",
            allowOutsideClick: false,
            showConfirmButton: false,
            onBeforeOpen: () => {
              Swal.showLoading();
            },
          });

          api
            .delete(`/marca/${id}`)
            .then(() => {
              Swal.close();
              setData({
                ...data,
                "data.marcas": data?.marcas?.filter((f) => f.id !== id),
              });
            })
            .catch((error) => {
              const msg =
                error?.response?.data?.error ||
                "Ocorreu um erro, tente novamente mais tarde!";

              swalWithBootstrapButtons.fire("Ops...", msg, "error");
            });
        }
      });
  }

  return (
    <>
      <Row>
        <Col lg={12}>
          <h5 className="txt-tertiary mb-3">Consultar marca</h5>
        </Col>
      </Row>

      <div className="card-form  p-0">
        <div className="p-3 justify-content-between d-flex align-center">
          <p className="txt-muted">Total de registros: {data?.total || 0}</p>
          <Button color="muted" id="toggler">
            <FiFilter /> Filtros
          </Button>
          <div />
          <Link
            to={`/acesso/marca`}
            color="green"
            className="btn-save btn btn-green"
          >
            Adicionar
          </Link>
        </div>
        <div className="filter-collapse">
          <UncontrolledCollapse toggler="#toggler">
            <Row className="mt-3">
              <Col lg={4} sm={6} className="mb-1">
                <AsyncSelect
                  loadOptions={(value) =>
                    debounceConsulta({
                      value,
                      param: "nome",
                    })
                  }
                  getOptionLabel={(option) => option.nome}
                  onChange={setFiltro}
                  cacheOptions
                  isClearable
                  value={filtro}
                  placeholder={"Busque pelo nome"}
                  noOptionsMessage={() => "Digite o nome"}
                />
              </Col>
            </Row>
          </UncontrolledCollapse>
        </div>
        <Row>
          <Col lg={12}>
            <Table responsive>
              <thead>
                <tr>
                  <th>Nome</th>
                  <th className="text-center">Relevância</th>
                  <th>Segmento</th>
                  <th className="text-center">Ações</th>
                </tr>
              </thead>
              <tbody>
                {data?.marcas?.map((marca) => (
                  <tr key={marca?.id}>
                    <td>{marca?.nome}</td>
                    <td className="text-center">{marca?.relevancia}</td>
                    <td>
                      <p>{marca?.segmento?.nome}</p>
                    </td>
                    <td className="text-center">
                      <UncontrolledButtonDropdown size="xs">
                        <DropdownToggle color="link link-gray" size="xs">
                          <FaEllipsisH />
                        </DropdownToggle>

                        <DropdownMenu right>
                          <DropdownItem className="justify-start">
                            <Link
                              to={`/acesso/marca-editar/${marca?.id}`}
                              style={{
                                textDecoration: "none",
                                color: "#16181B",
                              }}
                            >
                              <GrEdit />
                              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Editar
                            </Link>
                          </DropdownItem>
                          <DropdownItem
                            className="justify-start"
                            onClick={() => handleDelete(marca?.id)}
                          >
                            <GrTrash />
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Excluir
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledButtonDropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            <Pagination
              nextLabel={<FaChevronRight />}
              previousLabel={<FaChevronLeft />}
              pageCount={Number(data?.pages) || 1}
              onPageChange={({ selected }) => setPage(selected)}
              initialPage={page}
              containerClassName={"pagination justify-end"}
              activeClassName={"active"}
            />
          </Col>
        </Row>
      </div>
    </>
  );
}

export default ConsultarMarca;
